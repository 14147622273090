import {IOrderStoreModel} from './models';

export default (self: IOrderStoreModel) => {
  return {
    getOrderById(orderId: string) {
      return self.list?.find((order) => orderId === order.id);
    },
    get getOrdersCampaigns() {
      return self.campaigns.map((campaign) => ({label: campaign.id, value: campaign.id}));
    },
  };
};
