import React, {useMemo} from 'react'
import './styles.scss'
import SvgArrow from "@components/Icons/SvgArrow";

interface IPagination {
  activePage: number
  itemsCountPerPage?: number
  totalItemsCount: number
  onChange: (newPageNumber: number) => void
}

/**
 * Composant pour la pagination des ContractRow
 * @param activePage
 * @param itemsCountPerPage
 * @param totalItemsCount
 * @param onChange
 * @constructor
 */
const Pagination: React.FC<IPagination> = ({ activePage, itemsCountPerPage = 10, totalItemsCount, onChange }) => {

  const numberOfPages = useMemo(() => {
    return Math.ceil(totalItemsCount / itemsCountPerPage)
  }, [totalItemsCount, itemsCountPerPage])

  const renderPaginationButton = (isNext = true) => {
    const isDisabled = isNext ? activePage >= numberOfPages : activePage <= 1

    const onClick = () => {
      if (!isDisabled) {
        const newPage = isNext ? activePage + 1 : activePage - 1
        onChange(newPage)
        window.scrollTo({
          top: 0,
        });
      }
    }

    return (
        <li
            className={`list-none ${isDisabled ? 'stroke-grey-500' : 'stroke-darkGreen cursor-pointer'}`}
            onClick={onClick}
        >
          <SvgArrow direction={isNext ? 'right' : 'left'}></SvgArrow>
        </li>
    )
  }

  const renderPageIndexButton = (index: number) => {
    const isCurrentPage = activePage === index

    const onClick = () => {
      if (!isCurrentPage) {
        onChange(index)
        window.scrollTo({
          top: 0,
        });
      }
    }

    return (
      <div onClick={onClick}
           className=
               {`w-6 h-7 p-1 border rounded border-grey-300 font-500 text-lg text-center
               ${isCurrentPage ? 'bg-white text-green-100 cursor-default'
                   : 'bg-grey-100 text-grey-500 cursor-pointer'}`}
           key={index}>
        {index.toString()}
      </div>
    )
  }

  // On affiche la page 1, les deux pages avant la page active, les deux pages après la page active et la dernière page
  // Si ces 3 écarts ne se suivent pas, on met "..." entre eux
  const pageIndexButtons = useMemo(() => {
    const result = []

    if (numberOfPages < 6) {
      for (let i = 1; i < numberOfPages + 1; i++) {
        result.push(renderPageIndexButton(i))
      }
    } else {
      // Début
      const indexesToDisplay = []
      indexesToDisplay.push(1)

      let startIndex = activePage - 2

      if (startIndex <= 1) {
        startIndex = 2
      }

      let endIndex = activePage + 2

      // S'il y a un ou des nombres entre la page 1 et l'interval, on affiche "...""
      if (startIndex !== 2) {
        indexesToDisplay.push(null)
      }

      // Milieu
      for (let i = startIndex; i <= endIndex && i < numberOfPages; i++) {
        indexesToDisplay.push(i)
      }

      // S'il y a un ou des nombres entre la page active + 2 et la dernière page, on affiche "..."
      if (endIndex <= numberOfPages - 1) {
        indexesToDisplay.push(null)
      }

      // Fin
      indexesToDisplay.push(numberOfPages)

      indexesToDisplay.forEach((index, i) => {
        if (index !== null) {
          result.push(renderPageIndexButton(index))
        } else {
          result.push(
            <div className="self-center text-grey-600 cursor-default" key={`${i}-int`}>
              ...
            </div>
          )
        }
      })
    }

    return result
  }, [activePage, numberOfPages])

  return (
    <>
      {numberOfPages > 1 &&
        <div className="mt-10 mt-10Mobile flex items-center justify-between md:justify-center gap-1 md:gap-24">
          {renderPaginationButton(false)}
          <div className="flex gap-2.5">{pageIndexButtons}</div>
          {renderPaginationButton(true)}
        </div>
      }

    </>
  )
}

export default Pagination
