import React, { useMemo } from 'react'
import { formatPhoneNumber } from '@utils/string'

interface ITextInput extends React.HTMLProps<HTMLInputElement> {
  icon?: string
  error?: boolean
  setValue: (newValue: any) => void
  step?: number
}

// List of types which needs to be formatted
const typesToFormat = ['tel']

const TextInput: React.FC<ITextInput> = ({ error, icon, setValue, step = 1, ...inputProps }) => {
  const { type } = inputProps
  const inputClassName = `forms${icon ? '--icon' : ''}${type === 'number' ? '-numbers' : ''}${error ? ' error' : ''}`

  const isTypeToFormat = typesToFormat.includes(type ? type : 'text')

  // If we need to format the value after typing for certain types
  const formattedValue = useMemo(() => {
    if (type === 'tel') {
      return inputProps.value ? formatPhoneNumber(inputProps.value as string) : ''
    } else {
      return inputProps.value
    }
  }, [inputProps.value])

  const value = isTypeToFormat ? formattedValue : inputProps.value

  const onPlusClick = () => {
    setValue((inputProps.value as number) + step)
  }

  const onMinusClick = () => {
    setValue((inputProps.value as number) - step)
  }

  return (
    <div className={`containerFormsInput`}>
      <input value={value} {...inputProps} type={type} className={inputClassName} />
      {type === 'number' && (
        <ul className="forms-numbersArrows">
          <li className="forms-numbersUp lnr lnr-chevron-up" onClick={onPlusClick}></li>
          <li className="forms-numbersDown lnr lnr-chevron-down" onClick={onMinusClick}></li>
        </ul>
      )}
      {icon && <label htmlFor={inputProps.id} className={`icon icon-${icon}`}></label>}
    </div>
  )
}

export default TextInput
