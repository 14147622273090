import {isString} from '@lepicard/utils';

export const roundDecimal = (number: number, decimals = 2) => Math.round(number * 10 ** decimals) / 10 ** decimals;

export const negateNumber = (number: number) => 0 - number;

export function formatNumberWithSpaces(x: number | string): string {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

// Monad style 🐎

type FromAll<T extends number | string> = {
  spaces: () => ReturnType<typeof formatNumber<string>>;
  decimalComma: () => ReturnType<typeof formatNumber<string>>;
  render: () => T;
  renderWithUnit: (unit: string) => string;
};

type FromString = {};

type FromNumber = {
  negate: () => ReturnType<typeof formatNumber<number>>;
  fixed: () => ReturnType<typeof formatNumber<string>>;
  round: (decimals?: number) => ReturnType<typeof formatNumber<number>>;
};

export function formatNumber<T extends number | string = number>(
  rootVal: T
): T extends number ? FromAll<T> & FromNumber : FromAll<T> & FromString {
  let wrapper = {};

  const silentlyFailFn = () => {
    console.error(new Error(`function in formatNumber has been called but value isn't supported : ${rootVal}`));
    return formatNumber(rootVal);
  };

  const silentlyFail: Record<keyof FromString & keyof FromNumber, ReturnType<typeof formatNumber<any>>> = {
    negate: silentlyFailFn,
    fixed: silentlyFailFn,
    round: silentlyFailFn,
  };

  if (isString(rootVal)) {
    wrapper = {
      ...wrapper,
    };
  }

  if (typeof rootVal === 'number') {
    wrapper = {
      ...wrapper,
      negate: () => formatNumber(negateNumber(rootVal)),
      fixed: () => formatNumber(rootVal.toFixed(2)),
      round: (decimals = 2) => formatNumber(roundDecimal(rootVal, decimals)),
    };
  }

  // @ts-ignore
  return {
    ...silentlyFail,
    ...wrapper,
    spaces: () => formatNumber(formatNumberWithSpaces(rootVal)),
    decimalComma: () => formatNumber(rootVal.toString().replace('.', ',')),
    render: () => rootVal,
    renderWithUnit: (unit: string) => rootVal.toString() + `\u00A0${unit}`,
  };
}

export function preFormattedQuantity(value: number) {
  return formatNumber(value).round().spaces();
}
