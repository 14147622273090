import React from 'react';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import {formatDate} from '@utils/string';
import Tippy from '@tippyjs/react';

interface IProps {
  dueDate: string;
  total: number;
}

const DueDateIndicator: React.FC<IProps> = ({dueDate, total}) => {
  const today = dayjs();
  const due = dayjs(dueDate);
  const diff = due.diff(today, 'day');
  const formattedDate = formatDate(dueDate, false);

  let colorClass = '';
  let tooltipText = '';

  if (diff < 0) {
    colorClass = 'text-red';
    tooltipText = `Facture échue depuis le ${formattedDate}`;
  } else if (diff <= 30) {
    colorClass = 'text-yellow';
    tooltipText = `Échéance de la facture le ${formattedDate}`;
  }

  return (
    <span className={colorClass}>
      <Tippy offset={[0, 5]} content={<span className={colorClass}>{tooltipText}</span>} placement="bottom">
        <button>
          <FontAwesomeIcon icon={faCircleExclamation} /> {formattedDate}
        </button>
      </Tippy>
    </span>
  );
};

export default DueDateIndicator;
