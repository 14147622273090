import React, {useMemo} from 'react';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {formatDate} from '@utils/string';
import OrdersStatusTag, {OrdersStatusEnum} from '@components/Orders/OrdersStatusTag';
import {CardRowCountWrapper, CardRowCountWrapperItem} from '@components/UI/Card/CardRowCountWrapper';
import {isDef} from '@lepicard/utils';
import {formatNumber} from '@utils/number';
import {IApproDeliveriesRow} from '@components/ApproDeliveries/ApproDeliveriesRows/ApproDeliveriesRows';

interface IOrdersSubRow {
  product: any;
}

const SubRow: React.FC<IOrdersSubRow> = ({product}) => {
  const quantityLabel = formatNumber(product.quantity as number)
    .round()
    .renderWithUnit(product.unitOfMeasureCode);

  return (
    <CardRow type={CardRowType.SUB}>
      <div className="lg:flex lg:justify-between lg:items-end">
        <div className="mb-3 lg:mb-0">
          <CardSubTitle title={product.description} subTitle={product.familyName} />
        </div>
        <CardInfo
          label="Quantité"
          value={quantityLabel}
          flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.END_OF_LINE]}
        />
      </div>
    </CardRow>
  );
};

const SubRowReturn: React.FC<IOrdersSubRow> = ({product}) => {
  return (
    <CardRow type={CardRowType.SUB}>
      <div className="lg:flex lg:justify-between lg:items-end">
        <div className="mb-3 lg:mb-0">
          <CardSubTitle title={product.description} subTitle={product.familyName} />
        </div>
        <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
      </div>
    </CardRow>
  );
};

export const ApproDeliveriesRowByDelivery: React.FC<IApproDeliveriesRow> = ({data}: any) => {
  const allSubRows = useMemo<CardRowCountWrapperItem[]>(
    () => [
      ...data.products.map((p: any) => ({
        params: {product: p},
        key: p.uniqueId,
        render: SubRow,
      })),
      ...data.returnedProducts.map((p: any) => ({
        params: {product: p},
        key: p.uniqueId,
        render: SubRowReturn,
      })),
    ],
    [data.products, data.returnedProducts]
  );

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={`LIVRAISON ${data.no}`} />
          {isDef(data.letter) && <OrdersStatusTag status={OrdersStatusEnum.BILLED} />}
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfo label="Commande lié" value={data.linkedOrder} />
          {data.linkedContracts.length > 0 && <CardInfo label="Contrat lié" value={data.linkedContracts.join(', ')} />}
          <CardInfo label="Date de livraison" value={formatDate(data.shipmentDate)} />
        </CardInfosWrapper>
      </CardRow>
      <CardRowCountWrapper items={allSubRows} />
    </Card>
  );
};
