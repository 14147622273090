import './style.scss';
import React from 'react';
import {observer} from 'mobx-react-lite';
import ProfileCard from '@components/Profile/ProfileCard';
import {useStore} from '@stores/index';
import PageHead from '@components/UI/PageHead/PageHead';

const Profile: React.FC = observer(() => {
  const {sso: SSOStore} = useStore();
  const {selectedExtranetUser: currentAccount} = SSOStore;

  return (
    currentAccount && (
      <div className="Profile w-full flex-1">
        <PageHead title="Mon profil" />
        <ProfileCard />
      </div>
    )
  );
});

export default Profile;
