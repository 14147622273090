import React, {useState} from 'react';
import {toast} from 'react-toastify';
import FileSaver from 'file-saver';
import {formatDate} from '@utils/string';
import {defaultErrorToast} from '@utils/toast';
import {toaster} from '@utils/dictionnary';
import {isUndefined} from '@lepicard/utils';

export interface ICsvColumn {
  id: string;
  Header: string;

  accessor?: string;
  render?: (column: ICsvColumn, value?: any, otherAccessorValues?: Record<string, any>) => React.ReactNode;
  handleRender?: (mainData?: unknown, secondaryData?: unknown) => React.ReactNode;
  isDate?: boolean;
  othersAccessor?: string[];
  subAccessor?: string;
}

interface ICsvSettings {
  fileName: string;
  loadDatas?: () => Promise<any[]>;
  datas?: any[];
  headerValue: object;
}

const useCsvGenerator = (columns: ICsvColumn[]) => {
  const [isPending, setIsPending] = useState(false);

  const download = async ({loadDatas, datas, fileName, headerValue}: ICsvSettings) => {
    setIsPending(true);

    try {
      const downloadToaster = toast.info('Téléchargement en cours', {
        autoClose: false,
        hideProgressBar: true,
      });
      const result = datas ? datas : loadDatas ? await loadDatas() : [];

      // HeaderTable
      const headerRow = columns
        .map((column) => {
          return `${column.Header}`;
        })
        .join(';');

      // Body

      const subValue = (data) => {
        return (
          (data.lines && data.lines.length > 0 && data.lines.filter((line) => line.familyName)) ||
          (data.products && data.products.length > 0 && data.products) ||
          null
        );
      };

      const rows = result.flatMap((data) => {
        const subData = subValue(data);
        if (subData) {
          return subData.map((line) =>
            columns
              .map((column) => {
                const {accessor, subAccessor, render, isDate, othersAccessor, handleRender} = column;

                if (!isUndefined(handleRender)) {
                  return handleRender(line, data);
                }

                let value = accessor ? (subAccessor ? data[accessor][subAccessor] : data[accessor]) : undefined;
                if (value === undefined || accessor === 'amountIncludingVAT') {
                  value = line[accessor];
                }
                const otherAccessorValues = {};
                if (othersAccessor) {
                  othersAccessor.forEach((accessorKey) => {
                    otherAccessorValues[accessorKey] = data[accessorKey];
                  });
                }
                const cellContent = render
                  ? othersAccessor
                    ? render(column, value, otherAccessorValues)
                    : render(column, value)
                  : isDate
                  ? formatDate(value)
                  : value || typeof value === 'number'
                  ? value
                  : '';
                return `${cellContent}`;
              })
              .join(';')
          );
        } else {
          return columns
            .map((column) => {
              const {accessor, subAccessor, render, isDate, othersAccessor, handleRender} = column;

              if (!isUndefined(handleRender)) {
                return handleRender(data);
              }

              let value = accessor ? (subAccessor ? data[accessor][subAccessor] : data[accessor]) : undefined;
              const otherAccessorValues = {};
              if (othersAccessor) {
                othersAccessor.forEach((accessorKey) => {
                  otherAccessorValues[accessorKey] = data[accessorKey];
                });
              }
              const cellContent = render
                ? othersAccessor
                  ? render(column, value, otherAccessorValues)
                  : render(column, value)
                : isDate
                ? formatDate(value)
                : value || typeof value === 'number'
                ? value
                : '';
              return `${cellContent}`;
            })
            .join(';');
        }
      });

      const csvContent = ['', headerRow, ...rows].join(`\n`);
      const contentType = 'data:text/csv;charset=utf-8';
      const csvBlob = new Blob(['\uFEFF' + csvContent], {type: contentType});

      FileSaver.saveAs(csvBlob, `${fileName}.csv`);
      toast.update(downloadToaster, {
        render: 'Téléchargement réussi',
        type: toast.TYPE.SUCCESS,
        autoClose: 1000,
      });
    } catch (error) {
      console.log('error ?', error);
      defaultErrorToast(toaster.error.csv);
    }

    setIsPending(false);
  };

  return {
    download,
    isPending,
  };
};

export default useCsvGenerator;
