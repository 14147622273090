import { types } from 'mobx-state-tree'
import { ServiceStateModel } from '@stores/models'
import {CampaignsListModel} from "@stores/campaigns/models";


export const billModel = types.model({
    id: types.string,
    postingDate: types.string,
    dueDate: types.string,
})

export const BillLineModel = types.model({
    id: types.string,
    campaignId: types.string,
    amount: types.number,
    vatAmount: types.number,
    postingDate: types.maybeNull(types.string),
    billId: types.string,
    orderContactId: types.maybeNull(types.string),
    orderId: types.maybeNull(types.string),
    orderDescription: types.maybeNull(types.string),
    orderQty: types.maybeNull(types.number),
    shipmentDate: types.maybeNull(types.string),
    orderFamilyCode: types.maybeNull(types.string),
    orderFamilyCodeName: types.maybeNull(types.string),
    billPostingDate: types.maybeNull(types.string),
    description: types.string,
    description2: types.string,
    unitNetPrice: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
    unitOfMeasureCode: types.string,
    bill: billModel
})


export const groupListItemModel = types.model({
    libelle: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    qty: types.maybeNull(types.number),
    unitOfMeasureCode: types.maybeNull(types.string),
    amount: types.number
})


export const GroupListModel = types.model({
    list: types.array(groupListItemModel),
})


export const BillsStoreModel = types.model({
    fetchBillsState: ServiceStateModel,
    fetchCampaignsState:ServiceStateModel,
    list: types.array(BillLineModel),
    groupList: types.maybe(GroupListModel),
    campaigns: types.array(CampaignsListModel)
})

