import {getLabelByValue} from '@utils/pdf';
import {numberToLocaleString} from '@utils/string';
import {renderDateSignOff} from '@utils/tables';
import {formatNumber, preFormattedQuantity} from '@utils/number';
import {ICsvColumn} from '@hooks/useCsvGenerator';

export const csvColumns: ICsvColumn[] = [
  {
    Header: 'Facture N°',
    id: 'no',
    accessor: 'no',
  },
  {
    Header: 'Échéance',
    id: 'dueDate',
    accessor: 'dueDate',
    isDate: true,
    render: (column, value) => {
      return `${renderDateSignOff(value, '')} `;
    },
  },
  {
    id: 'familyName',
    Header: 'Familles',
    accessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    accessor: 'description',
  },
  {
    id: 'amountIncludingVAT',
    Header: 'Montant TTC',
    accessor: 'amountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'totalLedgerAmount',
    Header: 'Restant du ',
    accessor: 'totalLedgerAmount',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'quantity',
    Header: 'Quantité',
    accessor: 'quantity',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'netUnitPrice',
    Header: 'Prix unitaire HT',
    accessor: 'netUnitPrice',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'invoiceStatut',
    Header: 'Statut',
    accessor: 'letter',
    render: (column, value) => {
      return value ? `Soldé` : 'Non soldé';
    },
  },
];

export const pdfCol = [
  {
    Header: 'Facture N°',
    id: 'no',
    accessor: 'no',
  },
  {
    Header: 'Début de contrat',
    id: 'postingDate',
    accessor: 'postingDate',
    isDate: true,
  },
  {
    Header: 'Fin de contrat',
    id: 'dueDate',
    accessor: 'dueDate',
    isDate: true,
    render: (column, value) => {
      return `${renderDateSignOff(value, "Pas de date d'échéance")} `;
    },
  },
  {
    Header: 'Montant TTC',
    id: 'totalAmountIncludingVAT',
    accessor: 'totalAmountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'totalLedgerAmount',
    Header: 'Restant dû',
    accessor: 'totalLedgerAmount',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'invoiceStatut',
    Header: 'Statut',
    accessor: 'letter',
    render: (column, value) => {
      return value ? `Soldé` : 'Non soldé';
    },
  },
];
export const pdfSubCol = [
  {
    id: 'familyName',
    Header: 'Familles',
    subAccessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    subAccessor: 'description',
  },
  {
    id: 'amountIncludingVAT',
    Header: 'Montant TTC',
    subAccessor: 'amountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'netUnitPrice',
    Header: 'Prix unitaire HT',
    subAccessor: 'netUnitPrice',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'quantity',
    Header: 'Quantité',
    handleRender: (product, invoice) => {
      return preFormattedQuantity(product.quantity).renderWithUnit(product.unitOfMeasureCode);
    },
  },
];

export const headerValue = (formValues, groupByOption, store) => {
  return {
    campaignId: formValues.campaignId === 'all' ? 'Tous' : formValues.campaignId,
    groupBy: getLabelByValue(formValues.groupBy, groupByOption),
    // filterBy: formValues.tagsOption === 'in-delivery' ? 'En cours de livraison' : 'Retours',
    statut: getLabelByValue(formValues.status, store.getStatusOptions),
    itemFamilyCode: getLabelByValue(formValues.itemFamilyCode, store.getFamiliesOptions),
    search: formValues.search,
  };
};
