import React, {useMemo} from 'react';
import {useField} from 'formik';
import {cn} from '@utils/class-names';

interface ICheckBox {
  name: string;
  label: string;
  options: {value: string; label: string}[];
  className?: string;
}

const CheckBoxAllSelector: React.FC<ICheckBox> = ({name, label, options, className}) => {
  const [field, meta, helpers] = useField(name);

  const isChecked = useMemo(() => {
    return meta.value.length === options.length;
  }, [meta.value]);

  function toggleAll(): void {
    if (meta.value.length === options.length) {
      helpers.setValue([]);
    } else {
      helpers.setValue(options.map((option) => option.value));
    }
  }

  return (
    <div className={cn('lpa-checkbox cursor-pointer flex items-center mb-3.5', className)}>
      <div
        className="border border-grey-300 lpa-shadow-inner h-7 w-7 cursor-pointer relative rounded"
        onClick={toggleAll}
      >
        {isChecked && <img src="/icons/checkmark.svg" alt="" className="absolute pointer-events-none inset-0" />}
      </div>
      <div className="pl-2.5 cursor-pointer" onClick={toggleAll}>
        <span className="text-green-100 text-interactif">{label}</span>
      </div>
    </div>
  );
};

export default CheckBoxAllSelector;
