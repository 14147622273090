
export default (self: any) => {
    return {
        get getManufacturerList() {

            return self.manufacturerCodeList.filter(code => code != null).map((code) => ({ value: code }))
        },
        get getDescriptionsList() {
            return self.descriptionsList.map(description => ({ value: description }))
        },
        get getCustomerCampaigns() {
          return self.campaigns.map(campaign => ({ value: campaign.id }))
        }

    }
}
