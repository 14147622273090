import React from 'react';
import {cn} from '@utils/class-names';

interface ICardSeparator {
  className?: string;
}

export const CardSeparator: React.FC<ICardSeparator> = ({className}) => {
  return <hr className={cn('hr Card-innerSeparator', className)} />;
};
