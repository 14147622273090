import React, { useMemo } from 'react'
import './styles.scss'

interface IPagination {
  activePage: number
  itemsCountPerPage: number
  totalItemsCount: number
  onChange: (newPageNumber: number) => void
}

const Pagination: React.FC<IPagination> = ({ activePage, itemsCountPerPage, totalItemsCount, onChange }) => {


  const numberOfPages = useMemo(() => {
    return Math.ceil(
      itemsCountPerPage >= totalItemsCount ? 1 : totalItemsCount / itemsCountPerPage
    )
  }, [totalItemsCount, itemsCountPerPage])



  const renderPaginationButton = (isNext = true) => {
    const isDisabled = isNext ? activePage >= numberOfPages : activePage <= 1

    const onClick = () => {
      if (!isDisabled) {
        const newPage = isNext ? activePage + 1 : activePage - 1
        onChange(newPage)
      }
    }

    const arrowBtn = (
      <li
        className={` arrowBtn  arrow-${!isDisabled ? 'primary' : 'grey'} size-large`}
        onClick={onClick}
      >
        <i className={`lnr lnr-arrow-${isNext ? 'right' : 'left'}`}></i>
      </li>
    )

    return arrowBtn
  }

  const renderPageIndexButton = (index: number) => {
    const isCurrentPage = activePage === index

    const onClick = () => {
      if (!isCurrentPage) {
        onChange(index)
      }
    }

    return (
      <div onClick={onClick} className={`pageIndexButton ${isCurrentPage ? 'active' : ''}`} key={index}>
        {index.toString()}
      </div>
    )
  }

  // On affiche la page 1, les deux pages avant la page active, les deux pages après la page active et la dernière page
  // Si ces 3 écarts ne se suivent pas, on met "..." entre eux
  const pageIndexButtons = useMemo(() => {
    const result = []

    if (numberOfPages < 6) {
      for (let i = 1; i < numberOfPages + 1; i++) {
        result.push(renderPageIndexButton(i))
      }
    } else {
      // Début
      const indexesToDisplay = []
      indexesToDisplay.push(1)

      let startIndex = activePage - 2

      if (startIndex <= 1) {
        startIndex = 2
      }

      let endIndex = activePage + 2

      // Si il y a un ou des nombres entre la page 1 et l'interval, on affiche "...""
      if (startIndex !== 2) {
        indexesToDisplay.push(null)
      }

      // Milieu
      for (let i = startIndex; i <= endIndex && i < numberOfPages; i++) {
        indexesToDisplay.push(i)
      }

      // Si il y a un ou des nombres entre la page active + 2 et la dernière page, on affiche "..."
      if (endIndex <= numberOfPages - 1) {
        indexesToDisplay.push(null)
      }

      // Fin
      indexesToDisplay.push(numberOfPages)

      indexesToDisplay.forEach((index, i) => {
        if (index !== null) {
          result.push(renderPageIndexButton(index))
        } else {
          result.push(
            <div className="pageIndexButton hidden-interval" key={`${i}-int`}>
              ...
            </div>
          )
        }
      })
    }

    return result
  }, [activePage, numberOfPages])

  return (
    <>
      {numberOfPages > 1 &&
        <div className="mt-10 mt-10Mobile flex flex-justifyCenter flex-alignCenter">
          <div className="flex mr-10 mr-10Mobile">{pageIndexButtons}</div>
          {renderPaginationButton(false)}
          {renderPaginationButton(true)}
        </div>
      }

    </>
  )
}

export default Pagination
