import {ServiceStateModel} from './../models';
import {types} from 'mobx-state-tree';
import {FacetChoiceModel} from "@stores/shared/models";

const ApproOrdersOrder = types.model({
  id: types.string,
  orderDate: types.string,
  requestedDeliveryDate: types.string,
  invoiceStatut: types.number,
  shipmentStatut: types.number,
  deliveredPercentage: types.number,
  amountIncludingVAT: types.number,
  linkedContracts: types.array(types.string),
  lines: types.array(
    types.union(
      types.model({
        uniqueId: types.string,
        line: types.number,
        familyCode: types.string,
        familyName: types.string,
        description: types.string,
        linkedContract: types.string,
        amountIncludingVAT: types.number,
        quantity: types.number,
        quantityUnit: types.string,
        amount: types.number,
        netUnitPrice: types.number,
        restToDeliver: types.number,
        deliveredPercentage: types.number,
      }),
      types.model({
        uniqueId: types.string,
        description: types.string,
        amountIncludingVAT: types.number,
        type: types.number,
        attachedToLine: types.maybeNull(types.number),
      })
    )
  ),
  returns: types.array(
    types.union(
      types.model({
        cmdId: types.string,
        line: types.number,
        evc: types.string,
        rv: types.string,
        description: types.string,
        quantity: types.number,
        netUnitPrice: types.number,
        amountIncludingVAT: types.number,
        quantityUnit: types.string,
        returnDate: types.string,
        familyCode: types.string,
        familyName: types.string,
        type: types.number,
      }),
      types.model({
        uniqueId: types.string,
        description: types.string,
        amountIncludingVAT: types.number,
        type: types.number,
        attachedToLine: types.maybeNull(types.number),
      })
    )
  ),
});

const ApproOrdersProduct = types.model({
  description: types.string,
  familyCode: types.string,
  familyName: types.string,
  quantityUnit: types.string,
  quantity: types.number,
  quantityShipped: types.number,
  restToDeliver: types.number,
  deliveredPercentage: types.number,
  amountIncludingVAT: types.number,
  orders: types.array(
    types.model({
      orderData: types.model({
        id: types.string,
        orderDate: types.string,
        requestedDeliveryDate: types.string,
        invoiceStatut: types.number,
        shipmentStatut: types.number,
        deliveredPercentage: types.number,
        amountIncludingVAT: types.number,
        linkedContracts: types.array(types.string),
      }),
      productData: types.model({
        netUnitPrice: types.number,
        quantity: types.number,
        quantityUnit: types.string,
        amountIncludingVAT: types.number,
        restToDeliver: types.number,
        deliveredPercentage: types.number,
      }),
    })
  ),
  returnedOrders: types.array(
    types.model({
      cmdId: types.string,
      evc: types.string,
      rv: types.string,
      quantity: types.number,
      netUnitPrice: types.number,
      amountIncludingVAT: types.number,
      quantityUnit: types.string,
      returnDate: types.string,
    })
  ),
});

const ApproOrdersFamily = types.model({
  familyCode: types.string,
  familyName: types.string,
  quantity: types.number,
  quantityShipped: types.number,
  deliveredPercentage: types.number,
  amountIncludingVAT: types.number,
  products: types.array(
    types.model({
      description: types.string,
      familyCode: types.string,
      familyName: types.string,
      quantityUnit: types.string,
      quantity: types.number,
      quantityShipped: types.number,
      restToDeliver: types.number,
      deliveredPercentage: types.number,
      amountIncludingVAT: types.number,
      orders: types.array(
        types.model({
          orderData: types.model({
            id: types.string,
            orderDate: types.string,
            requestedDeliveryDate: types.string,
            invoiceStatut: types.number,
            shipmentStatut: types.number,
            deliveredPercentage: types.number,
            amountIncludingVAT: types.number,
            linkedContracts: types.array(types.string),
          }),
          productData: types.model({
            netUnitPrice: types.number,
            quantity: types.number,
            quantityUnit: types.string,
            amountIncludingVAT: types.number,
            restToDeliver: types.number,
            deliveredPercentage: types.number,
          }),
        })
      ),
      returnedOrders: types.array(
        types.model({
          cmdId: types.string,
          evc: types.string,
          rv: types.string,
          quantity: types.number,
          netUnitPrice: types.number,
          amountIncludingVAT: types.number,
          quantityUnit: types.string,
          returnDate: types.string,
        })
      ),
    })
  ),
});

export const ApproOrdersModel = types.model({
  list: types.array(types.union(ApproOrdersOrder, ApproOrdersProduct, ApproOrdersFamily)),
  facets: types.maybeNull(
    types.model({
      availableStatus: types.array(
        types.model({
          value: types.string,
          status: types.number,
          type: types.enumeration(['invoice', 'shipment']),
        })
      ),
      availableItemId: types.array(FacetChoiceModel),
    })
  ),
  campaigns: types.array(FacetChoiceModel),
  fetchOrdersState: ServiceStateModel,
  fetchOrdersGlobalFiltersOptionsState: ServiceStateModel,
});
