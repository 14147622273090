import OdvForm, { IOdvFields } from '@components/Forms/OdvForm'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import { ICotation } from '@services/cotations'
import { OdvData, postOdvData } from '@services/navagri'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import './OdvModalStyles.css'
import { defaultErrorToast, defaultSuccesToast } from '@utils/toast'
import { toaster } from '@utils/dictionnary';
interface IOdvModal {
  active: boolean
  toggle: () => void
  cotation: ICotation
  odvData?: OdvData
}

const OdvModal: React.FC<IOdvModal> = ({ active, toggle, cotation, odvData }) => {
  const onSubmit = async (values: IOdvFields) => {
    if (!odvData) {
      return
    }
    const payload = {
      id: Date.now().toString(),
      contactId: odvData.contactNum,
      specyId: odvData.specy.id,
      month: values.month,
      year: values.year,
      qty: values.qty,
      unitPrice: cotation.amount,
      cgv: values.cgv,
      campaignId: values.campaignId
    }

    try {
      await postOdvData(payload)

      defaultSuccesToast(toaster.success.request)
    } catch (error) {
      defaultErrorToast(toaster.error.global)
    }
  }

  console.log({ odvData })

  return (
    <>
      <Modal
        active={active}
        toggle={toggle}
        title={`Ordre de vente - ${odvData ? odvData.specy.name : ''}`}
        footer={null}
        maxWidth="100%"
        width="600px"
        className='OdvModal'
      >
        {odvData && active && (
          <Formik
            initialValues={{
              campaignId: cotation.campaignId.toString(),
              month: cotation.echanceId,
              year: cotation.campaignId.toString(),
              qty: odvData.maxQty,
              cgv: false
            }}
            onSubmit={onSubmit}
            validate={values => {
              let errors: any = {}

              if (values.qty > odvData.maxQty) {
                errors.qty = `Quantité maximum dépassée`
              }

              if (!values.cgv) {
                errors.cgv = 'Veuillez accepter les conditions générales'
              }

              return errors
            }}
            enableReinitialize
          >
            {(formikProps: FormikProps<IOdvFields>) => (
              <OdvForm {...formikProps} odvData={odvData} cotation={cotation} />
            )}
          </Formik>
        )}
        {!odvData && <Loader />}
      </Modal>
    </>
  )
}

export default OdvModal
