import fetch from '@utils/fetch';
import {IAdivalorFilters} from './navagri';

const endPoint = `${process.env.API_URL}/extranet`;

const endPoints = {
  planning: `${endPoint}/planning`,
};

export const fetchAdivalorPlanning = (filters: IAdivalorFilters) => fetch(endPoints.planning, 'get', filters);
