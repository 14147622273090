import {customRenderDateSignOff, renderDateCell, renderDateSignOff} from '@utils/tables';
import {formatDate} from '@utils/string';
import React from 'react';
import {IContractModel} from '@stores/contracts/models';
import {ICsvColumn} from '@hooks/useCsvGenerator';
import {IColumn} from '@components/PdfGenerator';
import {getLabelByValue} from '@utils/pdf';
import {isNull} from '@lepicard/utils';
import {formatNumber} from "@utils/number";

export enum ContractStatusEnum {
  'finished' = 'Soldé',
  'notStarted' = 'Non soldé',
  'inProgress' = 'En cours',
}

export const getContractStatus = (contract: IContractModel) => {
  const reliquatRate = contract.reliquatRate;
  const status = contract.status;

  // Les contrats terminés sont considérés comme Soldés
  if ([2].includes(status)) {
    return ContractStatusEnum.finished;
  }

  switch (reliquatRate) {
    case 0:
      return ContractStatusEnum.finished;
    case 100:
      return ContractStatusEnum.notStarted;
    default:
      return ContractStatusEnum.inProgress;
  }
};

export const pdfColumns = () => {
  const columns: IColumn[] = [
    {
      id: 'createdAt',
      Header: 'Date de création',
      accessor: 'createdAt',
      isDate: true,
    },
    {
      id: 'signOffDate',
      Header: 'Date de signature',
      accessor: 'signOffDate',
      render: (column, value) => {
        return `${renderDateSignOff(value, 'Contrat non signé')} `;
      },
      isDate: true,
    },

    {
      id: 'startDate',
      Header: 'Début/fin période livraison',
      accessor: 'shipStartingDate',
      othersAccessor: ['shipEndingDate', 'campaignId'],
      isDate: true,
      render: (column, value, newObject) => {
        return `${formatDate(value)} ${formatDate(newObject!.shipEndingDate)}`;
      },
    },

    {
      id: 'nextPaymentDate',
      Header: 'Date de paiement',
      accessor: 'nextPaymentDate',
      render: (column, value) => {
        return `${renderDateSignOff(value, 'Pas de date de paiement')} `;
      },
      isDate: true,
    },
    {
      id: 'id',
      Header: 'N° de contrat',
      accessor: 'id',
    },
    {
      id: 'speciesId',
      Header: 'Variété',
      accessor: 'brand',
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'ContractType',
      render: (column, value) => {
        return `${value ? value.description : ''} `;
      },
    },
    {
      id: 'unitPrice',
      Header: 'Prix HT',
      accessor: 'unitPrice',
      render: (column, value) => {
        return `${value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`;
      },
    },
    {
      id: 'qty',
      Header: 'Quantité',
      accessor: 'qty',
      render: (column, value) => {
        return `${value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} T`;
      },
    },

    {
      id: 'status',
      Header: 'Statut',
      accessor: 'reliquatRate',
      othersAccessor: ['status'],
      render: (column, value, othersAccessor) => {
        // @ts-ignore
        const partialContract = {
          reliquatRate: value,
          status: othersAccessor!.status,
        } as IContractModel;
        return getContractStatus(partialContract);
      },
    },
  ];
  return columns;
};

export const getCsvColumns = () => {
  const columns: ICsvColumn[] = [
    {
      id: 'id',
      Header: 'N° de contrat',
      accessor: 'id',
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'ContractType',
      render: (column, value) => {
        return `${value ? value.description : ''} `;
      },
    },
    {
      id: 'speciesId',
      Header: 'Variété',
      accessor: 'brand',
    },
    {
      id: 'startDate',
      Header: 'Début de période de livraison',
      accessor: 'shipStartingDate',
      isDate: true,
      render: (column, value) => {
        return `${formatDate(value)}`;
      },
    },
    {
      id: 'endDate',
      Header: 'Fin de période de livraison',
      accessor: 'shipEndingDate',
      isDate: true,
      render: (column, value) => {
        return `${formatDate(value)}`;
      },
    },

    {
      id: 'nextPaymentDate',
      Header: 'Date de paiement',
      accessor: 'nextPaymentDate',
      render: (column, value) => {
        return `${renderDateSignOff(value, 'Pas de date de paiement')} `;
      },
      isDate: true,
    },
    {
      id: 'qty',
      Header: 'Quantité',
      accessor: 'qty',
      render: (column, value) => {
        return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
      },
    },
    {
      id: 'outstandingQuantity',
      Header: 'Reliquat',
      accessor: 'outstandingQuantity',
      render: (column, value) => {
        return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
      },
    },
    {
      id: 'unitPrice',
      Header: 'Prix HT',
      accessor: 'unitPrice',
      render: (column, value) => {
        return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
      },
    },

    {
      id: 'status',
      Header: 'Statut',
      accessor: 'reliquatRate',
      othersAccessor: ['status'],
      render: (column, value, othersAccessor) => {
        // @ts-ignore
        const partialContract = {
          reliquatRate: value,
          status: othersAccessor!.status,
        } as IContractModel;
        return getContractStatus(partialContract);
      },
    },
  ];
  return columns;
};

const getFilterBy = (tag: string) => {
  return !tag
    ? 'all'
    : tag === 'isNotFinished'
    ? 'Contrats non soldés'
    : tag === 'nextInvoice'
    ? 'Règlements à venir'
    : 'all';
};

export const headerValues = (formValues, contractTypeOptions, store, filteredSpeciesOptions) => {
  return {
    speciesId: getLabelByValue(formValues?.speciesId, store.subFamilyAsOptions),
    campaignId: isNull(formValues.campaignId) || formValues.campaignId === 'all' ? '' : formValues.campaignId,
    itemId: getLabelByValue(formValues?.itemId, filteredSpeciesOptions),
    filterBy: getFilterBy(formValues.tagsOption) === 'all' ? '' : getFilterBy(formValues.tagsOption),
    contractType: getLabelByValue(formValues.contractType, contractTypeOptions),
    search: formValues.search,
  };
};
export const getCsvFileName = (formValues, tagsOptions, filteredSpeciesOptions, store) => {
  const itemId =
    formValues.itemId !== 'all'
      ? getLabelByValue(formValues.itemId, filteredSpeciesOptions).replace(/\s+/g, '_')
      : null;
  const speciesId =
    formValues.speciesId !== 'all' ? getLabelByValue(formValues.speciesId, store.subFamilyAsOptions) : null;
  const tagsOption = getLabelByValue(formValues.tagsOption, tagsOptions).replace(/\s+/g, '_');

  const name = itemId || speciesId || null;

  const campaignId = formValues.campaignId;

  return [campaignId, name, tagsOption].filter(Boolean).join('_');
};
