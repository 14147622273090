import { types } from 'mobx-state-tree'
import { ServiceStateModel } from '@stores/models'
import { type } from 'os'

export const CotationModel = types.model({
  amount: types.maybeNull(types.number),
  campaignId: types.number,
  echanceId: types.number,
  echanceName: types.string,
  specieId: types.string,
  specieName: types.string
})

export const CerealModel = types.model({
  label: types.string,
  value: types.string
})

export const CotationStoreModel = types.model('Adivalor', {
  cotations: types.array(CotationModel),
  // qualities: types.array(CotationModel),
  cereales: types.array(CerealModel),
  campaigns: types.array(types.string),
  zones: types.array(types.string),
  fetchCotationsState: ServiceStateModel
})
