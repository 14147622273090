import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {Logger} from '@services/Logger';

Logger.init();

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<App />);
