import {ThemeConfig} from 'tailwindcss/types/config';

export const lepicardTailwindThemeColors = {
  greenLepicard: '#006f42',
  grayLepicard: '#8f8f8f',
  red: '#EE513C',
  redPdf: {
    100: '#ff9a7a',
    200: '#ff8964',
    300: '#ff794d',
    400: '#ff6837',
    500: '#ff5721',
    600: '#e64e1e',
    700: '#cc461a',
  },
  greenCsv: {
    300: '#69bc77',
    400: '#56b366',
    500: '#43ab55',
    600: '#3c9a4d',
    700: '#368944',
  },
  orange: '#EE9C3C',
  yellow: '#FFCC69',
  green: {
    '000': '#0B3526',
    100: '#0F4C36',
    200: '#2E6652',
    300: '#318869',
    500: '#5D8476',
  },
  darkGreen: '#2D4D42',
  paleGrey: '#f6f7fb',
  userMenuPortrait: '#2E6652',
  pageHeadText: '#ffffff',
  grey: {
    '000': '#F7F8FB',
    100: '#EEF1F3',
    200: '#EEF8F4',
    300: '#D2E1E0',
    350: '#BFE1DF',
    400: '#B7C6C5',
    450: '#96b0a8',
    500: '#8BA59C',
    600: '#769B8E',
  },
  greenGradient: {
    '000': '#0C3D2B',
    100: '#104C35',
    200: '#167956',
    300: '#14865d',
  },
  whiteFont: '#E4EDEC',
  globalFont: '#0F4C36',
  hueRotate: '0deg',
  homeEntry: '#5D8476',
} satisfies ThemeConfig['colors'];
