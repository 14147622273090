import React from 'react';
import {StatusTag, StatusTagTheme} from '@components/UI/StatusTag/StatusTag';

export enum CollectInvoicesStatusEnum {
  SETTLED = 'Soldée',
  NOT_SETTLED = 'Non soldée',
}

interface IDeliveryStatusTag {
  status: CollectInvoicesStatusEnum;
}

export const CollectInvoicesStatusTag: React.FC<IDeliveryStatusTag> = ({status}) => {
  let theme = StatusTagTheme.GREEN;
  switch (status) {
    case CollectInvoicesStatusEnum.SETTLED:
      theme = StatusTagTheme.GREEN;
      break;
    case CollectInvoicesStatusEnum.NOT_SETTLED:
      theme = StatusTagTheme.YELLOW;
      break;
  }
  return <StatusTag theme={theme}>{status}</StatusTag>;
};
