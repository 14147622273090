import { IVarietySpeciesStoreModel } from './models'

export default (self: IVarietySpeciesStoreModel) => {
  return {
    get varietySpecieAsOptions() {
      return self.list.map(specie => ({ value: specie.speciesCode, label: specie.species.name }))
    },


    get subFamilyAsOption() {
      return self.subfamilyList.map(specie => ({ value: specie.code, label: specie.name }))
    }

  }
}
