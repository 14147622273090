import React from 'react';

interface ISearch {
  color?: string;
}

const Search: React.FC<ISearch> = ({color = '#69847B'}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8119 5.5C10.8119 8.16967 8.55464 10.375 5.71844 10.375C2.88224 10.375 0.625 8.16967 0.625 5.5C0.625 2.83033 2.88224 0.625 5.71844 0.625C8.55464 0.625 10.8119 2.83033 10.8119 5.5Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <line
        y1="-0.625"
        x2="6.71206"
        y2="-0.625"
        transform="matrix(0.697107 0.716967 -0.743503 0.668733 8.3176 9)"
        stroke={color}
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default Search;
