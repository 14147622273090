import {ThemeConfig} from 'tailwindcss/types/config';

export const cauchoiseTailwindThemeColors = {
  greenLepicard: '#036839',
  grayLepicard: '#8f8f8f',
  red: '#EE513C',
  redPdf: {
    100: '#ff9a7a',
    200: '#ff8964',
    300: '#ff794d',
    400: '#ff6837',
    500: '#ff5721',
    600: '#e64e1e',
    700: '#cc461a',
  },
  greenCsv: {
    300: '#69bc77',
    400: '#56b366',
    500: '#43ab55',
    600: '#3c9a4d',
    700: '#368944',
  },
  orange: '#F7A40C',
  yellow: '#FFCC69',
  green: {
    '000': '#012C18',
    100: '#023B21',
    200: '#02532D',
    300: '#036839',
    500: '#49715E',
  },
  darkGreen: '#012113',
  paleGrey: '#f6f7fb',
  userMenuPortrait: '#F7A40C',
  pageHeadText: '#202020',
  grey: {
    '000': '#F7F8FB',
    100: '#F2F3F6',
    200: '#E9EBF0',
    300: '#CBD0DC',
    350: '#AEC1C4',
    400: '#9EB8B7',
    450: '#88ADA5',
    500: '#749890',
    600: '#749890',
  },
  greenGradient: {
    '000': '#49715E',
    100: '#49715E',
    200: '#CBD0DC',
    300: '#CBD0DC',
  },
  whiteFont: '#E4EDEC',
  globalFont: '#023B21',
  hueRotate: '0deg',
  homeEntry: '#49715E',
} satisfies ThemeConfig['colors'];
