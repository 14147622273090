import React, { useState, useMemo } from 'react'
import ReactSelect from 'react-select'

export interface IOption {
  value: any
  label?: string
}

interface ISelectInput {
  value: any
  options: IOption[]
  onChange: (value: any) => void
  icon?: string
  className?: string
  error?: boolean
  isSearchable?: boolean
  placeholder?: string
  disabled?: boolean
  multiple?: boolean
  isClearable?: boolean
}

const SelectInput: React.FC<ISelectInput> = ({
  value,
  icon,
  className = '',
  multiple = false,
  error,
  options,
  isSearchable,
  onChange,
  placeholder,
  disabled,
  isClearable = false
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const selectClassName = `forms${icon ? '--icon' : ''} ${error ? 'error' : ''} ${isFocused ? 'simulateFocus' : ''}`

  const formattedOptions = useMemo(
    () =>
      options.map(option => ({
        value: option.value,
        label: option.label ? option.label : option.value
      })),
    [options]
  )

  // If we need to format the value after typing for certain types
  const valueItem = useMemo(() => formattedOptions.find(option => option.value === value), [value])

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  const handleChange = (newOption: any /* Because react-select typing sucks */) => {
    onChange(newOption ? newOption.value : newOption)
  }
  return (
    <div className={`containerFormsInput ${className}`}>
      <div className={`${selectClassName}`} style={{ padding: 5 }}>
        <ReactSelect
          options={formattedOptions}
          value={valueItem}
          onChange={handleChange}
          isSearchable={isSearchable}
          onFocus={onFocus}
          onBlur={onBlur}
          isMulti={multiple}
          placeholder={placeholder}
          isDisabled={disabled}
          isClearable={isClearable}
          styles={{
            control: base => ({ ...base, border: 'none', boxShadow: 'none' }),
            singleValue: base => ({ ...base, fontFamily: 'Arial', font: '400 14px Arial' }),
            placeholder: base => ({ ...base, fontFamily: 'Arial', font: '400 14px Arial' })
          }}
        />
      </div>
      {icon && <label className={`icon icon-${icon}`}></label>}
    </div>
  )
}

export default SelectInput
