import {generateId, isUndefined} from '@lepicard/utils';

interface Action {
  id: string;
  timestamp: number;
}

export class ConcurrentHelper {
  private static allActionIds: Map<string, Action> = new Map();
  private static cleaningInterval = 1_000 * 20;

  static {
    window.setInterval(() => {
      const now = +new Date();
      ConcurrentHelper.allActionIds.forEach((action, identifier) => {
        if (now - action.timestamp > ConcurrentHelper.cleaningInterval) {
          ConcurrentHelper.allActionIds.delete(identifier);
        }
      });
    }, ConcurrentHelper.cleaningInterval);
  }

  public static setAction(identifier: string): {actionId: string; isLastAction: () => boolean} {
    const actionId = generateId();
    this.allActionIds.set(identifier, {id: actionId, timestamp: +new Date()});
    return {
      actionId,
      isLastAction: () => ConcurrentHelper.isLastAction(identifier, actionId),
    };
  }

  public static isLastAction(identifier: string, actionId: string): boolean {
    const lastActionId = ConcurrentHelper.allActionIds.get(identifier);
    if (isUndefined(lastActionId)) {
      console.warn('You need to call ConcurrentHelper.SetAction() before checking if the action is the latest');
      return false;
    }

    if (lastActionId.id !== actionId) {
      console.log(
        '%c A previous action has been called after a more recent action, ignoring the action',
        'color: red;'
      );
      return false;
    }
    return true;
  }
}
