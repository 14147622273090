import React, {lazy, Suspense} from 'react';
import EnvironmentService from '@utils/environmentService';

const instanceToComponent = {
  lepicard: lazy(() => import('./LegalNoticesLepicard')),
  cauchoise: lazy(() => import('./LegalNoticesCauchoise')),
  luneray: lazy(() => import('./LegalNoticesLuneray')),
};

const legalesNotices: React.FC = () => {
  const Comp = instanceToComponent[EnvironmentService.getInstance()];
  return (
    <Suspense fallback={<div>Chargement...</div>}>
      <Comp />
    </Suspense>
  );
};

export default legalesNotices
