import React, {useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {IAccountingFilters} from '@services/navagri';
import './styles.scss';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import {useStore} from '@stores/index';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import {isNull, isUndefined} from '@lepicard/utils';
import {Form, Formik, FormikProps, useFormikContext} from 'formik';
import {useInitialSessionStorage} from '@utils/useInitialSessionStorage';
import {useWindowSize} from '@hooks/useWindowSize';
import AccountingRowMobile from '@components/Accounting/List/Mobile/AccountingRowMobile';
import Pagination from '@components/Pagination';
import usePagination from '@hooks/usePagination';
import TagsContainer from '@components/UI/TagsContainer/TagsContainer';
import Tag from '@components/UI/Tag/Tag';
import useModal from '@hooks/useModal';
import {documentTypes} from '@constants/document.types';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import Input from '@components/NewFields/Input';
import Search from '@components/Icons/Search';
import ExportButtons from '@components/UI/ExportButtons/ExportButtons';
import Select from '@components/NewFields/Select';
import OptionModal from '@components/UI/OptionModal/OptionModal';
import Button from '@components/UI/Button/Button';
import CheckBoxAllSelector from '@components/NewFields/CheckBoxAllSelector';
import CheckBox from '@components/NewFields/CheckBox';
import AccountingTableDesktop, {IFormattedData} from '@components/Accounting/List/Desktop/AccountingTableDesktop';
import {FormikHelpers} from 'formik/dist/types';
import useCsvGenerator from '@hooks/useCsvGenerator';
import usePdfGenerator from '@hooks/usePdfGenerator';
import {csvColumns, getCsvFileName, headerValue, pdfColumns} from '@components/Accounting/AccountingView/columns';
import Loader from '@components/Loader';
import {format} from 'date-fns';
import RadioGroup from '@components/NewFields/RadioGroup';
import RadioItem from '@components/NewFields/RadioItem';
import {IAccountingItemModel} from '@stores/accounting/models';
import {formatNumber} from '@utils/number';
import CustomRangePicker from '@components/UI/CustomRangePicker/CustomRangePicker';
import {getLabelByValue} from '@utils/pdf';
import NoData from '@components/NoData/NoData';

dayjs.locale('fr');

interface IAccountingView {
  typeLabel: string;
  loadData: (page: number, filters: IAccountingFilters) => void;
  loadDataToExport: (filters: IAccountingFilters) => Promise<any[]>;
  type: string;
}

interface FormikValues {
  documentType: Array<string>;
  startDate: Date | null;
  endDate: Date | null;
  search: string;
  tagsOption: string;
  sortedDocument: string;
}

const defaultValues: FormikValues = {
  documentType: [],
  startDate: null,
  endDate: null,
  search: '',
  tagsOption: '',
  sortedDocument: '',
};

/**
 * Tags component
 */
const AccountingViewFiltersTag: React.FC = () => {
  const {setFieldValue, values} = useFormikContext<any>();

  const tagsOptions = [
    {label: 'Factures dûes échues', value: 'overdueInvoices'},
    {label: 'Factures dûes non échues', value: 'notYetDueInvoices'},
  ];

  return (
    <div className="AccountingViewFilters-container-filter-tags">
      <TagsContainer>
        {tagsOptions.map((tag) => (
          <Tag
            key={tag.value}
            label={tag.label}
            onClick={() => setFieldValue('tagsOption', values.tagsOption === tag.value ? 'all' : tag.value)}
            active={values.tagsOption === tag.value}
          />
        ))}
      </TagsContainer>
    </div>
  );
};

export const getType = (type: string | number) => {
  if (Object.prototype.hasOwnProperty.call(documentTypes, type)) {
    return documentTypes[type];
  }
  return type;
};

// Format data to group payments
const sortLinkedItemsByDate = (items: any[]): any[] => {
  return items.sort((a, b) => new Date(b.postingDate).getTime() - new Date(a.postingDate).getTime());
};

export const formatData = (data: IFormattedData[]): any[] => {
  const formattedData: any[] = [];

  data.forEach((item) => {
    if (item.type === 1) {
      const existingItem = formattedData.find((d) => d.num === item.num);
      if (existingItem) {
        existingItem.linked.push(item.linked);
        existingItem.linked = sortLinkedItemsByDate(existingItem.linked);
      } else {
        formattedData.push({...item, linked: [item.linked]});
      }
    } else if (item.type === 3) {
      const existingItem = formattedData.find((d) => d.num === item.num);
      if (existingItem) {
        if (item.linked.type === 2) {
          existingItem.linked.push(item.linked);
          existingItem.linked = sortLinkedItemsByDate(existingItem.linked);
        }
      } else {
        formattedData.push({...item, linked: [item.linked]});
      }
    } else {
      formattedData.push(item);
    }
  });

  return formattedData;
};

const AccountingView: React.FC<IAccountingView> = observer(({typeLabel, type, loadData, loadDataToExport}) => {
  const {accounting: accountingStore} = useStore();

  const {windowWidth} = useWindowSize();
  const isDesktop = windowWidth >= 1024;

  const {active, toggle} = useModal();

  // Load data
  const handlePageChange = (pageNumber: number) => {
    loadData(pageNumber, filters);
  };
  const {pageNumber, onPageChange} = usePagination(handlePageChange);

  const data = accountingStore.list.toJS();

  const documentTypeOptions = Object.entries(documentTypes)
    .filter(([key]) => accountingStore.types.includes(Number(key)))
    .map(([key, value]) => {
      const item = documentTypes[key];
      return {value: key, label: item};
    });

  documentTypeOptions.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA < labelB) {
      return -1;
    } else if (labelA > labelB) {
      return 1;
    } else {
      return 0;
    }
  });

  // Formik config
  const [sessionFormValues, syncSessionFormValues] = useInitialSessionStorage<FormikValues>('accountingSessionStorage');
  const INITIAL_VALUES = !isNull(sessionFormValues) ? sessionFormValues : defaultValues;
  const [formValues, syncFormValues] = useState(INITIAL_VALUES);
  const updateFormFn = useRef<FormikHelpers<FormikValues>['setFieldValue']>(() => null);

  // Reset filters on tab change : Mes achats -> Mes ventes
  const formikRef = useRef<FormikProps<Record<string, unknown>>>(null);
  const resetFilters = () => {
    syncFormValues(defaultValues);
    accountingStore.setIsDescending(true);
  };

  useEffect(() => {
    formikRef.current?.resetForm({
      values: {
        documentType: [],
        startDate: null,
        endDate: null,
        search: '',
        tagsOption: '',
        sortedDocument: '',
      },
    });
    resetFilters();
  }, [typeLabel]);

  const handleFieldChange = (fieldName: any, value: any) => {
    syncFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  useEffectNotOnFirstRender(() => {
    const formValuesEntries = Object.entries(formValues);
    const defaultValuesEntries = Object.entries(defaultValues);

    // Deep equal comparison
    const isFormValuesEqualsDefaultValues =
      formValuesEntries.length === defaultValuesEntries.length &&
      !formValuesEntries.some(([formKey, formValue]) =>
        defaultValuesEntries.some(([defaultKey, defaultValue]) => {
          if (formKey !== defaultKey) {
            return false;
          }
          if (Array.isArray(formValue) && Array.isArray(defaultValue)) {
            return (
              formValue.length !== defaultValue.length ||
              formValue.some((formValueArr) => defaultValue.includes(formValueArr))
            );
          } else {
            return formValue !== defaultValue;
          }
        })
      );
    syncSessionFormValues(isFormValuesEqualsDefaultValues ? null : formValues);
  }, [formValues, defaultValues]);

  const filters = useMemo<IAccountingFilters>(() => {
    const newFilters: IAccountingFilters = {};

    if (formValues.documentType) {
      newFilters.type = formValues.documentType;
    }

    if (formValues.startDate) {
      newFilters.postingDate = {
        $gte: formValues.startDate,
      };
    }

    if (formValues.endDate) {
      newFilters.postingDate = {
        ...newFilters.postingDate,
        $lte: formValues.endDate,
      };
    }

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    if (formValues.tagsOption !== 'all') {
      newFilters.tagsOption = formValues.tagsOption;
    }

    if (formValues.sortedDocument !== '' || accountingStore.isDescending) {
      newFilters.sortedDocument = {name: formValues.sortedDocument, desc: accountingStore.isDescending};
    }

    loadData(1, newFilters);
    return newFilters;
  }, [formValues]);

  useEffectNotOnFirstRender(() => {
    onPageChange();
  }, [filters]);

  // Handle PDF & CSV download
  const loadExport = () => {
    return loadDataToExport(filters);
  };

  const {download: downloadPdf} = usePdfGenerator(pdfColumns(filters, type));
  const {download: downloadCsv} = useCsvGenerator(csvColumns(filters, type));

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadExport,
      title: `MON COMPTE - ${getTypeLabelTabName(typeLabel).toUpperCase()}`,
      subtitle: '',
      fileName: `Compta_${getTypeLabelTabName(typeLabel)}_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Compta',
      headerValue: headerValue(formValues, accountingStore),
    });
  };
  const getTypeLabelTabName = (typeLabel: string): string => {
    return typeLabel === 'customer' ? 'Mes achats' : 'Mes ventes ';
  };

  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadExport,
      fileName: `Export_Compta_${getTypeLabelTabName(typeLabel)}${getCsvFileName(formValues)}`,
      headerValue: headerValue(formValues, accountingStore),
    });
  };

  return (
    <div className="AccountingView">
      <FiltersCard className="AccountingView-recap mb-8">
        <h2 className="AccountingView-recap-title">Solde de mon compte</h2>
        <div className="AccountingView-recap-balance">
          <div>
            <div className="AccountingView-recap-balance-amount">
              Montant restant dû (TTC) <QuickHelp>Factures non réglées mais non échues</QuickHelp>
            </div>
            <div className="AccountingView-recap-balance-total">
              {accountingStore.totalDue
                ? formatNumber(accountingStore.totalDue as number)
                    .fixed()
                    .spaces()
                    .renderWithUnit('€')
                : '0 €'}
            </div>
          </div>
          {typeLabel !== 'supplier' && (
            <div>
              <div>
                {isNull(accountingStore.expiredTotal) || accountingStore.expiredTotal >= 0 ? (
                  <>
                    Dont échu (TTC) <QuickHelp>Factures non réglées et échues</QuickHelp>
                  </>
                ) : (
                  <>
                    Avoir en cours <QuickHelp>Montant crédité sur le compte du client</QuickHelp>
                  </>
                )}
              </div>
              <div className="AccountingView-recap-balance-total">
                {accountingStore.expiredTotal
                  ? formatNumber(accountingStore.expiredTotal as number)
                      .fixed()
                      .spaces()
                      .renderWithUnit('€')
                  : '0 €'}
              </div>
            </div>
          )}
        </div>
      </FiltersCard>
      <Formik
        innerRef={formikRef}
        initialValues={INITIAL_VALUES}
        onSubmit={(values) => console.log('submit', values)}
        children={({values, setFieldValue}: FormikProps<any>) => {
          useEffectNotOnFirstRender(() => {
            syncFormValues(values);
            updateFormFn.current = setFieldValue;
          }, [values]);
          return (
            <Form className="AccountingViewFilters">
              <div className="AccountingViewFilters-container">
                <div className="AccountingViewFilters-container-btns">
                  <button
                    className="AccountingViewFilters-container-btns-btnFilter text-interactif"
                    onClick={toggle}
                    type="button"
                  >
                    <img src="/icons/filters.svg" alt="" />
                    Filtres
                  </button>
                  <div className="AccountingViewFilters-container-btns-datePicker">
                    Période du
                    <CustomRangePicker
                      defaultValue={[
                        Object.prototype.hasOwnProperty.call(filters, 'postingDate') &&
                        Object.prototype.hasOwnProperty.call(filters.postingDate, '$gte') &&
                        isUndefined(filters?.postingDate?.$gte)
                          ? dayjs(filters?.postingDate?.$gte)
                          : undefined,
                        Object.prototype.hasOwnProperty.call(filters, 'postingDate') &&
                        Object.prototype.hasOwnProperty.call(filters.postingDate, '$lte') &&
                        isUndefined(filters?.postingDate?.$lte)
                          ? dayjs(filters?.postingDate?.$lte)
                          : undefined,
                      ]}
                      onChange={(dates, dateStrings) => {
                        if (dates && dates[0]) {
                          const startDate = dates[0].toDate();
                          setFieldValue('startDate', dayjs(startDate).format('YYYYMMDD'));
                        } else {
                          setFieldValue('startDate', null);
                        }

                        if (dates && dates[1]) {
                          const endDate = dates[1].toDate();
                          setFieldValue('endDate', dayjs(endDate).format('YYYYMMDD'));
                        } else {
                          setFieldValue('endDate', null);
                        }
                      }}
                      placeholder={['JJ/MM/AA', 'JJ/MM/AA']}
                      variant={'borderless'}
                    />
                  </div>
                  <Input name="search" placeholder="Rechercher par document" type="text" iconAfter={<Search />} />
                  <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />
                </div>
                <Input
                  className="AccountingViewFilters-container-search"
                  name="search"
                  placeholder="Rechercher par document"
                  type="text"
                  iconAfter={<Search />}
                />
                <div className="AccountingViewFilters-container-filter">
                  <Select
                    label="Type de document"
                    name="documentType"
                    options={documentTypeOptions}
                    className="AccountingViewFilters-container-filter-select"
                    multiple={true}
                    selectAllOption="Tous"
                    placeholder="Tous"
                  />
                  {typeLabel !== 'supplier' && <AccountingViewFiltersTag />}
                </div>
              </div>

              <OptionModal
                active={active}
                title="FILTRES"
                toggle={toggle}
                actions={<Button label="Fermer" onClick={toggle} className="w-full" />}
                closeOnEsc={false} // prevent the modal to take focus and close the date picker
              >
                <h4>Période :</h4>
                <CustomRangePicker
                  defaultValue={[
                    Object.prototype.hasOwnProperty.call(filters, 'postingDate') &&
                    Object.prototype.hasOwnProperty.call(filters.postingDate, '$gte') &&
                    isUndefined(filters?.postingDate?.$gte)
                      ? dayjs(filters?.postingDate?.$gte)
                      : undefined,
                    Object.prototype.hasOwnProperty.call(filters, 'postingDate') &&
                    Object.prototype.hasOwnProperty.call(filters.postingDate, '$lte') &&
                    isUndefined(filters?.postingDate?.$lte)
                      ? dayjs(filters?.postingDate?.$lte)
                      : undefined,
                  ]}
                  onChange={(dates, dateStrings) => {
                    if (dates && dates[0]) {
                      const startDate = dates[0].toDate();
                      setFieldValue('startDate', dayjs(startDate).format('YYYYMMDD'));
                    } else {
                      setFieldValue('startDate', null);
                    }

                    if (dates && dates[1]) {
                      const endDate = dates[1].toDate();
                      setFieldValue('endDate', dayjs(endDate).format('YYYYMMDD'));
                    } else {
                      setFieldValue('endDate', null);
                    }
                  }}
                  placeholder={['JJ/MM/AA', 'JJ/MM/AA']}
                />
                <hr className="hr-m" />
                <h4>Tri :</h4>
                <RadioGroup name="sortedDocument">
                  {(fieldProps) => (
                    <div className="AccountingView-RadioGroup">
                      <h5>Date :</h5>
                      <RadioItem
                        value="postingDateDesc"
                        label="Du plus récent au plus ancien"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(true);
                          setFieldValue('sortedDocument', 'postingDate');
                        }}
                        isCheckedProp={
                          accountingStore.isDescending
                            ? fieldProps.fieldValue
                              ? `${fieldProps.fieldValue}Desc`
                              : `${accountingStore.sortedDocument}Desc`
                            : fieldProps.fieldValue
                            ? `${fieldProps.fieldValue}Asc`
                            : `${accountingStore.sortedDocument}Asc`
                        }
                      />
                      <RadioItem
                        value="postingDateAsc"
                        label="Du plus ancien au plus récent"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(false);
                          setFieldValue('sortedDocument', 'postingDate');
                        }}
                        isCheckedProp={
                          'postingDateAsc' ===
                          (accountingStore.isDescending
                            ? `${fieldProps.fieldValue}Desc`
                            : `${fieldProps.fieldValue}Asc`)
                        }
                      />

                      <h5>Échéance :</h5>
                      <RadioItem
                        value="echeanceDesc"
                        label="Du plus récent au plus ancien"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(true);
                          setFieldValue('sortedDocument', 'echeance');
                        }}
                        isCheckedProp={
                          'echeanceDesc' ===
                          (accountingStore.isDescending
                            ? `${fieldProps.fieldValue}Desc`
                            : `${fieldProps.fieldValue}Asc`)
                        }
                      />
                      <RadioItem
                        value="echeanceAsc"
                        label="Du plus ancien au plus récent"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(false);
                          setFieldValue('sortedDocument', 'echeance');
                        }}
                        isCheckedProp={
                          'echeanceAsc' ===
                          (accountingStore.isDescending
                            ? `${fieldProps.fieldValue}Desc`
                            : `${fieldProps.fieldValue}Asc`)
                        }
                      />

                      <h5>Montant restant dû :</h5>
                      <RadioItem
                        value="restToPaidAsc"
                        label="Croissant"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(false);
                          setFieldValue('sortedDocument', 'restToPaid');
                        }}
                        isCheckedProp={
                          'restToPaidAsc' ===
                          (accountingStore.isDescending
                            ? `${fieldProps.fieldValue}Desc`
                            : `${fieldProps.fieldValue}Asc`)
                        }
                      />
                      <RadioItem
                        value="restToPaidDesc"
                        label="Décroissant"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(true);
                          setFieldValue('sortedDocument', 'restToPaid');
                        }}
                        isCheckedProp={
                          'restToPaidDesc' ===
                          (accountingStore.isDescending
                            ? `${fieldProps.fieldValue}Desc`
                            : `${fieldProps.fieldValue}Asc`)
                        }
                      />

                      <h5>Lettrage :</h5>
                      <RadioItem
                        value="letterAsc"
                        label="Croissant (A à Z)"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(false);
                          setFieldValue('sortedDocument', 'letter');
                        }}
                        isCheckedProp={
                          'letterAsc' ===
                          (accountingStore.isDescending
                            ? `${fieldProps.fieldValue}Desc`
                            : `${fieldProps.fieldValue}Asc`)
                        }
                      />
                      <RadioItem
                        value="letterDesc"
                        label="Décroissant (Z à A)"
                        {...fieldProps}
                        onRadioChange={() => {
                          accountingStore.setIsDescending(true);
                          setFieldValue('sortedDocument', 'letter');
                        }}
                        isCheckedProp={
                          'letterDesc' ===
                          (accountingStore.isDescending
                            ? `${fieldProps.fieldValue}Desc`
                            : `${fieldProps.fieldValue}Asc`)
                        }
                      />
                    </div>
                  )}
                </RadioGroup>
                <hr className="hr-m" />
                <h4>Type de document :</h4>
                <CheckBoxAllSelector
                  name="documentType"
                  options={documentTypeOptions}
                  label="Tous"
                  className={'mb-7'}
                />
                {documentTypeOptions.map(({value, label}: any, idx) => (
                  <CheckBox key={idx} name="documentType" label={label} value={value} />
                ))}
              </OptionModal>
            </Form>
          );
        }}
      />
      {!accountingStore.fetchAccountingState.isDone ? (
        <Loader />
      ) : (
        <>
          {Array.isArray(data) && data.length > 0 ? (
            isDesktop ? (
              <AccountingTableDesktop data={data} setFieldValue={handleFieldChange} typeLabel={typeLabel} />
            ) : (
              <div className="AccountingListMobile">
                {formatData(data).map((document: IAccountingItemModel, id: React.Key | null | undefined) => (
                  <AccountingRowMobile key={id} data={document} typeLabel={typeLabel} />
                ))}
              </div>
            )
          ) : (
            <NoData
              message={
                ' Aucune donnée ne correspond aux filtres sélectionnés. Essayez de les modifier pour trouver ce que vous cherchez.'
              }
            />
          )}

          <Pagination
            activePage={pageNumber}
            totalItemsCount={accountingStore.fetchAccountingState.total ?? 0}
            onChange={onPageChange}
          />
        </>
      )}
    </div>
  );
});

export default AccountingView;
