import React, { useMemo } from 'react'
import Table from '@components/Tables/Table'

const OptionsList: React.FC = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Num'
      }
    ],
    []
  )

  const loadData = () => {}

  return <Table columns={columns} loadData={loadData} data={[]} />
}

export default OptionsList
