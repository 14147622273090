import React from 'react';
import PageHead from '@components/UI/PageHead/PageHead';
import {CollectInvoicesList} from '@components/CollectInvoices/CollectInvoicesList/CollectInvoicesList';

export const CollectInvoices: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Mes Factures"
        description="Retrouvez vos factures facilement en les filtrant par période de récolte, cultures ou variétés de culture"
      />
      <CollectInvoicesList />
    </div>
  );
};
