import React, {useMemo, useState, useEffect, createContext} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import {IApproDeliveriesFilter, fetchApproDeliveries} from '@services/navagri';
import {getNoDataExerciceMessage} from '@utils/tables';
import {isUndefined} from '@lepicard/utils';
import Loader from '@components/Loader';
import usePagination from '@hooks/usePagination';
import {useRerenderSafeDebounce} from '@hooks/useRerenderSafeDebounce';
import {Formik} from 'formik';
import NoData from '@components/NoData/NoData';
import Pagination from '@components/Pagination';
import {
  ApproDeliveriesListFilters,
  groupByOption,
  tagsOptions,
} from '@components/ApproDeliveries/ApproDeliveriesFilters/ApproDeliveriesFilters';
import {ApproDeliveriesRows} from '@components/ApproDeliveries/ApproDeliveriesRows/ApproDeliveriesRows';
import usePdfGenerator from '@hooks/usePdfGenerator';
import useCsvGenerator from '@hooks/useCsvGenerator';
import {format} from 'date-fns';
import {csvColumns, getCsvFileName, headerValue, pdfCol, pdfSubCol} from './column';

interface IApproDeliveriesListFormContext {
  formValues: any;
  refreshFormValues: (newValues: any) => void;
  onDownloadPdf: () => void;
  onDownloadCsv: () => void;
}

export const ApproDeliveriesListFormContext = createContext<IApproDeliveriesListFormContext>({
  formValues: {},
  refreshFormValues: () => {},
  onDownloadPdf: () => {},
  onDownloadCsv: () => {},
});

const ApproDeliveriesList: React.FC = observer(() => {
  const {approDeliveriesStore, campaigns: campaignsStore} = useStore();

  const {currentCampaign} = campaignsStore;

  const list = approDeliveriesStore.list.toJS();

  const initialFormValues = {
    campaignId: approDeliveriesStore.getCampaignsOptions.some((option) => option.value === currentCampaign!.id)
      ? currentCampaign!.id
      : 'all',
    groupBy: 'delivery',
    status: [],
    itemFamilyCode: [],
    search: '',
    tagsOption: 'all',
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const filters = useMemo<IApproDeliveriesFilter>(() => {
    const newFilters: IApproDeliveriesFilter = {};

    if (formValues.campaignId !== 'all') {
      newFilters.campaignId = formValues.campaignId;
    }

    newFilters.groupBy = formValues.groupBy;

    if (formValues.tagsOption !== 'all') {
      newFilters.tagsOption = formValues.tagsOption;
    }

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    if (formValues.status.length >= 0) {
      newFilters.status = formValues.status;
    }

    if (formValues.itemFamilyCode.length >= 0) {
      newFilters.itemFamilyCode = formValues.itemFamilyCode;
    }

    return newFilters;
  }, [formValues]);

  const loadDataToExport = async () => {
    const {data} = await fetchApproDeliveries({$limit: Number.MAX_SAFE_INTEGER, ...filters, $skip: 0});
    return data;
  };

  const {isPending: isPdfPending, download: downloadPdf} = usePdfGenerator(pdfCol, pdfSubCol);
  const {isPending: isCsvPending, download: downloadCsv} = useCsvGenerator(csvColumns);

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadDataToExport,
      subtitle: 'deliveries',
      title: `APPRO. - MES LIVRAISONS`,
      fileName: `Appro_livraisons_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Appro',
      headerValue: headerValue(formValues, groupByOption, approDeliveriesStore),
    });
  };

  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadDataToExport,
      fileName: `Export_Appro_livraisons_${getCsvFileName(formValues, tagsOptions)}`,
      headerValue: headerValue(formValues, groupByOption, approDeliveriesStore),
    });
  };

  const loadData = (page: number) => {
    approDeliveriesStore.fetchDeliveries(page, filters);
  };

  const {pageNumber, onPageChange, updatePagination} = usePagination(loadData);

  const fetchDeliveriesDebounced = useRerenderSafeDebounce(approDeliveriesStore.fetchDeliveries, 500);
  useEffect(() => {
    fetchDeliveriesDebounced(1, filters);
    updatePagination(1);
  }, [filters]);

  const displayLoader = ['error', 'pending'].includes(approDeliveriesStore.fetchDeliveriesState.state);

  return (
    <ApproDeliveriesListFormContext.Provider
      value={{
        formValues: formValues,
        refreshFormValues: (newValues) => setFormValues(newValues),
        onDownloadPdf,
        onDownloadCsv,
      }}
    >
      <div>
        <Formik initialValues={formValues} onSubmit={() => {}}>
          <ApproDeliveriesListFilters />
        </Formik>

        {displayLoader ? (
          <Loader topMargin />
        ) : (
          <>
            <ApproDeliveriesRows />

            {(Array.isArray(list) && list.length === 0) || !Array.isArray(list) ? (
              <NoData message={getNoDataExerciceMessage(filters, currentCampaign?.id)} />
            ) : (
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={10}
                totalItemsCount={approDeliveriesStore.fetchDeliveriesState.total ?? 0}
                onChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </ApproDeliveriesListFormContext.Provider>
  );
});

const ApproDeliveriesListContainer: React.FC = observer(() => {
  const {campaigns: campaignsStore, approDeliveriesStore} = useStore();

  const areCampaignsOptionsLoaded = ['done', 'error'].includes(
    approDeliveriesStore.fetchDeliveriesGlobalFiltersOptionsState.state
  );

  useEffect(() => {
    if (!areCampaignsOptionsLoaded) {
      approDeliveriesStore.fetchDeliveriesGlobalFiltersOptions();
    }
  }, []);

  if (isUndefined(campaignsStore.currentCampaign?.id) || !areCampaignsOptionsLoaded) {
    return <Loader topMargin />;
  }

  return <ApproDeliveriesList />;
});

export default ApproDeliveriesListContainer;
