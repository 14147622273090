const toaster = {
  success: {
    disconnect: 'Vous avez bien été déconnecté',
    request: 'Votre demande a bien été prise en compte',
    sign: 'Votre demande de signature a bien été envoyée',
  },
  error: {
    global: 'Une erreur est survenue',
    csv: 'Une erreur est survenue dans la génération du csv',
    pdf: 'Une erreur est survenue dans la génération du pdf',
    download: 'Erreur lors du téléchargement',
    form: 'Le formulaire comporte des erreurs',
    endSession: 'Votre session a expirée, veuillez vous reconnecter',
    undefinedArea: 'Votre zone ne peut être définie, veuillez contacter votre commercial',
    tokenNotFound:
      'La session de réinitialisation de mot de passe est expirée. Veuillez effectuer une nouvelle demande.',
  },
};

export {toaster};
