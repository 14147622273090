import {ThemeConfig} from 'tailwindcss/types/config';

export const lunerayTailwindThemeColors = {
  greenLepicard: '#A93235',
  grayLepicard: '#AAA39D',
  red: '#EE513C',
  redPdf: {
    100: '#ff9a7a',
    200: '#ff8964',
    300: '#ff794d',
    400: '#ff6837',
    500: '#ff5721',
    600: '#e64e1e',
    700: '#cc461a',
  },
  greenCsv: {
    300: '#69bc77',
    400: '#56b366',
    500: '#43ab55',
    600: '#3c9a4d',
    700: '#368944',
  },
  orange: '#EE9C3C',
  yellow: '#FFCC69',
  green: {
    '000': '#491617',
    100: '#6A1F21',
    200: '#832729',
    300: '#862523',
    500: '#af5759',
  },
  darkGreen: '#320F10',
  paleGrey: '#f6f7fb',
  userMenuPortrait: '#631D1F',
  pageHeadText: '#3A4040',
  grey: {
    '000': '#F7F8FB',
    100: '#f0f3f6',
    200: '#EEF1F3',
    300: '#E7EDEB',
    350: '#CDCDC9',
    400: '#B5B0AB',
    450: '#A7A09B',
    500: '#878480',
    600: '#707371',
  },
  greenGradient: {
    '000': '#9C3B39',
    100: '#9C3B39',
    200: '#EBCCB7',
    300: '#EBCCB7',
  },
  whiteFont: '#E4EDEC',
  globalFont: '#3A4040',
  hueRotate: '210deg',
  homeEntry: '#3A4040',
} satisfies ThemeConfig['colors'];
