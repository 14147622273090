import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';
import './styles.scss';

type ICard = PropsWithChildren<{
  className?: string;
}>;

export const Card: React.FC<ICard> = ({children, className}) => {
  return <div className={cn('Card', className)}>{children}</div>;
};
