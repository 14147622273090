import React from 'react';
import {cn} from '@utils/class-names';
import './style.scss';

interface ITag {
  label: string;
  onClick?: () => void;
  active: boolean;
}

const Tag: React.FC<ITag> = (props) => {
  return (
    <button
      className={cn('Tag text-interactif', {
        isActive: props.active,
      })}
      onClick={props.onClick}
      type="button"
    >
      {props.label}
    </button>
  );
};

export default Tag;
