import React from 'react';
import ApproInvoicesList from '@components/ApproInvoices/ApproInvoicesList';
import PageHead from '@components/UI/PageHead/PageHead';

export const ApproInvoices: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Mes factures"
        description="Retrouvez toutes vos factures en approvisionnement et gagnez du temps dans la gestion de vos réglements"
      />
      <ApproInvoicesList />
    </div>
  );
};
