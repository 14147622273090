import React from 'react';
import ExportButton from '@components/UI/ExportButton/ExportButton';

interface IExportButtons {
  onPdfClick?: () => void;
  onCsvClick?: () => void;
}

const ExportButtons: React.FC<IExportButtons> = ({onPdfClick, onCsvClick}) => {
  return (
    <div className="ExportButton flex gap-1.5 min-w-max">
      {onPdfClick && <ExportButton iconName="download" label="pdf" onClick={onPdfClick} color="red" />}
      {onCsvClick && <ExportButton iconName="download" label="csv" onClick={onCsvClick} color="green" />}
    </div>
  );
};

export default ExportButtons;
