import React, { Fragment, useEffect } from 'react'
import LocalPdfViewer from '@components/LocalPdfViewer'
import { fetchOffers, IDriveFiles } from '@services/offers'
import { folderOffers } from '@constants/foldersOffers.options'
import useService from '@hooks/useService'

const ServiceOffers: React.FC = () => {
  const { service: offersService, data } = useService<IDriveFiles[]>()

  const onFetchFolder = () => {
    offersService(() => fetchOffers({ parentFolder: folderOffers.service }))
  }

  useEffect(() => {
    onFetchFolder()
  }, [])

  return (
    <div className="w-full flex-1 p-5">
      <div className="page-title mb-10">Présentation des offres</div>

      <div className="flex flex-wrap">
        {data &&
          data.map((f, i) => {
            return (
              <div className="flex flex-col m-5" key={i}>
                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                  <img src={`https://drive.google.com/thumbnail?id=${f.id}`}></img>
                </div>

                <a href={f.webViewLink} target="_blanck" className="btn-primary" style={{ display: 'inline-block' }}>
                  <span className="mb-10">{f.name}</span>
                </a>
              </div>
            )
          })}
      </div>
      {/*
      <LocalPdfViewer pdfPublicUrl="/img/pdf/catalogue_service.pdf" />
         */}
    </div>
  )
}

export default ServiceOffers
