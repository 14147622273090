/**
 * Get number of days between two dates
 * Warning: Does not take daylight saving into account
 */
export function getRemainingDays(date: string): number {
  const d = new Date(date);
  const today = new Date();
  const ONE_DAY = 24 * 60 * 60 * 1000;

  return Math.round((+d - +today) / ONE_DAY);
}

export function getRemainingDaysLabel(date: string) {
  const daysLeft = getRemainingDays(date);
  if (daysLeft > 1) {
    return `${daysLeft} jours`;
  }

  return `${daysLeft} jour`;
}
