import * as React from 'react';

const SvgArrowLeft = () => (
  <svg width="20" height="13" viewBox="0 0 20 13">
    <path
      d="M6 11.3125L1 6.3125M1 6.3125L6 1.3125M1 6.3125L18.5 6.3125"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SvgArrowRight = () => (
  <svg width="20" height="13" viewBox="0 0 20 13">
    <path
      d="M14 1.3125L19 6.3125M19 6.3125L14 11.3125M19 6.3125H1.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SvgArrow: React.FC<{direction: string}> = ({direction}) => {
  return direction === 'left' ? SvgArrowLeft() : SvgArrowRight();
};

export default SvgArrow;
