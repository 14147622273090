import { ICampaignsStoreModel } from './models'
import compareAsc from 'date-fns/compareAsc'

export default (self: ICampaignsStoreModel) => {
  return {
    getCampaignById(collectId: string) {
      return self.list.find(collect => collectId === collect.id)
    },

    get campaignAsOptions() {
      return self.list.map(campaign => ({ value: campaign.id }))
    },

    get currentCampaign() {

      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      return self.list.find(campaign => campaign.status === 1 && compareAsc(new Date(campaign.endDate), yesterday) === 1)

    }
  }
}
