import {Instance, types} from 'mobx-state-tree';
import {ServiceStateModel} from '@stores/models';
import {FacetChoiceModel} from '@stores/shared/models';

export type ICollectInvoiceModel = Instance<typeof CollectInvoiceModel>;
export type ICollectInvoicesStoreModel = Instance<typeof CollectInvoicesStoreModel>;

const CollectInvoiceModel = types.model({
  no: types.string,
  documentDate: types.string,
  dueDate: types.string,
  contracts: types.array(
    types.model({
      no: types.string,
      type: types.string,
    })
  ),
  species: types.array(types.string),
  isOpenInvoice: types.boolean,
  quantity: types.number,
  quantityUnitOfMeasureCode: types.string,
  amount: types.number,
  amountIncludingVat: types.number,
});

export const CollectInvoicesStoreModel = types.model({
  globalFacets: types.maybeNull(
    types.model({
      availableCampaigns: types.array(FacetChoiceModel),
      availableSubFamilies: types.array(FacetChoiceModel),
      availableSpecies: types.array(
        types.compose(
          FacetChoiceModel,
          types.model({
            subFamilyValue: types.string,
          })
        )
      ),
    })
  ),
  facets: types.maybeNull(
    types.model({
      availableContractTypes: types.array(FacetChoiceModel),
    })
  ),
  global: types.maybeNull(
    types.model({
      totalQuantity: types.number,
      totalQuantityUnitOfMeasure: types.string,
      totalAmount: types.number,
      totalAmountIncludingVAT: types.number,
    })
  ),
  list: types.array(types.union(CollectInvoiceModel)),
  fetchCampaignsState: ServiceStateModel,
  fetchInvoicesState: ServiceStateModel,
});
