import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import styles from '../styles';
import {IPdfGenerator} from '@components/PdfGenerator';
import {Table} from '@components/PdfGenerator/table';

export const ApproPdf: React.FC<IPdfGenerator> = ({
  datas = [],
  columns,
  subColumns,
  title,
  subtitle,
  pdfType,
  headerValue,
}) => {
  return (
    <>
      <View style={styles.titleCard}>
        <View style={styles.titleSubCard}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.subTitleContainer}>
          <View style={[styles.subTitle, styles.subTitlePadding]}>
            <Text>Exercice : </Text>
            <Text style={styles.subValue}>{headerValue?.campaignId ? headerValue.campaignId : 'Toutes'}</Text>
            <Text> Grouper : </Text>
            <Text style={styles.subValue}>{headerValue?.groupBy ? headerValue.groupBy : 'Toutes'}</Text>
            {headerValue?.filterBy && (
              <>
                <Text>Filtrer par : </Text>
                <Text style={styles.subValue}>{headerValue?.filterBy ? headerValue.filterBy : ''}</Text>
              </>
            )}
          </View>
          <View
            style={
              headerValue?.statut || headerValue?.itemFamilyCode
                ? [styles.subTitlePadding, styles.subTitleBorder]
                : undefined
            }
          >
            {headerValue?.statut && (
              <View style={[styles.subTitle, {width: '100%', paddingBottom: '8px'}]}>
                <Text>Statut : </Text>
                <Text style={styles.subValue}>{headerValue.statut}</Text>
              </View>
            )}
            {headerValue?.itemFamilyCode && (
              <View style={[styles.subTitle]}>
                <Text>Familles d'articles : </Text>
                <Text style={[styles.subValue, styles.maxWidth]}>{headerValue.itemFamilyCode}</Text>
              </View>
            )}
          </View>

          {headerValue?.search && (
            <View>
              <View style={[styles.subTitle, styles.subTitlePadding, styles.subTitleBorder]}>
                <Text>Recherche personalisée : </Text>
                <Text style={styles.subValue}>{headerValue?.search ? headerValue.search : ''}</Text>
              </View>
            </View>
          )}
        </View>
      </View>

      <Table
        columns={columns}
        datas={datas}
        pdfType={pdfType}
        headerValue={headerValue}
        subtitle={subtitle}
        subColumns={subColumns}
        border={true}
      />
    </>
  );
};
