import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';
import {isUndefined} from '@lepicard/utils';
import EnvironmentService from '@utils/environmentService';

type ICardTitle = PropsWithChildren<{
  onDownload?: () => void;
  title: string;
  subTitle?: string;
}>;

export const CardTitle: React.FC<ICardTitle> = ({onDownload, title, subTitle, children}) => {
  const titleNode = <div className="CardDocument-title">{title}</div>;

  return (
    <h3
      className={cn('CardDocument-titleGroup', {
        isDownloadable: !isUndefined(onDownload),
        blackAndWhiteIcon: EnvironmentService.instanceConfig.global.downloadIconInBlackAndWhite,
      })}
      onClick={onDownload}
    >
      {!isUndefined(onDownload) && <img src="/icons/file.svg" alt="" />}
      {isUndefined(subTitle) ? (
        titleNode
      ) : (
        <div>
          <div className="CardDocument-subTitle">{subTitle}</div>
          {titleNode}
        </div>
      )}

      {!isUndefined(children) && children}
    </h3>
  );
};
