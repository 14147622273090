
export default (self: any) => {
    return {


        get getApproFileId() {

            //@ts-ignore
            return self.list.filter(file => file.id !== "11QWRgVKLKSOP_p7X2pteWAOik16zc-ch")
        }
    }
}
