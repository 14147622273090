import {IApproInvoicesStoreModel} from '@stores/appro-invoices/store';

export default (self: IApproInvoicesStoreModel) => {
  return {
    get getCampaignsOptions() {
      return self.globalFacets?.availableCampaigns.toJS().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
    get getStatusOptions() {
      return self.facets?.availableStatus.toJS().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
    get getFamiliesOptions() {
      return self.facets?.availableFamilies.toJS().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
  };
};
