import React from 'react'
import SoldOrdersList from '@components/SoldOrders/SoldOrdersList'

const SoldOrders: React.FC = () => {
    return (
        <div className="w-full flex-1 p-5">
            <div className="page-title mb-10">Mes récapitulatifs</div>
            <SoldOrdersList />
        </div>
    )
}

export default SoldOrders
