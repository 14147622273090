import React from 'react';
import {ContractStatusEnum} from '@components/Contracts/ContractsList/columns';
import {StatusTag, StatusTagTheme} from '@components/UI/StatusTag/StatusTag';

interface IContractStatusTag {
  status: ContractStatusEnum | string;
}

const ContractStatusTag: React.FC<IContractStatusTag> = ({status}) => {
  const theme = [ContractStatusEnum.finished.toString()].includes(status)
    ? StatusTagTheme.GREEN
    : StatusTagTheme.YELLOW;
  return <StatusTag theme={theme}>{status}</StatusTag>;
};

export default ContractStatusTag;
