import React from 'react';
import {cn} from '@utils/class-names';
import './styles.scss';

interface INoData {
  message?: string;
  className?: string;
}

const NoData: React.FC<INoData> = ({message = `Aucune donnée n'a été trouvée.`, ...props}) => {
  return (
    <div className={cn('NoData Card min-h-[150px] text-center justify-center', props.className)}>
      <div className="Card-row text-title-2">{message}</div>
    </div>
  );
};

export default NoData;
