import React from 'react';
import PageHead from '@components/UI/PageHead/PageHead';
import ApproContractsList from '@components/ApproContracts/ApproContractsList/ApproContractsList';

const ApproContracts: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Mes contrats d'approvisionnement"
        description="Consultez vos contrats en cours et organisez vos livraisons."
      />
      <ApproContractsList />
    </div>
  );
};

export default ApproContracts;
