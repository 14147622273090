import Select from '@components/NewFields/Select';
import Pagination from '@components/Pagination';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import usePagination from '@hooks/usePagination';
import useService from '@hooks/useService';
import {IFDSFilter, fetchFDSItems} from '@services/navagri';
import {useStore} from '@stores/index';
import {getNoDataMessage, getPaginationParams} from '@utils/tables';
import {Form, Formik, FormikProps} from 'formik';
import React, {useState, useEffect} from 'react';
import FdsRow from './fdsRow';
import NoData from '@components/NoData/NoData';

const FdsList: React.FC = () => {
  const {serviceFds: ServiceFdsStore} = useStore();
  const [manuFacturerCodeOptions, setmanuFacturerCodeOptions] = useState([]);
  const [descriptionOptions, setDescriptionOptions] = useState([]);

  const onFiltersChange = (values: any) => {
    const newFilters: IFDSFilter = {};
    if (values.manufacturerCode !== filters.manufacturerCode) {
      if (values.manufacturerCode === 'all' && filters.manufacturerCode === undefined) {
        // unintended/auto filter
      } else {
        values.description = 'all';
      }
    }
    Object.keys(values).forEach((key) => {
      const currentValue = values[key];

      if (currentValue !== '' && currentValue !== 'all') {
        newFilters[key as keyof IFDSFilter] = currentValue;
      }
    });
    setFilters(newFilters);
  };
  const {
    data: result,
    service,
    pending,
  } = useService<any>({defaultValue: {total: 0, limit: 10, manufacturerCode: [], descriptions: []}});

  const onFetchData = (page: number) => {
    service(() => fetchFDSItems({...getPaginationParams(page), ...filters}));
  };

  const loadData = (page: number) => {
    onFetchData(page);
  };

  const {pageNumber, onPageChange} = usePagination(loadData);
  const [filters, setFilters] = useState({});

  useEffectNotOnFirstRender(() => {
    onPageChange();
  }, [filters]);

  useEffect(() => {
    ServiceFdsStore.fetchFdsItems();
  }, []);

  useEffect(() => {
    formatOption();
  }, [result]);

  useEffect(() => {
    loadData(1);
  }, [filters]);

  const formatOption = () => {
    const manuFacturerCode = result.manufacturerCode.filter((code) => code != null).map((code: any) => ({value: code}));
    const descriptionOptions = result.descriptions.filter((code) => code != null).map((code: any) => ({value: code}));

    setmanuFacturerCodeOptions(manuFacturerCode);
    setDescriptionOptions(descriptionOptions);
  };

  return (
    <>
      <div>
        <Formik
          initialValues={{
            description: 'all',
            manufacturerCode: 'all',
          }}
          onSubmit={(values) => console.log('submit', values)}
          children={({values}: FormikProps<any>) => {
            useEffectNotOnFirstRender(() => {
              if (onFiltersChange) {
                onFiltersChange(values);
              }
            }, [values]);
            return (
              <Form className="w-full mb-5">
                <FiltersCard className="grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4 py-5 px-3.5 lg:py-6 lg:px-4 mb-7">
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="manufacturerCode" className="mr-1 lg:mr-2.5">
                        Fournisseur
                      </label>
                    </div>
                    <Select
                      inputId="manufacturerCode"
                      name="manufacturerCode"
                      options={[{label: 'Tous', value: 'all'}, ...manuFacturerCodeOptions]}
                      placeholder="Tous"
                    />
                  </div>
                  {/* filter hidden decision is taken about if it should exist, be a selector, or a search bar */}
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="description" className="mr-1 lg:mr-2.5">
                        Produit
                      </label>
                    </div>
                    <Select
                      inputId="description"
                      name="description"
                      options={[
                        {
                          label: 'Tous',
                          value: 'all',
                        },
                        ...descriptionOptions,
                      ]}
                      placeholder="Tous"
                    />
                  </div>
                </FiltersCard>
              </Form>
            );
          }}
        />
      </div>
      <div>
        <div className="flex flex-col gap-3.5 lg:gap-5">
          {Array.isArray(result.data) && result.total === 0 ? (
            <NoData message={getNoDataMessage(filters)} className="mt-5" />
          ) : Array.isArray(result.data) ? (
            result.data.map((c: any, idx: any) => <FdsRow key={idx} fds={c} />)
          ) : result.data !== undefined ? (
            <FdsRow key={0} fds={result.data} />
          ) : null}
        </div>
        <Pagination
          activePage={pageNumber}
          totalItemsCount={!Array.isArray(result.data) ? 0 : result.total}
          onChange={onPageChange}
        />
      </div>
    </>
  );
};
export default FdsList;
