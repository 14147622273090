import React, {useContext} from 'react';
import Button from '@components/UI/Button/Button';
import ExportButtons from '@components/UI/ExportButtons/ExportButtons';
import CheckBoxAllSelector from '@components/NewFields/CheckBoxAllSelector';
import CheckBox from '@components/NewFields/CheckBox';
import Search from '@components/Icons/Search';
import {FiltersButton} from '@components/UI/FiltersButton/FiltersButton';
import OptionModal from '@components/UI/OptionModal/OptionModal';
import useModal from '@hooks/useModal';
import {useWindowSize} from '@hooks/useWindowSize';
import {useFormikContext} from 'formik';
import {ContractsListFormContext} from '@components/ApproContracts/ApproContractsList/ApproContractsList';
import Input from '@components/NewFields/Input';
import Select from '@components/NewFields/Select';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import {useStore} from '@stores/index';

export const groupByOption = [
  {
    label: 'par contrats',
    value: 'contract',
  },
  {
    label: 'par articles',
    value: 'product',
  },
  {
    label: "par famille d'articles",
    value: 'family',
  },
];

export const ApproContractsListFilters: React.FC = () => {
  const {refreshFormValues} = useContext(ContractsListFormContext);
  const {approContractsStore} = useStore();
  const {values, setFieldValue} = useFormikContext();

  useEffectNotOnFirstRender(() => {
    refreshFormValues(values);
  }, [values]);

  return (
    <div className="w-full mb-5">
      <FiltersCard className="grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4 py-5 px-3.5 lg:py-6 lg:px-4 mb-7">
        <div className="flex-grow flex gap-3">
          <div className="flex items-center min-w-[110px] lg:min-w-min">
            <label htmlFor="campaign" className="mr-1 lg:mr-2.5">
              Exercice
            </label>
            <QuickHelp>Période du 1er juillet au 30 juin</QuickHelp>
          </div>
          <Select
            name="campaignId"
            options={[{label: 'Tous', value: 'all'}, ...approContractsStore.getCampaignsOptions]}
          />
        </div>
        <div className="flex-grow flex gap-3">
          <div className="flex items-center min-w-[110px] lg:min-w-min">
            <label htmlFor="species" className="mr-1 lg:mr-2.5">
              Grouper
            </label>
          </div>
          <Select name="groupBy" options={groupByOption} placeholder="Tous" />
        </div>
      </FiltersCard>
      <ApproContractListAdvancedFilters />
    </div>
  );
};

export const ApproContractListAdvancedFilters: React.FC = () => {
  const {windowWidth} = useWindowSize();

  const Component =
    windowWidth > 1024 ? ApproContractListAdvancedFiltersDesktop : ApproContractListAdvancedFiltersMobile;
  return (
    <div className="mb-4">
      <Component />
    </div>
  );
};

export const ApproContractListAdvancedFiltersMobile: React.FC = () => {
  const {approContractsStore} = useStore();
  const {onDownloadPdf, onDownloadCsv, formValues} = useContext(ContractsListFormContext);
  const {active, toggle} = useModal();

  return (
    <>
      <div className="flex justify-between gap-5">
        <FiltersButton onClick={toggle} />
        {formValues.groupBy === 'contract' && <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />}
      </div>
      <Input name="search" placeholder="Rechercher" type="text" className="my-4" iconAfter={<Search />} />

      <OptionModal
        active={active}
        title="Filtres"
        toggle={toggle}
        actions={<Button label="Fermer" onClick={toggle} className="w-full" />}
      >
        <h4 className="text-title-4 text-green-100 mb-3.5">Statut :</h4>
        <CheckBoxAllSelector
          name="status"
          options={approContractsStore.getStatusOptions}
          label="Tous"
          className={'mb-7'}
        />
        {approContractsStore.getStatusOptions.map(({value, label}, idx) => (
          <CheckBox key={value} name="status" label={label} value={value} />
        ))}

        <hr className="hr-m" />
        <h4 className="text-title-4 text-green-100 mb-3.5">Famille d'articles :</h4>
        <CheckBoxAllSelector
          name="itemFamilyCode"
          options={approContractsStore.getItemFamilyCodeOptions}
          label="Tous"
          className={'mb-7'}
        />
        {approContractsStore.getItemFamilyCodeOptions.map(({value, label}, idx) => (
          <CheckBox key={idx} name="itemFamilyCode" label={label} value={value} />
        ))}
      </OptionModal>
    </>
  );
};

export const ApproContractListAdvancedFiltersDesktop: React.FC = () => {
  const {approContractsStore} = useStore();
  const {onDownloadPdf, onDownloadCsv, formValues} = useContext(ContractsListFormContext);

  return (
    <>
      <div className="flex justify-between gap-5 mb-4">
        <Input
          name="search"
          placeholder="Rechercher"
          type="text"
          className="h-10 hidden lg:block max-w-xl"
          iconAfter={<Search />}
        />
        {formValues.groupBy === 'contract' && <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />}
      </div>
      <div className="flex justify-between gap-4 ">
        <div className="flex gap-2 grow flex-shrink-[9999]">
          <Select
            label="Statut"
            name="status"
            multiple={true}
            placeholder="Tous"
            options={approContractsStore.getStatusOptions}
            selectAllOption="Tous"
          />
          <Select
            label="Famille d'articles"
            name="itemFamilyCode"
            multiple={true}
            placeholder="Tous"
            options={approContractsStore.getItemFamilyCodeOptions}
            selectAllOption="Tous"
          />
        </div>
      </div>
    </>
  );
};
