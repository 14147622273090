import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';

type ICardInfosWrapper = PropsWithChildren<{
  className?: string;
}>;

export const CardInfosWrapper: React.FC<ICardInfosWrapper> = ({children, className}) => {
  return <div className={cn('Card-infos', className)}>{children}</div>;
};
