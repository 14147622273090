import React from 'react';
import PageHead from '@components/UI/PageHead/PageHead';
import FdsList from '@components/serviceFds/';
const ServiceFds: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Fiche de données de sécurité"
        description="Consultez les Fiches de Données de Sécurité de tous vos produits."
      />
      <FdsList />
    </div>
  );
};

export default ServiceFds;
