const routes = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  ACTIVATION: '/activation',

  HOME: '/',
  PROFILE: '/profile',
  REPRESENTATIVES: '/representatives',
  DURABILITY: '/durability',
  DURABILITYSUCCESS: '/durabilitySuccess',
  ACCOUNTING: '/accounting',
  QUOTATIONS: '/quotations',
  COLLECT: '/collect',
  SERVICES: '/services',
  CONTRACTS: '/contracts',
  DELIVERIES: '/deliveries',
  PAGE: '/page',
  CEREAL_PRICES: '/cereals',
  APPRO: '/appro',
  CGV: '/cgv',
  LEGALES_NOTICE: '/legal-notices',
};

export const collectRoutes = {
  INVOICES: `${routes.COLLECT}/invoices`,
  OFFERS: `${routes.COLLECT}/offers`,
  OPTIONS: `${routes.COLLECT}/options`,
};

export const servicesRoutes = {
  BSV: `${routes.SERVICES}/bsv`,
  ADIVALOR: `${routes.SERVICES}/adivalor`,
  OFFERS: `${routes.SERVICES}/offers`,
  FDS: `${routes.SERVICES}/fds`,
};

export const cmsRoutes = {
  /* APPRO_OFFERS: `${routes.PAGE}/7`, */
  SERVICE_OFFERS: `${routes.PAGE}/11`,
  SERVICE_REGLEMENTATION: `${routes.PAGE}/26`,
  SERVICE2: `${routes.PAGE}/30`,
  /* COLLECT_OFFERS: `${routes.PAGE}/31` */
};

export const approRoutes = {
  APPRO_CONTRACTS: `${routes.APPRO}/contracts`,
  APPRO_OFFERS: `${routes.APPRO}/offers`,
  APPRO_ORDERS: `${routes.APPRO}/orders`,
  APPRO_INVOICES: `${routes.APPRO}/bills`,
  APPRO_SOLD_ORDERS: `${routes.APPRO}/soldOrders`,
  APPRO_DELIVERIES: `${routes.APPRO}/deliveries`,
};

export default routes;
