import React, { Fragment} from 'react';
import { formatDate } from '@utils/string';
import DeliveryStatusTag from '../DeliveryStatusTag/DeliveryStatusTag';
import {isUndefined} from '@lepicard/utils';
import {getContractTypeLabel} from "@utils/contracts/contractType";
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow} from '@components/UI/Card/CardRow';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {ICollectDeliveriesModel} from '@stores/collect-deliveries/models';
import {renameSpecie} from "@utils/species/species";

interface IDeliveryRow {
  collectDelivery: ICollectDeliveriesModel;
}

export const NORME_TYPE_LABEL_MAPPING: Record<string, {name: string}> = {
  HUMIDITE: {name: 'Humidité'},
  PS: {name: 'PS'},
  PROTEINE: {name: 'Protéine'},
  IMPURETE: {name: 'Impureté'},
};

export function normeToLowercase(key: string): string {
  const keyToLowercase = key.toLowerCase();
  return keyToLowercase.charAt(0).toUpperCase() + keyToLowercase.slice(1);
}

const DeliveryRow: React.FC<IDeliveryRow> = ({collectDelivery}) => {
  // @ts-ignore
  const contractId = collectDelivery.subItem.Contract?.id ?? '';
  // @ts-ignore
  const contractTypeId = collectDelivery.subItem.Contract?.contractType;
  const contractType = getContractTypeLabel(contractId ?? '', contractTypeId);
  const deliveryDate = `${formatDate(collectDelivery.receiptedDate)} `;

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={`LIVRAISON N°${collectDelivery.libelle}`} />
          <div className="Card-tags">
            <DeliveryStatusTag outstandingQuantity={collectDelivery.subItem.Contract?.outstandingQuantity} />
          </div>
        </CardTitleContainer>

        <CardInfosWrapper className="mt-4">
          <CardInfo label="Date de livraison" value={deliveryDate} />
          <CardInfo label="N° de contrat" value={collectDelivery.contractId} />
          <CardInfo label="Immatriculation" value={collectDelivery.truckRegistration} />
        </CardInfosWrapper>
      </CardRow>

      <CardRow className="DeliveryContent">
        <CardInfosWrapper>
          <div className="CardBrand">
            <div className="CardBrand-name">{renameSpecie(collectDelivery.species)}</div>
            {!isUndefined(contractType) && (
              <div>
                <span className="CardBrand-name">{contractType.name}</span>
                <span className="CardBrand-subname">{contractType.subName}</span>
              </div>
            )}
          </div>
          <CardInfo
            label="Quantité norme"
            value={collectDelivery.subItem.qty ? `${collectDelivery.subItem.qty.toFixed(2)} T` : ''}
            flags={[CardInfoFlag.END_OF_LINE, CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
        </CardInfosWrapper>
      </CardRow>

      <CardRow className="bg-grey-100">
        <div className="DeliveryNorme">
          <div className="DeliveryNorme-title">NORMES :</div>
          <div className="DeliveryNorme-content">
            {collectDelivery.subItem.collects_analyses
              .filter((analyse) => analyse.analyseCode && analyse.analyseValue)
              .map((analyse) => (
                <Fragment key={analyse.analyseCode}>
                  <div className="DeliveryNorme-item">
                    <span className="Card-label">{`${
                      NORME_TYPE_LABEL_MAPPING[analyse.analyseCode]?.name || normeToLowercase(analyse.analyseCode)
                    } : `}</span>
                    <span className="Card-value">{` ${analyse.analyseValue.toFixed(2)}`}&nbsp;%</span>
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </CardRow>
    </Card>
  );
};

export default DeliveryRow;
