import React from 'react'
import OptionsList from '@components/Collect/OptionsList'

const CollectOptions: React.FC = () => {
  return (
    <div className="w-full flex-1 p-5">
      <div className="page-title">Mes Options</div>
      <OptionsList />
    </div>
  )
}

export default CollectOptions
