import React, { PropsWithChildren } from 'react';
import { Placement } from '@popperjs/core';
import Tippy from '@tippyjs/react';

interface IQuickHelp {
  children: React.ReactNode;
  backgroundColor?: string;
  className?: string
  placement?: Placement
}

const QuickHelp: React.FC<IQuickHelp> = ({ children, backgroundColor, className, placement = 'bottom' }) => {
  const defaultBackgroundColorClass = 'bg-grey-100';

  const buttonClassName = `rounded-full text-grey-600 ${backgroundColor ? backgroundColor : defaultBackgroundColorClass
    } w-[18px] h-[18px] font-600 text-[11px] ${className ? className : ''}`;
  return (
    <Tippy content={<span>{children}</span>} placement={placement}>
      <button type="button"
        className={buttonClassName}
      >?</button>
    </Tippy>
  );
};

export default QuickHelp;
