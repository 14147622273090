import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from '@stores/index'
import Loader from '@components/Loader'

interface ICmsPage {}

const CmsPage: React.FC<ICmsPage> = observer(({}) => {
  const { pages: pagesStore } = useStore()
  const { pageId } = useParams()

  const {
    fetchPageByIdState: { isPending },
    pageById
  } = pagesStore

  useEffect(() => {
    if (pageId) {
      pagesStore.fetchPageById(parseInt(pageId))
    }
  }, [pageId])

  return pageId ? (
    <div className="w-full flex-1 p-5">
      {isPending ? (
        <Loader />
      ) : pageById ? (
        <>
          <div className="page-title">{pageById.title}</div>
          <div
            className="ckEdited w-full mt-5 p-5"
            dangerouslySetInnerHTML={{
              __html:
                '<div style="text-align:center;"><div style="margin:8px 0px 4px;"><a href="https://www.calameo.com/books/002503715afe35d1f88f9?authid=CZDvrE5C5lbV" target="_blank">Bat8 Lepicard Plaq Recolte 2019</a></div><iframe src="//v.calameo.com/?bkcode=002503715afe35d1f88f9&AuthID=CZDvrE5C5lbV" width="300" height="194" frameborder="0" scrolling="no" allowtransparency allowfullscreen style="margin:0 auto;"></iframe><div style="margin:4px 0px 8px;"><a href="http://www.calameo.com/">Publish at Calameo</a></div></div>'
            }}
          />
        </>
      ) : (
        <div>Page inconnue</div>
      )}
    </div>
  ) : (
    <div>Page inconnue</div>
  )
})

export default CmsPage
