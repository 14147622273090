import {numberToLocaleString} from '@utils/string';
import {getLabelByValue} from '@utils/pdf';
import {isNull, isUndefined} from '@lepicard/utils';
import {getFormatedQuantity} from '@components/Deliveries/DeliveriesList/column';
import {formatNumber} from '@utils/number';
import {CollectInvoicesStatusEnum} from '@components/CollectInvoices/CollectInvoicesStatusTag/CollectInvoicesStatusTag';
import {renameSpecie} from '@utils/species/species';
import {getContractTypeLabel} from '@utils/contracts/contractType';

export const pdfColumns = () => [
  {
    Header: 'Facture N°',
    id: 'id',
    accessor: 'no',
  },
  {
    Header: 'Type de contrat',
    id: 'contractTypeName',
    accessor: 'contracts',
    othersAccessor: ['species'],
    render: (column, value, otherAccessor) => {
      const allContractTypes = value.map((c) => getContractTypeLabel(c.no, c.type)).filter((ct) => !isUndefined(ct));

      return `${otherAccessor.species.map(renameSpecie).join(', ')} ${
        allContractTypes.length > 0 ? `${allContractTypes[0].name} ${allContractTypes[0].subName}` : ''
      } `;
    },
  },
  {
    Header: 'Date',
    id: 'date',
    accessor: 'dueDate',
    isDate: true,
  },
  {
    id: 'contractId',
    Header: 'N° de Contrat',
    accessor: 'contracts',
    render: (column: any, value: any) => {
      return value.length > 0 ? value[0].no : '-';
    },
  },
  {
    Header: 'Quantité facturée',
    id: 'quantity',
    accessor: 'quantity',
    render: (column, value) => {
      return ` ${value ? numberToLocaleString(value) : ''} T `;
    },
  },
  {
    Header: 'CA HT',
    id: 'amount',
    accessor: 'amount',
    render: (column, value) => {
      return ` ${value ? numberToLocaleString(value) : ''} € `;
    },
  },
  {
    Header: 'CA TTC',
    id: 'amountIncludingVat',
    accessor: 'amountIncludingVat',
    render: (column, value) => {
      return ` ${value ? numberToLocaleString(value) : ''} € `;
    },
  },
  {
    Header: 'Statut paiement',
    id: 'status',
    accessor: 'isOpenInvoice',
    render: (column, value) => {
      return value ? CollectInvoicesStatusEnum.NOT_SETTLED : CollectInvoicesStatusEnum.SETTLED;
    },
  },
];

export const csvColumns = [
  {
    Header: 'Facture N°',
    id: 'id',
    accessor: 'no',
  },
  {
    Header: 'Type de contrat',
    id: 'contractTypeName',
    accessor: 'contracts',
    othersAccessor: ['species'],
    render: (column, value, otherAccessor) => {
      const allContractTypes = value.map((c) => getContractTypeLabel(c.no, c.type)).filter((ct) => !isUndefined(ct));
      return `${otherAccessor.species.map(renameSpecie).join(', ')} ${
        allContractTypes.length > 0 ? `${allContractTypes[0].name} ${allContractTypes[0].subName}` : ''
      } `;
    },
  },
  {
    Header: 'Date',
    id: 'date',
    accessor: 'dueDate',
    isDate: true,
  },
  {
    id: 'contractId',
    Header: 'N° de Contrat',
    accessor: 'contracts',
    render: (column: any, value: any) => {
      return value.length > 0 ? value[0].no : '-';
    },
  },
  {
    Header: 'Quantité facturée',
    id: 'quantity',
    accessor: 'quantity',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    Header: 'CA HT',
    id: 'amount',
    accessor: 'amount',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    Header: 'CA TTC',
    id: 'amountIncludingVat',
    accessor: 'amountIncludingVat',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    Header: 'Statut paiement',
    id: 'status',
    accessor: 'isOpenInvoice',
    render: (column, value) => {
      return value ? CollectInvoicesStatusEnum.NOT_SETTLED : CollectInvoicesStatusEnum.SETTLED;
    },
  },
];

const getFilterBy = (tag: string) => {
  return !tag ? 'all' : tag === 'open-invoice' ? 'Factures non soldées' : 'all';
};

function getLabelByValueForInvoice(filters, value) {
  const filter = filters.find((filter) => filter.value === value);
  return filter ? filter.label : null;
}

export const headerValues = (formValues, collectInvoicesStore, contractTypeOptions) => {
  return {
    speciesId: getLabelByValueForInvoice(collectInvoicesStore.getSubFamilyOptions, formValues.subFamily),
    itemId: getLabelByValueForInvoice(collectInvoicesStore.getSpecieOptions, formValues.specie),
    campaignId: isNull(formValues.campaignId) || formValues.campaignId === 'all' ? '' : formValues.campaignId,
    filterBy: getFilterBy(formValues.tagsOption) === 'all' ? '' : getFilterBy(formValues.tagsOption),
    contractType: getLabelByValue(formValues.contractTypes, contractTypeOptions),
    search: formValues.search,
    subValues: {
      totalQty: getFormatedQuantity(collectInvoicesStore.global?.totalQuantity),
      totalAmountLabel: formatNumber(collectInvoicesStore.global.totalAmount).fixed().spaces().renderWithUnit('€'),
      totalAmountIncludingVATLabel: formatNumber(collectInvoicesStore.global.totalAmountIncludingVAT)
        .fixed()
        .spaces()
        .renderWithUnit('€'),
    },
  };
};

export const getCsvFileName = (formValues, tagsOptions, filteredSpeciesOptions, store) => {
  const itemId =
    formValues.subFamily !== 'all'
      ? getLabelByValueForInvoice(store.getSubFamilyOptions, formValues.subFamily).replace(/\s+/g, '_')
      : null;
  const speciesId =
    formValues.specie !== 'all' ? getLabelByValueForInvoice(store.getSpecieOptions, formValues.specie) : null;
  const tagsOption = getLabelByValue(formValues.tagsOption, tagsOptions).replace(/\s+/g, '_');

  const name = speciesId || itemId || null;
  const campaignId = formValues.campaignId;

  return [campaignId, name, tagsOption].filter(Boolean).join('_');
};
