import {useCallback, useEffect, useRef} from 'react';
import debounce from 'just-debounce-it';

export function useRerenderSafeDebounce(fn: (...args: any[]) => Promise<void> | void, wait: number) {
  const ref = useRef(fn);
  useEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useCallback(debounce(ref.current, wait), []);
}
