import {lepicardTailwindThemeColors} from './lepicard';
import {cauchoiseTailwindThemeColors} from './cauchoise';
import {lunerayTailwindThemeColors} from './luneray';
import {ThemeConfig} from 'tailwindcss/types/config';

const allColors: Record<string, ThemeConfig['colors']> = {
  lepicard: lepicardTailwindThemeColors,
  cauchoise: cauchoiseTailwindThemeColors,
  luneray: lunerayTailwindThemeColors,
};

export function getTailwindThemeColors(instanceName: string): ThemeConfig['colors'] {
  return allColors[instanceName];
}
