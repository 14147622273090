import {IAccountModel} from '@stores/accounts/store';
import {IAccountStoreModel} from './store';

export default (self: IAccountStoreModel) => {
  return {
    findAccountById(id: string) {
      const accountIndex = self.list.findIndex((account) => account.id === id);

      return accountIndex !== -1 ? self.list[accountIndex] : null;
    },

    get numberOfAccounts() {
      return self.list.length;
    },

    // returns a list of the accounts excluding the selected one
    get unSelectedAccounts() {
      if (self.selectedAccount) {
        const selectedAccount = self.selectedAccount as IAccountModel;
        return self.list.filter((account) => account.id !== selectedAccount.id);
      } else {
        return self.list;
      }
    },

    get salesPeople() {
      return (
        self.selectedAccount?.contact?.ContactSalesPeople?.filter(
          (person) => person.SalesPersonPurchaser && ['PA', 'PV'].includes(person.prodActivityCode)
        ) ?? []
      );
    },
  };
};
