import React, {useState} from 'react';
import PdfGenerator, {IColumn} from '@components/PdfGenerator';
import {toast} from 'react-toastify';
import FileSaver from 'file-saver';
import {pdf, StyleSheet, Text} from '@react-pdf/renderer';
import {defaultErrorToast} from '@utils/toast';
import {toaster} from '@utils/dictionnary';
import {useStore} from '@stores/index';
import {IPdfHeaderRow} from '@components/PdfGenerator/PdfHeaderRow/PdfHeaderRow';
import {IAccountModel} from '@stores/accounts/store';

interface IPdfSettings {
  title: string;
  subtitle?: string;
  fileName: string;
  loadDatas?: () => Promise<any[]> | any[];
  datas?: any[];
  filters?: any[];
  groupByLibelle?: string;
  subComponent?: () => void;
  pdfType?: string;
  headerValue?: object;
}

const usePdfGenerator = (columns: IColumn[], subColumns?: IColumn[]) => {
  const [isPending, setIsPending] = useState(false);

  const {sso: ssoStore} = useStore();
  const currentAccount = ssoStore.selectedExtranetUser;

  const contactInfos: IPdfHeaderRow['contactInformations'] = {
    name: currentAccount?.contact?.name ?? '',
    address: currentAccount?.contact?.address ?? '',
    zipCode: currentAccount?.contact?.postalCode ?? '',
    city: currentAccount?.contact?.city ?? '',
  };

  const download = async ({
    loadDatas,
    datas,
    title,
    subtitle,
    fileName,
    filters,
    groupByLibelle,
    subComponent,
    pdfType,
    headerValue,
  }: IPdfSettings) => {
    setIsPending(true);

    try {
      const downloadToaster = toast.info('Téléchargement en cours', {
        autoClose: false,
        hideProgressBar: true,
      });
      const result = datas ? datas : loadDatas ? await loadDatas() : [];
      const blob = await pdf(
        <PdfGenerator
          datas={result}
          columns={columns}
          subColumns={subColumns}
          title={title}
          subtitle={subtitle}
          filters={filters}
          pdfType={pdfType}
          headerValue={headerValue}
          groupByLibelle={groupByLibelle}
          subComponent={subComponent ? (data) => subComponent(data) : undefined}
          contactInfos={contactInfos}
        />
      ).toBlob();
      const contentType = 'data:application/pdf;base64';
      const blobToDownload = new Blob([blob], {type: contentType});
      FileSaver.saveAs(blobToDownload, `${fileName}.pdf`);
      toast.update(downloadToaster, {
        render: 'Téléchargement réussi',
        type: toast.TYPE.SUCCESS,
        autoClose: 1000,
      });
    } catch (error) {
      defaultErrorToast(toaster.error.pdf);
    }

    setIsPending(false);
  };

  return {
    download,
    isPending,
  };
};

export default usePdfGenerator;
