import {addBreadcrumb, captureException, init} from '@sentry/react';
import {isUndefined} from '@lepicard/utils';
import {SeverityLevel} from '@sentry/types/types/severity';
import * as Sentry from '@sentry/react';

const enum LogLevel {
  emerg = 'emerg',
  alert = 'alert',
  crit = 'crit',
  error = 'error',
  warning = 'warning',
  notice = 'notice',
  info = 'info',
  debug = 'debug',
}

interface LogData {
  error?: Error;
  message: string;
  data?: Record<string | number | symbol, unknown>;
}
interface LogDataError {
  error?: Error;
  _message: string;
  data?: Record<string | number | symbol, unknown>;
}

export class Logger {
  private static levelsMap: Record<LogLevel, SeverityLevel> = {
    emerg: 'fatal',
    alert: 'error',
    crit: 'error',
    error: 'error',
    warning: 'warning',
    notice: 'log',
    info: 'info',
    debug: 'debug',
  };

  private static logStyles: Record<LogLevel, [string, string]> = {
    emerg: ['#db431d', 'white'],
    alert: ['#db431d', 'white'],
    crit: ['#db431d', 'white'],
    error: ['#db431d', 'white'],
    warning: ['#f4a261', 'black'],
    notice: ['#e9c46a', 'black'],
    info: ['#2a9d8f', 'white'],
    debug: ['#264653', 'white'],
  };

  public static init() {
    if (process.env.NODE_CONFIG_ENV === 'development') {
      return;
    }
    init({
      dsn: process.env.SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          maskAllText: process.env.NODE_CONFIG_ENV === 'production',
          blockAllMedia: process.env.NODE_CONFIG_ENV === 'production',
        }),
      ],
      environment: process.env.NODE_CONFIG_ENV,
      release: process.env.APP_RELEASE,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: process.env.NODE_CONFIG_ENV === 'production' ? 0.1 : 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  private static log(level: LogLevel, logData: LogData | LogDataError) {
    const logStyle = this.logStyles[level];
    const message = 'message' in logData ? logData.message : logData._message;
    const logOutput = [
      '%c LP ',
      `background: ${logStyle[0]}; color: ${logStyle[1]};`,
      message,
      logData.data,
      logData.error,
    ].filter((e) => !isUndefined(e));
    console.log(...logOutput);

    if (level === LogLevel.debug) {
      return;
    }

    const isError = !isUndefined(logData.error);

    addBreadcrumb({
      type: isError ? 'error' : 'default',
      level: this.levelsMap[level],
      message: message,
      timestamp: +new Date(),
      data: logData.data,
    });

    if (isError) {
      captureException(logData.error);
    }
  }

  public static emerg = (logData: LogDataError) => Logger.log(LogLevel.emerg, logData);
  public static alert = (logData: LogDataError) => Logger.log(LogLevel.alert, logData);
  public static crit = (logData: LogDataError) => Logger.log(LogLevel.crit, logData);
  public static error = (logData: LogDataError) => Logger.log(LogLevel.error, logData);
  public static warning = (logData: LogData) => Logger.log(LogLevel.warning, logData);
  public static notice = (logData: LogData) => Logger.log(LogLevel.notice, logData);
  public static info = (logData: LogData) => Logger.log(LogLevel.info, logData);
  public static debug = (logData: LogData) => Logger.log(LogLevel.debug, logData);
}
