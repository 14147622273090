import React, {useMemo} from 'react';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardRowCountWrapper, CardRowCountWrapperItem} from '@components/UI/Card/CardRowCountWrapper';
import {getShowMoreInvoicesLabel} from '@components/UI/Card/CardShowMore';
import {formatNumber} from '@utils/number';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {IApproInvoicesRow} from '@components/ApproInvoices/ApproInvoicesRows/ApproInvoicesRows';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {isDef} from "@lepicard/utils";
import {
  ApproInvoicesStatusEnum,
  ApproInvoicesStatusTag
} from "@components/ApproInvoices/ApproInvoicesStatusTag/ApproInvoicesStatusTag";

interface IInvoicesGeneralSubRow {
  product: any;
  invoice: any;
}

const SubRow: React.FC<IInvoicesGeneralSubRow> = ({product, invoice}) => {
  const quantityLabel = formatNumber(invoice.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(invoice.unitOfMeasureCode);

  const netUnitPriceLabel = formatNumber(invoice.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  const amountHtLabel = formatNumber(invoice.amount as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardSubTitle title={`FACTURE ${invoice.documentNo}`} />
        {isDef(invoice.letter) && <ApproInvoicesStatusTag status={ApproInvoicesStatusEnum.SOLD} />}
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande lié" value={invoice.orderNo} />
        {isDef(invoice.contractNo) && <CardInfo label="Contrat lié" value={invoice.contractNo} />}
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo label="Quantité" value={quantityLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardInfo label="Prix unitaire HT" value={netUnitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo label="Montant HT" value={amountHtLabel} flags={[CardInfoFlag.QUANTITY]} />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const ApproInvoicesRowByProduct: React.FC<IApproInvoicesRow> = ({data}: any) => {
  const allSubRows = useMemo<CardRowCountWrapperItem[]>(
    () =>
      data.invoices.map((i: any) => ({
        params: {product: data, invoice: i},
        key: i.uniqueId,
        render: SubRow,
      })),
    [data.invoices]
  );

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.description} subTitle={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      <CardRowCountWrapper items={allSubRows} numberOfItemsInPreview={0} showMoreLabelFn={getShowMoreInvoicesLabel} />
    </Card>
  );
};
