import React from 'react'
import Alert from '@components/Alert'
import { useField, FieldInputProps, FieldMetaProps, FieldHelperProps } from 'formik'

export interface ICustomFieldInputProps extends FieldInputProps<any> {
  error?: boolean
}

export interface IFieldWrapper {
  name: string
  label?: string
  className?: string
  labelClassName?: string
  children?: (
    field: ICustomFieldInputProps,
    meta: FieldMetaProps<any>,
    helper: FieldHelperProps<any>
  ) => React.ReactNode
  required?: boolean
  direction?: 'column' | 'row' | 'row-reverse'
  suffix?: string | React.ReactNode
  inputContainerWidth?: number | string
  showError?: boolean
  inputContainerClassName?: string
}

const FieldWrapper: React.FC<IFieldWrapper> = ({
  name,
  direction = 'column',
  children,
  label,
  className = '',
  labelClassName = '',
  suffix,
  required,
  inputContainerWidth,
  showError = true,
  inputContainerClassName = ''
}) => {
  let [field, meta, helper] = useField(name)

  const inputId = `input-${name}`

  const hasError = Boolean(meta.touched && meta.error)
  const error = hasError ? meta.error : undefined

  return (
    <div className={`containerColumn${direction === 'row' ? ' directionRow' : ''} justify-center ${className} `}>
      {error && showError && <Alert text={error} className="mt-5" />}
      <div
        className={`flex flex-row items-center ${inputContainerClassName}`}
        style={{ width: '100%', minWidth: inputContainerWidth }}
      >
        {children && children({ ...field, error: hasError }, meta, helper)}
        {typeof suffix === 'string' ? <div className="p-5 ml-10">{suffix}</div> : suffix}
      </div>
      {label && (
        <label htmlFor={inputId} className={`label--input ${labelClassName}`}>
          {label}
          {required && <i className="fc-red-STATUS-primary"> *</i>}
        </label>
      )}
    </div>
  )
}

export default FieldWrapper
