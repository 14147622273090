export const speciesList = (species : any) => {
  let uniqueSubfamily = species.map(specie => ({ value: specie.code, label: specie.subFamilyName }))
  const filteredArr = uniqueSubfamily.reduce((acc, current) => {
    const x = acc.find(item => item.label === current.label);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return filteredArr

}
