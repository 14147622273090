import {isNull} from '@lepicard/utils';
import {capitalizeFirstLetter} from '@utils/string';

type IRenameSpecie = {
  <T>(value: string): string;
  <T>(value: string | null): string | null;
};

export function renameSpecie(value: string | null) {
  if (isNull(value)) {
    return null;
  }

  // Handle specific case for "ORGE DE BRASSERIE D HIVER"
  value = value.replace('D HIVER', "D'HIVER");

  const splittedValue = value.split(' ');

  const SPECIES_MAPPING: Record<string, {name: string}> = {
    BLE: {name: 'Blé'},
    MAIS: {name: 'Maïs'},
    FEVEROLE: {name: 'Féverole'},
    IMPURETE: {name: 'Impureté'},
  };

  if (splittedValue.length > 1 && SPECIES_MAPPING[splittedValue[0]]) {
    return `${SPECIES_MAPPING[splittedValue[0]].name}  ${splittedValue[1].toLowerCase()}`;
  }
  if (!SPECIES_MAPPING[value]) {
    const valueToLowercase = value.toLowerCase();
    return capitalizeFirstLetter(valueToLowercase);
  } else {
    return SPECIES_MAPPING[value].name;
  }
}
