import React, {createContext, useEffect, useMemo, useState} from 'react';
import {useStore} from '@stores/index';
import {observer} from 'mobx-react-lite';
import {Formik} from 'formik';
import {fetchApproContracts, IApproContractsFilter} from '@services/navagri';
import Loader from '@components/Loader';
import usePagination from '@hooks/usePagination';
import {ApproContractsListFilters, groupByOption} from '../filters';
import Pagination from '@components/Pagination';
import {ApproContractsRows} from '@components/ApproContracts/ApproContractsRow/ApproContractsRows';
import {isUndefined} from '@lepicard/utils';
import {useRerenderSafeDebounce} from '@hooks/useRerenderSafeDebounce';
import NoData from '@components/NoData/NoData';
import {getNoDataExerciceMessage} from '@utils/tables';
import usePdfGenerator from '@hooks/usePdfGenerator';
import {columns, csvColumns, headerValue, pdfCol, pdfSubCol} from '../column';
import {format} from 'date-fns';
import useCsvGenerator from '@hooks/useCsvGenerator';

interface IContracstListFormContext {
  formValues: any;
  refreshFormValues: (newValues: any) => void;
  onDownloadPdf: () => void;
  onDownloadCsv: () => void;
}

export const ContractsListFormContext = createContext<IContracstListFormContext>({
  formValues: {},
  refreshFormValues: () => {},
  onDownloadPdf: () => {},
  onDownloadCsv: () => {},
});

const ApproContractsList: React.FC = observer(() => {
  const {approContractsStore, campaigns: campaignsStore} = useStore();
  const {currentCampaign} = campaignsStore;

  const list = approContractsStore.list.toJS();

  const initialFormValues = {
    campaignId: approContractsStore.getCampaignsOptions.some((option) => option.value === currentCampaign!.id)
      ? currentCampaign!.id
      : 'all',
    groupBy: 'contract',
    status: [],
    itemFamilyCode: [],
    search: '',
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const filters = useMemo<IApproContractsFilter>(() => {
    const newFilters: IApproContractsFilter = {};

    if (formValues.campaignId !== 'all') {
      newFilters.campaignId = formValues.campaignId;
    }

    newFilters.groupBy = formValues.groupBy;

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    if (formValues.status.length >= 0) {
      newFilters.status = formValues.status;
    }

    if (formValues.itemFamilyCode.length >= 0) {
      newFilters.itemFamilyCode = formValues.itemFamilyCode;
    }

    return newFilters;
  }, [formValues]);

  const loadDataToExport = async () => {
    const {data} = await fetchApproContracts({$limit: Number.MAX_SAFE_INTEGER, ...filters, $skip: 0});
    return data;
  };

  const {isPending: isPdfPending, download: downloadPdf} = usePdfGenerator(pdfCol, pdfSubCol);
  const {isPending: isCsvPending, download: downloadCsv} = useCsvGenerator(csvColumns);

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadDataToExport,
      title: `APPRO. - MES CONTRATS`,
      fileName: `Appro_contrats_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Appro',
      subtitle: 'approContract',
      headerValue: headerValue(formValues, groupByOption, approContractsStore),
    });
  };
  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadDataToExport,
      fileName: `Export_Appro_contrats_${formValues.campaignId}`,
      headerValue: headerValue(formValues, groupByOption, approContractsStore),
    });
  };

  const loadData = (page: number) => {
    approContractsStore.fetchContracts(page, filters);
  };

  const {pageNumber, onPageChange, updatePagination} = usePagination(loadData);

  const fetchContractsDebounced = useRerenderSafeDebounce(approContractsStore.fetchContracts, 500);
  useEffect(() => {
    fetchContractsDebounced(1, filters);
    updatePagination(1);
  }, [filters]);

  const displayLoader = ['error', 'pending'].includes(approContractsStore.fetchContractsState.state);

  return (
    <ContractsListFormContext.Provider
      value={{
        formValues: formValues,
        refreshFormValues: (newValues) => setFormValues(newValues),
        onDownloadPdf,
        onDownloadCsv,
      }}
    >
      <div>
        <Formik initialValues={formValues} onSubmit={() => {}}>
          <ApproContractsListFilters />
        </Formik>

        {displayLoader ? (
          <Loader topMargin />
        ) : (
          <>
            <ApproContractsRows />

            {(Array.isArray(list) && list.length === 0) || !Array.isArray(list) ? (
              <NoData message={getNoDataExerciceMessage(filters, currentCampaign?.id)} />
            ) : (
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={10}
                totalItemsCount={approContractsStore.fetchContractsState.total ?? 0}
                onChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </ContractsListFormContext.Provider>
  );
});

const ApproContractsListContainer: React.FC = observer(() => {
  const {campaigns: campaignsStore, approContractsStore} = useStore();

  const areCampaignsOptionsLoaded = ['done', 'error'].includes(
    approContractsStore.fetchContractsGlobalFiltersOptionsState.state
  );

  useEffect(() => {
    if (!areCampaignsOptionsLoaded) {
      approContractsStore.fetchContractsGlobalFiltersOptions();
    }
  }, []);

  if (isUndefined(campaignsStore.currentCampaign?.id) || !areCampaignsOptionsLoaded) {
    return <Loader topMargin />;
  }

  return <ApproContractsList />;
});

export default ApproContractsListContainer;
