import React from 'react';
import './style.scss';
import {isDef} from '@lepicard/utils';

interface IHomeCard {
  titre: string;
  description: string;
  notif?: boolean;
  icon?: string;
}

const HomeCard: React.FC<IHomeCard> = ({titre, description, icon, notif = false}) => {
  const notifCount = 2;
  return (
    <>
      <div className="Card HomeCard mb-3">
        <div className="flex items-center">
          <div className="flex items-start">
            {isDef(icon) && <img src={icon} alt="" className="mr-3" />}
            <div>
              <div className="HomeEntry-green-1 HomeEntry-text mb-2 flex items-center">
                {titre}
                {false && <span className="bulle text-orange">{notifCount}</span>}
              </div>
              <div className="HomeEntry-paragrah-1 HomeEntry-grey-6">{description}</div>
            </div>
          </div>
          <div className="flex-grow"></div>
          <div className="HomeEntry-green-1 text-center">
            <img className="max-w-none" src={'/icons/HomePage/Icon_Chevron.svg'} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeCard;
