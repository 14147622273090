import {apiErrorFieldMapping, IActivationFields} from './index';
import {isDef, isNull} from '@lepicard/utils';

export interface CreateUserErrors {
  [key: string]: string | undefined;

  activationCode?: string;

  userCivility?: string;
  userFirstname?: string;
  userLastname?: string;
  userEmail?: string;
  userPhone?: string;

  accountLegalForm?: string;
  accountName?: string;
  accountPostalCode?: string;
  accountCity?: string;
  accountEmail?: string;
  accountPhone?: string;

  cgu?: string;
}

export default (values: IActivationFields) => {
  const errors: CreateUserErrors = {};

  if (!isDef(values.activationCode)) {
    errors.activationCode = "Veuillez saisir un code d'activation";
  }
  if (!values.cgu) {
    errors.cgu = 'Veuillez valider les conditions générales de ventes';
  }

  return errors;
};
