import React from 'react'
import Button from '../Button'
import Loader from '@components/Loader'

interface IExportButtons {
  pdfLoading?: boolean
  csvLoading?: boolean
  onDownloadPdf: () => void
  onDownloadCsv: () => void
}

const ExportButtons: React.FC<IExportButtons> = ({
  onDownloadPdf,
  onDownloadCsv,
  pdfLoading = false,
  csvLoading = false
}) => {
  return (
    <div className="p-5 flex flex-col md:flex-row items-center">
      <div className="whitespace-nowrap font-medium mr-10 ml-10 mb-5 md:mb-0">Exporter la vue :</div>
      <div className="flex flex-row">
        <Button buttonClass="outline" className="border-red bg-white mr-5" onClick={onDownloadPdf}>
          {pdfLoading ? (
            <Loader color="#e53e3e" className="w-5 h-10" />
          ) : (
            <img src="/img/SVG/pdf-icon.svg" className="max-w-none w-6 h-6" />
          )}
        </Button>
        <Button buttonClass="outline" className="border-green-200 bg-white" onClick={onDownloadCsv}>
          {csvLoading ? (
            <Loader color="#38a169" className="w-5 h-10" />
          ) : (
            <img src="/img/SVG/csv-icon.svg" className="max-w-none w-6 h-6" />
          )}
        </Button>
      </div>
    </div>
  )
}

export default ExportButtons
