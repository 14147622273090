import React from 'react';
import './styles.scss';

interface IPageHead {
  title: string;
  description?: React.ReactNode;
}

const PageHead: React.FC<IPageHead> = ({title, description}) => {
  return (
    <div className="PageHead pt-8 md:pt-16 mb-8 relative -mt-7">
      <div className="bg-green-gradient-200 absolute inset-0 -bottom-[75px] -mx-4 lg:-mx-7"></div>
      <div className="relative">
        <h1 className="text-title-1 mb-3.5 uppercase">{title}</h1>
        {description && <div className="text-paragraph-1 lg:max-w-[580px]">{description}</div>}
      </div>
    </div>
  );
};

export default PageHead;
