import { ServiceStateModel } from '@stores/models'
import { types, Instance } from 'mobx-state-tree'

export interface ISpecieModel extends Instance<typeof SpecieModel> { }
export interface ISpeciesStoreModel extends Instance<typeof SpeciesStoreModel> { }

export const SpecieModel = types.model({
  id: types.identifier,
  name: types.string,
  subFamilyCode: types.string,
})

export const SubfamilyListModel = types.model({
  code: types.array(types.string),
  name: types.string
})

export const SpeciesStoreModel = types.model({
  list: types.array(SpecieModel),
  subfamilyList: types.array(SubfamilyListModel),
  fetchSpeciesState: ServiceStateModel
})

export const SpeciesListModel = types.model({
  code : types.string,
  name : types.string,
  itemNo: types.string,
  subFamilyCode : types.string,
  subFamilyName: types.string
})
