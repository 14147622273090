import React from 'react';
import {formatNumber} from '@utils/number';
import {useStore} from '@stores/index';
import {isNull} from '@lepicard/utils';

export const CollectInvoicesTotal: React.FC = () => {
  const {collectInvoicesStore} = useStore();

  if (isNull(collectInvoicesStore.global)) {
    return null;
  }

  const totalQuantityLabel = formatNumber(collectInvoicesStore.global.totalQuantity)
    .round(3)
    .spaces()
    .renderWithUnit(collectInvoicesStore.global.totalQuantityUnitOfMeasure);

  const totalAmountLabel = formatNumber(collectInvoicesStore.global.totalAmount).fixed().spaces().renderWithUnit('€');
  const totalAmountIncludingVATLabel = formatNumber(collectInvoicesStore.global.totalAmountIncludingVAT)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <div className="DeliverySummary">
      <div className="DeliverySummary-item">
        <div className="DeliverySummary-label">Quantité facturée :</div>
        <div className="DeliverySummary-value">{totalQuantityLabel}</div>
      </div>

      <div className="DeliverySummary-item">
        <div className="DeliverySummary-label">Chiffre d’affaires HT :</div>
        <div className="DeliverySummary-value">{totalAmountLabel}</div>
      </div>
      <div className="DeliverySummary-item">
        <div className="DeliverySummary-label">Chiffre d’affaires TTC :</div>
        <div className="DeliverySummary-value">{totalAmountIncludingVATLabel}</div>
      </div>
    </div>
  );
};
