import React from 'react';
import {formatDate} from '@utils/string';
import {renameSpecie} from '@utils/species/species';
import {isUndefined} from '@lepicard/utils';
import {getContractTypeLabel} from '@utils/contracts/contractType';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow} from '@components/UI/Card/CardRow';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {
  CollectInvoicesStatusEnum,
  CollectInvoicesStatusTag,
} from '@components/CollectInvoices/CollectInvoicesStatusTag/CollectInvoicesStatusTag';
import {ICollectInvoiceModel} from '@stores/collect-invoices/models';
import useDownloadPdf from '@hooks/useDownloadPdf';
import {formatNumber} from '@utils/number';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {CardSeparator} from '@components/UI/Card/CardSeparator';

interface ICollectInvoiceRow {
  collectInvoice: ICollectInvoiceModel;
}

export const CollectInvoicesRow: React.FC<ICollectInvoiceRow> = ({collectInvoice}) => {
  const allContractTypes = collectInvoice.contracts
    .map((c) => getContractTypeLabel(c.no, c.type))
    .filter((ct) => !isUndefined(ct));

  const quantityLabel = formatNumber(collectInvoice.quantity)
    .round()
    .spaces()
    .renderWithUnit(collectInvoice.quantityUnitOfMeasureCode);

  const amountLabel = formatNumber(collectInvoice.amount).fixed().spaces().renderWithUnit('€');

  const amountIncludingVatLabel = formatNumber(collectInvoice.amountIncludingVat).fixed().spaces().renderWithUnit('€');

  const {download: downloadPdf} = useDownloadPdf();

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle
            title={`FACTURE N°${collectInvoice.no}`}
            subTitle={formatDate(collectInvoice.documentDate)}
            onDownload={() => downloadPdf(collectInvoice.no, 1, collectInvoice.no)}
          />
          <div className="Card-tags">
            <CollectInvoicesStatusTag
              status={
                collectInvoice.isOpenInvoice ? CollectInvoicesStatusEnum.NOT_SETTLED : CollectInvoicesStatusEnum.SETTLED
              }
            />
          </div>
        </CardTitleContainer>
      </CardRow>

      <CardRow>
        <CardInfosWrapper>
          <CardInfoGroup>
            <div className="CardBrand">
              <div className="CardBrand-name">{collectInvoice.species.map(renameSpecie).join(', ')}</div>
              {allContractTypes.length > 0 &&
                allContractTypes.map((ct, idx) => (
                  <div key={idx}>
                    <span className="CardBrand-name">{ct!.name}</span>
                    <span className="CardBrand-subname">{ct!.subName}</span>
                  </div>
                ))}
            </div>
          </CardInfoGroup>
          <CardInfo label="N° de contrat" value={collectInvoice.contracts.map((c) => c.no).join(', ')} />
          <CardInfo label="Date d'échéance" value={formatDate(collectInvoice.dueDate)} />
        </CardInfosWrapper>
      </CardRow>

      <CardRow className="bg-grey-100">
        <CardInfosWrapper>
          <CardInfo
            label="Quantité facturée"
            value={quantityLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
          <CardSeparator className="lg:hidden" />
          <CardInfo label="Chiffre d’affaires HT" value={amountLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardInfo
            label="Chiffre d’affaires TTC"
            value={amountIncludingVatLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
        </CardInfosWrapper>
      </CardRow>
    </Card>
  );
};
