import React from 'react';
import {Progressbar, ProgressbarTheme} from '@components/UI/Progressbar/Progressbar';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {CardShowMore} from '@components/UI/Card/CardShowMore';
import {Card} from '@components/UI/Card/Card';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {Accordion} from '@components/UI/Accordion/Accordion';
import {formatDate} from '@utils/string';
import {getShowMoreOrderLabel, OrdersRowsCardTags} from '@components/Orders/OrdersRow/OrdersRows';
import OrdersStatusTag, {OrdersStatusEnum} from '@components/Orders/OrdersStatusTag';
import {formatNumber} from '@utils/number';

const OrdersSubRow: React.FC<{item: any}> = ({item}) => {
  const unitLabel = `\u00A0${item.productData.quantityUnit}`;
  const totalQuantityLabel = formatNumber(item.productData.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(item.productData.quantityUnit);
  const unitPriceLabel =
    `${formatNumber(item.productData.netUnitPrice as number)
      .fixed()
      .spaces()
      .renderWithUnit('€')}\u00A0/${unitLabel}` ?? '';
  const amountLabel = formatNumber(item.productData.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');
  const restToDeliverLabel = `${formatNumber(item.productData.restToDeliver as number)
    .round()
    .spaces()
    .render()}\u00A0/\u00A0${totalQuantityLabel}`;

  // TODO: Redevance phyto & co

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardTitle title={`COMMANDE ${item.orderData.id}`} />
        <OrdersRowsCardTags
          invoiceStatut={item.orderData.invoiceStatut}
          shipmentStatut={item.orderData.shipmentStatut}
        />
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Prévision de livraison" value={formatDate(item.orderData.requestedDeliveryDate)} />
        {item.orderData.linkedContracts.length > 0 && (
          <CardInfo label="Contrat lié" value={item.orderData.linkedContracts.join(', ')} />
        )}
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo
            label="Quantité"
            value={totalQuantityLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
          <CardInfo label="Prix unitaire HT" value={unitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo label="Montant TTC" value={amountLabel} flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Reste à livrer"
          value={restToDeliverLabel}
          flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

const OrdersSubRowReturn: React.FC<{item: any}> = ({item}) => {
  const unitLabel = `\u00A0${item.quantityUnit}`;
  const totalQuantityLabel = formatNumber(item.quantity as number)
    .negate()
    .round()
    .spaces()
    .renderWithUnit(item.quantityUnit);
  const unitPriceLabel =
    `${formatNumber(item.netUnitPrice as number)
      .fixed()
      .spaces()
      .renderWithUnit('€')}\u00A0/${unitLabel}` ?? '';
  const amountLabel = formatNumber(item.amountIncludingVAT as number)
    .negate()
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardTitle title={`Retour ${item.rv}`} />
        <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande liée" value={item.cmdId} />
        <CardInfo label="Date de retour" value={formatDate(item.returnDate)} />
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper className="mb-4">
        <CardInfoGroup>
          <CardInfo
            label="Quantité"
            value={totalQuantityLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
          />
          <CardInfo
            label="Prix unitaire HT"
            value={unitPriceLabel}
            flags={[CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
          />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo
            label="Montant TTC"
            value={amountLabel}
            flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.RETURN]}
          />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const OrdersRowByItem: React.FC = ({data}: any) => {
  const totalQuantityLabel = formatNumber(data.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(data.quantityUnit);
  const restToDeliverLabel = `${formatNumber(data.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/')}\u00A0${totalQuantityLabel}`;

  const totalItems = data.orders.length + data.returnedOrders.length;

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.description} subTitle={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfo label="Quantité" value={totalQuantityLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardInfo
            label="Montant TTC"
            value={formatNumber(data.amountIncludingVAT as number)
              .fixed()
              .spaces()
              .renderWithUnit('€')}
            flags={[CardInfoFlag.QUANTITY]}
          />
          <CardInfo label="Reste à livrer" value={restToDeliverLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardSeparator className="lg:hidden" />
          <CardInfo
            label="Pourcentage livré"
            value={<Progressbar percent={data.deliveredPercentage} theme={ProgressbarTheme.SOFT} />}
            flags={[CardInfoFlag.PROGRESSBAR, CardInfoFlag.END_OF_LINE]}
          />
        </CardInfosWrapper>
      </CardRow>
      <Accordion
        foot={({onClick, toggleIcon, isAccordionOpen}: any) => (
          <CardShowMore toggleIcon={toggleIcon} onClick={onClick}>
            {getShowMoreOrderLabel(isAccordionOpen, totalItems)}
          </CardShowMore>
        )}
      >
        {data.orders.map((item) => (
          <OrdersSubRow key={item.orderData.id} item={item} />
        ))}
        {data.returnedOrders.map((item, idx) => (
          <OrdersSubRowReturn key={idx} item={item} />
        ))}
      </Accordion>
    </Card>
  );
};
