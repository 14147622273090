import React, {useContext} from 'react';
import {withRouter} from 'react-router';
import './styles.scss';
import {observer} from 'mobx-react-lite';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';
import {Link} from 'react-router-dom';
import {useStore} from "@stores/index";
import EnvironmentService from "@utils/environmentService";

const Login: React.FC = observer(({}) => {
  const {login, logOut, error}: IAuthContext = useContext(AuthContext);
  const {sso: SSOStore} = useStore();

  function resetCurrentSession() {
    logOut();
    SSOStore.additionalInformationsState.resetState();
  }

  const skippedErrors = [
     'Bad authorization state. Refreshing the page and log in again might solve the issue.'
  ]

  if ((error && !skippedErrors.includes(error)) || SSOStore.additionalInformationsState.hasFailed) {
    return (
      <>
        <div className="SpineBubble-content">
          <div className="BubbleContent">
            <div className="BubbleContent-header">
              <h1 className="Login-title">Une erreur s’est produite.</h1>

              <p>Nous sommes désolé, une erreur s’est produite. Veuillez réessayer ultérieurement.</p>

              <p>
                <strong>Vous pouvez fermer cette fenêtre de navigation.</strong>
              </p>
            </div>
            <div className="BubbleContent-body">
              <p>
                Si le problème persiste contacter notre service client au 02 32 70 41 85 ou par mail
                à <a href={`mailto:${EnvironmentService.instanceConfig.login.issueEmail}`}>{EnvironmentService.instanceConfig.login.issueEmail}</a>.
              </p>
            </div>
          </div>
        </div>

        <div className="SpineBubble-footer">
          <button className="btn btn-primary uppercase" onClick={() => resetCurrentSession()}>
            Se déconnecter
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="SpineBubble-content">
        <div className="BubbleContent">
          <div className="BubbleContent-header">
            <h1 className="Login-title">Connexion</h1>
            <p>Accédez à votre compte {EnvironmentService.instanceConfig.global.instanceFullName}</p>
          </div>
          <div className="BubbleContent-body">
            <div className="Login-buttons">
              <button className="btn btn-primary uppercase" onClick={() => login()}>
                Se connecter
              </button>
              <Link to="/activation" className="btn btn-primary uppercase">
                Activer votre compte
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/*
          <div className="SpineBubble-footer"></div>
          */}
    </>
  );
});

export default withRouter(Login);
