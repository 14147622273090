import React from 'react';
import {OrdersRowByOrder} from '@components/Orders/OrdersRow/OrdersRowByOrder';
import {OrdersRowByItem} from '@components/Orders/OrdersRow/OrdersRowByItem';
import {OrdersRowByFamily} from '@components/Orders/OrdersRow/OrdersRowByFamily';
import {useStore} from '@stores/index';
import {isUndefined} from '@lepicard/utils';
import OrdersStatusTag, {OrdersStatusEnum} from '@components/Orders/OrdersStatusTag';

export function getShowMoreProductLabel(isOpen: boolean, length: number) {
  const action = isOpen ? 'Masquer' : 'Afficher';
  return length > 1 ? `${action} les ${length} articles` : `${action} l'article`;
}

export function getShowMoreOrderLabel(isOpen: boolean, length: number) {
  const action = isOpen ? 'Masquer' : 'Afficher';
  return length > 1 ? `${action} les ${length} commandes` : `${action} la commande`;
}

export function getShowMoreContractLabel(isOpen: boolean, length: number) {
  const action = isOpen ? 'Masquer' : 'Afficher';
  return length > 1 ? `${action} les ${length} contrats` : `${action} le contrat`;
}

export const OrdersRowsCardTags: React.FC<{invoiceStatut: number; shipmentStatut: number}> = ({
  invoiceStatut,
  shipmentStatut,
}) => {
  const isSettledStatus = invoiceStatut === 999_999 || shipmentStatut === 999_999;
  return (
    <div className="Card-tags">
      {isSettledStatus ? (
        <OrdersStatusTag status={OrdersStatusEnum.SETTLED} />
      ) : (
        <>
          {invoiceStatut === 2 && <OrdersStatusTag status={OrdersStatusEnum.BILLED} />}
          {shipmentStatut === 2 && <OrdersStatusTag status={OrdersStatusEnum.SHIPPED} />}
        </>
      )}
    </div>
  );
};

export const OrdersRows: React.FC = () => {
  const {approOrdersStore} = useStore();
  const list = approOrdersStore.list.toJS();

  return (
    <div className="flex flex-col gap-3.5 lg:gap-5">
      {list.map((data) => {
        if (!isUndefined(data.id)) {
          return <OrdersRowByOrder key={data.id} data={data} />;
        }
        if (!isUndefined(data.description)) {
          return <OrdersRowByItem key={data.description} data={data} />;
        }
        if (!isUndefined(data.familyCode)) {
          return <OrdersRowByFamily key={data.familyCode} data={data} />;
        }
        return null;
      })}
    </div>
  );
};
