export default `
<section class="bg-lighter-gray xs-pt-30 xs-pb-100 lg-pb-60">
        <div class="container">
            
<p>Le site LEPICARD AGRICULTURE offre à chaque utilisateur l’accès à un certain nombre de services disponibles au moment de sa connexion.</p>



<p>Les présentes Conditions Générales d’Utilisation ont pour objet de fixer les règles d’utilisation du Site par l’Utilisateur ainsi que les obligations de LEPICARD AGRICULTURE.<br>Ainsi, l’Utilisateur s’engage, lors de chacune de ses visites sur le Site, à respecter les présentes Conditions Générales d’Utilisation qui s’appliquent à l’ensemble des produits et services disponibles sur le Site.</p>



<p>En conséquence, l’Utilisateur accepte, pleinement et sans aucune réserve, l’ensemble des présentes Conditions Générales d’Utilisation du Site avant toute utilisation du Site.</p>



<p>L’acceptation des Conditions Générales d’Utilisation intervient lorsque l’utilisateur, après avoir rempli le Formulaire d’Inscription, clique sur le bouton de validation figurant sur la page récapitulative.</p>



<p>LEPICARD AGRICULTURE est libre de modifier les Conditions Générales d’Utilisation à tout moment. Il est donc conseillé à l’Utilisateur de se référer, régulièrement, à la dernière version des Conditions Générales d’Utilisation disponible en permanence à l’adresse suivante : www.lepicard.fr</p>



<p><strong><em>I. DEFINITIONS</em></strong><strong></strong></p>



<p>Dans les présentes Conditions Générales d’Utilisation, les mots ou expressions commençant avec une majuscule auront la signification suivante :</p>



<ul><li><strong>Utilisateur Enregistré</strong>&nbsp;désigne toute personne physique ayant accès au Site quel que soit le lieu où elle se trouve et les modalités de sa connexion au Site.</li></ul>



<ul><li><strong>Codes d’accès</strong>&nbsp;désigne l’identifiant de l’Utilisateur Enregistré et son mot de passe de connexion fournis à LEPICARD AGRICULTURE lors de son inscription, lui permettant de s’identifier et de se connecter aux différents services fournis sur le Site.</li></ul>



<ul><li><strong>Compte</strong>&nbsp;désigne l’espace mis à la disposition de l’Utilisateur Enregistré sur le Site, lui permettant d’accéder, après s’être identifié par ses Codes d’accès, à toutes les données concernant son contrat, à ses factures éventuelles et à son Profil.</li></ul>



<ul><li><strong>Cookie</strong>&nbsp;désigne un petit fichier d’informations qu’un site Web peut envoyer sur le disque dur d’un ordinateur personnel pour ensuite en retrouver la trace. Certains services personnalisés du Site utilisent, pour leur bon fonctionnement, des cookies temporaires et requièrent l’acceptation par l’Utilisateur de ces cookies. Si le navigateur de l’ordinateur personnel de l’Utilisateur est configuré pour les refuser, l’accès à ces services peut se révéler altéré, voire impossible. Les cookies utilisés sur le Site permettent également d’identifier les services et rubriques que l’Utilisateur a visités, et plus généralement son comportement en matière de visites. Ces informations sont utiles pour mieux personnaliser les services, contenus. L’Utilisateur peut désactiver les cookies ou les supprimer en utilisant les options de son navigateur. Chaque navigateur étant différent, l’Utilisateur est invité à consulter la notice de son navigateur pour le paramétrer à sa convenance. La durée de conservation de ces informations dans l’ordinateur de l’Utilisateur est de douze mois à compter de leur placement.</li></ul>



<ul><li><strong>Informations</strong>&nbsp;désigne l’ensemble des informations et publications accessibles sur le Site.</li></ul>



<ul><li><strong>Profil</strong>&nbsp;désigne les informations communiquées par l’Utilisateur lors de son inscription ou lors d’une connexion, à savoir les données personnelles de l’Utilisateur Enregistré nécessaires à la gestion de son accès sur le Site.</li></ul>



<ul><li><strong>Site</strong>&nbsp;désigne l’ensemble des produits et services proposés par LEPICARD AGRICULTURE sur Internet depuis l’adresse <a href="https://www.lepicard.fr/">Lepicard.fr</a></li></ul>



<ul><li><strong>Titulaire</strong>&nbsp;désigne toute personne physique ou morale titulaire de droits patrimoniaux et/ou moraux sur les Informations.</li></ul>



<p><strong><em>II. OBJET</em></strong><strong></strong></p>



<p>LEPICARD AGRICULTURE accorde à l’Utilisateur une licence d’utilisation personnelle du Site, révocable, non transférable, non cessible, non-exclusive.&nbsp;</p>



<p><strong><em>III. ACCES ET UTILISATION DES INFORMATIONS DISPONIBLES SUR LE SITE</em></strong><strong></strong></p>



<p><strong>3.1</strong>. Les équipements (ordinateurs, téléphones, logiciels, moyens de télécommunication …) permettant l’accès au Site et à certains de ses services spécifiques sont à la charge exclusive de l’Utilisateur, de même que les frais de télécommunications induits par leur utilisation.</p>



<p><strong>3.2</strong>. Lors de son inscription en ligne, l’Utilisateur Enregistré s’engage à fournir des informations vraies, exactes et complètes comme demandées dans le Formulaire d’inscription et donc à les tenir à jour sans délais. Dans l’hypothèse où l’Utilisateur fournirait des informations fausses, inexactes, périmées ou incomplètes, LEPICARD AGRICULTURE &nbsp;est en droit de suspendre ou de résilier et de lui refuser, immédiatement et pour le futur, l’accès à tout ou partie du Site.</p>



<p><strong>3.3</strong>. Les mineurs ne sont pas admis à s’inscrire sur le Site. Toute utilisation des sur le Site par un utilisateur mineur est effectuée sous l’entière responsabilité du (des) titulaire(s) de l’autorité parentale sur l’utilisateur mineur concerné.</p>



<p><strong>3.4.</strong>&nbsp;LEPICARD AGRICULTURE accorde à l’Utilisateur Enregistré un droit de consultation, d’utilisation et d’accès aux Informations dans les conditions suivantes :</p>



<p>De manière générale, et sans que cette liste puisse être considérée comme exhaustive, l’Utilisateur Enregistré s’engage à :</p>



<ul><li>ne télécharger les Informations sur son équipement que pour un usage exclusivement personnel, non marchand et limité dans le temps ; n’imprimer les Informations téléchargées sur support papier qu’à la condition que les copies ainsi constituées fassent l’objet d’un usage exclusivement personnel, ce qui exclut notamment toute reproduction à des fins professionnelles ou commerciales ou de diffusion en nombre, gratuite ou payante ;</li><li>à ne pas recopier tout ou partie du Site sur un autre site ou un réseau interne d’entreprise ;</li><li>ne pas reproduire ou résumer la version numérique des Informations à des fins de revue ou de panorama de presse&nbsp;;</li><li>ne pas réaliser une revue ou un panorama de presse à partir des informations ou photographies signalées comme appartenant à un prestataire tiers, ni à réutiliser ces informations pour les proposer sur quelque support que ce soit en dehors du strict cadre légal de la copie privée ;</li><li>ne pas extraire ou réutiliser, y compris à des fins privées, sans autorisation écrite et préalable de LEPICARD AGRICULTURE, une partie substantielle ou non du contenu des bases de données et archives constituées par le Site ; • à mettre en place des systèmes de contrôle adaptés en fonction des évolutions techniques pour éviter tout “piratage” des Informations et en particulier pour éviter l’utilisation illicite ou contraire aux présentes Conditions Générales d’Utilisation de toute Information de quelque manière et sous quelque forme que ce soit ;</li><li>à informer LEPICARD AGRICULTURE dès la connaissance d’un ” piratage ” et en particulier de toute utilisation illicite ou non-contractuelle des Informations et ce quel que soit le mode de diffusion utilisé.</li></ul>



<p>La violation de ces dispositions impératives soumet l’Utilisateur Enregistré ainsi que toutes les personnes qui y ont participé aux sanctions pénales et civiles prévues par la loi.</p>



<p><strong><em>IV. UTILISATION DES DONNEES PERSONNELLES ET RESPECT DE LA VIE PRIVEE</em></strong><strong></strong></p>



<p><strong>IV.1</strong>. Les Codes d’accès permettent à l’Utilisateur de s’identifier et de se connecter aux services souscrits du Site. Ces Codes d’accès sont personnels et confidentiels. Ils peuvent être modifiés en ligne par l’Utilisateur (uniquement le mot de passe) ou par LEPICARD AGRICULTURE (l’identifiant et/ou le mot de passe).<br>L’Utilisateur est entièrement responsable de l’utilisation des Codes d’accès le concernant.<br>Sauf preuve contraire, toute connexion au Site ou transmission de données effectuées à partir des Codes d’accès de l’Utilisateur sera réputée avoir été effectuée par ce dernier.</p>



<p><strong>IV.2.</strong>&nbsp;L’Utilisateur s’engage à conserver ses Codes d’accès secrets et s’interdit de les divulguer sous quelque forme que ce soit à des tiers.</p>



<p>A cet égard, l’Utilisateur a la possibilité de s’assurer qu’à l’issue de chaque session, il peut se déconnecter explicitement. En cas de perte, de vol ou de divulgation accidentelle de ses Codes d’accès, l’Utilisateur doit en informer sans délai, LEPICARD AGRICULTURE par courrier électronique ou postal, qui procèdera alors à l’annulation dans les meilleurs délais des Codes d’accès de l’Utilisateur. L’Utilisateur recevra un nouveau mot de passe.<br>Toute perte, vol, détournement ou utilisation non autorisée des Codes d’accès et leurs conséquences relèvent de la responsabilité de l’Utilisateur.</p>



<p><strong>IV.3</strong>. L’ouverture d’une nouvelle session par des Codes d’accès déjà en cours d’utilisation entraînera automatiquement la fermeture de la 1ère session ouverte.</p>



<p><strong>IV.4.</strong>&nbsp;LEPICARD AGRICULTURE respecte la vie privée des Utilisateurs Enregistrés et se conforme strictement aux lois en vigueur sur la protection de la vie privée et des libertés individuelles. Ainsi, les données personnelles transmises par chaque Utilisateur sont destinées exclusivement aux services de LEPICARD AGRICULTURE. Elles ne sont en aucun cas transmises à des tiers à des fins de publicité et de promotion sans leur accord préalable.</p>



<p>En application des dispositions de la loi n° 78-17 du 6 janvier 1978, modifiée par la loi n° 2004-801 du 7 août 2004, relative à l’informatique, aux fichiers et aux libertés, chaque Utilisateur dispose d’un droit d’opposition, d’accès, de rectification et de suppression des données le concernant. Chaque Utilisateur peut exercer ce droit en ligne à tout moment en écrivant LEPICARD AGRICULTURE – DPO RGPD –&nbsp; 21, rue Jacques Ferny – B.P. 6 – 76760 YERVILLE</p>



<p>Par ailleurs, LEPICARD AGRICULTURE &nbsp;garantit que tout traitement de données personnelles est également soumis à la loi n° 78-17 du 6 janvier 1978, modifiée par la loi n° 2004-801 du 7 août 2004, relative à l’informatique, aux fichiers et aux libertés.</p>



<p><strong>V. REGLES D’USAGE DE L’INTERNET</strong></p>



<p>LEPICARD AGRICULTURE s’engage à faire ses meilleurs efforts pour sécuriser l’accès, la consultation et l’utilisation des Informations conformément aux règles d’usages de l’Internet.</p>



<p>Le Site est accessible 24 heures sur 24, 7 jours sur 7 sauf en cas de force majeure ou de survenance d’un événement hors du contrôle de LEPICARD AGRICULTURE et sous réserve des éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement du Site. Les interventions de maintenance pourront être effectuées sans que les Utilisateurs aient été préalablement avertis.</p>



<p>L’Utilisateur déclare accepter les caractéristiques et les limites de l’Internet, et en particulier reconnaître que :</p>



<ul><li>son utilisation du Site se fait à ses risques et périls ; le Site lui est accessible “en état” et en fonction de sa disponibilité ;</li><li>&nbsp;tout matériel téléchargé par l’Utilisateur ou obtenu de toute autre manière lors de l’utilisation du Site, l’est à ses risques et périls ; l’Utilisateur est seul responsable pour tout dommage subi par son ordinateur ou toute perte de données consécutif au téléchargement de ce matériel ou à la consultation du Site ;</li><li>il lui appartient donc de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels de la contamination par des éventuels virus circulant à travers le Site et/ou les Informations ;</li><li>il est seul responsable de l’utilisation du contenu des sites ayant un lien hypertexte avec le Site, LEPICARD AGRICULTURE &nbsp;déclinant toute responsabilité quant à ces contenus ;</li><li>il a connaissance de la nature de l’Internet, en particulier de ses performances techniques et des temps de réponse pour consulter, interroger ou transférer les Informations ;</li><li>la communication de ses Codes d’accès ou d’une manière générale de toute information jugée confidentielle est faite sous sa propre responsabilité ;</li><li>il lui appartient de prendre toutes mesures nécessaires pour s’assurer que les caractéristiques techniques de son équipement lui permettent la consultation des Informations ;</li></ul>



<p><strong>VI. PROPRIETE INTELLECTUELLE DE LEPICARD AGRICULTURE</strong></p>



<p><strong>VIII.1.</strong>&nbsp;LEPICARD AGRICULTURE est le propriétaire exclusif de tous les droits de propriété intellectuelle portant tant sur la structure que sur le contenu du Site.</p>



<p><strong>VIII.2.</strong>&nbsp;Les présentes Conditions Générales d’Utilisation n’entraînent le transfert d’aucun droit de propriété intellectuelle au profit de l’Utilisateur tant sur la structure que sur le contenu du Site. Ainsi, l’Utilisateur s’engage notamment à ne pas utiliser les Informations de manière à porter atteinte aux droits de LEPICARD AGRICULTURE et à ce que cette utilisation ne constitue pas une contrefaçon ou une concurrence déloyale ou parasitaire des Informations.</p>



<p><strong>VII. LOI APPLICABLE ET ATTRIBUTION DE JURIDICTION</strong></p>



<p><strong>XI.1.</strong>&nbsp;Les présentes Conditions Générales d’Utilisation sont soumises à tous égards au droit français. Elles ont été rédigées en langue française qui sera considérée, en toute hypothèse, comme la langue unique.</p>



<p><strong>XI.2.</strong>&nbsp;Les parties conviennent de faire leur possible pour résoudre à l’amiable toute contestation susceptible de résulter de l’interprétation, de l’exécution et/ou de la cessation des présentes Conditions Générales d’Utilisation.</p>
        </div>
    </section>`
