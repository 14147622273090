import React, {lazy, Suspense} from 'react';
import EnvironmentService from '@utils/environmentService';

const instanceToComponent = {
  lepicard: lazy(() => import('./CgvLepicard')),
  cauchoise: lazy(() => import('./CgvCauchoise')),
  luneray: lazy(() => import('./CgvLuneray')),
};

const cgvPage: React.FC = () => {
  const Comp = instanceToComponent[EnvironmentService.getInstance()];
  return (
    <Suspense fallback={<div>Chargement...</div>}>
      <Comp />
    </Suspense>
  );
};

export default cgvPage;
