import React, {useState} from 'react';
import {Progressbar, ProgressbarTheme} from '@components/UI/Progressbar/Progressbar';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {CardShowMore} from '@components/UI/Card/CardShowMore';
import {CardRowHeightWrapper, CardRowHeightWrapperType} from '@components/UI/Card/CardRowHeightWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {Accordion} from '@components/UI/Accordion/Accordion';
import {Arrow} from '@components/Icons/Arrow';
import {getShowMoreProductLabel, OrdersRowsCardTags} from '@components/Orders/OrdersRow/OrdersRows';
import {formatDate} from '@utils/string';
import OrdersStatusTag, {OrdersStatusEnum} from '@components/Orders/OrdersStatusTag';
import {formatNumber} from '@utils/number';

interface IOrdersPreviewRow {
  item: any;
  onClick: () => void;
  toggleIcon: React.ReactNode;
}

const OrdersPreviewRow: React.FC<IOrdersPreviewRow> = ({onClick, toggleIcon, item}) => {
  const totalItems = item.orders.length + item.returnedOrders.length;
  const showMoreLabel = `${totalItems} ${totalItems > 1 ? 'commandes' : 'commande'}`;
  return (
    <CardRow type={CardRowType.SUB_PREVIEW} onClick={onClick}>
      <div className="flex justify-between gap-1">
        <CardSubTitle title={item.description} />
        <div className="flex gap-2 items-center shrink-0">
          <p>{showMoreLabel}</p>
          {toggleIcon}
        </div>
      </div>
    </CardRow>
  );
};

const OrdersSubRow: React.FC = ({item}: any) => {
  const unitLabel = `\u00A0${item.productData.quantityUnit}`;
  const totalQuantityLabel = formatNumber(item.productData.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(item.productData.quantityUnit);
  const unitPriceLabel =
    `${formatNumber(item.productData.netUnitPrice as number)
      .fixed()
      .spaces()
      .render()}\u00A0€\u00A0/${unitLabel}` ?? '';
  const amountLabel = formatNumber(item.productData.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');
  const restToDeliverLabel = `${formatNumber(item.productData.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/')}\u00A0${totalQuantityLabel}`;

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardTitle title={'COMMANDE ' + item.orderData.id} />
        <OrdersRowsCardTags
          invoiceStatut={item.orderData.invoiceStatut}
          shipmentStatut={item.orderData.shipmentStatut}
        />
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Prévision de livraison" value={formatDate(item.orderData.requestedDeliveryDate)} />
        {item.orderData.linkedContracts.length > 0 && (
          <CardInfo label="Contrat lié" value={item.orderData.linkedContracts.join(', ')} />
        )}
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo
            label="Quantité"
            value={totalQuantityLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
          <CardInfo label="Prix unitaire HT" value={unitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo label="Montant TTC" value={amountLabel} flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Reste à livrer"
          value={restToDeliverLabel}
          flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

const OrdersSubRowReturn: React.FC = ({item}: any) => {
  const unitLabel = `\u00A0${item.quantityUnit}`;
  const totalQuantityLabel = formatNumber(item.quantity as number)
    .negate()
    .round()
    .spaces()
    .renderWithUnit(item.quantityUnit);
  const unitPriceLabel = `${formatNumber(item.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€')}\u00A0/${unitLabel}`;
  const amountLabel = formatNumber(item.amountIncludingVAT as number)
    .negate()
    .fixed()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardTitle title={'Retour ' + item.rv} />
        <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
      </CardTitleContainer>
      <CardSeparator className="lg:hidden" />
      <CardInfosWrapper>
        <CardInfo label="Commande liée" value={item.cmdId} />
        <CardInfo label="Date de retour" value={formatDate(item.returnDate)} />
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper className="mb-4">
        <CardInfoGroup>
          <CardInfo
            label="Quantité"
            value={totalQuantityLabel}
            flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
          />
          <CardInfo
            label="Prix unitaire HT"
            value={unitPriceLabel}
            flags={[CardInfoFlag.QUANTITY, CardInfoFlag.RETURN]}
          />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo
            label="Montant TTC"
            value={amountLabel}
            flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.RETURN]}
          />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const OrdersRowByFamily: React.FC = ({data}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const showMoreIcon = <Arrow direction={isOpen ? 'top' : 'bottom'} color="#0F4C36" />;

  const rowWrapperClosedQuantity = 5;

  function onPreviewClick(): void {
    if (!isOpen) {
      setIsOpen(true);
    }
  }

  const totalAmountLabel = `${data.amountIncludingVAT.toFixed(2)}\u00A0€`;

  const displayedProducts = isOpen ? data.products : data.products.slice(0, rowWrapperClosedQuantity);
  const hiddenProductsCount = data.products.length - rowWrapperClosedQuantity;

  const subRows = (
    <>
      {displayedProducts.map((product) => (
        <Accordion
          key={product.description}
          head={({onClick, toggleIcon}) => (
            <OrdersPreviewRow
              item={product}
              onClick={() => {
                onPreviewClick();
                onClick();
              }}
              toggleIcon={toggleIcon}
            />
          )}
        >
          {product.orders.map((order) => (
            <OrdersSubRow item={order} key={order.orderData.id} />
          ))}
          {product.returnedOrders.map((returnedOrder, idx) => (
            <OrdersSubRowReturn item={returnedOrder} key={idx} />
          ))}
        </Accordion>
      ))}
    </>
  );

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfo label="Montant TTC" value={totalAmountLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardSeparator className="lg:hidden" />
          <CardInfo
            label="Pourcentage livré"
            value={<Progressbar percent={data.deliveredPercentage} theme={ProgressbarTheme.SOFT} />}
            flags={[CardInfoFlag.PROGRESSBAR, CardInfoFlag.END_OF_LINE]}
          />
        </CardInfosWrapper>
      </CardRow>
      {data.products.length > rowWrapperClosedQuantity ? (
        <CardRowHeightWrapper type={CardRowHeightWrapperType.LOCK} active={!isOpen}>
          {subRows}
        </CardRowHeightWrapper>
      ) : (
        subRows
      )}

      {data.products.length - rowWrapperClosedQuantity > 0 && (
        <CardShowMore
          toggleIcon={showMoreIcon}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {getShowMoreProductLabel(isOpen, hiddenProductsCount)}
        </CardShowMore>
      )}
    </Card>
  );
};
