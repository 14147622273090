import { ContractsStoreModel } from './models'
import { Instance, types } from 'mobx-state-tree'
import actions from './actions'
import views from './views'

export interface IContractsStore extends Instance<typeof ContractsStore> {}

export interface IUpdateAccountInfos {
  firstName: string
  lastName: string
  mobile: string
  phone: string
}

export const ContractsStore = types.optional(ContractsStoreModel.views(views).actions(actions), {})
