import {getPaginationParams} from '@utils/tables';
import {fetchCustomerAccounts, fetchSupplierAccounts, IAccountingFilters} from '@services/navagri';
import {AccountingModel, AccountingItemModel} from './models';
import {Instance, flow, types} from 'mobx-state-tree';
import {ConcurrentHelper} from '@utils/ConcurrentHelper';

export interface IAccountingsStore extends Instance<typeof AccountingsStore> {}
export interface IAccountingItemModel extends Instance<typeof AccountingItemModel> {}

export const AccountingsStore = types.optional(
  AccountingModel.views((self) => {
    return {};
  }).actions((self) => {
    return {
      fetchAccounting: flow(function* (type: 'customer' | 'supplier', page: number, filters: IAccountingFilters = {}) {
        const serviceState = self.fetchAccountingState;
        serviceState.pending();

        try {
          const service = type === 'customer' ? fetchCustomerAccounts : fetchSupplierAccounts;

          const {isLastAction} = ConcurrentHelper.setAction('fetchAccounting' + type);
          const result = yield service({...getPaginationParams(page), ...filters});
          if (!isLastAction()) {
            return;
          }

          const {data, total, limit, totalDue, expiredTotal, types} = result;

          self.list = data;
          self.totalDue = totalDue;
          self.expiredTotal = expiredTotal;
          serviceState.setPagination(parseInt(total), parseInt(limit));
          self.types = types;

          serviceState.done();
        } catch (error) {
          console.log({error});
          serviceState.fail();
        }
      }),
      setIsDescending(value: boolean, columnName?: string) {
        self.isDescending = value;
        self.sortedColumn = columnName;
      },
    };
  }),
  {}
);
