import {renderDateCell} from '@utils/tables';
import React from 'react';
import {getType} from '@components/Accounting/AccountingView/index';
import {documentTypes} from '@constants/document.types';
import {formatNumber} from '@utils/number';
import dayjs from 'dayjs';
import {getLabelByValue} from '@utils/pdf';

export const csvColumns = (filters: any, type: string) => [
  {
    Header: 'Date compta.',
    id: 'postingDate',
    accessor: 'postingDate',
    isDate: true,
  },
  {
    Header: 'Designation',
    id: 'document',
    accessor: 'num',
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: 'type',
    render: (column, value) => {
      return getType(value);
    },
  },
  {
    Header: 'Date Échéance',
    id: 'echeance',
    accessor: 'echeance',
    isDate: true,
  },
  {
    Header: 'Débit',
    id: 'debit',
    accessor: 'initialAmount',
    othersAccessor: ['type'],
    render: (column, value, newObject) => {
      return formatNumber(getDebitRender(type, value, false)).decimalComma().render();
    },
  },
  {
    Header: 'Crédit',
    id: 'initialAmount',
    accessor: 'initialAmount',
    othersAccessor: ['type'],
    render: (column, value, newObject) => {
      return formatNumber(getCreditRender(type, value, false)).decimalComma().render();
    },
  },
  {
    Header: 'Solde',
    id: 'restToPaid',
    accessor: 'restToPaid',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    Header: 'Lettrage',
    id: 'letter',
    accessor: 'letter',
    render: (column, value) => {
      return getLetter(value, filters);
    },
  },
];

export const pdfColumns = (filters: any, type: string) => [
  {
    Header: 'Date compta.',
    id: 'postingDate',
    accessor: 'postingDate',
    isDate: true,
  },
  {
    Header: 'Designation',
    id: 'document',
    accessor: 'num',
  },
  ...dataColumns(filters, type),
];

export const dataColumns = (filters: any, type: string) => [
  {
    Header: 'Type',
    id: 'type',
    accessor: 'type',
    render: (column, value) => {
      return getType(value);
    },
  },
  {
    Header: 'Date échéance',
    id: 'echeance',
    accessor: 'echeance',
    isDate: true,
  },
  {
    Header: 'Débit',
    id: 'debit',
    accessor: 'initialAmount',
    othersAccessor: ['type'],
    render: (column, value, newObject) => {
      return getDebitRender(type, value);
    },
  },
  {
    Header: 'Crédit',
    id: 'initialAmount',
    accessor: 'initialAmount',
    othersAccessor: ['type'],
    render: (column, value, newObject) => {
      return getCreditRender(type, value);
    },
  },
  {
    Header: 'Solde',
    id: 'restToPaid',
    accessor: 'restToPaid',
    othersAccessor: ['type'],
    render: (column, value) => {
      return typeof value === 'number' ? getRestToPaid(type, value) : '-';
    },
  },

  {
    Header: 'Lettrage',
    id: 'letter',
    accessor: 'letter',
    render: (column, value) => {
      return getLetter(value, filters);
    },
  },
];

export const getCreditRender = (pageType: string, amountValue: number, includeSymbolAndSpaces: boolean = true) => {
  let render;
  if (amountValue < 0 && pageType == 'customer') {
    if (includeSymbolAndSpaces) {
      render = formatNumber(amountValue as number)
        .negate()
        .fixed()
        .spaces()
        .renderWithUnit('€');
    } else {
      render = formatNumber(amountValue as number)
        .negate()
        .fixed()
        .renderWithUnit('');
    }
    return render;
  } else if (amountValue < 0 && pageType == 'supplier') {
    if (includeSymbolAndSpaces) {
      render = formatNumber(amountValue as number)
        .negate()
        .fixed()
        .spaces()
        .renderWithUnit('€');
    } else {
      render = formatNumber(amountValue as number)
        .negate()
        .fixed()
        .renderWithUnit('');
    }
    return render;
  }
  return '';
};

export const getDebitRender = (pageType: string, amountValue: number, includeSymbolAndSpaces: boolean = true) => {
  let render;
  if (amountValue > 0 && pageType === 'customer') {
    if (includeSymbolAndSpaces) {
      render = formatNumber(amountValue as number)
        .fixed()
        .spaces()
        .renderWithUnit('€');
    } else {
      render = formatNumber(amountValue as number)
        .fixed()
        .renderWithUnit('');
    }
    return render;
  } else if (amountValue > 0 && pageType === 'supplier') {
    if (includeSymbolAndSpaces) {
      render = formatNumber(amountValue as number)
        .fixed()
        .spaces()
        .renderWithUnit('€');
    } else {
      render = formatNumber(amountValue as number)
        .fixed()
        .renderWithUnit('');
    }
    return render;
  }

  return '';
};

export const getRestToPaid = (documentType: number, amountValue: number) => {
  if (amountValue === 0) {
    return formatNumber(amountValue as number)
      .spaces()
      .renderWithUnit('€');
  } else if (documentType === 3) {
    return formatNumber(amountValue as number)
      .fixed()
      .spaces()
      .renderWithUnit('€');
  }
  if (amountValue < 0) {
    return formatNumber(amountValue as number)
      .negate()
      .fixed()
      .spaces()
      .renderWithUnit('€');
  } else {
    return formatNumber(amountValue as number)
      .fixed()
      .spaces()
      .renderWithUnit('€');
  }
};

const getLetter = (letter: string, filters: any) => {
  if (filters.hasOwnProperty('postingDate')) return letter;
  const eq = {
    AAA: 'ouvertures',
    AAB: 'non lettrées',
    AAC: 'non payées',
  };

  if (documentTypes.hasOwnProperty(letter)) return eq[letter];
  return letter;
};

const getFormatedPrice = (value: number): string => {
  return ` ${value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}  €`;
};

const datePdfFormater = (formValues) => {
  const startDate = dayjs(formValues.startDate).format('DD/MM/YYYY');
  const endDate = dayjs(formValues.endDate).format('DD/MM/YYYY');
  if (formValues.startDate && formValues.endDate) {
    return `Du ${startDate} au ${endDate}`;
  }
  if (formValues.startDate && !formValues.endDate) {
    return `À partir du ${startDate}`;
  }
  if (!formValues.startDate && formValues.endDate) {
    return `Jusqu'au ${endDate}`;
  }
  return 'Toutes dates';
};

export const headerValue = (formValues, accountingStore) => {
  return {
    due: accountingStore.totalDue ? getFormatedPrice(accountingStore.totalDue) : 0,
    echue: accountingStore.expiredTotal ? getFormatedPrice(accountingStore.expiredTotal) : 0,
    exercice: datePdfFormater(formValues),
    documents: !formValues.tagsOption
      ? 'Toutes les factures'
      : formValues.tagsOption === 'notYetDueInvoices'
      ? 'Facture non échues'
      : 'Facture échues',
    search: formValues.search,
  };
};

export const getCsvFileName = (formValues) => {
  const startDate = formValues.startDate ? dayjs(formValues.startDate).format('DD/MM/YYYY') : null;
  const endDate = formValues.endDate ? dayjs(formValues.endDate).format('DD/MM/YYYY') : null;
  const csvFileName = [startDate, endDate].filter(Boolean).join('_');
  return csvFileName ? `_${csvFileName}` : '';
};
