import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';

type ITagsContainer = PropsWithChildren<{
  className?: string;
}>;

const TagsContainer: React.FC<ITagsContainer> = ({children, className}) => {
  return <div className={cn('flex flex-row flex-wrap gap-x-1.5 gap-y-2 w-max', className)}>{children}</div>;
};

export default TagsContainer;
