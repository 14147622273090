import React, {PropsWithChildren, useMemo, useState} from 'react';
import {CardShowMore, getShowMoreLabel} from '@components/UI/Card/CardShowMore';
import {Arrow} from '@components/Icons/Arrow';

export interface CardRowCountWrapperItem<T extends Record<string, any> = any> {
  params: T;
  key: string;
  render: React.FC<T>;
}

type ICardRowCountWrapper = PropsWithChildren<{
  items: CardRowCountWrapperItem[];
  numberOfItemsInPreview?: number;
  showMoreLabelFn?: (isOpen: boolean, collapsibleTotal: number) => string;
}>;

export const CardRowCountWrapper: React.FC<ICardRowCountWrapper> = ({
  items,
  numberOfItemsInPreview = 3,
  showMoreLabelFn = getShowMoreLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const showMoreIcon = <Arrow direction={isOpen ? 'top' : 'bottom'} color="#0F4C36" />;

  const displayedItems = useMemo(() => {
    if (isOpen) {
      return items;
    }

    return items.slice(0, numberOfItemsInPreview);
  }, [items, isOpen]);

  const hiddenItemTotal = Math.max(items.length - numberOfItemsInPreview, 0);

  return (
    <>
      {displayedItems.map((item) => (
        <item.render key={item.key} {...item.params} />
      ))}
      {hiddenItemTotal !== 0 && (
        <CardShowMore
          toggleIcon={showMoreIcon}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {showMoreLabelFn(isOpen, hiddenItemTotal)}
        </CardShowMore>
      )}
    </>
  );
};
