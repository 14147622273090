import { AccountModel, AccountStoreModel, ContactModel, SalesPersonPurchaser } from './models'
import { Instance, types } from 'mobx-state-tree'
import actions from './actions'
import views from './views'

export interface IContactModel extends Instance<typeof ContactModel> { }
export interface IAccountModel extends Instance<typeof AccountModel> { }
export interface IAccountStoreModel extends Instance<typeof AccountStoreModel> { }
export interface IAccountsStore extends Instance<typeof AccountsStore> { }
export interface ISalesPersonStore extends Instance<typeof SalesPersonPurchaser> { }

export interface IUpdateAccountInfos {
  firstName: string
  lastName: string
  mobile: string
  phone: string
  address?: string
  zipCode?: string
  city?: string
  registrationNum?: string
  vatRegistrationNum?: string
  certifOwnerContactNum?: string
  certiphytoNum?: string
  certiphytoValidityDate?: string,

}

export const AccountsStore = types.optional(AccountStoreModel.views(views).actions(actions), {
  selectedAccount: null
})
