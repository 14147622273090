import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';
import Tippy from '@tippyjs/react';
import {isUndefined} from '@lepicard/utils';

export enum AlertTheme {
  RED = 'red',
  YELLOW = 'yellow',
}

type IAlertNotification = PropsWithChildren<{
  className?: string;
  theme: AlertTheme;
  label: string;
  tooltipText?: string;
}>;

export const AlertNotification: React.FC<IAlertNotification> = ({className, theme, label, tooltipText}) => {
  let textColor = '';
  let bgColor = '';

  if (theme?.includes(AlertTheme.YELLOW)) {
    textColor = 'text-orange';
    bgColor = 'bg-orange';
  } else if (theme?.includes(AlertTheme.RED)) {
    textColor = 'text-red';
    bgColor = 'bg-red';
  }

  const warningIconClass = cn(`rounded-full text-white w-[18px] h-[18px] font-600 text-[11px] mr-2 flex items-center justify-center`, bgColor);

  return (
    <div className={cn('flex items-center', className)}>
      {isUndefined(tooltipText) ? (
        <div className={warningIconClass}>!</div>
      ) : (
        <Tippy>
          <button className={warningIconClass}>!</button>
        </Tippy>
      )}
      <div className={cn('Card-info', textColor)}>{label}</div>
    </div>
  );
};
