import {isNull} from '@lepicard/utils';

export const useInitialSessionStorage = <T = unknown,>(key: string): [T | null, (value: T | null) => void] => {
  const initialValue = getValueFromStorage();

  function getValueFromStorage(): T | null {
    const storageItem = window.sessionStorage.getItem(key);
    if (isNull(storageItem)) {
      return null;
    }
    return JSON.parse(storageItem);
  }

  const syncValueInStorage = (value: T | null): void => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  };

  return [initialValue, syncValueInStorage];
};
