import React, { Fragment, useEffect } from 'react'
import LocalPdfViewer from '@components/LocalPdfViewer'
import { fetchOffers, IDriveFiles } from '@services/offers';
import { folderOffers } from '@constants/foldersOffers.options'

import useService from '@hooks/useService'


const CollectOffers: React.FC = () => {
  const { service: offersService, data } = useService<IDriveFiles[]>()


  const onFetchFolder = () => {
    offersService(() => fetchOffers({ parentFolder: folderOffers.collect }))
  }

  useEffect(() => {
    onFetchFolder()
  }, [])

  return (
    <div className="w-full flex-1 p-5">
      <div className="page-title mb-10">Présentation des offres</div>

      {data && data.map((f, i) => {
        return (
          <Fragment key={i}>

            <div style={{ marginBottom: '10px' }}>
              
              <img src={`https://drive.google.com/thumbnail?id=${f.id }`}></img>
            </div>

            <a href={f.webViewLink} target="_blanck" className="btn-primary">
              <span className="mb-10">{f.name}</span>
            </a>
          </Fragment>
        )
      })}

      {/*
      <LocalPdfViewer pdfPublicUrl="/img/pdf/LEPICARD_recolte_2019.pdf" />
      */}

    </div>
  )
}

export default CollectOffers
