import '../src/assets/index.scss';
import React from 'react';
import Router from './Router';
import {StoreProvider} from './stores';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import '../node_modules/react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import './assets/css/lnr-icons.css';
import '@components/Forms/styles.scss';
import {AuthProvider} from 'react-oauth2-code-pkce';
import {SSOConfig} from '@services/sso';

const App: React.FC = () => {
  return (
    <AuthProvider authConfig={SSOConfig.authConfig()}>
      <StoreProvider>
        <BrowserRouter>
          <Router />
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </BrowserRouter>
      </StoreProvider>
    </AuthProvider>
  );
};

export default App;
