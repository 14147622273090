import fetch from '@utils/fetch'

export interface IBlogArticle {
  id: number
  date: string
  title: {
    rendered: string
  }
  excerpt: {
    raw: string
    rendered: string
  }
  content: {
    rendered: string
  }
  categories: number[]
  status: string
  featured_media: number
  _embedded: {
    'wp:featuredmedia': {
      source_url: string
    }[]
  }
}

export interface IBlogArticlePostData {
  id: number
  date: string
  title: string
  excerpt: string
  content: string
  categories: number[]
  status: string
  featured_media: number
}

export interface IBlogCategory {
  id: number
  name: string
}

const endPoint = `${process.env.BLOG_API_URL}`

const endPoints = {
  posts: `${endPoint}/posts`,
  pages: `${endPoint}/pages`
}

// TO DO : securiser l'appel -> NE PAS UTILISER EN PROD
// NUM: Le password est en dure + accessible au front
const basicAuthLogin = {
  username: 'lepicard',
  password: 'NYhNlZcQ(thopsB^M*'
}

export const fetchArticles = (page = 1, limit = 10): Promise<IBlogArticle[]> =>
  fetch(
    `${endPoints.posts}`,
    'get',
    { page, per_page: limit, status: 'publish', _embed: true },
    null,
    basicAuthLogin,
    'wp'
  )

export const fetchArticleById = (articleId: number): Promise<IBlogArticle> =>
  fetch(`${endPoints.posts}/${articleId}`, 'get', { /* context: 'edit', */ _embed: true }, null, basicAuthLogin, 'wp')

export const fetchPageById = (articleId: number): Promise<IBlogArticle> =>
  fetch(`${endPoints.pages}/${articleId}`, 'get', { context: 'edit', _embed: true }, null, basicAuthLogin, 'wp')

export const fetchPages = (page = 1, limit = 10): Promise<IBlogArticle[]> =>
  fetch(
    `${endPoints.pages}`,
    'get',
    { page, per_page: limit, status: 'publish', /* context: 'edit', */ _embed: true },
    null,
    basicAuthLogin,
    'wp'
  )
