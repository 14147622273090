import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';

type ICardTitleContainer = PropsWithChildren<{
  className?: string;
}>;

export const CardTitleContainer: React.FC<ICardTitleContainer> = ({className, children}) => {
  return <div className={cn('CardDocument')}>{children}</div>;
};
