import React from 'react';

interface IFiltersButton {
  onClick: () => void;
}

export const FiltersButton: React.FC<IFiltersButton> = ({onClick}) => {
  return (
    <button className="flex items-center text-interactif" onClick={onClick} type="button">
      <img src="/icons/filters.svg" alt="" className="me-1.5" />
      Filtres
    </button>
  );
};
