import {types} from 'mobx-state-tree';
import {ServiceStateModel} from '@stores/models';

export const AdivalorPlanningModel = types.model({
  startDate: types.string,
  endDate: types.string,
  type: types.string,
  DepotInfo: types.array(
    types.model({
      departements: types.string,
      depot: types.array(types.string),
    })
  ),
});

export const AdivalorStoreModel = types.model('Adivalor', {
  planning: types.array(AdivalorPlanningModel),
  types: types.array(types.string),
  locations: types.array(types.string),
  total: types.maybeNull(types.number),
  fetchPlanningState: ServiceStateModel,
});
