import React from 'react';
import {Page, Text, Image, View, Document} from '@react-pdf/renderer';
import styles from './styles';
import {ComptaPdf} from '@components/PdfGenerator/Compta/ComptaPdf';
import {ApproPdf} from '@components/PdfGenerator/Appro/ApproPdf';
import {CollectePdf} from '@components/PdfGenerator/Collecte/CollectePdf';
import {IPdfHeaderRow, PdfHeaderRow} from "@components/PdfGenerator/PdfHeaderRow/PdfHeaderRow";
import EnvironmentService from "@utils/environmentService";

export interface IHeaderValue {
  due?: string;
  exercice?: string;
  echue?: string;
  documents?: string;
  search?: string;
  campaignId?: string;
  groupBy?: string;
  filterBy?: string;
  statut?: string;
  itemFamilyCode?: string;
  speciesId?: string;
  itemId?: string;
  contractType?: string;
  subValues?: {
    totalQty?: string;
    totalRestToInvoice?: string;
    totalPurch?: string;
    totalAmountLabel?: string;
    totalAmountIncludingVATLabel?: string;
  };
}
export interface IColumn {
  id?: string;
  Header: string;
  accessor?: string;
  render?: (column: IColumn, value?: any, otherAccessorValues?: Record<string, any>) => React.ReactNode | string;
  isDate?: boolean;
  othersAccessor?: string[];
  subAccessor?: string;
}

export interface IPdfGenerator {
  title: string;
  subtitle?: string;
  columns: IColumn[];
  subColumns?: IColumn[];
  datas?: any[];
  filters?: any;
  groupByLibelle?: string;
  subComponent?: () => any;
  pdfType?: string;
  headerValue?: IHeaderValue;
  contactInfos: IPdfHeaderRow['contactInformations']
}

const PdfGenerator: React.FC<IPdfGenerator> = ({
  datas = [],
  columns,
  subColumns,
  title,
  subtitle,
  subComponent,
  pdfType,
  headerValue,
  contactInfos,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        {/* Header de la page */}
        <View style={styles.pageHeader}>
          <Image style={styles.image} src={EnvironmentService.getImageInstancePath('/img/pdf-logo.png')} />
        </View>

        {/* Partie info: date et info contact */}
        <PdfHeaderRow contactInformations={contactInfos} />

        {/* Compta type document */}
        {pdfType && pdfType === 'Compta' && (
          <ComptaPdf
            title={title}
            columns={columns}
            subColumns={subColumns}
            subtitle={subtitle}
            pdfType={pdfType}
            headerValue={headerValue}
            datas={datas}
          />
        )}

        {/* Appro type document */}
        {pdfType && pdfType === 'Appro' && (
          <ApproPdf
            title={title}
            columns={columns}
            subColumns={subColumns}
            subtitle={subtitle}
            pdfType={pdfType}
            headerValue={headerValue}
            datas={datas}
            subComponent={subComponent}
          />
        )}

        {/* Collecte type document */}
        {pdfType && pdfType === 'Collecte' && (
          <CollectePdf
            title={title}
            columns={columns}
            subColumns={subColumns}
            subtitle={subtitle}
            pdfType={pdfType}
            headerValue={headerValue}
            datas={datas}
            subComponent={subComponent}
          />
        )}

        {/* pagination */}
        <Text
          style={styles.pagination}
          render={({pageNumber, totalPages}) => {
            return `page ${pageNumber} / ${totalPages}`;
          }}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PdfGenerator;
