import {useState} from 'react';

export const useAccordion = (defaultState: boolean = false) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(defaultState);

  function toggleAccordion(): void {
    setIsAccordionOpen(!isAccordionOpen);
  }

  return {
    isAccordionOpen,
    setIsAccordionOpen,
    toggleAccordion,
  };
};
