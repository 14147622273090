import React, {Fragment, useMemo} from 'react';
import './styles.scss';
import {useStore} from '@stores/index';
import {isDef} from '@lepicard/utils';
import EnvironmentService from '@utils/environmentService';

interface IProfileCard {}

const instanceConfig = EnvironmentService.getInstanceConfig();

const ProfileCard: React.FC<IProfileCard> = () => {
  const {sso: SSOStore} = useStore();
  const {user: currentUser, selectedExtranetUser} = SSOStore;

  const sortedExtranetUsers = useMemo(() => {
    if (!isDef(selectedExtranetUser) || !isDef(currentUser?.extranetUsers)) {
      return [];
    }

    return [selectedExtranetUser, ...currentUser.extranetUsers.filter((u) => u.id !== selectedExtranetUser?.id)];
  }, [currentUser, selectedExtranetUser]);

  return (
    <>
      currentUser &&
      <div className="Profile-card Card relative">
        <div className="ProfileCard-green-1 ProfileCard-title">Mes informations personnelles</div>

        <div className="value_row md:mb-6">
          <div className="value_ItemsRow mr-[30px]">
            <span className="ProfileCard-green-2 Profile_valueTitle">Nom</span>
            <span className="ProfileCard-green-1 Profile_value">{currentUser?.profile?.familyName ?? '-'}</span>
          </div>
          <div className="value_ItemsRow mr-0">
            <span className="ProfileCard-green-2 Profile_valueTitle">Prénom</span>
            <span className="ProfileCard-green-1 Profile_value">{currentUser?.profile?.givenName ?? '-'}</span>
          </div>
        </div>
        <div className="value_row">
          <div className="value_ItemsRow">
            <span className="ProfileCard-green-2 Profile_valueTitle">Adresse mail</span>
            <span className="ProfileCard-green-1 Profile_value">{currentUser?.profile?.email ?? '-'}</span>
          </div>
          <div className="value_ItemsRow">
            <span className="ProfileCard-green-2 Profile_valueTitle">Téléphone</span>
            <span className="ProfileCard-green-1 Profile_value">{currentUser?.profile?.phoneNumber ?? '-'}</span>
          </div>
        </div>

        <div className="lines"></div>
        <div className="ProfileCard-green-1 ProfileCard-title mb-0">Structure(s) liée(s) au compte</div>
        <div className="lines"></div>

        {/* TODO SSO : Afficher toutes les infos de l'utilisateur sélectionné, pour les autres, afficher les infos minimales (SSO) */}

        {sortedExtranetUsers.map((extranetUser) => {
          return (
            <Fragment key={extranetUser.id}>
              <div className="ProfileCard-green-1 ProfileCard-title">{extranetUser.name}</div>
              <div className="value_row mb-6">
                <div className="value_ItemsRow">
                  <span className="ProfileCard-green-2 Profile_valueTitle">Statut juridique</span>
                  <span className="ProfileCard-green-1 Profile_value">{extranetUser.legalForm ?? '-'}</span>
                </div>
                <div className="value_ItemsRow">
                  <span className="ProfileCard-green-2 Profile_valueTitle">Siret</span>
                  <span className="ProfileCard-green-1 Profile_value">{extranetUser.registrationNumber ?? '-'}</span>
                </div>
                <div className="value_ItemsRow mr-0 mb-0">
                  <span className="ProfileCard-green-2 Profile_valueTitle">N° de TVA</span>
                  <span className="ProfileCard-green-1 Profile_value">
                    {extranetUser.contact?.vatRegistrationNum ?? '-'}
                  </span>
                </div>
              </div>

              <div className="value_row mb-6">
                <div className="value_ItemsRow">
                  <span className="ProfileCard-green-2 Profile_valueTitle">Email</span>
                  <span className="ProfileCard-green-1 Profile_value">{extranetUser?.contact?.email ?? '-'}</span>
                </div>
                <div className="value_ItemsRow">
                  <span className="ProfileCard-green-2 Profile_valueTitle">Téléphone fixe</span>
                  <span className="ProfileCard-green-1 Profile_value">{extranetUser?.contact?.phone ?? '-'}</span>
                </div>
                <div className="value_ItemsRow mr-0 mb-0">
                  <span className="ProfileCard-green-2 Profile_valueTitle">Téléphone portable</span>
                  <span className="ProfileCard-green-1 Profile_value">{extranetUser?.contact?.mobile ?? '-'}</span>
                </div>
              </div>

              <div className="flex flex-col md:flex-row">
                <div className="value_ItemsRow">
                  <div className="flex flex-col">
                    <span className="ProfileCard-green-2 Profile_valueTitle mb-2 lg:mb-3">Adresse de facturation</span>
                    <span className="ProfileCard-green-1 Profile_value">{extranetUser.contact?.address}</span>
                    <span className="ProfileCard-green-1 Profile_value">
                      {extranetUser.contact?.postalCode} {extranetUser.contact?.city}
                    </span>
                  </div>
                </div>
                <div className="value_ItemsRow mr-0 mt-4 md:mt-0">
                  <div className="flex flex-col">
                    <span className="ProfileCard-green-2 Profile_valueTitle mb-2 lg:mb-3">Adresse de livraison</span>
                    <span className="ProfileCard-green-1 Profile_value">{extranetUser.contact?.address}</span>
                    <span className="ProfileCard-green-1 Profile_value">
                      {extranetUser.contact?.postalCode} {extranetUser.contact?.city}
                    </span>
                  </div>
                </div>
              </div>
              <div className="lines"></div>
            </Fragment>
          );
        })}

        <div className="flex flex-col md:flex-row">
          <div className="ProfileCard-green-1 md:w-1/2 ContactInfo mb-3 md:mb-0">
            <span className="Profile_value text-title-4">Certaines informations ne sont pas à jour ?</span>
            <span className="Profile_value text-lg font-medium">
              Demandez à être recontacté pour mettre à jour vos informations.
            </span>
          </div>
          <div className="md:w-1/2 flex text-sm md:justify-end items-center">
            <a
              className="btn btn-primary contactMe uppercase contactMe"
              href={`mailto:${instanceConfig.profil.communicationEmail}`}
            >
              Contactez-moi
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileCard;
