import React, {useEffect, useMemo, useState} from 'react';
import FieldWrapper, {INewFieldWrapper} from '@components/NewFieldWrapper/FieldWrapper';
import ReactSelect from 'react-select';
import {cn} from '@utils/class-names';
import {isEmptyArray, useField, useFormikContext} from 'formik';
import {isUndefined} from '@lepicard/utils';
import './styles.scss';

interface ISelectProps {
  name: string;
  options: IOption[];
  placeholder?: string;
  isSearchable?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  isClearable?: boolean;
  labelType?: Omit<INewFieldWrapper['labelType'], 'label-floating'>;
  className?: string;
  required?: boolean;
  label?: string;
  inputId?: string;
  selectAllOption?: string;
}

export interface IOption {
  value: any;
  label?: string;
  disabled?: boolean;
}

const selectAllKey = '__select-all';

const Select: React.FC<ISelectProps> = (props) => {
  const wrapperProps: Omit<INewFieldWrapper, 'children'> = {
    name: props.name,
    label: props.label,
    required: props.required,
    className: props.className,
    labelType: props.labelType ?? isUndefined(props.label) ? 'no-label' : 'label-out-row',
  };

  const [field, meta, helper] = useField(props.name);

  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const formattedOptions = useMemo(
    () =>
      props.options.map((option) => ({
        value: option.value,
        label: option.label ? option.label : option.value,
        disabled: option.disabled ?? false
      })),
    [props.options]
  );

  // Update select value from outside change
  const valueItem = useMemo(() => {
    if (Array.isArray(field.value)) {
      return field.value.map((val) => formattedOptions.find((option) => option.value === val));
    } else {
      return (
        formattedOptions.find((option) => option.value === field.value) ??
        formattedOptions.find((option) => option.value === meta.initialValue)
      );
    }
  }, [field.value, formattedOptions]);

  useEffect(() => {
    if (Array.isArray(field.value)) {
      const filteredValues = field.value.filter(
        (val) => !isUndefined(formattedOptions.find((option) => option.value === val))
      );
      if (field.value.length !== filteredValues.length) {
        if (filteredValues.length !== 0) {
          helper.setValue(filteredValues);
        } else if (!isUndefined(formattedOptions.find((option) => option.value === meta.initialValue))) {
          helper.setValue(meta.initialValue);
        }
      }
    } else {
      const isValueInOptions = !isUndefined(formattedOptions.find((option) => option.value === field.value));
      if (!isValueInOptions) {
        const isInitialValueInOptions = !isUndefined(
          formattedOptions.find((option) => option.value === meta.initialValue)
        );
        if (isInitialValueInOptions) {
          helper.setValue(meta.initialValue);
        }
      }
    }
  }, [field.value, formattedOptions]);

  return (
    <FieldWrapper {...wrapperProps}>
      {({label, showError, id}) => {
        const handleChange = (newOption: any) => {
          if (Array.isArray(newOption) && props.multiple) {
            // Handle selectAllOption
            if (!isUndefined(props.selectAllOption)) {
              if (!isUndefined(newOption.find((option) => option.value === selectAllKey))) {
                if (newOption.length - 1 === formattedOptions.length) {
                  helper.setValue([]);
                } else {
                  helper.setValue(formattedOptions.map((option) => option.value));
                }
                return;
              }
            }

            helper.setValue(newOption.map((option) => option.value));
          } else {
            helper.setValue(newOption.value);
          }
        };

        const additionalField = {
          inputId: props.inputId ?? id,
          className: props.className,
          placeholder: props.placeholder ?? 'Sélectionner...',
        };

        const allFormattedOptions = isUndefined(props.selectAllOption)
          ? formattedOptions
          : [{value: selectAllKey, label: props.selectAllOption}, ...formattedOptions];

        return (
          <div className={cn('lpa-select', {isError: showError, isFocused})}>
            <ReactSelect
              {...additionalField}
              options={allFormattedOptions}
              value={valueItem}
              onChange={handleChange}
              isSearchable={props.isSearchable}
              onFocus={onFocus}
              onBlur={onBlur}
              isMulti={props.multiple}
              isDisabled={props.disabled}
              isOptionDisabled={(option) => option?.disabled}
              isClearable={props.isClearable}
              classNamePrefix="lpa-select"
              unstyled
            />
          </div>
        );
      }}
    </FieldWrapper>
  );
};

export default Select;
