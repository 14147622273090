import { ServiceStateModel } from '@stores/models'
import { types, Instance } from 'mobx-state-tree'

export interface ICampaignsModel extends Instance<typeof CampaignsModel> {}
export interface ICampaignsStoreModel extends Instance<typeof CampaignsStoreModel> {}

export const CampaignsModel = types.model({
  id: types.string,
  startDate: types.string,
  endDate: types.string,
  status: types.number
})

export const CampaignsStoreModel = types.model({
  list: types.array(CampaignsModel),
  fetchCampaignsState: ServiceStateModel
})


export const CampaignsListModel = types.model({
  id : types.string
})
