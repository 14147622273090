import {types} from 'mobx-state-tree';
import {ServiceStateModel} from '@stores/models';
import {CampaignsListModel} from '@stores/campaigns/models';

export const BillModel = types.model({
  id: types.string,
  documentNum: types.string,
  contactId: types.string,
  familyCode: types.string,
  shipmentDate: types.maybeNull(types.string),
  description: types.string,
  quantity: types.maybeNull(types.number),
  quantityInvoiced: types.maybeNull(types.number),
  campaignCode: types.maybeNull(types.string),
  unitOfMeasureCode: types.maybeNull(types.string),
  locationCode: types.maybeNull(types.string),
  order: types.maybeNull(types.string),
});

export const groupListItemModel = types.model({
  libelle: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
});

export const GroupListModel = types.model({
  list: types.array(groupListItemModel),
});

export const ServiceFdsModel = types.model({
  fetchFdsState: ServiceStateModel,
  list: types.array(BillModel),
  groupList: types.maybe(GroupListModel),
  campaigns: types.array(CampaignsListModel),
  fetchCampaignsState: ServiceStateModel,
  descriptionsList: types.array(types.string),
  manufacturerCodeList: types.array(types.maybeNull(types.string)),
});
