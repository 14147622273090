

// type
// downloadNav document type
export const customersDocuments = [
    {
        type: 2,
        navType: 7
    },
    {
        type: 3,
        navType: 8
    }
]



// type
// downloadNav document type
export const suppliersDocuments = [
    {
        type: 2,
        navType: 1
    }
]


// if type 9 
//Transaction No_

