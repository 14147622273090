import './style.scss';
import React, {useMemo, useState} from 'react';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import {IQuotationsTable} from '@components/Quotations/QuotationsList/QuotationsListUI';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import Select from '@components/NewFields/Select';
import {Formik} from 'formik';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import {isNull, isUndefined} from '@lepicard/utils';
import NoData from '@components/NoData/NoData';

interface IFormattedData {
  specieId: string;
  specieLabel: string;
  years: {
    yearId: number;
    yearLabel: number;
    echeances: {
      echeanceLabel: string;
      amount: number;
    }[];
  }[];
}

const QuotationsTableMobileItem: React.FC<{data: IFormattedData}> = ({data}) => {
  return (
    <div className="QuotationsTableMobileItem rounded shadow-lg overflow-hidden mb-3.5">
      <div className="flex items-center justify-between bg-green-100 py-3.5 px-4">
        <div className="text-white text-title-4">{data.specieLabel}</div>
        <img className="w-6" src={`/img/SVG/icons/${data.specieId}.svg`} alt="" />
      </div>
      {data.years.map((year) => (
        <React.Fragment key={year.yearId}>
          <div className="QuotationsTableMobileItem-line text-title-2 py-2.5 px-4 bg-grey-100 text-green-100">
            {year.yearLabel}
          </div>
          {year.echeances.map((echeance, idx) => (
            <div key={idx} className="QuotationsTableMobileItem-line flex justify-between items-center bg-white p-4">
              <p className="text-paragraph-2 text-green-200">{echeance.echeanceLabel}</p>
              <p className="text-title-3 text-green-100">{echeance.amount} €/T</p>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export const QuotationsTableMobile: React.FC<IQuotationsTable> = ({data, className}) => {
  const [filters, setFilters] = useState({species: 'all'});

  const formattedData = useMemo(() => {
    const listBySpecieByYear: IFormattedData[] = [];

    data.years.forEach((year) => {
      year.echeances.forEach((echeance) => {
        echeance.species.forEach((specie) => {
          if (isUndefined(specie!.amount) || isNull(specie!.amount)) {
            return;
          }

          let specieObj = listBySpecieByYear.find((l) => l.specieId === specie.id);
          if (isUndefined(specieObj)) {
            specieObj = {
              specieId: specie.id,
              specieLabel: specie.name,
              years: [],
            };
            listBySpecieByYear.push(specieObj);
          }

          let yearObj = specieObj.years.find((y) => y.yearId === year.id);
          if (isUndefined(yearObj)) {
            yearObj = {
              yearId: year.id,
              yearLabel: year.name,
              echeances: [],
            };
            specieObj.years.push(yearObj);
          }

          yearObj.echeances.push({
            echeanceLabel: specie!.echeanceName,
            amount: specie!.amount,
          });
        });
      });
    });

    return listBySpecieByYear as IFormattedData[];
  }, [data]);

  const species = useMemo(() => {
    const listSet: Set<{label: string; value: string}> = new Set([{value: 'all', label: 'Toutes'}]);
    data.species.forEach((c) => {
      listSet.add({value: c.id, label: c.name});
    });

    return Array.from(listSet);
  }, [data]);

  const filteredFormattedData = useMemo(() => {
    if (filters.species === 'all') {
      return formattedData;
    }

    return formattedData.filter((f) => filters.species === f.specieId);
  }, [formattedData, filters]);

  return (
    <Formik
      initialValues={{
        species: 'all',
      }}
      onSubmit={(values) => {}}
    >
      {({values}) => {
        useEffectNotOnFirstRender(() => {
          setFilters(values);
        }, [values]);
        return (
          <div className={className}>
            <FiltersCard className="flex gap-2 mb-8">
              <div className="flex items-center">
                <label htmlFor="species" className="mr-1 lg:mr-2.5">
                  Culture
                </label>
                <QuickHelp>
                  Retrouvez vos contrats facilement en les filtrant par période de récolte, culture ou variété de
                  culture
                </QuickHelp>
              </div>
              <Select inputId="campaign" name="species" options={species} />
            </FiltersCard>

            {filteredFormattedData.length === 0 && <NoData message="Aucune donné disponible" />}

            {filteredFormattedData.map((d) => (
              <QuotationsTableMobileItem data={d} key={d.specieId} />
            ))}
          </div>
        );
      }}
    </Formik>
  );
};
