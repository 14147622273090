import React, {useId} from 'react';
import {useField} from 'formik';
import {isUndefined} from '@lepicard/utils';
import {cn} from '@utils/class-names';
import './styles.scss';

interface ICheckBox {
  name: string;
  value?: string | number | readonly string[];
  label: string | React.ReactNode;
  subLabel?: string;
  className?: string;
  checked?: boolean;
}

const CheckBox: React.FC<ICheckBox> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const id = useId();

  const isChecked = Boolean(Array.isArray(meta.value) ? meta.value.includes(props.value) : meta.value);

  const showError = meta.touched && !isUndefined(meta.error);

  return (
    <div
      className={cn('lpa-checkbox', {isError: showError}, 'cursor-pointer flex items-center mb-3.5', props.className)}
    >
      <label htmlFor={id} className="lpa-checkbox-box border border-grey-300 lpa-shadow-inner cursor-pointer relative rounded">
        <input {...field} id={id} type="checkbox" className="sr-only" value={props.value} checked={isChecked} />
        {isChecked && <img src="/icons/checkmark.svg" alt="" className="absolute pointer-events-none inset-0" />}
      </label>
      <label htmlFor={id} className="pl-2.5 cursor-pointer">
        <span className="lpa-checkbox-label text-green-100 text-interactif">{props.label}</span>
        {!isUndefined(props.subLabel) && (
          <span className="text-grey-500 before:content-['_'] text-paragraph-2">{props.subLabel}</span>
        )}
      </label>
    </div>
  );
};

export default CheckBox;
