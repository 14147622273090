import {Config} from 'tailwindcss';
import {PluginAPI} from 'tailwindcss/types/config';
import {getTailwindThemeColors} from './tailwind/themeConfig';
import {sharedTailwindThemeColors} from "./tailwind/shared";
const plugin = require('tailwindcss/plugin');

const tailwindColors = require('tailwindcss/colors');
delete tailwindColors[`lightBlue`];
delete tailwindColors[`warmGray`];
delete tailwindColors[`trueGray`];
delete tailwindColors[`coolGray`];
delete tailwindColors[`blueGray`];

const instanceName = process.env.INSTANCE_NAME || process.env.APP_RELEASE?.split('-')?.[1];

if (typeof instanceName != 'string') {
  throw new Error(`instanceName:"${instanceName}" is not valid.`);
}

export default {
  important: true,
  content: ['./src/index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    screens: {
      sm: '480px',
      md: '834px',
      lg: '1024px',
      xl: '1440px',
    },
    fontFamily: {
      inter: ['Inter'],
    },
    fontSize: {
      xs: ['0.75rem', {lineHeight: '1rem'}],
      sm: ['0.875rem', {lineHeight: '1.25rem'}],
      base: ['1rem', {lineHeight: '1.5rem'}],
      lg: ['1.125rem', {lineHeight: '1.75rem'}],
      xl: ['1.25rem', {lineHeight: '1.75rem'}],
      '2xl': ['1.5rem', {lineHeight: '2rem'}],
      '3xl': ['1.875rem', {lineHeight: '2.25rem'}],
      '4xl': ['2.25rem', {lineHeight: '2.5rem'}],
      '5xl': ['3rem', {lineHeight: '1'}],
      '6xl': ['3.75rem', {lineHeight: '1'}],
      '7xl': ['4.5rem', {lineHeight: '1'}],
      '8xl': ['6rem', {lineHeight: '1'}],
      '9xl': ['8rem', {lineHeight: '1'}],
    },
    fontWeight: {
      300: '300', // Should not be used
      500: '500',
      600: '600',
      700: '700',
      medium: '500',
      semibold: '600',
      bold: '700',
    },
    colors: {
      ...tailwindColors,
      ...sharedTailwindThemeColors,
      ...getTailwindThemeColors(instanceName),
    },
    spacing: {
      0: '0.0rem',
      px: '0.1rem',
      0.5: '0.2rem',
      1: '0.4rem',
      1.5: '0.6rem',
      2: '0.8rem',
      2.5: '1.0rem',
      3: '1.2rem',
      3.5: '1.4rem',
      4: '1.6rem',
      4.5: '1.875rem',
      5: '2.0rem',
      6: '2.4rem',
      7: '2.8rem',
      8: '3.2rem',
      9: '3.6rem',
      10: '4.0rem',
      11: '4.4rem',
      12: '4.8rem',
      14: '5.6rem',
      16: '6.4rem',
      20: '8.0rem',
      24: '9.6rem',
      28: '11.2rem',
      32: '12.8rem',
      36: '14.4rem',
      40: '16.0rem',
      44: '17.6rem',
      48: '19.2rem',
      52: '20.8rem',
      56: '22.4rem',
      60: '24.0rem',
      64: '25.6rem',
      72: '28.8rem',
      80: '32.0rem',
      96: '38.4rem',
    },
    borderRadius: {
      none: '0px',
      sm: '2px',
      DEFAULT: '4px',
      md: '6px',
      lg: '8px',
      xl: '12px',
      '2xl': '16px',
      '3xl': '24px',
      full: '9999px',
    },
  },
  plugins: [
    plugin(function ({addComponents, theme}: PluginAPI) {
      addComponents({
        '.lpa-shadow': {
          boxShadow: '0px 2px 4px 0px rgba(8, 36, 39, 0.15)',
        },
        '.lpa-shadow-large': {
          boxShadow: '0px 2px 12px 0px rgba(8, 36, 39, 0.15)',
        },
        '.lpa-shadow-inner': {
          boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05) inset',
        },
      });
    }),
  ],
} satisfies Config;
