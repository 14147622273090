import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import styles from '@components/PdfGenerator/styles';

interface IPdfProductReturned {
  family: string;
  productName: string;
}

const rowStyle = {
  ...styles.subTablevalue,
  borderBottomWidth: 1,
  borderColor: '#D2E1E0',
};

export const PdfProductReturned: React.FC<IPdfProductReturned> = ({family, productName}) => {
  return (
    <View style={styles.tableWrapper} wrap={false}>
      <View style={{...rowStyle, width: '30%'}}>
        <Text>{family}</Text>
      </View>
      x
      <View style={{...rowStyle, width: '30%'}}>
        <Text>{productName}</Text>
      </View>
      <View style={{...rowStyle, width: '40%'}}>
        <Text>RETOUR</Text>
      </View>
    </View>
  );
};
