import {isDef, isString, isUndefined} from '@lepicard/utils';

export const getTransformedDisplayedLines = (lines: any[]) => {
  const displayedLines: any[] = [];
  const attachedLinesByProductLine = new Map<string, any[]>();

  lines.forEach((line: any) => {
    if (!isDef(line.attachedToLine)) {
      displayedLines.push(line);
    } else {
      const mapEntry = attachedLinesByProductLine.get(line.attachedToLine);
      if (isUndefined(mapEntry)) {
        attachedLinesByProductLine.set(line.attachedToLine, [line]);
      } else {
        mapEntry.push(line);
      }
    }
  });

  return {
    displayedLines,
    attachedLinesByProductLine,
  };
};
