import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import {formatDate} from '@utils/string';
import styles, {cellStyle, libelleCellStyle, subRowStyle} from '@components/PdfGenerator/styles';
import {IColumn, IHeaderValue} from '@components/PdfGenerator/index';
import {PdfProductReturned} from '@components/PdfGenerator/PdfSubTable/PdfProductReturned/PdfProductReturned';
import {isUndefined} from '@lepicard/utils';

const subTableWidths = ['15%', '20%', '15%', '15%', '35%'];
const approSubTableWidths = ['15%', '29%', '14%', '16%', '26%'];
const subTableDeliveriesWidths = ['30%', '30%', '40%'];

const tableCellStyle = (index, styles, subtitle) => ({
  ...styles.subTableHeader,
  width:
    subtitle === 'deliveries'
      ? subTableDeliveriesWidths[index]
      : subtitle === 'approInvoices' || subtitle === 'approContract'
      ? approSubTableWidths[index]
      : subTableWidths[index],
});
const subTableValueStyle = (index, styles, subtitle) => ({
  ...styles.subTablevalue,
  width:
    subtitle === 'deliveries'
      ? subTableDeliveriesWidths[index]
      : subtitle === 'approInvoices' || subtitle === 'approContract'
      ? approSubTableWidths[index]
      : subTableWidths[index],
  borderBottomWidth: 1,
  borderColor: '#D2E1E0',
});

function subTableRenderChain(column, line, data) {
  const {subAccessor, render, isDate, handleRender} = column;
  const subValue = subAccessor ? line[subAccessor] : undefined;

  if (!isUndefined(handleRender)) {
    return handleRender(line, data);
  }

  if (!isUndefined(render)) {
    return render(column, subValue);
  }

  return isDate ? formatDate(subValue) : subValue ? subValue : '';
}

export const TableDataLines = ({data, styles, subColumns, subtitle}) => {
  const lines = data.lines ?? [];
  const returnedLines = data.returns ?? [];

  return (
    <>
      <View style={styles.subTable}>
        {/* sous tableau header */}
        <View style={styles.tableWrapper}>
          {subColumns?.map(({Header}, index) => (
            <View style={tableCellStyle(index, styles, subtitle)} key={index}>
              <Text>{Header}</Text>
            </View>
          ))}
        </View>

        {lines
          .filter((line) => line.familyName)
          .map((line, index) => (
            <View key={index} style={styles.tableWrapper} wrap={false}>
              {subColumns?.map((column, cellIndex) => {
                return (
                  <View style={subTableValueStyle(cellIndex, styles, subtitle)} key={cellIndex}>
                    <Text>{subTableRenderChain(column, line, data)}</Text>
                  </View>
                );
              })}
            </View>
          ))}

        {returnedLines
          .filter((line) => line.familyName)
          .map((returnedLine, idx) => (
            <PdfProductReturned key={idx} family={returnedLine.familyName} productName={returnedLine.description} />
          ))}
      </View>
    </>
  );
};

export const TableDataProducts = ({data, styles, subColumns, subtitle}) => {
  const products = data.products ?? [];
  const returnedProducts = data.returnedProducts ?? [];

  return (
    <View style={styles.subTable}>
      {/* sous tableau header */}
      <View style={styles.tableWrapper}>
        {subColumns?.map(({Header}, index) => (
          <View style={tableCellStyle(index, styles, subtitle)} key={index}>
            <Text>{Header}</Text>
          </View>
        ))}
      </View>

      {products.map((line, index) => (
        <View key={index} style={styles.tableWrapper} wrap={false}>
          {subColumns?.map((column, cellIndex) => {
            return (
              <View style={subTableValueStyle(cellIndex, styles, subtitle)} key={index}>
                <Text>{subTableRenderChain(column, line, data)}</Text>
              </View>
            );
          })}
        </View>
      ))}

      {returnedProducts.map((returnedProduct) => (
        <PdfProductReturned
          key={returnedProduct.uniqueId}
          family={returnedProduct.familyName}
          productName={returnedProduct.description}
        />
      ))}
    </View>
  );
};

interface ITable {
  subtitle?: string;
  columns: IColumn[];
  subColumns?: IColumn[];
  datas?: any[];
  subComponent?: () => any;
  pdfType?: string;
  headerValue?: IHeaderValue;
  border?: boolean;
}
export const Table: React.FC<ITable> = ({
  columns,
  datas,
  pdfType,
  headerValue,
  subtitle,
  subColumns,
  subComponent,
  border,
}) => {
  const columnWidth = `${(100 / columns.length).toString()}%`;

  return (
    <View
      style={[styles.table, (subComponent || border || subtitle === 'invoices') && styles.tableWithBorder]}
      wrap={true}
    >
      <View style={styles.row}>
        {columns.map(({Header}, index) => (
          <View style={cellStyle(index, index, pdfType, headerValue, columnWidth, subtitle)} key={index}>
            <Text style={styles.tableHeaderText}>{Header}</Text>
          </View>
        ))}
      </View>

      {datas.map((data, index) => {
        const subTableLinesLength =
          (data.lines?.length ?? data.products?.length ?? 0) +
          (data.returns?.length ?? data.returnedProducts?.length ?? 0);

        return (
          <View key={index} wrap={subTableLinesLength >= 8}>
            <View style={subRowStyle(index, pdfType, headerValue)}>
              {columns.map((column, cellIndex) => {
                const {accessor, othersAccessor, render, isDate, subAccessor} = column;
                const value = accessor ? (subAccessor ? data[accessor][subAccessor] : data[accessor]) : undefined;

                const otherAccessorValues = {};
                if (othersAccessor) {
                  othersAccessor.forEach((accessorKey) => {
                    otherAccessorValues[accessorKey] = data[accessorKey];
                  });
                }

                return (
                  <View
                    style={[libelleCellStyle(cellIndex, index, pdfType, headerValue, columnWidth, subtitle)]}
                    key={cellIndex}
                  >
                    <View>
                      <Text>
                        {render
                          ? render(column, value, otherAccessorValues)
                          : isDate
                          ? formatDate(value)
                          : value
                          ? value
                          : ''}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>

            {/* Subvalues mapping */}
            {data.lines && <TableDataLines styles={styles} data={data} subColumns={subColumns} subtitle={subtitle} />}

            {data.products && (
              <TableDataProducts styles={styles} data={data} subColumns={subColumns} subtitle={subtitle} />
            )}

            {subComponent && (
              <View style={styles.subComponent}>
                <View style={styles.tableWrapper}>
                  <Text style={[{marginRight: 10}, {fontFamily: 'Helvetica-Bold'}]}>NORMES : </Text>
                  <Text> {subComponent(data)}</Text>
                </View>
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};
