import React, {ButtonHTMLAttributes} from 'react';
import {isUndefined} from '@lepicard/utils';
import {cn} from '@utils/class-names';
import './style.scss';

interface IButton extends React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label: string;
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium';
}

const Button: React.FC<IButton> = ({label, color = 'primary', size = 'medium', className = '', ...props}) => {
  return (
    <button
      {...props}
      className={cn('lpa-btn', color, size, size == 'medium' ? 'text-title-2' : 'text-title-3', className)}
      type={isUndefined(props.type) ? 'button' : props.type}
    >
      {label}
    </button>
  );
};

export default Button;
