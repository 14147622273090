import React from 'react';
import {observer} from 'mobx-react-lite';
import DurabilityForm from '@components/Forms/DurabilityForm';
import PageHead from '@components/UI/PageHead/PageHead';

const Durability: React.FC = observer(() => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Déclaration de durabilité"
        description="Facilité vos démarche en soumettant directement votre déclaration de durabilité via notre formulaire."
      />
      <DurabilityForm />
    </div>
  );
});

export default Durability;
