interface IDocumentTypes {
  [key: string]: string;
}

export const documentTypes: IDocumentTypes = {
  '0': 'Compensation',
  '1': 'Paiement',
  '2': 'Facture',
  '3': 'Avoir',
  '4': 'Intérêt',
  '6': 'VIEC',
};
