import {types} from 'mobx-state-tree';
import {ServiceStateModel} from '@stores/models';

// TODO: Make global
export const FacetChoiceModel = types.model({
  value: types.string,
  label: types.string,
});

// ----

export const ApproInvoicesInvoiceGroup = types.model({
  no: types.string,
  postingDate: types.string,
  dueDate: types.string,
  letter: types.maybeNull(types.string), // Can be an empty string ''
  totalLedgerAmount: types.number,
  totalAmountIncludingVAT: types.number,
  lines: types.array(
    types.union(
      types.model({
        uniqueId: types.string,
        documentNo: types.string,
        lineNo: types.number,
        amountIncludingVAT: types.number,
        shipmentDate: types.string,
        description: types.string,
        orderNo: types.string,
        contractNo: types.string,
        quantity: types.number,
        unitOfMeasureCode: types.string,
        amount: types.number,
        netUnitPrice: types.number,
        type: types.number,
        familyCode: types.string,
        familyName: types.string,
      }),
      types.model({
        uniqueId: types.string,
        description: types.string,
        amountIncludingVAT: types.number,
        type: types.number,
        attachedToLine: types.maybeNull(types.number),
      })
    )
  ),
});

export const ApproInvoicesProductGroup = types.model({
  description: types.string,
  familyCode: types.string,
  familyName: types.string,
  invoices: types.array(
    types.union(
      types.model({
        uniqueId: types.string,
        documentNo: types.string,
        lineNo: types.number,
        postingDate: types.string,
        letter: types.maybeNull(types.string), // Can be an empty string ''
        orderNo: types.string,
        contractNo: types.string,
        quantity: types.number,
        unitOfMeasureCode: types.string,
        amount: types.number,
        netUnitPrice: types.number,
        type: types.number,
      })
    )
  ),
});

export const ApproInvoicesFamilyGroup = types.model({
  familyCode: types.string,
  familyName: types.string,
  products: types.array(ApproInvoicesProductGroup),
});

export const ApproInvoicesModel = types.model({
  list: types.array(types.union(ApproInvoicesInvoiceGroup, ApproInvoicesProductGroup, ApproInvoicesFamilyGroup)),
  facets: types.maybeNull(
    types.model({
      availableStatus: types.array(FacetChoiceModel),
      availableFamilies: types.array(FacetChoiceModel),
    })
  ),
  globalFacets: types.maybeNull(
    types.model({
      availableCampaigns: types.array(FacetChoiceModel),
    })
  ),
  fetchInvoicesGlobalFiltersOptionsState: ServiceStateModel,
  fetchInvoicesState: ServiceStateModel,
});
