import React, {useState, useEffect} from 'react';
import './styles.scss';
import QuotationsListUI from '@components/Quotations/QuotationsList/QuotationsListUI';
import moment from 'moment';
import Loader from '@components/Loader';
import * as navicarService from '@services/navicar';
import {useStore} from '@stores/index';
import {IAccountModel} from '@stores/accounts/store';
import useService from '@hooks/useService';
import {fetchOdv, OdvData} from '@services/navagri';
import {
  fetchCotations,
  ICotation,
  ICotations,
  ICotationsTaxe,
  IEcheanceHeader,
  ISpecie,
  IYear,
} from '@services/cotations';
import useModal from '@hooks/useModal';
import OdvModal from '../OdvModal';
import PageHead from '@components/UI/PageHead/PageHead';
import {defaultErrorToast} from '@utils/toast';
import {toaster} from '@utils/dictionnary';
import {isNull} from '@lepicard/utils';
import NoData from '@components/NoData/NoData';
import {useInterval} from '@hooks/useInterval';

const QuotationsSuspended: React.FC = () => {
  return <NoData message={'SUSPENSION DES COTATIONS'} className="relative z-40" />;
};

interface IQuotationsHeader {
  zone: string;
  lastUpdate: string;
  className: string;
}

const QuotationsHeader: React.FC<IQuotationsHeader> = ({zone, lastUpdate, className}) => {
  return (
    <div className={`text-paragraph-1 ${className}`}>
      <div className="text-quotation-zone mb-2">{zone}</div>
      <div className="text-quotation mb-1.5">Marchandises livrées à la moisson</div>
      <div className="text-grey-350">Mise à jour - {lastUpdate}</div>
    </div>
  );
};

interface IQuotationsList {}

interface IContact {
  correspondenceType: number;
}

const QuotationsList: React.FC<IQuotationsList> = () => {
  const [isCotationActive, setIsCotationActive] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [wasLastApiCallSuccess, setWasLastApiCallSuccess] = useState(true);
  const [contact, setContact] = useState<IContact>({
    correspondenceType: 1,
  });
  const {sso: SSOStore} = useStore();
  const {selectedExtranetUser: currentAccount, user: currentUser} = SSOStore;

  const fetchInitialData = async () => {
    setContact(currentAccount?.contact as IContact);
    await updateData();
    setIsLoaded(true);
  };

  useEffect(() => {
    fetchInitialData();
  }, [currentAccount]);

  const {service: odvService, data: odvData} = useService<OdvData>();

  const [selectedData, setSelectedData] = useState<ICotation>();

  const odvModal = useModal();

  const fetchOdvData = async () => {
    if (selectedData) {
      odvService(() => fetchOdv(selectedData.specieId, selectedData.campaignId, selectedData.echanceId));
    }
  };

  useEffect(() => {
    fetchOdvData();
  }, [selectedData]);

  const selectCotation = (cotation: ICotation) => {
    setSelectedData(cotation);
    odvModal.toggle();
  };

  const [lastUpdate, setLastUpdate] = useState<string | null>(null);
  const [zone, setZone] = useState<string | null>(null);
  const [data, setData] = useState<{species: ISpecie[]; years: IYear[]; qualities: ICotationsTaxe[]}>({
    species: [],
    years: [],
    qualities: [],
  });

  useInterval(async () => {
    if (isLoaded) {
      updateData();
    }
  }, 10_000);

  async function updateData(): Promise<void> {
    const res: ICotations | null = await fetchCotations().catch((e) => {
      if (wasLastApiCallSuccess) {
        if (e?.data?.code === 'NO_ZONE') {
          defaultErrorToast(toaster.error.undefinedArea);
        } else {
          defaultErrorToast(toaster.error.global);
        }
      }
      return null;
    });

    const isError = isNull(res);
    setWasLastApiCallSuccess(!isError);
    if (isError) {
      return;
    }

    setIsCotationActive(res.status);
    if (!res.status) {
      return;
    }

    const species: any = [];
    const years: any = [];

    setLastUpdate(res.lastUpdate);
    setZone(res.zone.name);

    res.cotations.map((c: ICotation) => {
      const index = species.findIndex((s: any) => s.id == c.specieId);

      /**
       * set species
       * ajouter c.amount si besoin de n'afficher que les species non null
       */
      if (index < 0) {
        species.push({
          id: c.specieId,
          name: c.specieName,
          campaignId: null,
          amount: null,
          echeanceId: null,
          echeanceName: null,
        });
      }
    });

    res.cotations.map((c: ICotation) => {
      /**
       * set years
       */

      const indexYear = years.findIndex((s: any) => s.id == c.campaignId);

      let year: IYear;
      if (indexYear < 0) {
        year = {
          id: c.campaignId,
          name: c.campaignId,
          echeances: [],
        };
        years.push(year);
      } else {
        year = years[indexYear];
      }

      /**
       * set echeances
       */
      const indexEcheances = year.echeances.findIndex((s: any) => s.id == c.echanceId);

      let echeance: IEcheanceHeader;

      if (indexEcheances < 0) {
        echeance = {
          id: c.echanceId,
          name: c.echanceName,
          species: JSON.parse(JSON.stringify(species)),
        };
        year.echeances.push(echeance);
      } else {
        echeance = year.echeances[indexEcheances];
      }

      /**
       * Associer la cotation à year.echeance.species
       */
      const indexYearEcheanceSpecie = echeance.species.findIndex((s: any) => s.id == c.specieId);

      if (indexYearEcheanceSpecie > -1) {
        const specie = echeance.species[indexYearEcheanceSpecie];

        specie.amount = c.amount;
        specie.campaignId = year.id;
        specie.echeanceId = echeance.id;
        specie.echeanceName = echeance.name;
      }
    });

    setData({species, years, qualities: res.qualities});
  }

  if (!isLoaded) {
    return <Loader />;
  }

  const headerParams = {
    zone: zone ?? '',
    lastUpdate: lastUpdate ? moment(lastUpdate).format('DD/MM/YYYY HH:mm') : '',
  };

  return (
    <div className="w-full flex-1">
      <PageHead title="Mes Cotations" description={<QuotationsHeader {...headerParams} className="lg:hidden" />} />
      <div>
        {!isCotationActive && <QuotationsSuspended />}
        {isCotationActive && (
          <QuotationsListUI
            setSelectedData={selectCotation}
            data={data}
            header={<QuotationsHeader {...headerParams} className="hidden lg:block" />}
          />
        )}
        {isCotationActive && selectedData && <OdvModal {...odvModal} odvData={odvData} cotation={selectedData} />}
      </div>
    </div>
  );
};

export default QuotationsList;
