import {Card} from '@components/UI/Card/Card';
import {CardInfo, CardInfoFlag, CardInfoReversed} from '@components/UI/Card/CardInfo';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardRow} from '@components/UI/Card/CardRow';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import React from 'react';
import '../styles.scss';

interface IDepotInfo {
  departements: string;
  depot: string[];
}
interface IAdvilorData {
  startDate: string;
  endDate: string;
  DepotInfo: IDepotInfo[];
  type: string;
}
interface IAdvilorRow {
  data: IAdvilorData;
}
const AdvilorRow: React.FC<IAdvilorRow> = ({data}) => {
  const generateRowValues = (data: string[]) => {
    return data.join(', ');
  };

  return (
    <Card className="mb-4">
      <CardRow>
        <CardInfosWrapper className="mb-4">
          <CardInfo
            label="Dates"
            value={`du ${data.startDate} au ${data.endDate}`}
            flags={[CardInfoFlag.DOUBLE_WIDTH]}
          ></CardInfo>
        </CardInfosWrapper>
        <CardTitleContainer>
          <CardTitle title={data.type.toUpperCase()} />
        </CardTitleContainer>
      </CardRow>

      <CardRow>
        {data.DepotInfo &&
          data.DepotInfo.map((depot, idx) => {
            return (
              <React.Fragment key={idx}>
                <CardInfosWrapper className="hidden sm:block">
                  <CardInfoReversed
                    label={depot.departements.toUpperCase()}
                    value={' ' + generateRowValues(depot.depot)}
                    flags={[CardInfoFlag.FILL_LINE]}
                  ></CardInfoReversed>
                </CardInfosWrapper>
                <CardInfosWrapper className="block sm:hidden">
                  <div>
                    <div className="adivalorMobileTitle">{depot.departements.toUpperCase()}</div>
                    <div className="adivalorMobileVaues">{generateRowValues(depot.depot)}</div>
                  </div>
                </CardInfosWrapper>
                {idx < data.DepotInfo.length - 1 && <CardSeparator />}
              </React.Fragment>
            );
          })}
      </CardRow>
    </Card>
  );
};
export default AdvilorRow;
