import React from 'react'
import FieldWrapper, { IFieldWrapper } from '@components/Layout/FieldWrapper'
import SelectInput, { IOption } from '@components/Inputs/SelectInput'

interface ISelectProps extends IFieldWrapper {
  placeholder?: string
  options: IOption[]
  icon?: string
  isSearchable?: boolean
  selectClassName?: string
  disabled?: boolean
  multiple?: boolean
  isClearable?: boolean
}

const Select: React.FC<ISelectProps> = ({
  options,
  icon,
  isSearchable,
  selectClassName = '',
  multiple = false,
  placeholder,
  disabled,
  isClearable,
  ...props
}) => {
  return (
    <FieldWrapper {...props}>
      {(field, meta, helper) => {
        const onChange = (newValue: any) => {
          helper.setValue(newValue)
        }
        return (
          <SelectInput
            {...field}
            placeholder={placeholder}
            multiple={multiple}
            className={selectClassName}
            options={options}
            disabled={disabled}
            onChange={onChange}
            isClearable={isClearable}
          />
        )
      }}
    </FieldWrapper>
  )
}

export default Select
