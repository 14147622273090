import { types } from 'mobx-state-tree'
import { ServiceStateModel } from '@stores/models'

export const PageModel = types.model({
  id: types.identifierNumber,
  date: types.string,
  title: types.string,
  excerpt: types.string,
  content: types.string,
  status: types.string,
  imageUrl: types.maybe(types.string)
})

export const PagesModel = types.model('News', {
  list: types.array(PageModel),
  pageById: types.maybe(PageModel),
  fetchPagesState: ServiceStateModel,
  fetchPageByIdState: ServiceStateModel
})
