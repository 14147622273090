import React from 'react'
import { useField } from 'formik'
import Alert from '@components/Alert'

interface ICheckBox {
  name: string
  label: string
  checked: boolean
}

const CheckBox: React.FC<ICheckBox> = ({ name, checked, label }) => {
  const [field, meta] = useField(name)

  return (
    <label style={{ cursor: 'pointer' }}>
      <input {...field} type="checkbox" checked={checked} />
      <span className="pl-10">{label}</span>
      {meta.touched && meta.error && <Alert text={meta.error} className="mt-5" />}
    </label>
  )
}

export default CheckBox
