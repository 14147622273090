import React from 'react';
import OrdersList from '@components/Orders/OrdersList';
import PageHead from '@components/UI/PageHead/PageHead';

const ApproOrders: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Mes commandes"
        description="Retrouvez vos commandes d'approvisionnement et retours en un clic, par date, article ou type de produit."
      />
      <OrdersList />
    </div>
  );
};

export default ApproOrders;
