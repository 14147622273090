import { ISpeciesStoreModel } from './models'

export default (self: ISpeciesStoreModel) => {
  return {
    getSpecieById(specieId: string) {
      return self.list.find(specie => specieId === specie.id)
    },

    get specieAsOptions() {
      return self.list.map(specie => ({ value: specie.id, label: specie.name }))
    },


    get subFamilyAsOption() {
      return self.subfamilyList.map(specie => ({ value: specie.code, label: specie.name }))
    }


  }
}
