import {formatDate} from './string';
import React from 'react';
import compareAsc from 'date-fns/compareAsc';
import {isUndefined} from '@lepicard/utils';

export const renderDateCell =
  (withTime = false) =>
  ({cell}: any) => {
    return <>{cell.value && formatDate(cell.value, withTime)}</>;
  };

export const customRenderDateSignOff =
  (withTime: boolean = false, textToShow: string) =>
  ({cell}: any) => {
    let noDate = new Date(1753, 1, 1);
    return (
      <>
        {compareAsc(noDate, new Date(cell.value)) === 1 ? textToShow : cell.value && formatDate(cell.value, withTime)}
      </>
    );
  };

export const renderDateSignOff = (value: any, textToShow?: string) => {
  let noDate = new Date(1753, 1, 1);
  return compareAsc(noDate, new Date(value)) === 1 ? textToShow : value && formatDate(value, false);
};

export const getPaginationParams = (page: number, limit: number = 10) => {
  return {
    $limit: limit,
    $skip: (page - 1) * limit,
  };
};

export const getGroupedByPaginationParams = (page: number = 1, limit: number = 10) => {
  return {
    $limit: limit,
    $skip: (page - 1) * limit,
    page: page,
  };
};

export const isFilterSelected = (filters: Record<string, any> | undefined): boolean => {
  return ((filters && filters?.speciesId) ||
    filters?.itemId ||
    (filters?.status && filters?.status.length > 0)) as boolean;
};

export const getNoDataMessage = (
  filters?: Record<string, any> | undefined,
  currentCampaign?: string | undefined
): string => {
  if (!isUndefined(filters) && !isFilterSelected(filters) && 'campaignId' in filters) {
    const campaignId = filters.campaignId;
    if (isUndefined(campaignId)) {
      return `Aucune donnée n'a été trouvée. Veuillez sélectionner une campagne.`;
    }
    return currentCampaign === campaignId
      ? `Aucune donnée n'a été trouvée pour la campagne ${campaignId} en cours.`
      : `Aucune donnée n'a été trouvée pour la campagne sélectionnée.`;
  }
  return `Aucune donnée ne correspond aux filtres sélectionnés. Essayez de les modifier pour trouver ce que vous cherchez.`;
};

export const getNoDataExerciceMessage = (
  filters: Record<string, any> | undefined,
  currentExercice: string | undefined
): string => {
  if (!isUndefined(filters) && !isFilterSelected(filters) && 'campaignId' in filters) {
    const campaignId = filters.campaignId;
    if (isUndefined(campaignId)) {
      return `Aucune donnée n'a été trouvée. Veuillez sélectionner un exercice.`;
    }
    return currentExercice === campaignId
      ? `Aucune donnée n'a été trouvée pour l'exercice ${campaignId} en cours.`
      : `Aucune donnée n'a été trouvée pour l'exercice sélectionné.`;
  }
  return `Aucune donnée ne correspond aux filtres sélectionnés. Essayez de les modifier pour trouver ce que vous cherchez.`;
};
