import React from 'react';
import ApproDeliveriesList from '@components/ApproDeliveries/ApproDeliveriesList';
import PageHead from '@components/UI/PageHead/PageHead';

const ApproDeliveries: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Mes Livraisons"
        description="Retrouvez vos livraisons selon la date, l'article our le type de produit."
      />
      <ApproDeliveriesList />
    </div>
  );
};

export default ApproDeliveries;
