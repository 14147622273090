import React from 'react'
import { observer } from 'mobx-react-lite'
import EnvironmentService from "@utils/environmentService";

const DurabilitySuccess: React.FC<any> = observer(() => {

    return (
        <div className="content p-20 fc-primary-primary">
            <p className="p-10 size-veryLarge">Merci,  </p>

            <p>Votre déclaration a bien été prise en compte.  Vous allez recevoir sur votre adresse mail un message pour procéder à la signature.</p>
            <p>Les équipes de {EnvironmentService.instanceConfig.global.instanceFullName}.</p>
        </div>
    )
})

export default DurabilitySuccess
