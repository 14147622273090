import React, { useRef, useEffect } from 'react'
import Modal from '@components/Modal'
import CGU from '../CGU'
import Button from '@components/Buttons/Button'

interface ICguModal {
  active: boolean
  toggle: () => void
  onAccept: () => void
}

const CguModal: React.FC<ICguModal> = ({ active, toggle , onAccept}) => {
  return (
    <Modal active={active} toggle={toggle} title="Conditions générales d'utilisation" minWidth="30rem" footer={null}>
      <div className="py-10">
        <div className="px-10 overflow-y-scroll flex flex-col" style={{ maxHeight: '500px' }}>
          <div id="cguPdf" dangerouslySetInnerHTML={{ __html: CGU as string }}></div>
          <Button className="flex justify-center items-center mt-10" onClick={onAccept}>
            J'accepte les conditions générales
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CguModal
