import {getExpeditionStatus, getStatusText} from '@constants/statuses.options';
import {getLabelByValue} from '@utils/pdf';
import {numberToLocaleString} from '@utils/string';
import {customRenderDateSignOff, renderDateSignOff} from '@utils/tables';
import {formatNumber, preFormattedQuantity} from '@utils/number';
import {ICsvColumn} from '@hooks/useCsvGenerator';

export const csvColumns: ICsvColumn[] = [
  {
    Header: 'Commande N°',
    id: 'id',
    accessor: 'id',
  },
  {
    Header: 'Contrat lié',
    id: 'linkedContracts',
    accessor: 'linkedContracts',
    render: (value) => {
      return Array.isArray(value) && value.length > 0 ? value : '-';
    },
  },
  {
    id: 'familyName',
    Header: 'Familles',
    accessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    accessor: 'description',
  },
  {
    Header: 'Livraison prévue',
    id: 'requestedDeliveryDate',
    accessor: 'requestedDeliveryDate',
    isDate: true,
    render: (column, value) => {
      return `${renderDateSignOff(value, "Pas de date d'échéance")} `;
    },
  },
  {
    id: 'quantity',
    Header: 'Quantité totale',
    accessor: 'quantity',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'shippedQuantity',
    Header: 'Quantité livrée',
    handleRender(product, order) {
      const value = product.quantity - product.restToDeliver;
      return formatNumber(value).fixed().decimalComma().render();
    },
  },
  {
    id: 'netUnitPrice',
    Header: 'Prix unitaire HT',
    accessor: 'netUnitPrice',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'amountIncludingVAT',
    Header: 'Montant TTC',
    accessor: 'amountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'shipmentStatut',
    Header: 'Expédition',
    accessor: 'executionRate',
    render: (column, value) => {
      return `${getExpeditionStatus(value)} `;
    },
  },
  {
    id: 'invoiceStatut',
    Header: 'Facturation',
    accessor: 'executionRate',
    render: (column, value) => {
      return `${getStatusText(value)} `;
    },
  },
];

export const pdfCol = [
  {
    Header: 'Commande N°',
    id: 'id',
    accessor: 'id',
  },
  {
    Header: 'Contrat lié',
    id: 'linkedContracts',
    accessor: 'linkedContracts',
    render: (value) => {
      return Array.isArray(value) && value.length > 0 ? value : '-';
    },
  },
  {
    Header: 'Livraison prévue',
    id: 'requestedDeliveryDate',
    accessor: 'requestedDeliveryDate',
    isDate: true,
    render: (column, value) => {
      return `${renderDateSignOff(value, "Pas de date d'échéance")} `;
    },
  },
  {
    Header: 'Montant TTC',
    id: 'amountIncludingVAT',
    accessor: 'amountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'shipmentStatut',
    Header: 'Expédition',
    accessor: 'executionRate',
    render: (column, value) => {
      return `${getExpeditionStatus(value)} `;
    },
  },
  {
    id: 'invoiceStatut',
    Header: 'Facturation',
    accessor: 'executionRate',
    render: (column, value) => {
      return `${getStatusText(value)} `;
    },
  },
];
export const pdfSubCol = [
  {
    id: 'familyName',
    Header: 'Familles',
    subAccessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    subAccessor: 'description',
  },
  {
    id: 'quantity',
    Header: 'Livré/Quantité',
    handleRender(product, order) {
      const unit = product.quantityUnit;
      const shippedQuantityLabel = preFormattedQuantity(product.quantity - product.restToDeliver).renderWithUnit(unit);
      const totalQuantityLabel = preFormattedQuantity(product.quantity).renderWithUnit(unit);
      return `${shippedQuantityLabel} / ${totalQuantityLabel}`;
    },
  },
  {
    id: 'amountIncludingVAT',
    Header: 'Montant TTC',
    subAccessor: 'amountIncludingVAT',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
  {
    id: 'netUnitPrice',
    Header: 'Prix unitaire HT',
    subAccessor: 'netUnitPrice',
    render: (column, value) => {
      return typeof value === 'number' ? `${numberToLocaleString(value)} €` : '';
    },
  },
];

export const headerValue = (formValues, groupByOption, tagsOptions, store) => {
  return {
    campaignId: formValues.campaignId === 'all' ? 'Tous' : formValues.campaignId,
    groupBy: getLabelByValue(formValues.groupBy, groupByOption),
    filterBy: getLabelByValue(formValues.tagsOption, tagsOptions),
    statut: getLabelByValue(formValues.status, store.getApproStatusOptions),
    itemFamilyCode: getLabelByValue(formValues.itemFamilyCode, store.getItemFamilyCodeOptions),
    search: formValues.search,
  };
};

export const getCsvFileName = (formValues, tagsOptions) => {
  return getLabelByValue(formValues.tagsOption, tagsOptions)
    ? `${formValues.campaignId}_${getLabelByValue(formValues.tagsOption, tagsOptions)}`
    : `${formValues.campaignId}`;
};
