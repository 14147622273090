import React, {PropsWithChildren, useState} from 'react';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardRow} from '@components/UI/Card/CardRow';
import './styles.scss';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {IAccountingItemModel} from '@stores/accounting/models';
import {formatDate} from '@utils/string';
import DueDateIndicator from '@components/UI/DueDateIndicator/DueDateIndicator';
import {getType} from '@components/Accounting/AccountingView';
import {getCreditRender, getDebitRender, getRestToPaid} from '@components/Accounting/AccountingView/columns';
import useDownloadPdf from '@hooks/useDownloadPdf';
import {customersDocuments, suppliersDocuments} from '@constants/documentsDownload.options';
import {Arrow} from '@components/Icons/Arrow';
import {CardShowMore} from '@components/UI/Card/CardShowMore';
import {cn} from '@utils/class-names';
import EnvironmentService from '@utils/environmentService';

interface IAccountingListMobile {
  data: IAccountingItemModel;
  typeLabel: string;
}

function getMoreDocumentsLabel(isOpen: boolean, length: number) {
  const action = isOpen ? 'Masquer' : 'Afficher';
  return length > 1 ? `${action} les ${length} documents rattachés` : `${action} le document rattaché`;
}

type ICardRowComponent = PropsWithChildren<{
  document: IAccountingItemModel;
  className?: string;
}>;

const AccountingRowMobile: React.FC<IAccountingListMobile> = ({data, typeLabel}) => {
  const {download: download} = useDownloadPdf();
  const [isOpen, setIsOpen] = useState(false);
  const showMoreIcon = <Arrow direction={isOpen ? 'top' : 'bottom'} color="#0F4C36" />;

  const downloadPdfLoader = (value: string, type: number, name: string) => {
    download(value, type, name);
  };

  const CardRowComponent: React.FC<ICardRowComponent> = ({children, document, className}) => {
    let navDoctype: any = null;
    if (typeLabel == 'supplier') {
      navDoctype = suppliersDocuments.find((f) => f.type === data.type);
    }
    if (typeLabel == 'customer') {
      navDoctype = customersDocuments.find((f) => f.type === data.type);
    }

    return (
      <CardRow key={document.num} className={className}>
        <CardTitleContainer>
          <h3
            className={cn('CardDocument-titleGroup', {
              blackAndWhiteIcon: EnvironmentService.instanceConfig.global.downloadIconInBlackAndWhite,
            })}
            onClick={() => downloadPdfLoader(document.num, navDoctype.navType, document.num)}
          >
            <div className="flex">
              <img src="/icons/file.svg" alt="" />
              <div>
                <div className="CardDocument-subTitle">{formatDate(document.postingDate, false)}</div>
                <div className="CardDocument-title">{`${getType(document.type)} ${document.num}`}</div>
              </div>
            </div>
            <div className="CardDocument-titleGroup-right">
              {document.initialAmount < 0 && (
                <>
                  <div className="CardDocument-titleGroup-right-type">Crédit (TTC)</div>
                  <div className="CardDocument-titleGroup-right-total">
                    {getCreditRender('customer', document.initialAmount, true)}
                  </div>
                </>
              )}
              {document.initialAmount > 0 && (
                <>
                  <div className="CardDocument-titleGroup-right-type">Débit (TTC)</div>
                  <div className="CardDocument-titleGroup-right-total">
                    {getDebitRender('customer', document.initialAmount, true)}
                  </div>
                </>
              )}
            </div>
          </h3>
        </CardTitleContainer>
        {children}
      </CardRow>
    );
  };

  return (
    <Card className="AccountingListMobile-card">
      <CardRowComponent document={data} />

      <CardRow>
        <CardInfosWrapper>
          <div className="flex justify-between">
            <div>
              <span className="Card-label">Échéance</span>
              {data.restToPaid > 0 ? (
                <DueDateIndicator dueDate={data.echeance} total={data.restToPaid} />
              ) : (
                formatDate(data.echeance, false)
              )}
            </div>
            {data.letter.length > 0 && <CardInfo label="Lettrage" value={data.letter} />}
          </div>
          {/*<CardInfo label="Facture liée" value={'FVC65846'} /> TODO: linked invoices*/}
        </CardInfosWrapper>
      </CardRow>

      <CardRow>
        <CardInfosWrapper>
          <CardInfo
            label="Montant restant dû (TTC)"
            value={getRestToPaid(data.type, data.restToPaid)}
            flags={[CardInfoFlag.END_OF_LINE, CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]}
          />
        </CardInfosWrapper>
      </CardRow>

      {isOpen && Array.isArray(data.linked) ? (
        data.linked.map((linkedItem, index) => {
          return (
            <CardRowComponent key={index} document={linkedItem} className="AccountingListMobile-card-linkedDocument">
              <CardRow className="AccountingListMobile-card-linkedDocument-echeance">
                <CardInfosWrapper>
                  <div>
                    <span>Échéance : </span>
                    {data.restToPaid > 0 ? (
                      <DueDateIndicator dueDate={linkedItem.echeance} total={linkedItem.restToPaid} />
                    ) : (
                      formatDate(linkedItem.echeance, false)
                    )}
                  </div>
                </CardInfosWrapper>
              </CardRow>
            </CardRowComponent>
          );
        })
      ) : (
        <></>
      )}

      {data.linked?.length > 0 && (
        <CardShowMore
          toggleIcon={showMoreIcon}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {getMoreDocumentsLabel(isOpen, data.linked?.length)}
        </CardShowMore>
      )}
    </Card>
  );
};

export default AccountingRowMobile;
