import React, {Fragment, useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import './styles.scss';
import routes, {collectRoutes, servicesRoutes, cmsRoutes, approRoutes} from '@routes';
import useTimeout from '@hooks/useTimeout';
import {useWindowSize} from '@hooks/useWindowSize';
import EnvironmentService from '@utils/environmentService';

interface Sidebar {
  isSidebarOpened: boolean;
  toggleSideBar: () => void;
}

interface ISubPath {
  path: string;
  name: string;
}

interface INavigationItemWithPath extends ISubPath {
  icon?: string;
  subPaths?: never;
  spritePosition?: string;
}

interface INavigationItemWithSubPaths {
  name: string;
  icon?: string;
  path?: never;
  spritePosition?: string;
  subPaths: ISubPath[];
}

type INavigationItem = INavigationItemWithPath | INavigationItemWithSubPaths;

const instanceConfig = EnvironmentService.getInstanceConfig();

const navigationItems: INavigationItem[] = [
  {
    path: routes.HOME,
    name: 'Accueil',
    icon: 'menu-accueil',
  },
  {
    path: routes.ACCOUNTING,
    name: 'Mon compte',
    icon: 'menu-compta',
  },
  {
    name: 'Ma collecte',
    icon: 'menu-collecte',
    subPaths: [
      {
        path: routes.CONTRACTS,
        name: 'Mes contrats',
      },
      {
        path: routes.DELIVERIES,
        name: 'Mes livraisons',
      },
      {
        path: collectRoutes.INVOICES,
        name: 'Mes factures'
      },
      {
        path: routes.QUOTATIONS,
        name: 'Mes cotations',
      },
      ...(instanceConfig.durabilite.enablePage
        ? [
            {
              path: '/durability',
              name: 'Déclaration durabilité',
            },
          ]
        : []),
      /*{
        name: 'Présentation des offres',
        path: collectRoutes.OFFERS
      }*/
    ],
  },
  {
    name: 'Mes approvisionnements',
    icon: 'menu-shop',
    subPaths: [
      ...(instanceConfig.approContrats.enablePage
        ? [
          {
            name: 'Mes contrats',
            path: approRoutes.APPRO_CONTRACTS,
          },
        ]
        : []),
      {
        name: 'Mes commandes',
        path: approRoutes.APPRO_ORDERS,
      },
      {
        name: 'Mes livraisons',
        path: approRoutes.APPRO_DELIVERIES,
      },
      {
        name: 'Mes factures',
        path: approRoutes.APPRO_INVOICES
      },
      /*{
        name: 'Mon récapitulatif',
        path: approRoutes.APPRO_SOLD_ORDERS
      },
      {
        name: 'Présentation des offres',
        path: approRoutes.APPRO_OFFERS
      }*/
    ],
  },
  {
    name: 'Mes services',
    icon: 'menu-services',
    subPaths: [
      ...(instanceConfig.bsv.enablePage
        ? [
            {
              name: 'Bulletins Santé du Végétal',
              path: servicesRoutes.BSV,
            },
          ]
        : []),
      {
        name: 'Centres ADIVALOR',
        path: servicesRoutes.ADIVALOR,
      },
      /*
      {
        name: 'Réglementation',
        path: cmsRoutes.SERVICE_REGLEMENTATION
      },*/
      /*{
        name: 'Présentation des offres',
        path: servicesRoutes.OFFERS
      },*/
      ...(instanceConfig.bsv.enablePage
        ? [
            {
              name: 'Mes fiches de sécurité',
              path: servicesRoutes.FDS,
            },
          ]
        : []),
    ],
  },
  /* {
    path: routes.CEREAL_PRICES,
    name: 'Cours Culture',
    icon: 'chart-bars'
  }, */
];

const Sidebar: React.FC<Sidebar> = ({isSidebarOpened, toggleSideBar}) => {
  const location = useLocation();

  const [openedNavigationItemIndex, setOpenedNavigationItemIndex] = useState<number>();
  const [hoveredNavigationItemIndex, setHoveredNavigationItemIndex] = useState<number>();

  const matchPath = (path: string | ISubPath[]) => {
    const match = (path: string) => path === location.pathname;

    if (Array.isArray(path)) {
      return path.findIndex((pathItem) => match(pathItem.path)) !== -1;
    } else {
      return match(path);
    }
  };

  // We set a timeout in order to wait half a second before displaying navigation item's title when the
  // sidebar opening animation starts
  const {init: initAnimationTimeout, done: animationTimeoutDone} = useTimeout({duration: 500, startOnRender: false});

  useEffect(() => {
    if (isSidebarOpened) {
      initAnimationTimeout();
    } else {
      setOpenedNavigationItemIndex(undefined);
    }
  }, [isSidebarOpened]);

  const closeSideBarIfNeeded = () => {
    if (isSidebarOpened) {
      toggleSideBar();
    }
  };

  const {windowWidth} = useWindowSize();
  useEffect(() => {
    if (windowWidth >= 1440) {
      closeSideBarIfNeeded();
    }
  }, [windowWidth]);

  return (
    <Fragment>
      <div className={`sidebarWrapper ${isSidebarOpened ? '' : 'collapsed'}`}>
        <div className="sidebarNavWrapper">
          <div className={`sidebarNav`}>
            <button type="button" onClick={toggleSideBar} className="sidebarNav-closeButton">
              <span className="bar-1"></span>
              <span className="bar-2"></span>
            </button>
            <div className="sidebarNav-itemsList">
              {navigationItems.map((navigationItem, index) => {
                const isLinkActive = navigationItem.subPaths
                  ? matchPath(navigationItem.subPaths)
                  : matchPath(navigationItem.path);

                const sideBarOpened = isSidebarOpened && animationTimeoutDone;

                const itemRow = (
                  <>
                    {navigationItem.icon ? (
                      <img
                        src={`/icons/${navigationItem.icon}-${isLinkActive ? 'on' : 'off'}.svg`}
                        alt=""
                        className={sideBarOpened ? 'sideBarOpened' : ''}
                      />
                    ) : (
                      <div
                        className={`navIcon ${sideBarOpened ? 'sideBarOpened' : ''}`}
                        style={{
                          width: '42px',
                          height: '42px',
                          backgroundPosition: navigationItem.spritePosition,
                          backgroundRepeat: 'no-repeat',
                          backgroundImage: 'url(/img/sprite-menu.png)',
                        }}
                      ></div>
                    )}
                    <span>{navigationItem.name}</span>
                  </>
                );

                const itemClassName = `sidebarNav-itemsList-item ${isLinkActive ? 'active' : ''}`;

                const openSubPaths = () => {
                  setOpenedNavigationItemIndex(isSubPathListOpened ? undefined : index);
                };

                const isSubPathListOpened = openedNavigationItemIndex === index;
                const isSubPathHovered = hoveredNavigationItemIndex === index;

                const onMouseEnter = () => {
                  setHoveredNavigationItemIndex(index);
                };

                const onMouseLeave = () => {
                  setTimeout(() => {
                    setHoveredNavigationItemIndex(undefined);
                  }, 500);
                };

                return navigationItem.path ? (
                  <Link
                    className={itemClassName}
                    to={Array.isArray(navigationItem.path) ? navigationItem.path[1] : navigationItem.path}
                    key={index}
                    onClick={() => {
                      closeSideBarIfNeeded();
                      setOpenedNavigationItemIndex(undefined);
                    }}
                  >
                    {' '}
                    {itemRow}
                  </Link>
                ) : (
                  <div
                    className={`w-full ${isSubPathListOpened ? 'subPathsOpened click' : ''}`}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    key={index}
                    style={{zIndex: 1000}}
                  >
                    <div className={`${itemClassName} cursor-pointer`} onClick={openSubPaths}>
                      {itemRow}{' '}
                      <div className="opener">
                        <i
                          className={`lnr lnr-chevron-${
                            isSubPathListOpened ? 'up' : 'down'
                          } text-lg opacity-100 font-semibold`}
                        />
                      </div>
                    </div>
                    <div className={`subPathsLevel1 ${isSubPathListOpened ? '' : 'hidden'}`}>
                      {navigationItem.subPaths &&
                        navigationItem.subPaths.map((subPath, subIndex) => {
                          const isSubPathActive = matchPath(subPath.path);

                          return (
                            <Link
                              className={`sidebarNav-itemsList-subPathItem hover:font-semibold ${
                                isSubPathActive ? 'active' : ''
                              }`}
                              to={subPath.path}
                              key={subIndex}
                              onClick={closeSideBarIfNeeded}
                            >
                              {' '}
                              {subPath.name}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="sidebarNav-mobileMask"></div>
    </Fragment>
  );
};

export default Sidebar;
