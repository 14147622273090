import React from 'react';
import {observer} from 'mobx-react-lite';
import PageHead from '@components/UI/PageHead/PageHead';
import RepresentativeList from '@components/Representative';

const Representative: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead title="Mes interlocuteurs de proximité" />
      <RepresentativeList />
    </div>
  );
};

export default Representative;
