import {defaultErrorToast, defaultSuccesToast} from '@utils/toast';
import {types} from 'mobx-state-tree';
import {toaster} from '@utils/dictionnary';

export const ServiceStateModel = types.optional(
  types
    .model({
      state: types.enumeration(['initial', 'pending', 'done', 'error']),
      error: types.maybeNull(types.string),
      total: types.maybeNull(types.number),
      limit: types.number,
    })
    .views((self) => ({
      get isPending() {
        return self.state === 'pending';
      },

      get hasFailed() {
        return self.state === 'error';
      },

      get isDone() {
        return self.state === 'done';
      },
    }))
    .actions((self) => {
      return {
        pending() {
          self.state = 'pending';

          if (self.error) {
            self.error = null;
          }
        },

        fail(sendToast: boolean = true, message?: string) {
          self.state = 'error';
          if (message) {
            self.error = message;
            if (sendToast) {
              defaultErrorToast(message);
            }
          } else if (sendToast) {
            defaultErrorToast(toaster.error.global);
          }
        },

        done(message?: string) {
          self.state = 'done';

          if (message) {
            defaultSuccesToast(message);
          }
        },

        setPagination(total: number, limit: number) {
          self.total = total;
          self.limit = limit;
        },

        resetState() {
          self.state = 'initial';
        },
      };
    }),
  {state: 'initial', error: null, total: 0, limit: 0}
);
