import React from 'react'
import usePdfGenerator from '@hooks/usePdfGenerator'
import { fetchAdivalorPlanning } from '@services/adivalor'
import Button from '@components/Buttons/Button'
import useCsvGenerator from '@hooks/useCsvGenerator'

const TestExport: React.FC = () => {
  const columns = [
    {
      Header: 'Lieu',
      accessor: 'location'
    },
    {
      Header: 'Date',
      accessor: 'start',
      isDate: true
    }
  ]

  const { isPending: isPdfLoading, download: downloadPdf } = usePdfGenerator(columns)

  const { isPending: isCsvLoading, download: downloadCsv } = useCsvGenerator(columns)

  const loadDatas = async () => {
    const { planning } = await fetchAdivalorPlanning()

    return [...planning, ...planning, ...planning]
  }

  const onPdfDownloadClick = () => {
    downloadPdf({
      loadDatas,
      title: 'Titre',
      subtitle: 'Sous-Titre',
      fileName: 'test'
    })
  }

  const onCsvDownloadClick = () => {
    downloadCsv({
      loadDatas,
      fileName: 'test'
    })
  }

  return (
    <div className="w-full flex-1 p-5">
      <div className="page-title">Test des services d'export</div>
      <div className="w-full p-5 flex flex-col justify-start items-start">
        <Button isLoading={isPdfLoading} onClick={onPdfDownloadClick}>
          Exporter en PDF
        </Button>
        <Button className="mt-10" isLoading={isCsvLoading} onClick={onCsvDownloadClick}>
          Exporter en CSV
        </Button>
      </div>
    </div>
  )
}

export default TestExport
