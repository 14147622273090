import React from 'react'
import './styles.scss'

interface IAlert {
  text: string
  icon?: string
  className?: string
}

const Alert: React.FC<IAlert> = ({ text, className = '', icon = null }) => {
  return (
    <div className={`alert error ${className}`}>
      {icon && <i className={`lnr lnr-${icon} mr-4`} />}
      <p className="size-tiny weight-light">{text}</p>
    </div>
  )
}

export default Alert
