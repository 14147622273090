import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';
import {isUndefined} from '@lepicard/utils';

export enum CardRowType {
  MAIN = 'isMainRow',
  SECONDARY = 'isSecondaryRow',
  SUB = 'isSubRow',
  SUB_PREVIEW = 'isSubPreviewRow',
  SHOW_MORE = 'isShowMore',
}

type ICardRow = PropsWithChildren<{
  type?: CardRowType;
  className?: string;
  onClick?: () => void;
}>;

export const CardRow: React.FC<ICardRow> = ({children, type = CardRowType.MAIN, className, onClick}) => {
  const isButton = type === CardRowType.SHOW_MORE;
  const As = isButton ? 'button' : 'div';

  const additionalProps: any = {};
  if (isButton) {
    additionalProps.type = 'button';
  }
  return (
    <As
      className={cn('Card-row', type, className, {
        'cursor-pointer': !isUndefined(onClick),
      })}
      onClick={onClick}
      {...additionalProps}
    >
      {children}
    </As>
  );
};
