import { ServiceStateModel } from '@stores/models'
import { types, Instance } from 'mobx-state-tree'
import { type } from 'os'
import {CampaignsListModel} from "@stores/campaigns/models";

export interface IOrderModel extends Instance<typeof OrderModel> { }
export interface IOrderStoreModel extends Instance<typeof OrdersStoreModel> { }

export const OrderModel = types.model({
    id: types.identifier,
    billLineId: types.number,
    campaignId: types.string,
    documentNum: types.string,
    contactId: types.string,
    familyCode: types.string,
    speciesCode: types.string,
    documentType: types.number,
    shipmentStatut: types.maybeNull(types.number),
    invoiceStatut: types.maybeNull(types.number),
    description: types.string,
    shipementDate: types.string,
    qty: types.number,
    qtySold: types.number,
    unitOfMesure: types.string,
    unitPrice: types.number,
    netUnitPrice: types.number,
    vatRate: types.number,
    salesPersonCode: types.string,
    prepayment: types.number,
    quantityShipped: types.number,
    quantityInvoiced: types.number,
    shippedRate: types.maybeNull(types.number),
    totalAmount: types.maybe(types.number),
    quantityToInvoiced: types.number,
    dueDate: types.string,
    orderDate: types.string,
    amount: types.number,
})

export const groupListItemModel = types.model({
    libelle: types.string,
    totalPrice: types.number,
    qty: types.number,
    unitOfMesure: types.string,
    totalShipped: types.number,
    qtySold: types.number,
    totalExecutionRate: types.maybeNull(types.number),
    date: types.string,
    documentType: types.maybeNull(types.number)
})


export const GroupListModel = types.model({
    list: types.array(groupListItemModel),
    campaignTotalAmount: types.number,
})

export const OrdersStoreModel = types.model({
    groupList: types.maybe(GroupListModel),
    list: types.maybe(types.array(OrderModel)),
    campaigns: types.array(CampaignsListModel),
    fetchOrdersState: ServiceStateModel
})
