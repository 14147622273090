import React from 'react';
import {StatusTag, StatusTagTheme} from '@components/UI/StatusTag/StatusTag';

interface IOrdersStatusTag {
  status: OrdersStatusEnum;
}

export enum OrdersStatusEnum {
  BILLED = 'Facturée',
  SHIPPED = 'Expédiée',
  SETTLED = 'Soldé',
  RETURNED = 'Retour client',
}

const OrdersStatusTag: React.FC<IOrdersStatusTag> = ({status}) => {
  let theme = StatusTagTheme.GREEN;
  switch (status) {
    case OrdersStatusEnum.BILLED:
    case OrdersStatusEnum.SHIPPED:
    case OrdersStatusEnum.SETTLED:
      theme = StatusTagTheme.GREEN;
      break;
    case OrdersStatusEnum.RETURNED:
      theme = StatusTagTheme.GREY;
      break;
    /*
    case OrdersStatusEnum.notSold:
    case OrdersStatusEnum.inProgress:
      theme = StatusTagTheme.YELLOW;
      break;
      */
  }

  return <StatusTag theme={theme}>{status}</StatusTag>;
};

export default OrdersStatusTag;
