import fetch from '@utils/fetch'


export interface IDriveFiles {
    name?: string
    parents?: Array<string>
    webViewLink?: string;
    thumbnailLink?: string;
    id?: string;
}

const endPoint = `${process.env.API_URL}/extranet`


const endPoints = {
    offers: `${endPoint}/offers`
}


export const fetchOffers = (filters: any) => fetch(endPoints.offers, 'get', filters)
