import React from 'react';
import './styles.scss';
import FieldWrapper, {INewFieldWrapper} from '@components/NewFieldWrapper/FieldWrapper';
import {useField} from 'formik';
import {cn} from '@utils/class-names';
import {isUndefined} from '@lepicard/utils';

interface IInputProps {
  name: string;
  type: 'text' | 'number' | 'tel' | 'email' | 'password' | 'hidden';
  label?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  icon?: string;
  min?: string | number;
  max?: string | number;
  step?: number;
  labelType?: INewFieldWrapper['labelType'];
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  onFocus?: () => void;
}

const Input: React.FC<IInputProps> = (props) => {
  const [field, meta, helper] = useField(props.name);

  const wrapperProps: Omit<INewFieldWrapper, 'children'> = {
    name: props.name,
    label: props.label,
    required: props.required,
    className: props.className,
    labelType: props.labelType ?? (isUndefined(props.label) ? 'no-label' : 'label-floating'),
  };

  return (
    <FieldWrapper {...wrapperProps}>
      {({label, labelType, showError, id}) => {
        const additionalField = {
          id: id,
          type: props.type,
          className: props.className,
          disabled: props.disabled,
          min: props.min,
          max: props.max,
          step: props.step,
          placeholder: !isUndefined(props.label) ? ' ' : props.placeholder,
          onFocus: props.onFocus,
        };

        const hasBeforeIcon = !isUndefined(props.iconBefore);
        const hasAfterIcon = !isUndefined(props.iconAfter);

        return (
          <div
            className={cn('lpa-input', {
              isError: showError,
              hasFloatingLabel: labelType === 'label-floating',
              hasAfterIcon,
              hasBeforeIcon,
            })}
          >
            {hasBeforeIcon && <div className="lpa-input__icon lpa-input__icon__before">{props.iconBefore}</div>}
            <input {...field} {...additionalField} />
            {hasAfterIcon && <div className="lpa-input__icon lpa-input__icon__after">{props.iconAfter}</div>}
            {!isUndefined(label) && labelType === 'label-floating' && <label>{label}</label>}
          </div>
        );
      }}
    </FieldWrapper>
  );
};

export default Input;
