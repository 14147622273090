import {StyleSheet} from '@react-pdf/renderer';
import {IHeaderValue} from '@components/PdfGenerator/index';
import EnvironmentService from '@utils/environmentService';

const toPx = (n: number) => n * 0.65;
const toFigmaPx = (n: number) => n * 0.8 * 0.3; // 30% scaling for Figma | 80% for html -> pdf

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 8,
    paddingTop: 20,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: 'Helvetica',
  },
  pageHeader: {
    marginTop: -20,
    marginLeft: -20,
    marginRight: -20,
    height: 100,
    backgroundColor: '#F9FAFB',
    borderBottomWidth: toPx(0.5),
    borderBottomColor: '#D2E1E0',
  },
  image: {
    marginTop: 15,
    padding: 15,
    width: 250,
  },
  titleCard: {
    borderWidth: '1px',
    borderColor: '#D2E1E0',
    flexDirection: 'column',
    marginTop: 40,
    marginBottom: 20,
  },
  titleSubCard: {
    backgroundColor: EnvironmentService.getInstance() === 'luneray' ? '#6A1F21' : '#167F5A',
    paddingVertical: toFigmaPx(48),
    paddingHorizontal: toFigmaPx(60),
  },
  title: {
    fontSize: 16,
    textAlign: 'left',
    color: '#FFFFFF',
    fontFamily: 'Helvetica-Bold',
  },
  subTitleContainer: {
    paddingVertical: toFigmaPx(20),
    paddingHorizontal: toFigmaPx(60),
  },
  subTitle: {
    flexDirection: 'row',
    fontSize: 10,
    textAlign: 'left',
    fontFamily: 'Helvetica-Bold',
  },
  subTitlePadding: {
    paddingVertical: toFigmaPx(40),
  },
  subTitleBorder: {
    borderTopWidth: 1,
    borderColor: '#D2E1E0',
  },
  //
  subTitleValuesContainer: {
    backgroundColor: '#F9FAFB',
    borderTopWidth: 1,
    borderColor: '#D2E1E0',
    color: '#728094',
    paddingVertical: toFigmaPx(60),
    paddingHorizontal: toFigmaPx(60),
  },
  //
  subValue: {
    fontSize: 10,
    color: EnvironmentService.getInstance() === 'luneray' ? '#862523' : '#167956',
    marginRight: 25,
    fontFamily: 'Helvetica-Bold',
  },
  subValueCollecte: {
    fontSize: 10,
    color: EnvironmentService.getInstance() === 'luneray' ? '#862523' : '#167956',
    marginRight: 25,
    marginLeft: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F9FAFB',
  },
  subRow: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 8,
  },
  pagination: {
    position: 'absolute',
    bottom: 15,
    right: 15,
  },
  libelle: {
    flexDirection: 'row',
  },
  cardInfo: {
    flexDirection: 'row',
    marginTop: 40,
    marginLeft: 80,
    fontSize: 10,
  },
  Date: {
    marginRight: 200,
    flexDirection: 'row',
  },
  textInfos: {
    fontSize: 10,
  },
  contactInfos: {
    flex: 1,
  },
  contactName: {
    color: EnvironmentService.getInstance() === 'luneray' ? '#862523' : '#167956',
    textTransform: 'uppercase',
    marginBottom: 3,
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 10,
  },
  subTable: {
    marginLeft: 10,
    marginRight: 10,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderColor: '#D2E1E0',
    marginBottom: toFigmaPx(60),
  },
  subComponent: {
    marginLeft: 10,
    marginRight: 10,
    borderWidth: 1,
    borderColor: '#D2E1E0',
    marginBottom: 10,
    backgroundColor: '#F9FAFB',
    padding: 10,
  },
  tableWrapper: {
    color: '#728094',
    flexDirection: 'row',
  },
  subTableHeader: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderColor: '#D2E1E0',
    borderBottomWidth: 1,
    backgroundColor: '#F9FAFB',
    fontFamily: 'Helvetica-Bold',
  },
  subTablevalue: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    backgroundColor: '#F9FAFB',
    color: 'black',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    borderTopWidth: 1,
    borderColor: '#D2E1E0',
    marginHorizontal: 20,
  },
  titleCardRow: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  column: {
    flexDirection: 'column',
  },
  subTitles: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: EnvironmentService.getInstance() === 'luneray' ? '#af5759' : '#167E58',
  },
  data: {
    fontSize: 8,
  },
  table: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderColor: '#D2E1E0',
    borderWidth: 0,
  },
  tableHeader: {
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    borderBottomWidth: 1,
    borderColor: '#D2E1E0',
  },
  tableHeaderText: {
    color: '#728094',
    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
  },
  cell: {
    borderColor: '#D2E1E0',
    justifyContent: 'center',
    height: toFigmaPx(175),
    padding: 5,
  },
  libelleCell: {
    borderColor: '#D2E1E0',
    paddingTop: toFigmaPx(30),
    paddingBottom: toFigmaPx(30),
    paddingLeft: toFigmaPx(30),
    height: '100%',
  },
  tableWithBorder: {
    borderWidth: 1,
  },
  maxWidth: {
    maxWidth: 450,
    marginLeft: 5,
  },
});

export default styles;

export const getColumnWidth = (subtitle, pdfType, index, columnWidth) => {
  if (pdfType === 'Appro' && subtitle === 'deliveries') {
    return columnWidth;
  } else if (pdfType === 'Appro' && subtitle === 'orders') {
    if (index === 1) {
      return '17%';
    } else {
      return columnWidth;
    }
  } else if (pdfType === 'Appro') {
    if (index === 3) {
      return '30%';
    } else if (index === 4) {
      return '12%';
    } else {
      return '15%';
    }
  } else if (pdfType === 'Collecte' && subtitle === 'invoices') {
    if (index === 1) {
      return '16%';
    } else {
      return '12%';
    }
  } else {
    return columnWidth;
  }
};

export const subRowStyle = (index, pdfType, headerValue) => ({
  ...styles.subRow,
  backgroundColor:
    pdfType !== 'Appro' && !headerValue?.subValues ? (index % 2 === 0 ? '#FFFFFF' : '#F9FAFB') : '#FFFFFF',
});

export const cellStyle = (index, indexBorder, pdfType, headerValue, columnWidth, subtitle) => ({
  ...styles.cell,
  borderBottomWidth: 0.5,
  width: getColumnWidth(subtitle, pdfType, index, columnWidth),
  borderRightWidth:
    (subtitle === 'deliveries' && index === 3) ||
    (pdfType === 'Appro' && index === 5) ||
    (subtitle === 'invoices' && index === 7) ||
    (headerValue?.subValues && subtitle !== 'invoices' && index === 6)
      ? 0
      : 1,
  borderLeftWidth: pdfType === 'Appro' || headerValue?.subValues ? 0 : indexBorder === 0 ? 1 : 0,
  borderTopWidth: pdfType === 'Appro' || headerValue?.subValues ? 0 : 1,
});

export const libelleCellStyle = (
  index: number,
  indexBorder: number,
  pdfType: string | undefined,
  headerValue: IHeaderValue | undefined,
  columnWidth: string,
  subtitle: string | undefined
) => ({
  ...styles.libelleCell,
  width:
    pdfType === 'Appro' && subtitle === 'deliveries'
      ? columnWidth
      : pdfType === 'Appro' && subtitle === 'orders'
      ? index === 1
        ? '17%'
        : columnWidth
      : pdfType === 'Appro'
      ? index === 3
        ? '30%'
        : index === 4
        ? '12%'
        : '15%'
      : pdfType === 'Collecte' && subtitle === 'invoices'
      ? index === 1
        ? '16%'
        : '12%'
      : columnWidth,
  borderRightWidth:
    (subtitle === 'deliveries' && index === 3) ||
    (pdfType === 'Appro' && index === 5) ||
    (subtitle === 'invoices' && index === 7) ||
    (headerValue?.subValues && subtitle !== 'invoices' && index === 6)
      ? 0
      : 1,
  borderLeftWidth: pdfType === 'Appro' || headerValue?.subValues ? 0 : index === 0 ? 1 : 0,
  borderTopWidth: pdfType === 'Appro' && indexBorder !== 0 ? 1 : headerValue?.subValues ? 0.5 : 0.5,
  borderBottomWidth: pdfType === 'Appro' || headerValue?.subValues ? 0 : 0.5,
});
