import React from 'react';
import Button from '@components/UI/Button/Button';
import {Link} from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="text-center">
      <h3 className="text-title-3 mb-2">Error 404</h3>
      <p className="mb-4">La page demandée n&apos;existe pas.</p>
      <Link to="/">
        <Button label="Retourner à l'accueil" className="mx-auto" />
      </Link>
    </div>
  );
};

export default NotFound;
