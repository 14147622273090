import React from 'react';
import {isUndefined} from '@lepicard/utils';

interface ICardSubTitle {
  title: string;
  subTitle?: string;
}

export const CardSubTitle: React.FC<ICardSubTitle> = ({title, subTitle}) => {
  return (
    <div>
      {!isUndefined(subTitle) && <span className="text-paragraph-2">{subTitle}</span>}
      <h4 className="text-info-1 uppercase">{title}</h4>
    </div>
  );
};
