import {ICollectDeliveriesStoreModel} from './models';
import {speciesList} from '@stores/utils/speciesList';
import {renameSpecie} from '@utils/species/species';

export default (self: ICollectDeliveriesStoreModel) => {
  return {
    get getCollectCampaigns() {
      return self.campaigns.map((campaign) => ({value: campaign.id}));
    },

    get specieAsOptions() {
      return self.species.map((specie) => ({
        value: specie.itemNo,
        label: renameSpecie(specie.name),
        specie: specie.code,
      }));
    },

    get subFamilyAsOptions() {
      return speciesList(self.species).map((option: any) => ({
        ...option,
        label: renameSpecie(option.label),
      }));
    },
  };
};
