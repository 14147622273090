import React from 'react'
// @ts-ignore because rodal does not have a declaration file
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import './styles.scss'
import Button from '@components/Buttons/Button'

interface IModal {
  active: boolean
  toggle: () => void
  children: React.ReactNode
  footer?: React.ReactNode | null
  bodyClassName?: string
  onClose?: () => void
  buttonText?: string
  width?: string | number
  title?: string
  minWidth?: string
  maxWidth?: string
  className?: string
}

const customStyles = {
  height: 'auto',
  maxHeight: '100%',
  zIndex: 10000,
  width: 'auto',
  overflowY: 'auto'
}

const Modal: React.FC<IModal> = ({
  active,
  toggle,
  title,
  footer,
  children,
  bodyClassName = '',
  onClose,
  buttonText = 'OK',
  width = 'auto',
  minWidth = 'auto',
  maxWidth = 'auto',
  className = ''
}) => {
  const handleClose = () => {
    toggle()

    if (onClose) {
      onClose()
    }
  }

  return (
    <Rodal
      className={`modal ${className}`}
      visible={active}
      onClose={handleClose}
      showCloseButton={false}
      customStyles={{ ...customStyles, width, minWidth, maxWidth }}
    >
      <div className="modal-header">
        <div className="text-3xl text-center flex-grow">{title && title}</div>{' '}
        <i className="modalClose lnr lnr-cross size-veryLarge" onClick={handleClose}></i>
      </div>
      <div className={`modal-body  ${bodyClassName}`}>{children}</div>
      {footer !== null && (
        <div className="modal-footer p-10">
          {footer ? (
            footer
          ) : (
              <Button className="pl-60 pr-60" onClick={handleClose}>
                {buttonText}
              </Button>
            )}
        </div>
      )}
    </Rodal>
  )
}

export default Modal
