import React from 'react';
import {observer} from 'mobx-react-lite';
import {ICotation, ICotationsTaxe, ISpecie, IYear} from '@services/cotations';
import {useWindowSize} from '@hooks/useWindowSize';
import {QuotationsTableDesktop} from '@components/Quotations/QuotationsList/Table/Desktop/QuotationsTableDesktop';
import {QuotationsTableMobile} from '@components/Quotations/QuotationsList/Table/Mobile/QuotationsTableMobile';

interface IQuotationsQualityItem {
  tax: ICotationsTaxe;
}

const QuotationsQualityItem: React.FC<IQuotationsQualityItem> = ({tax}) => {
  return (
    <div className="QuotationsQualityItem">
      <div className="QuotationsQualityItem-icon">
        <img src={`/img/SVG/icons/species/green/${tax.specieId}.svg`} alt="" className="w-6" />
      </div>
      <p className="QuotationsQualityItem-title text-title-4">{tax.title}</p>
      <p className="QuotationsQualityItem-tax text-paragraph-2">Taxe : {tax.amount}€/T</p>
      <p className="QuotationsQualityItem-text">{tax.description}</p>
    </div>
  );
};

interface IQuotationsQuality {
  taxes: ICotationsTaxe[];
  className: string;
}

const QuotationsQuality: React.FC<IQuotationsQuality> = ({taxes, className}) => {
  return (
    <div className={`QuotationsQuality ${className}`}>
      <div className="QuotationsQuality-title">Qualité</div>
      <div className="QuotationsQuality-container">
        {taxes.map((tax, idx: number) => {
          return <QuotationsQualityItem tax={tax} key={idx} />;
        })}
      </div>
    </div>
  );
};

export interface IQuotationsTable {
  data: {species: ISpecie[]; years: IYear[]};
  header: React.ReactNode;
  className: string;
}

interface IQuotationsList {
  setSelectedData: (cotation: ICotation) => void;
  data: {species: ISpecie[]; years: IYear[]; qualities: ICotationsTaxe[]};
  header: React.ReactNode;
}

const QuotationsListUI: React.FC<IQuotationsList> = observer(({setSelectedData, data, header}) => {
  const {windowWidth} = useWindowSize();

  const isDesktop = windowWidth >= 1024;

  const tableParams: IQuotationsTable = {
    data: data,
    header: header,
    className: 'mb-8',
  };

  return (
    <>
      {process.env.ONLINE_CONTRACTUALISATION == '1' && (
        <div className="text-white w-1/3 mb-4">
          <button className="w-full mt-5 mb-6 text-greenLepicard rounded border-2 p-2 border-greenLepicard bg-white shadow-lg hover:bg-greenLepicard hover:text-white hover:border-white hover:border-2">
            Activer la contractualisation en ligne
          </button>
        </div>
      )}

      {isDesktop ? <QuotationsTableDesktop {...tableParams} /> : <QuotationsTableMobile {...tableParams} />}

      {<QuotationsQuality taxes={data.qualities} className="mb-8" />}

      <div className="bg-white rounded shadow-lg overflow-hidden p-4">
        <div className="text-center" style={{fontSize: 12}}>
          <p>
            &#40;Tous les prix sont donnés à titre indicatif et sont susceptibles d’être modifiés à tout moment, à la
            hausse ou à la baisse, en fonction de l’évolution des marchés - Les prix s’entendent net de coût de
            stockage&#41;
          </p>
          <p>
            Les barèmes de réfactions s’appliquent à ces prix. Les contrats d’achat doivent être transmis par fax au
            siège avant 18H00. Désinsectisation : 5.00 €/T.
          </p>
        </div>
      </div>
    </>
  );
});

export default QuotationsListUI;
