import { useState } from 'react'

function useList<D extends {}>(idField: string = 'id') {
  const [items, setItems] = useState<D[]>([])
  const [isToggleAllItems, setToggleAllItem] = useState<boolean>(false)

  const toggleItem = (item: D) => {
    const newList = [...items]
    const index = items.findIndex(
      currenItem => currenItem[idField] !== undefined && currenItem[idField] === item[idField]
    )

    if (index === -1) {
      newList.push(item)
    } else {
      setToggleAllItem(false)
      newList.splice(index, 1)
    }

    setItems(newList)
  }

  const toggleAllItems = (list) => {
    if (!isToggleAllItems === false) {
      setItems([])
    } else {
      setItems(list)
    }
    setToggleAllItem(!isToggleAllItems)
  }

  const isItemInList = (id: any) => {
    return items.find((item: D) => item[idField] === id) !== undefined
  }

  const emptyList = () => {
    setItems([])
  }

  return {
    items,
    isToggleAllItems,
    toggleItem,
    isItemInList,
    toggleAllItems,
    emptyList
  }
}

export default useList
