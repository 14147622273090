import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';

type ICardInfoGroup = PropsWithChildren<{
  className?: string;
}>;

export const CardInfoGroup: React.FC<ICardInfoGroup> = ({children, className}) => {
  return <div className={cn('Card-infoGroup', className)}>{children}</div>;
};
