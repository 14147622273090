import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';

type IFiltersCard = PropsWithChildren<{
  className?: string;
}>;

const FiltersCard: React.FC<IFiltersCard> = ({children, className}) => {
  return (
    <div className={cn('FiltersCard relative bg-white lpa-shadow py-5 px-3.5 lg:py-6 lg:px-4 rounded', className)}>
      {children}
    </div>
  );
};

export default FiltersCard;
