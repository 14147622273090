import './style.scss';
import React from 'react';
import {cn} from '@utils/class-names';

export enum ProgressbarTheme {
  SOFT = 'soft',
  CONTRAST = 'contrast',
}

interface IProgressbar {
  percent: number;
  theme: ProgressbarTheme;
}

export const Progressbar: React.FC<IProgressbar> = ({percent, theme}) => {
  const normalisePercent = percent < 0 ? 0 : percent > 100 ? 100 : percent;
  return (
    <div
      className={cn('Progressbar', {
        'Progressbar-internal': percent >= 30,
        'Progressbar-external': percent < 30,
        'Progressbar-contrast': theme === ProgressbarTheme.CONTRAST,
      })}
      style={
        {
          '--completion-ratio': normalisePercent * 0.01,
          '--completion-percent': normalisePercent + '%',
        } as React.CSSProperties
      }
    >
      <div className="Progressbar-bar"></div>
      <div className="Progressbar-textBox">
        <span className="Progressbar-text text-info-2">{percent} %</span>
      </div>
    </div>
  );
};
