import React, {useState, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import useModal from '@hooks/useModal';
import {fetchCollectInvoices, ICollectInvoicesFilters} from '@services/navagri';
import {getNoDataMessage} from '@utils/tables';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import Select from '@components/NewFields/Select';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import Button from '@components/UI/Button/Button';
import Loader from '@components/Loader';
import CheckBox from '@components/NewFields/CheckBox';
import OptionModal from '@components/UI/OptionModal/OptionModal';
import {Form, Formik, FormikProps, FormikValues, useFormikContext} from 'formik';
import Tag from '@components/UI/Tag/Tag';
import TagsContainer from '@components/UI/TagsContainer/TagsContainer';
import Input from '@components/NewFields/Input';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import Pagination from '@components/Pagination';
import usePagination from '@hooks/usePagination';
import ExportButtons from '@components/UI/ExportButtons/ExportButtons';
import {isNull, isUndefined} from '@lepicard/utils';
import NoData from '@components/NoData/NoData';
import Search from '@components/Icons/Search';
import CheckBoxAllSelector from '@components/NewFields/CheckBoxAllSelector';
import usePrevious from '@hooks/usePrevious';
import {CollectInvoicesRow} from '@components/CollectInvoices/CollectInvoicesRow/CollectInvoicesRow';
import {IFacetChoiceModel} from '@stores/shared/models';
import {CollectInvoicesTotal} from '@components/CollectInvoices/CollectInvoicesTotal/CollectInvoicesTotal';
import {csvColumns, pdfColumns} from '@components/CollectInvoices/CollectInvoicesList/column';
import useCsvGenerator from '@hooks/useCsvGenerator';
import usePdfGenerator from '@hooks/usePdfGenerator';
import {format} from 'date-fns';
import {headerValues, getCsvFileName} from './column';

type IHandleFormRewrite = {
  subFamilyAsOptions: IFacetChoiceModel[];
  specieAsOptions: Array<IFacetChoiceModel & {subFamilyValue: string}>;
};

const HandleFormRewrite: React.FC<IHandleFormRewrite> = observer(({subFamilyAsOptions, specieAsOptions}) => {
  const {values, setFieldValue} = useFormikContext<Record<string, any>>();

  const previousValues = usePrevious(values);

  useEffect(() => {
    // Ignore first render
    if (isUndefined(previousValues)) {
      return;
    }

    // If campaign" filter value changes, reset subFamily & specie
    if (values.campaign !== previousValues.campaign) {
      if (values.campaign === 'all' && previousValues.campaign === undefined) {
        // unintended/auto filter
      } else {
        values.subFamily = 'all';
        values.specie = 'all';
      }
    }

    // If subFamily filter value changes, reset the specie
    if (values.subFamily !== previousValues.subFamily) {
      if (values.subFamily === 'all' && previousValues.subFamily === undefined) {
        // don't filter intentionally
      } else {
        values.specie = 'all';
      }
    }

    // If specie filter value changes, adapt subFamily according to specie new filter value.
    if (values.specie !== previousValues.specie && values.specie != 'all') {
      if (values.specie === undefined) {
        values.specie = 'all';
      } else {
        const choosenSpecie = specieAsOptions.find((s) => s.value === values.specie);
        values.subFamily = subFamilyAsOptions.find(
          (subFamily) => subFamily.value === choosenSpecie?.subFamilyValue
        )?.value;
      }
    }

    // Reset contractTypes when global filters changes
    const params: string[] = ['campaignId', 'subFamily', 'specie'];
    params.forEach((param) => {
      if (values[param] !== previousValues?.[param]) {
        if (values[param] !== 'all') {
          if (values.contractTypes.length > 0) {
            values.contractTypes = [];
          }
        }
      }
    });
  }, [values]);
  return null;
});

const CollectInvoicesListWrapper: React.FC = observer(() => {
  const {collectInvoicesStore, campaigns: campaignsStore} = useStore();
  const {currentCampaign} = campaignsStore;

  const currentCampaignId = currentCampaign!.id;

  const tagsOptions = [{label: 'Factures non soldées', value: 'open-invoice'}];

  const defaultValues: FormikValues = {
    campaignId: collectInvoicesStore.getCampaignOptions.some((option) => option.value === currentCampaignId)
      ? currentCampaign!.id
      : 'all',
    subFamily: 'all',
    specie: 'all',
    contractTypes: [],
    search: '',
    tagsOption: 'all',
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const filters = useMemo<ICollectInvoicesFilters>(() => {
    if (isNull(formValues)) {
      return {campaignId: currentCampaignId};
    }

    const newFilters: ICollectInvoicesFilters = {};

    if (formValues.campaignId !== 'all') {
      newFilters.campaignId = formValues.campaignId;
    }

    if (formValues.subFamily !== 'all') {
      newFilters.subFamily = formValues.subFamily;
    }

    if (formValues.specie !== 'all') {
      newFilters.specie = formValues.specie;
    }

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    if (formValues.contractTypes.length > 0) {
      newFilters.contractTypes = formValues.contractTypes;
    }

    if (formValues.tagsOption !== 'all') {
      newFilters.tagsOption = formValues.tagsOption;
    }

    return newFilters;
  }, [formValues]);

  const loadData = (page: number) => {
    collectInvoicesStore.fetchInvoices(page, filters);
  };

  useEffect(() => {
    onPageChange();
  }, [filters]);

  const list = collectInvoicesStore.list ? collectInvoicesStore.list.toJS() : [];

  const {active, toggle} = useModal();

  const loadDataToExport = async () => {
    const {data} = await fetchCollectInvoices({$limit: Number.MAX_SAFE_INTEGER, ...filters, $skip: 0});
    return data;
  };

  const {download: downloadPdf} = usePdfGenerator(pdfColumns());
  const {download: downloadCsv} = useCsvGenerator(csvColumns);

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadDataToExport,
      title: 'COLLECTE - MES FACTURES',
      subtitle: 'invoices',
      fileName: `collecteFactures_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Collecte',
      headerValue: headerValues(formValues, collectInvoicesStore, collectInvoicesStore.getContractTypesOptions),
    });
  };

  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadDataToExport,
      fileName: `Export_Collecte_factures_${getCsvFileName(
        formValues,
        tagsOptions,
        filteredSpecieOptions,
        collectInvoicesStore
      )}`,
      headerValue: headerValues(formValues, collectInvoicesStore, collectInvoicesStore.getContractTypesOptions),
    });
  };

  const {pageNumber, onPageChange} = usePagination(loadData);

  const filteredSpecieOptions = useMemo(() => {
    return collectInvoicesStore.getSpecieOptions.filter((option) => {
      return (
        isUndefined(formValues?.subFamily) ||
        formValues.subFamily === 'all' ||
        option.subFamilyValue === formValues.subFamily
      );
    });
  }, [collectInvoicesStore.getSpecieOptions, formValues]);

  return (
    <>
      <div>
        <Formik
          initialValues={defaultValues}
          onSubmit={(values) => console.log('submit', values)}
          children={({values, setFieldValue}: FormikProps<any>) => {
            useEffectNotOnFirstRender(() => {
              setFormValues(values);
            }, [values]);

            return (
              <Form className="w-full mb-5">
                <HandleFormRewrite
                  subFamilyAsOptions={collectInvoicesStore.getSubFamilyOptions}
                  specieAsOptions={collectInvoicesStore.getSpecieOptions}
                />
                <FiltersCard className="grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4 shadow-none">
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="campaignId" className="mr-1 lg:mr-2.5">
                        Campagne
                      </label>
                      <QuickHelp>Période du 1er juillet N au 30 juin N+1</QuickHelp>
                    </div>
                    <Select
                      inputId="campaignId"
                      name="campaignId"
                      options={[{label: 'Toutes', value: 'all'}, ...collectInvoicesStore.getCampaignOptions]}
                    />
                  </div>
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="subFamily" className="mr-1 lg:mr-2.5">
                        Culture
                      </label>
                      <QuickHelp>Choisissez parmi la liste pour afficher les résultats par culture</QuickHelp>
                    </div>
                    <Select
                      inputId="subFamily"
                      name="subFamily"
                      options={[
                        {
                          label: 'Tous',
                          value: 'all',
                        },
                        ...collectInvoicesStore.getSubFamilyOptions,
                      ]}
                      placeholder="Tous"
                    />
                  </div>
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="specie" className="mr-1 lg:mr-2.5">
                        Variété
                      </label>
                      <QuickHelp>Choisissez parmi la liste pour afficher les résultats par variété</QuickHelp>
                    </div>
                    <Select
                      inputId="specie"
                      name="specie"
                      options={[{value: 'all', label: 'Tous'}, ...filteredSpecieOptions]}
                    />
                  </div>
                </FiltersCard>
                <CollectInvoicesTotal />

                <div className="mb-4">
                  <div className="flex justify-between gap-5">
                    <button className="flex items-center lg:hidden text-interactif" onClick={toggle} type="button">
                      <img src="/icons/filters.svg" alt="" className="me-1.5" />
                      Filtres
                    </button>
                    <Input
                      name="search"
                      placeholder="Rechercher"
                      type="text"
                      className={'h-10 hidden lg:block max-w-xl'}
                      iconAfter={<Search />}
                    />
                    <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />
                  </div>
                  <Input
                    name="search"
                    placeholder="Rechercher"
                    type="text"
                    className="lg:hidden my-4"
                    iconAfter={<Search />}
                  />
                </div>
                <div className="flex justify-between gap-4">
                  <div className="hidden lg:flex gap-5 grow max-w-[1000px] ">
                    <Select
                      label="Type de contrat"
                      name="contractTypes"
                      multiple={true}
                      placeholder="Tous"
                      options={collectInvoicesStore.getContractTypesOptions}
                      selectAllOption="Tous"
                    />
                  </div>
                  <TagsContainer>
                    {tagsOptions.map((tag) => (
                      <Tag
                        key={tag.value}
                        label={tag.label}
                        onClick={() => setFieldValue('tagsOption', values.tagsOption === tag.value ? 'all' : tag.value)}
                        active={values.tagsOption === tag.value}
                      />
                    ))}
                  </TagsContainer>
                </div>

                <OptionModal
                  active={active}
                  title="Filtres"
                  toggle={toggle}
                  actions={<Button label="Fermer" onClick={toggle} className="w-full" />}
                >
                  <h4 className="text-title-4 text-green-100 mb-3.5">Type de contrat :</h4>
                  <CheckBoxAllSelector
                    name="contractTypes"
                    options={collectInvoicesStore.getContractTypesOptions}
                    label="Tous"
                    className={'mb-7'}
                  />
                  {collectInvoicesStore.getContractTypesOptions.map(
                    ({value, label}: any, idx: React.Key | null | undefined) => (
                      <CheckBox key={idx} name="contractTypes" label={label} value={value} />
                    )
                  )}
                </OptionModal>
              </Form>
            );
          }}
        />
      </div>

      {!collectInvoicesStore.fetchInvoicesState.isDone ? (
        <Loader />
      ) : (
        <div>
          <div className="flex flex-col gap-3.5 lg:gap-5">
            {Array.isArray(list) && list.length > 0 ? (
              list.map((c, idx) => <CollectInvoicesRow key={idx} collectInvoice={c} />)
            ) : (
              <NoData message={getNoDataMessage(filters, currentCampaignId)} />
            )}
          </div>

          <Pagination
            activePage={pageNumber}
            totalItemsCount={collectInvoicesStore.fetchInvoicesState.total ?? 0}
            onChange={onPageChange}
          />
        </div>
      )}
    </>
  );
});

export const CollectInvoicesList: React.FC = observer(() => {
  const {campaigns: campaignsStore, collectInvoicesStore} = useStore();

  const areCampaignsOptionsLoaded = ['done', 'error'].includes(collectInvoicesStore.fetchCampaignsState.state);

  useEffect(() => {
    if (!areCampaignsOptionsLoaded) {
      collectInvoicesStore.fetchCampaigns();
    }
  }, []);

  const currentCampaignId = campaignsStore.currentCampaign?.id;

  if (isUndefined(currentCampaignId) || !areCampaignsOptionsLoaded) {
    return <Loader />;
  }

  return <CollectInvoicesListWrapper />;
});
