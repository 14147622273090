import React, {PropsWithChildren} from 'react';
import {useAccordion} from '@hooks/useAccordion';
import {Arrow} from '@components/Icons/Arrow';
import {isUndefined} from '@lepicard/utils';

type IHead = {
  onClick: () => void;
  toggleIcon: React.ReactNode;
};

type IAccordion = PropsWithChildren<{
  head?: (props: IHead) => React.ReactNode;
  foot?: (props: IHead) => React.ReactNode;
}>;

export const Accordion: React.FC<IAccordion> = ({children, head, foot}) => {
  const {isAccordionOpen, setIsAccordionOpen, toggleAccordion} = useAccordion();

  const params = {
    isAccordionOpen,
    onClick: toggleAccordion,
    toggleIcon: <Arrow direction={isAccordionOpen ? 'top' : 'bottom'} color="#0F4C36" />,
  };

  return (
    <>
      {!isUndefined(head) && head(params)}
      {isAccordionOpen && children}
      {!isUndefined(foot) && foot(params)}
    </>
  );
};
