import React, {PropsWithChildren} from 'react';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';

export function getShowMoreLabel(isOpen: boolean, collapsibleTotal: number) {
  const action = isOpen ? 'Masquer' : 'Afficher';
  return collapsibleTotal > 1 ? `${action} les ${collapsibleTotal} articles` : `${action} l'article`;
}

export function getShowMoreDeliveriesLabel(isOpen: boolean, collapsibleTotal: number) {
  const action = isOpen ? 'Masquer' : 'Afficher';
  return collapsibleTotal > 1 ? `${action} les ${collapsibleTotal} livraisons` : `${action} la livraison`;
}

export function getShowMoreInvoicesLabel(isOpen: boolean, collapsibleTotal: number) {
  const action = isOpen ? 'Masquer' : 'Afficher';
  return collapsibleTotal > 1 ? `${action} les ${collapsibleTotal} factures` : `${action} la facture`;
}

type ICardShowMore = PropsWithChildren<{
  onClick: () => void;
  toggleIcon: React.ReactNode;
}>;

export const CardShowMore: React.FC<ICardShowMore> = ({children, onClick, toggleIcon}) => {
  return (
    <CardRow type={CardRowType.SHOW_MORE} className="Card-showMoreContent" onClick={onClick}>
      <div className="text-interactif">{children}</div>
      {toggleIcon}
    </CardRow>
  );
};
