import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';

export enum CardRowHeightWrapperType {
  SCROLL = 'isScroller',
  LOCK = 'isLocked',
}

type ICardRowHeightWrapper = PropsWithChildren<{
  className?: string;
  type: CardRowHeightWrapperType;
  active: boolean;
}>;

export const CardRowHeightWrapper: React.FC<ICardRowHeightWrapper> = ({children, className, type, active}) => {
  return (
    <div
      className={cn('Card-rowScrollWrapper', className, {
        [type]: active,
      })}
    >
      {children}
    </div>
  );
};
