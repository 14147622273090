import React from 'react';
import styles from '@components/PdfGenerator/styles';
import {format} from 'date-fns';
import {Text, View} from '@react-pdf/renderer';

export interface IPdfHeaderRow {
  contactInformations: {
    name: string;
    address: string;
    zipCode: string;
    city: string;
  };
}

export const PdfHeaderRow: React.FC<IPdfHeaderRow> = ({contactInformations}) => {
  return (
    <View style={styles.cardInfo} wrap={false}>
      <View style={styles.Date}>
        <Text style={styles.bold}>Date : </Text>
        <Text>{format(new Date(), `dd/MM/yyyy`)}</Text>
      </View>
      <View style={styles.contactInfos}>
        <Text style={styles.contactName}>{contactInformations.name}</Text>
        <Text style={styles.textInfos}>{contactInformations.address}</Text>
        <Text style={styles.textInfos}>{`${contactInformations.zipCode} ${contactInformations.city}`}</Text>
      </View>
    </View>
  );
};
