import { types } from 'mobx-state-tree'
import { ServiceStateModel } from '@stores/models'


export const OfferModel = types.model({
    id: types.string,
    name: types.string,
    webViewLink: types.string,
    parents: types.array(types.string)
})

export const OffersStoreModel = types.model({
    list: types.array(OfferModel),
    fetchOffersState: ServiceStateModel
})