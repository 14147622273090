import React from 'react';
import './styles.scss';
import {cn} from '@utils/class-names';
import EnvironmentService from '@utils/environmentService';

const instanceConfig = EnvironmentService.getInstanceConfig();

const BulletinsSanteDuVegetalLinks: {label: string; link: string}[] = [];

if (instanceConfig.bsv.showNormandieLink) {
  BulletinsSanteDuVegetalLinks.push({
    label: 'Normandie',
    link: 'https://draaf.normandie.agriculture.gouv.fr/bulletin-de-sante-du-vegetal-r114.html',
  });
}
if (instanceConfig.bsv.showIdfLink) {
  BulletinsSanteDuVegetalLinks.push({
    label: 'Île-de-France',
    link: 'https://driaaf.ile-de-france.agriculture.gouv.fr/epidemiosurveillance-et-bulletin-de-sante-du-vegetal-bsv-r189.html',
  });
}
if (instanceConfig.bsv.showHdfLink) {
  BulletinsSanteDuVegetalLinks.push({
    label: 'Hauts-de-France',
    link: 'https://draaf.hauts-de-france.agriculture.gouv.fr/les-bulletins-de-sante-du-vegetal-bsv-r37.html',
  });
}

const BulletinsSanteDuVegetal: React.FC = () => {
  return (
    <div className="BulletinsSanteDuVegetal w-full flex-1">
      <div className="BulletinsSanteDuVegetal-imageContainer md:hidden">
        <img alt="" src="/img/bsv_bg.jpg" className="BulletinsSanteDuVegetal-image" />
      </div>
      <div className="BulletinsSanteDuVegetal-wrapper">
        <div className="BulletinsSanteDuVegetal-grid">
          <div className="BulletinsSanteDuVegetal-grid-card">
            <div className="BulletinsSanteDuVegetal-title">Bulletins Santé du Végétal</div>
            <div className="BulletinsSanteDuVegetal-text">
              Le recueil des données de surveillance contribue à connaitre de manière fiable la situation phytosanitaire
              du territoire.
            </div>
            <div className="BulletinsSanteDuVegetal-text">
              Les bulletins de santé du végétal (BSV) permettent d’évaluer le risque lié à la présence de bioagresseurs,
              et raisonner les itinéraires techniques de protection des végétaux dans le but de réduire l’utilisation
              des produits phytopharmaceutiques.
            </div>
            <div className="BulletinsSanteDuVegetal-text">
              Consultez les derniers bulletins en cliquant sur les liens ci-joints
            </div>
            <div className="BulletinsSanteDuVegetal-link">
              {BulletinsSanteDuVegetalLinks.map((bsvLink, index) => (
                <a href={bsvLink.link} key={index} target="_blank" className="mb-10" rel="noreferrer">
                  <div
                    className={cn('BulletinsSanteDuVegetal-link-card', {
                      'mb-0': index === BulletinsSanteDuVegetalLinks.length - 1,
                    })}
                  >
                    <div className="BulletinsSanteDuVegetal-link-cardRow">
                      <span className="BulletinsSanteDuVegetal-text-link mb-0">{bsvLink.label}</span>
                      <span className="lnr lnr-arrow-right font-bold text-green-100"></span>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulletinsSanteDuVegetal;
