import React from 'react'
import DeliveriesList from '@components/Deliveries/DeliveriesList'
import PageHead from "@components/UI/PageHead/PageHead";

const Deliveries: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead title="Mes Livraisons" description="Retrouvez vos livraisons facilement en les filtrant par période de récolte, cultures ou variétés de culture" />
      <DeliveriesList />
    </div>
  )
}

export default Deliveries
