import { SoldOrdersStoreModel } from './models'
import { types, flow, cast } from 'mobx-state-tree'
import { fetchSoldOrders, ISoldOrdersFilter } from "@services/navagri"
import { getPaginationParams, getGroupedByPaginationParams } from '@utils/tables'
import views from "./views";


export const SoldOrdersStore = types.optional(
    SoldOrdersStoreModel.views(views).actions(self => {
        return {
            fetchSoldOrders: flow(function* (page: number, filters?: ISoldOrdersFilter) {
                const serviceState = self.fetchSoldOrdersState
                serviceState.pending()

                try {
                    const { data, total, limit } = yield fetchSoldOrders({ ...getPaginationParams(page), ...filters })
                    self.list = data
                    serviceState.setPagination(total, limit)
                    serviceState.done()
                } catch (e) {
                    serviceState.fail()
                }
            }),

            fetchSoldOrdersByGroup: flow(function* (page: number, filters?: ISoldOrdersFilter) {
                const serviceState = self.fetchSoldOrdersGrouByState
                serviceState.pending()
                try {
                    const { data, total, limit, campaigns } = yield fetchSoldOrders({ ...getGroupedByPaginationParams(page), ...filters })
                    self.groupList = data
                    self.campaigns = campaigns
                    serviceState.setPagination(total, limit)
                    serviceState.done()
                } catch (e) {
                    serviceState.fail()
                }
            })
        }
    }),
    {}
)
