import {IOption} from "@components/NewFields/Select";

const civilities: IOption[] = [
  {label: "Civilité*", value: null, disabled: true},
  {label: "Madame", value: 2},
  {label: "Monsieur", value: 1},
  {label: "Autre", value: 0},
]

export default civilities;
