import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import styles from '../styles';
import {IPdfGenerator} from '@components/PdfGenerator';
import {Table} from '@components/PdfGenerator/table';

export const ComptaPdf: React.FC<IPdfGenerator> = ({datas = [], columns, title, subtitle, pdfType, headerValue}) => {
  return (
    <>
      <View style={styles.titleCard}>
        <View style={styles.titleSubCard}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View>
          <Text style={styles.subTitles}>Solde de mon compte</Text>
          <View style={styles.container}>
            <View style={styles.titleCardRow}>
              <View style={styles.column}>
                {headerValue?.due && (
                  <View style={styles.titleCardRow}>
                    <Text style={styles.bold}>Montant restant dû (TTC) : </Text>
                    <Text style={styles.subValue}>{headerValue?.due ? headerValue.due : ''}</Text>
                  </View>
                )}
                <View style={styles.titleCardRow}>
                  <Text style={styles.bold}>Période : </Text>
                  <Text style={styles.subValue}>{headerValue?.exercice ? headerValue.exercice : ''}</Text>
                </View>
              </View>
              <View style={styles.column}>
                {headerValue?.echue && (
                  <View style={styles.titleCardRow}>
                    <Text style={styles.bold}>Dont échu (TTC) : </Text>
                    <Text style={styles.subValue}>{headerValue?.echue ? headerValue.echue : ''}</Text>
                  </View>
                )}
                <View style={styles.titleCardRow}>
                  <Text style={styles.bold}>Documents : </Text>
                  <Text style={styles.subValue}>{headerValue?.documents ? headerValue.documents : ''}</Text>
                </View>
              </View>
            </View>
          </View>
          {headerValue?.search && (
            <View style={{paddingHorizontal: 20}}>
              <View style={[styles.subTitle, {borderTopWidth: 1}, {borderColor: '#D2E1E0'}, {paddingVertical: 10}]}>
                <Text>Recherche personalisée : </Text>
                <Text style={styles.subValue}>{headerValue?.search ? headerValue.search : ''}</Text>
              </View>
            </View>
          )}
        </View>
      </View>

      <Table columns={columns} datas={datas} pdfType={pdfType} headerValue={headerValue} subtitle={subtitle} />
    </>
  );
};
