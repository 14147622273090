import Button from '@components/Buttons/Button'
import CheckBox from '@components/Fields/CheckBox'
import Input from '@components/Fields/Input'
import Select from '@components/Fields/Select'
import { ICotation } from '@services/cotations'
import { OdvData, OdvDataOtherPeriod, OdvDataPeriod } from '@services/navagri'
import { useStore } from '@stores/index'
import { format } from 'date-fns'
import { Form, FormikProps } from 'formik'
import React, { useMemo, useState } from 'react'

export interface IOdvFields {
  campaignId: string
  qty: number
  month: number
  year: string
  cgv: boolean
}

interface IOdvForm extends FormikProps<IOdvFields> {
  odvData: OdvData
  cotation: ICotation
}

interface IOption {
  label: string
  value: any
}

const OdvForm: React.FC<IOdvForm> = ({ values, odvData, cotation, isValid, dirty, submitCount }) => {
  const yearOptions: IOption[] = odvData.otherPeriods.map(item => ({
    label: item.campaignId.toString(),
    value: item.campaignId
  }))

  yearOptions.push({
    label: odvData.campaignId.toString(),
    value: odvData.campaignId
  })

  const monthOptions = useMemo(
    () =>
      odvData.otherPeriods.reduce((result: IOption[], period: OdvDataOtherPeriod) => {
        const periodOptions = period.periods.map((item: OdvDataPeriod) => {
          const month = item.period.split('-')[1]

          return { label: month, value: parseInt(month) }
        })
        return [...result, ...periodOptions]
      }, []),
    []
  )

  const [info, setInfo] = useState(false)

  const { accounts: accountsStore } = useStore()
  const selectedAccount = accountsStore.selectedAccount
  const manager =
    selectedAccount && selectedAccount.contact && selectedAccount.contact.ContactSalesPeople
      ? selectedAccount.contact.ContactSalesPeople.find(item => item.prodActivityCode === 'PV')
      : undefined

  return (
    <Form className="w-full">
      <div className="flex justify-around items-center mt-10">
        <div className="w-5/12 text-center text-greenLepicard font-medium border-b-2 border-solid border-greenLepicard">
          EXPLOITANT{' '}
          {selectedAccount && selectedAccount.lastName ? selectedAccount.lastName.toUpperCase() : odvData.contactNum}
        </div>
        <div className="flex flex-col w-5/12 items-center">
          <div className="w-full mb-2 text-center text-grayLepicard font-light">Récolte</div>
          <Select name="campaignId" options={yearOptions} className="w-full" />
        </div>
      </div>

      <div className="flex justify-around">
        <div
          className="flex justify-around items-center mt-10 w-5/12 rounded-lg"
          style={{ backgroundColor: '#E7F0EB' }}
        >
          <div className="flex flex-col w-full px-5 py-2">
            <div onClick={() => setInfo(!info)} className="flex cursor-pointer self-end rounded-full" title="info">
              <img src="/img/SVG/icons/infoCircle.svg" className="w-6" />
            </div>

            <div className="flex justify-around items-center">
              <Input type="number" name="qty" className="w-full" step={0.1} min={0} max={odvData.maxQty} />
              <span className="pl-4">T</span>
            </div>
            <div className="flex justify-end mt-2">
              <span className="text-sm">({odvData.maxQty.toFixed(1)} max)</span>
            </div>
          </div>
        </div>
        <div
          className="flex justify-around items-center mt-10 w-5/12 rounded-lg"
          style={{ backgroundColor: '#ECECEC' }}
        >
          <div className="px-5  py-2 flex flex-col w-full h-full">
            <div className="flex-auto pt-5 font-medium text-3xl align-middle">
              {(values.qty * cotation.amount).toFixed(1)} €
            </div>
            <div className="flex justify-end mt-2">
              <span className="text-sm">Prix ({cotation.amount.toFixed(2)}€ / T)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center">
        {info && (
          <div className="flex mt-8 p-3 bg-greenLepicard w-11/12 rounded-lg overflow-hidden text-white text-sm">
            <img src="/img/SVG/icons/infoCircle_w.svg" className="w-6 pr-1" />
            Le contrat sera conclut à la date du {format(new Date(odvData.lastUpdate), 'dd/MM/yyyy')} <br /> Si vous
            souhaitez contractualiser plus de {odvData.maxQty.toFixed(1)} tonnes. Merci de contacter votre Responsable
            commercial
            {manager && manager.SalesPersonPurchaser
              ? ` ${manager.SalesPersonPurchaser.name} ${manager.SalesPersonPurchaser.phone}`
              : ''}
            .
          </div>
        )}
      </div>

      <div className="flex justify-around items-center mt-8">
        <div className="flex flex-col w-5/12">
          <div className="text-center mb-2 text-grayLepicard font-light">Echéance mois</div>
          <Select name="month" options={monthOptions} placeholder="Echeance mois" className="w-full" />
        </div>
        <div className="flex flex-col w-5/12">
          <div className="text-center mb-2 text-grayLepicard font-light">Echéance année</div>
          <Select name="year" options={yearOptions} placeholder="Echeance année" className="w-full" />
        </div>
      </div>

      <div className="flex mt-10 justify-center w-full font-light" style={{ fontSize: '14px' }}>
        <div className="w-11/12 text-center">
          <CheckBox
            name="cgv"
            label="J'accepte les conditions générales de ventes de Lepicard."
            checked={values.cgv}
            showError={submitCount > 0}
          />
        </div>
      </div>

      <div className="mt-10 flex justify-center w-full">
        <Button type="submit" disabled={!isValid || !dirty}>
          Passer un ordre de vente
        </Button>
      </div>
    </Form>
  )
}

export default OdvForm
