import React from 'react';
import './style.scss';
import {cn} from '@utils/class-names';

interface IContactCard {
  representative: {
    name?: string | null;
    email?: string | null;
    phone?: string | null;

    contact?: string | null;
    postCode?: string | null;
    address?: string | null;
    city?: string | null;
  };
  description?: string | null;
  className?: string | null;
}

const ContactCard: React.FC<IContactCard> = ({representative, description, className}) => {
  return (
    <div className={cn(`Card ContactCard`, className)}>
      <div className="p-4 md:p-7 flex align-items-center">
        <div>
          {representative.contact ? (
            <>
              <div className="HomeEntry-green-1 HomeEntry-text mb-2">{representative.name}</div>
              <div className="HomeEntry-paragrah-1 HomeEntry-grey-6 mb-4 uppercase">{description}</div>
              <div className="HomeEntry-green-1 HomeEntry-text mb-3">{representative.contact}</div>
              <div className="HomeEntry-green-1 HomeEntry-paragraph-2 mb-4">
                {representative.address}
                <br />
                {representative.postCode} {representative.city}
              </div>
            </>
          ) : (
            <>
              <div className="ContactCard-green-1 ContactCard-text">{representative.name}</div>
              <div className="ContactCard-paragraph-1 ContactCard-grey-6">{description}</div>
            </>
          )}
          <div className="HomeEntry-green-1 HomeEntry-paragraph-2 ContactCard-info">
            <div className="ContactCard-infoLink">
              <img src="/icons/Icon_Mail.svg" className="mr-2" alt="mail" />
              <a href={`mailto:${representative.email}`}>{representative.email}</a>
            </div>
            <div className="ContactCard-infoLink">
              <img src="/icons/Icon_Mobile.svg" className="mr-2" alt="phone" />
              <a href={`tel:${representative.phone}`}>{representative.phone}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactCard;
