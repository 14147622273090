const CONTRACT_CTA_TYPE_LABEL_MAPPING: Record<string, {name: string; subName: string}> = {
  '1.ACOMPTE': {name: 'SECUREA', subName: 'Acompte'},
  '121.ACENG.FIXE': {name: 'SECUREA', subName: 'Acompte Engagement Fixe'},
  '122.ACENG.FIXE.FERME': {name: 'SECUREA', subName: 'Acompte Engagement Stockage Ferme'},
  '131.ACENG..TOTAL': {name: 'SECUREA', subName: 'Acompte Engagement Total'},
  '132.ACENG.TT.FERME': {name: 'SECUREA', subName: 'Acompte Engagement Total Stockage Ferme'},
  '2.STO FERME': {name: 'LIBERTA', subName: 'Stockage Ferme'},
  '3.DEFINIF': {name: 'LIBERTA', subName: 'Définitif'},
  '4.DEPOT-VENTE': {name: 'LIBERTA', subName: 'Dépot-Vente'},
};

const CONTRACT_CTO_TYPE_LABEL_MAPPING: Record<string, {name: string; subName: string}> = {
  '2.STO FERME': {name: 'OBJECTIVA', subName: 'Stockage Ferme'},
  '3.DEFINIF': {name: 'OBJECTIVA', subName: 'Définitif'},
  '4.DEPOT-VENTE': {name: 'OBJECTIVA', subName: 'Dépot-Vente'},
};

interface IContractTypeFacet {
  value: string;
  type: string;
}

interface IContractTypeConvertedFacet {
  value: string;
  label: string;
}

export function convertContractTypeOptions(contractTypesFacets: IContractTypeFacet[]): IContractTypeConvertedFacet[] {
  return contractTypesFacets.map((facet) => {
    const list = facet.type === 'CTA' ? CONTRACT_CTA_TYPE_LABEL_MAPPING : CONTRACT_CTO_TYPE_LABEL_MAPPING;
    const item = list[facet.value];
    return {value: `${facet.type}-${facet.value}`, label: `${item.name} - ${item.subName}`};
  });
}

export function getContractTypeLabel(
  contractId: string,
  contractTypeId: string
): {name: string; subName: string} | undefined {
  return contractId.startsWith('CTA')
    ? CONTRACT_CTA_TYPE_LABEL_MAPPING[contractTypeId]
    : CONTRACT_CTO_TYPE_LABEL_MAPPING[contractTypeId];
}
