import React from 'react';
import {ConfigProvider, DatePicker} from 'antd';
import locale from 'antd/locale/fr_FR';
import {Moment} from 'moment';
import tailwindConfig from '../../../../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';

interface ICustomRangePicker {
  defaultValue?: dayjs | undefined;
  onChange: (dates: [Moment, Moment], dateStrings: [string, string]) => void;
  placeholder: [string, string];
  variant?: string;
}

const CustomRangePicker: React.FC<ICustomRangePicker> = ({defaultValue, onChange, placeholder, variant}) => {
  const fullConfig = resolveConfig(tailwindConfig);
  const colors = fullConfig.theme.accentColor;

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            activeBorderColor: colors.greenLepicard,
            hoverBorderColor: colors.greenLepicard,
            activeShadow: `0 0 0 2px ${colors.green['200']}`,
            cellActiveWithRangeBg: colors.grey['200'],
            colorPrimary: colors.greenLepicard,
          },
        },
      }}
      locale={locale}
    >
      <DatePicker.RangePicker
        placeholder={placeholder}
        allowEmpty={[true, true]}
        size={'large'}
        variant={variant ? variant : undefined}
        onChange={onChange}
        format={'DD/MM/YYYY'}
        defaultValue={defaultValue}
      />
    </ConfigProvider>
  );
};

export default CustomRangePicker;
