export function getLabelByValue(values: any, data: any[]): string {
  if (values === null || values === 'all') {
    return '';
  }
  if (Array.isArray(values)) {
    return values
      .map((value) => getLabelByValue(value, data))
      .filter((label) => label !== null)
      .join(', ');
  } else {
    const foundItem = data.find((item) => item.value === values);
    return foundItem ? foundItem.label : null;
  }
}
