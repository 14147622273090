import {IApproContractsStoreModel} from '@stores/appro-contracts/store';

export default (self: IApproContractsStoreModel) => {
  return {
    get getCampaignsOptions() {
      return self.globalFacets?.availableCampaigns.toJS().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
    get getStatusOptions() {
      return self.facets?.availableStatus.toJS().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
    get getItemFamilyCodeOptions() {
      return self.facets?.availableItemFamilyCode.toJS().toSorted((a, b) => a.label.localeCompare(b.label)) ?? [];
    },
  };
};
