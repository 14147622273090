import React, { useEffect, useState } from 'react'
import { Formik, Form, FormikProps } from 'formik'
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender'

interface IFiltersContainer {
  initialValues: Object
  ref?: any
  onSubmit?: (values: Object) => void
  validate?: (values: Object) => Object
  onChange?: (values: Object) => void
  children: React.ReactNode
  mobileColReverse?: boolean
  className?: string
}

const FiltersContainer: React.FC<IFiltersContainer> = ({
  initialValues,
  onSubmit,
  onChange,
  validate,
  children,
  mobileColReverse = false,
  ref,
  className = ''
}) => {
  const handleSubmit = (values: Object) => {
    if (onSubmit) {
      onSubmit(values)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ref={ref}
      validate={validate}
      children={({ values }: FormikProps<any>) => {
        useEffectNotOnFirstRender(() => {
          if (onChange) {
            onChange(values)
          }
        }, [values])

        return (
          <Form className='w-full'>
            <div
              className={`flex ${mobileColReverse ? 'flex-col-reverse' : 'flex-col'} lg:${
                mobileColReverse ? 'flex-row-reverse' : 'flex-row'
                } flex-shrink-0 flex-wrap ${className}`}
            >
              {children}
            </div>
          </Form>
        )
      }}
    />
  )
}

export default FiltersContainer
