import { ServiceStateModel } from './../models'
import { types } from 'mobx-state-tree'

export const ErrorstModel = types.model({
    activationCode: types.maybe(types.string),
    email: types.maybe(types.string),
    postalCode: types.maybe(types.string),
    phone: types.maybe(types.string),
})
export const ActivationStoreModel = types.model('Activation', {
    activationState: ServiceStateModel,
    apiErrors: types.maybe(ErrorstModel),
    hasBeenCreated: types.boolean
})
