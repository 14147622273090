import * as React from 'react';

interface IArrow {
  direction: 'left' | 'right' | 'top' | 'bottom';
  color: string;
}

export const Arrow: React.FC<IArrow> = ({color, direction}) => {
  let rotation = 0;
  switch (direction) {
    case 'left':
      rotation = 90;
      break;
    case 'right':
      rotation = -90;
      break;
    case 'top':
      rotation = -180;
      break;
    case 'bottom':
      rotation = 0;
      break;
  }

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`rotate(${rotation}, 7, 7)`}>
        <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.5" stroke={color} d="m11,5l-4,4l-4,-4" />
      </g>
    </svg>
  );
};
