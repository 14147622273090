import {ServiceStateModel} from '@stores/models';
import {SpecieModel, SpeciesListModel} from '@stores/species/models';
import {types, Instance} from 'mobx-state-tree';
import {CampaignsListModel, CampaignsModel} from '@stores/campaigns/models';

export interface IContractModel extends Instance<typeof ContractModel> {}
export interface ICommentListModel extends Instance<typeof CommentsListModel> {}
export interface IContractStoreModel extends Instance<typeof ContractsStoreModel> {}

export const CommentsListModel = types.model({
  contractId: types.string,
  comment: types.string,
});

export const ContractTypeModel = types.model({
  description: types.string,
});

export const ContractModel = types.model({
  id: types.identifier,
  itemId: types.string,
  campaignId: types.string,
  contactId: types.string,
  startDate: types.string,
  endDate: types.string,
  speciesId: types.string,
  qty: types.number,
  outstandingQuantity: types.number,
  brand: types.string,
  unitPrice: types.number,
  type: types.number,
  contractType: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  'Species Code': types.string,
  'Item No_': types.string,
  'Campaign No_': types.string,
  reliquatRate: types.number,
  reliquat: types.number,
  totalLedgers: types.number,
  species: types.maybeNull(SpecieModel),
  ContractType: types.maybeNull(ContractTypeModel),
  signOffDate: types.string,
  nextPaymentDate: types.maybeNull(types.string),
  shipStartingDate: types.string,
  shipEndingDate: types.string,
  status: types.number,
});

const ContractTypeFacetModel = types.model({
  type: types.string,
  value: types.string,
  categoryName: types.string,
});

export const ContractsStoreModel = types.model({
  list: types.array(ContractModel),
  facets: types.maybeNull(
    types.model({
      contractTypes: types.array(ContractTypeFacetModel),
    })
  ),
  campaigns: types.array(CampaignsListModel),
  species: types.array(SpeciesListModel),
  comments: types.array(CommentsListModel),
  fetchCampaignsState: ServiceStateModel,
  fetchContractsState: ServiceStateModel,
  fetchContractCampaignsState: ServiceStateModel,
});
