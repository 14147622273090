import {ServiceStateModel} from '@stores/models';
import {types, Instance} from 'mobx-state-tree';
import {SpeciesListModel} from '@stores/species/models';
import {CampaignsListModel} from '@stores/campaigns/models';

export type ICollectDeliveriesModel = Instance<typeof CollectDeliveryModel>;
export type ICollectDeliveriesStoreModel = Instance<typeof CollectDeliveriesStoreModel>;

export const SpeciesModel = types.model({
  name: types.maybeNull(types.string),
});

export const CollectAnalysis = types.model({
  value: types.number,
  analyseValue: types.number,
  averagedValues: types.maybeNull(types.number),
  tempValue: types.maybeNull(types.number),
  analyseCode: types.string,
});

export const ContractType = types.model({
  description: types.string,
});

export const contractModel = types.model({
  id: types.identifier,
  itemId: types.string,
  campaignId: types.string,
  contactId: types.string,
  startDate: types.string,
  endDate: types.string,
  speciesId: types.string,
  qty: types.number,
  outstandingQuantity: types.number,
  brand: types.string,
  unitPrice: types.number,
  type: types.number,
  contractType: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  'Species Code': types.string,
  'Contract Type': types.maybeNull(types.string),
  ContractType: types.maybeNull(ContractType),
});

export const CollectModelinCollectLine = types.model({
  id: types.identifier,
  campaignId: types.string,
  contactId: types.maybeNull(types.string),
  speciesId: types.string,
  contractId: types.maybeNull(types.string),
  netWeight: types.number,
  standardWeight: types.number,
  receiptedDate: types.string,
  invoiceQty: types.number,
  truckRegistration: types.string,
  'Campaign No_': types.string,
  species: SpeciesModel,
});

export const Purch = types.model({
  unitCost: types.number,
  amount: types.number,
});

export const CollectDeliverySubItem = types.model({
  Contract: types.maybeNull(contractModel),
  qty: types.number,
  qtyToInvoice: types.number,
  restToInvoice: types.number,
  brutQty: types.number,
  //collect: types.maybeNull(CollectModelinCollectLine), // TODO: Voir si nécessaire
  purch: types.maybeNull(Purch),
  purchQty: types.number,
  collects_analyses: types.array(CollectAnalysis),
});
export const CollectDeliveryModel = types.model({
  libelle: types.maybeNull(types.string),
  totalQty: types.maybeNull(types.number),
  species: types.maybeNull(types.string),
  receiptedDate: types.maybeNull(types.string),
  shipmentDate: types.maybeNull(types.string),
  totalAmount: types.maybeNull(types.number),
  invoiceQty: types.maybeNull(types.number),
  contractId: types.maybeNull(types.string),
  contractTypeName: types.maybeNull(types.string),
  truckRegistration: types.maybeNull(types.string),
  standardWeight: types.maybeNull(types.number),
  totalRestToInvoice: types.number,
  collects_analysis: types.array(CollectAnalysis),
  subItem: CollectDeliverySubItem,
});

const ContractTypeFacetModel = types.model({
  type: types.string,
  value: types.string,
  categoryName: types.string,
});

// TODO: @Elliot - Clean up everything
export const CollectDeliveriesStoreModel = types.model({
  fetchCampaignsDeliveriesState: ServiceStateModel,
  fetchCollectDeliveriesState: ServiceStateModel,
  fetchCollectDeliveriesCampaignsState: ServiceStateModel,

  global: types.maybeNull(
    types.model({
      totalAmount: types.maybeNull(types.number),
      totalPurch: types.maybeNull(types.number),
      totalQty: types.maybeNull(types.number),
      restToSell: types.maybeNull(types.number),
      totalToInvoice: types.maybeNull(types.number),
      totalRestToInvoice: types.maybeNull(types.number),
      totalRestToDelivery: types.maybeNull(types.number),
    })
  ),
  list: types.array(CollectDeliveryModel),
  facets: types.maybeNull(
    types.model({
      contractTypes: types.array(ContractTypeFacetModel),
    })
  ),
  campaigns: types.array(CampaignsListModel),
  species: types.array(SpeciesListModel),
});
