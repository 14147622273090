import React, {PropsWithChildren} from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './styles.scss';
import {isNull, isUndefined} from '@lepicard/utils';

type IModal = PropsWithChildren<{
  active: boolean;
  title: string;
  toggle: () => void;
  actions?: React.ReactNode | null;
  bodyClassName?: string;
  onClose?: () => void;
  buttonText?: string;
  width?: string | number;
  minWidth?: string;
  maxWidth?: string;
  className?: string;
  closeOnEsc?: boolean;
}>;

const customStyles = {
  height: 'auto',
  maxHeight: '100%',
  zIndex: 10000,
  width: 'auto',
};

const Modal: React.FC<IModal> = ({
  active,
  toggle,
  title,
  actions,
  children,
  onClose,
  className = '',
  closeOnEsc = true,
}) => {
  const handleClose = () => {
    toggle();

    if (onClose) {
      onClose();
    }
  };

  return (
    <Rodal
      className={`OptionModal ${className}`}
      visible={active}
      onClose={handleClose}
      showCloseButton={false}
      closeOnEsc={!isUndefined(closeOnEsc) ? closeOnEsc : true}
      customStyles={customStyles}
      animation="slideUp"
    >
      <div className="pt-7 h-full overflow-y-auto">
        <div className="bg-white h-full flex flex-col">
          <div className="bg-white px-3.5 pt-7 flex-grow">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-title-2">{title}</h2>
              <button onClick={handleClose} className="bg-grey-100 p-1.5 rounded-full" type="button">
                <img src="/icons/cross.svg" alt="" className="w-3 h-3" />
              </button>
            </div>
            <hr className="hr-m mt-3" />
            <div>{children}</div>
          </div>
          {!isNull(actions) && <div className="sticky inset-x-0 bottom-0 px-3.5 py-2 bg-white">{actions}</div>}
        </div>
      </div>
    </Rodal>
  );
};

export default Modal;
