import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);
import './style.scss';

import 'react-datepicker/dist/react-datepicker.css';
import FieldWrapper, {INewFieldWrapper} from '@components/NewFieldWrapper/FieldWrapper';
import {useField} from 'formik';
import {isString, isUndefined} from '@lepicard/utils';
import {format, parse} from 'date-fns';
import {cn} from '@utils/class-names';

interface IDatePicker {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  // Those values can be provided to show the user the interval he's selecting
  startDate?: string;
  endDate?: string;
  // Those values enable the component to determine if it's start selector or endSelector
  selectsStart?: true;
  selectsEnd?: true;
  labelType?: INewFieldWrapper['labelType']; // TODO: @Elliot: terminer l'intégration
  className?: string;
}

const DatePicker: React.FC<IDatePicker> = (props) => {
  const [field, meta, helper] = useField(props.name);

  const wrapperProps: Omit<INewFieldWrapper, 'children'> = {
    name: props.name,
    label: props.label,
    required: props.required,
    className: props.className,
    labelType: props.labelType ?? isUndefined(props.label) ? 'no-label' : 'label-floating',
  };

  return (
    <FieldWrapper {...wrapperProps}>
      {({label, labelType, showError, id}) => {
        //const [field, meta, helper] = useField(props.name);

        const [isFocused, setIsFocused] = useState(false);

        const onChange = (value: Date) => {
          setIsFocused(false);
          if (helper.setTouched) {
            helper.setTouched(true);
          }
          helper.setValue(value ? format(value, 'yyyy-MM-dd') : '');
        };

        const onBlur = () => {
          setIsFocused(false);
          if (helper.setTouched) {
            helper.setTouched(true);
          }
        };

        const formatStringDate = (stringDate: string | undefined) => {
          return stringDate ? new Date(stringDate) : undefined;
        };

        const finalStartDate = useMemo(() => formatStringDate(props.startDate), [props.startDate]);
        const finalEndDate = useMemo(() => formatStringDate(props.endDate), [props.endDate]);

        const finalMinDate = useMemo(() => {
          return isString(props.minDate) ? formatStringDate(props.minDate) : props.minDate;
        }, [props.minDate]);

        const finalMaxDate = useMemo(() => {
          return isString(props.minDate) ? formatStringDate(props.minDate) : props.minDate;
        }, [props.maxDate]);
        const onFocus = () => {
          setIsFocused(true);
        };

        const value = useMemo(() => {
          return field.value ? parse(field.value, 'yyyy-MM-dd', new Date()) : null;
        }, [field.value]);

        const pickerRef = useRef<any>(null);

        useEffect(() => {
          if (pickerRef && pickerRef.current !== null) {
            pickerRef.current.input.readOnly = true;
          }
        }, [pickerRef]);

        const hasFloatingLabel = !isUndefined(label) && labelType === 'label-floating';

        return (
          <label className={cn('lpa-datepicker', {isError: showError, isFocused, hasFloatingLabel})}>
            <ReactDatePicker
              id={id}
              dateFormat="dd/MM/yyyy"
              selected={value}
              onChange={onChange}
              locale="fr"
              placeholderText={props.placeholder}
              onBlur={onBlur}
              minDate={finalMinDate}
              maxDate={finalMaxDate}
              startDate={finalStartDate}
              endDate={finalEndDate}
              selectsStart={props.selectsStart}
              selectsEnd={props.selectsEnd}
              onFocus={onFocus}
              ref={pickerRef}
              isClearable
            />
            {hasFloatingLabel && (
              <label className="lpa-datepicker-label" htmlFor={id}>
                {label}
              </label>
            )}
          </label>
        );
      }}
    </FieldWrapper>
  );
};

export default DatePicker;
