import { ServiceStateModel } from '@stores/models'
import { types, Instance } from 'mobx-state-tree'

export interface IVarietySpecieModel extends Instance<typeof VarietySpecieModel> { }
export interface IVarietySpeciesStoreModel extends Instance<typeof SpeciesStoreModel> { }


export const SubfamilyModel = types.model({
  code: types.string,
  name: types.string
})


export const SpeciesModel = types.model({
  id: types.string,
  name: types.string,
  subFamilyCode: types.string,
  subfamily: SubfamilyModel
})


export const VarietySpecieModel = types.model({
  speciesCode: types.string,
  varietyCode: types.string,
  species: SpeciesModel,
})

export const subfamilyListModel = types.model({
  code: types.array(types.string),
  name: types.string
})



export const SpeciesStoreModel = types.model({
  list: types.array(VarietySpecieModel),
  subfamilyList: types.array(subfamilyListModel),
  fetchVarietySpeciesState: ServiceStateModel
})
