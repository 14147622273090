import React, {Fragment, useContext, useEffect, useRef} from 'react';
import './styles.scss';
import {useStore} from '@stores/index';
import {getInitials} from '@utils/string';
import {Link, useHistory, useLocation} from 'react-router-dom';
import useDropdown from '@hooks/useDropdown';
import {observer} from 'mobx-react-lite';
import {isNull} from '@lepicard/utils';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';
import {SSOConfig} from '@services/sso';
import EnvironmentService from '@utils/environmentService';

interface INavBar {
  isSidebarOpened: boolean;
  toggleSideBar: () => void;
  searchBarComponent?: React.ReactNode;
}

const NavBar: React.FC<INavBar> = observer(({isSidebarOpened, toggleSideBar, searchBarComponent}) => {
  const {tokenData, token, login, logOut, idToken, error}: IAuthContext = useContext(AuthContext);

  const {sso: SSOStore} = useStore();
  const {selectedExtranetUser: currentAccount, user: currentUser} = SSOStore;

  const history = useHistory();
  const location = useLocation();

  const dropDown = useRef<HTMLDivElement>(null);
  const {active: userMenuActive, toggle: toggleUserMenu} = useDropdown(dropDown);
  useEffect(() => {
    if (userMenuActive) {
      toggleUserMenu();
    }
  }, [location]);

  const onLogoutClick = () => {
    logOut();
    window.location.replace(SSOConfig.getDisconnectionUrl(currentUser));
  }

  if (isNull(currentAccount)) {
    return null;
  }

  const userMenuPreview = (
    <div className="userMenuPreview">
      <div className="userMenuPreview-account">
        <p className="userMenuPreview-name">
          {currentUser?.profile?.givenName} {currentUser?.profile?.familyName}
        </p>{' '}
        <span>|</span>{' '}
        <p className="userMenuPreview-business">
          {currentAccount?.legalForm} {currentAccount?.name}
        </p>
      </div>
      {currentAccount?.contact?.certifOwnerContactNum && (
        <p className="weight-light size-medium">{currentAccount?.contact?.certifOwnerContactNum}</p>
      )}
    </div>
  );

  const userMenuPortrait = (withOpener: boolean) => {
    return (
      <span className="userMenuPortrait">
        {getInitials(currentUser?.profile?.givenName, currentUser?.profile?.familyName)}
        {withOpener && <div className="userNavOpener"></div>}
      </span>
    );
  };

  const userMenuDropdown = (
    <div className={`userMenuDropdown ${userMenuActive ? 'isUserMenuOpened' : ''}`}>
      <div className="userMenuDropdown-name">
        {userMenuPortrait(false)}
        {userMenuPreview}
      </div>
      <div className="userMenuDropdown-actions">
        <button className="userMenuDropdown-action" onClick={() => history.push('/profile')}>
          <img src="/icons/profile.svg" alt="" />
          <p>Mon profil</p>
        </button>
        <button className="userMenuDropdown-action" onClick={() => history.push('/representatives')}>
          <img src="/icons/mobile.svg" alt="" />
          <p>Mes interlocuteurs</p>
        </button>
      </div>

      <div className="userMenuDropdown-actions">
        <Link to="/activation" className="userMenuDropdown-action">
          <img src="/icons/profile-add.svg" alt="" />
          <span>Activer un nouveau compte</span>
        </Link>
        {currentUser?.switchUrl && (
          <a href={SSOConfig.getSwitchUrl(currentUser)} className="userMenuDropdown-action">
            <img src="/icons/login.svg" alt="" />
            <span>Me connecter avec une autre structure</span>
          </a>
        )}
        <a href={SSOConfig.baseUrl} className="userMenuDropdown-action">
          <img src="/icons/account.svg" alt="" />
          <span>Gérer mon compte {EnvironmentService.instanceConfig.global.instanceFullName}</span>
        </a>
      </div>

      {currentUser?.availableExtranetServices && currentUser.availableExtranetServices.length > 0 && (
        <div className="userMenuDropdown-actions">
          {currentUser.availableExtranetServices.map((service) => {
            return (
              <Fragment key={service.id}>
                <a href={service.url as string} target="_blank" rel="noreferrer" className="userMenuDropdown-action">
                  <img src="/icons/factory.svg" alt="" />
                  <span>{service.name}</span>
                </a>
              </Fragment>
            );
          })}
        </div>
      )}

      <button className="userMenuDropdown-logOut" onClick={onLogoutClick}>
        <img src="/icons/Icon_Logout.svg" alt="" />
        Déconnexion
      </button>
    </div>
  );

  return (
    <div>
      <div className="headerNav bg-green-gradient-100">
        <button type="button" onClick={toggleSideBar} className="headerNav-burgerMenu">
          <span className="bar-1"></span>
          <span className="bar-2"></span>
          <span className="bar-3"></span>
        </button>
        <div className="headerNav-logoContainer">
          <button onClick={() => history.push('/')} className="logo flex items-center">
            <img
              width="225"
              height="48"
              src={EnvironmentService.getImageInstancePath('/img/logo.svg')}
              alt=""
              className={`${EnvironmentService.getInstance() === 'luneray' ? '' : 'mb-2'} ml-2`}
            />
          </button>
          {searchBarComponent && searchBarComponent}
        </div>
        <div className={`userNav ${userMenuActive ? 'isUserMenuOpened' : ''}`} ref={dropDown}>
          <button onClick={toggleUserMenu} className="userMenu">
            <div className="userNav-name">
              {userMenuPortrait(true)}
              {userMenuPreview}
              <div className="userNavOpener"></div>
            </div>
          </button>
          {userMenuDropdown}
        </div>
        <div className="userMenu-mobileMask"></div>
      </div>
      <div className="userNav-mobile" onClick={toggleUserMenu}>
        <p>
          {currentUser?.profile?.givenName} {currentUser?.profile?.familyName} <span>|</span>{' '}
          {currentAccount?.legalForm} {currentAccount?.name}
        </p>
        <div className="userNavOpener"></div>
      </div>
    </div>
  );
});

export default NavBar;
