import {getLabelByValue} from '@utils/pdf';
import {formatNumber, preFormattedQuantity} from '@utils/number';
import {ICsvColumn} from '@hooks/useCsvGenerator';

export const csvColumns: ICsvColumn[] = [
  {
    Header: 'Livraison N°',
    id: 'no',
    accessor: 'no',
  },
  {
    Header: 'Debut de livraison',
    id: 'shipmentDate',
    accessor: 'shipmentDate',
    isDate: true,
  },
  {
    id: 'familyName',
    Header: 'Familles',
    accessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    accessor: 'description',
  },
  {
    Header: 'Commande lié',
    id: 'linkedOrder',
    accessor: 'linkedOrder',
    render: (column, value) => {
      return value ? value : '-';
    },
  },
  {
    Header: 'Contrat lié',
    id: 'linkedContracts',
    accessor: 'linkedContracts',
    render: (column, value) => {
      return Array.isArray(value) && value.length > 0 ? value : '-';
    },
  },
  {
    id: 'quantity',
    Header: 'Quantité livrée',
    accessor: 'quantity',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
];

export const pdfCol = [
  {
    Header: 'Livraison N°',
    id: 'no',
    accessor: 'no',
  },
  {
    Header: 'Debut de livraison',
    id: 'shipmentDate',
    accessor: 'shipmentDate',
    isDate: true,
  },
  {
    Header: 'Commande lié',
    id: 'linkedOrder',
    accessor: 'linkedOrder',
    render: (column, value) => {
      return value ? value : '-';
    },
  },
  {
    Header: 'Contrat lié',
    id: 'linkedContracts',
    accessor: 'linkedContracts',
    render: (column, value) => {
      return Array.isArray(value) && value.length > 0 ? value : '-';
    },
  },
];

export const pdfSubCol = [
  {
    id: 'familyName',
    Header: 'Familles',
    subAccessor: 'familyName',
  },
  {
    id: 'description',
    Header: 'Produits',
    subAccessor: 'description',
  },
  {
    id: 'quantity',
    Header: 'Quantité livrée',
    handleRender: (delivery, product) => {
      return preFormattedQuantity(delivery.quantity).renderWithUnit(delivery.unitOfMeasureCode);
    },
  },
];

export const headerValue = (formValues, groupByOption, store) => {
  return {
    campaignId: formValues.campaignId === 'all' ? 'Tous' : formValues.campaignId,
    groupBy: getLabelByValue(formValues.groupBy, groupByOption),
    filterBy: formValues.tagsOption === 'all' ? '' : 'Retours',
    statut: getLabelByValue(formValues.status, store.getStatusOptions),
    itemFamilyCode: getLabelByValue(formValues.itemFamilyCode, store.getItemFamilyCodeOptions),
    search: formValues.search,
  };
};

export const getCsvFileName = (formValues, tagsOptions) => {
  return getLabelByValue(formValues.tagsOption, tagsOptions)
    ? `${formValues.campaignId}_${getLabelByValue(formValues.tagsOption, tagsOptions)}`
    : `${formValues.campaignId}`;
};
