import {IAccountModel} from '@stores/accounts/store';
import {useStore} from '@stores/index';
import React, {useEffect} from 'react';
import './style.scss';
import ContactCard from '@components/Card/contactCard';
import {Link} from 'react-router-dom';
import HomeCard from '@containers/Home/HomeCard/HomeCard';
import EnvironmentService from '@utils/environmentService';

const instanceConfig = EnvironmentService.getInstanceConfig();

const Home: React.FC = () => {
  const {sso: SSOStore} = useStore();
  const {selectedExtranetUser: currentAccount, user: currentUser, salesPeople: salesPeople} = SSOStore;
  const customerLocation = currentAccount?.contact?.customer?.location;

  salesPeople.sort((a, b) => {
    return b.prodActivityCode!.localeCompare(a.prodActivityCode!);
  });

  const previousExtranetLink = EnvironmentService.getInstanceConfig().homepage_previousExtranet;

  return (
    <>
      <div className="w-full h-full">
        <div className="HomeEntry-title HomeEntry-green-1">
          Bienvenue dans votre espace personnel
          {currentAccount ? ' ' + currentUser!.profile?.givenName + ' ' + currentUser!.profile?.familyName : ''}
        </div>

        <div className="mb-10">
          <div className="HomeEntry-green-2 HomeEntry-sub-title">Mon activité</div>
          <Link to="/accounting">
            <HomeCard
              titre="Ma comptabilité"
              description="Vos factures, versements, avoirs et remboursements en approvisionnement et collecte"
              notif={true}
              icon="/icons/HomePage/Icon_Compta.svg"
            />
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-9 mb-10">
          <div>
            <div className="HomeEntry-green-2 HomeEntry-sub-title">Gestion de ma collecte</div>

            <Link to="/contracts">
              <HomeCard
                titre="Mes contrats de vente"
                description="Vos contrats de vente de produits agricoles"
                icon="/icons/HomePage/Icon_Contract.svg"
              />
            </Link>

            <Link to="/deliveries">
              <HomeCard
                titre="Mes livraisons"
                description="Vos camions avec leur tonnage et leurs normes par campagne"
                icon="/icons/HomePage/Icon_Expedition.svg"
              />
            </Link>

            <Link to="/collect/invoices">
              <HomeCard
                titre="Mes factures"
                description="Toutes vos factures collecte en un clic"
                icon="/icons/HomePage/Icon_Facture.svg"
              />
            </Link>

            <Link to="/quotations">
              <HomeCard
                titre="Mes cotations"
                description="Vos cotations en temps réel partout avec vous"
                icon="/icons/HomePage/Icon_Taux.svg"
              />
            </Link>
          </div>

          <div>
            <div className="HomeEntry-green-2 HomeEntry-sub-title"> Mes approvisionnements</div>

            {instanceConfig.approContrats.enablePage && (
              <Link to="/appro/contracts">
                <HomeCard
                  titre="Mes contrats d'achat"
                  description="Vos contrats d'achats en cours et soldés"
                  icon="/icons/HomePage/Icon_Contract.svg"
                />
              </Link>
            )}

            <Link to="/appro/orders">
              <HomeCard
                titre="Mes commandes"
                description="Vos commandes d'achat par marché, produit ou exercice"
                icon="/icons/HomePage/Icon_Commande.svg"
              />
            </Link>
            <Link to="/appro/deliveries">
              <HomeCard
                titre="Mes livraisons"
                description="Suivez vos expéditions en cours et livrées"
                icon="/icons/HomePage/Icon_Livraison.svg"
              />
            </Link>
            <Link to="/appro/bills">
              <HomeCard
                titre="Mes factures"
                description="Vos factures d'achat par date, produit ou marché..."
                icon="/icons/HomePage/Icon_Facture.svg"
              />
            </Link>
          </div>
        </div>

        {previousExtranetLink && (
          <div className="mb-10">
            <div className="HomeEntry-green-2 HomeEntry-sub-title">{previousExtranetLink.sectionTitle}</div>
            <Link to={{pathname: previousExtranetLink.link}} target={'_blank'}>
              <HomeCard titre={previousExtranetLink.buttonTitle} description={previousExtranetLink.buttonText} />
            </Link>
          </div>
        )}

        {(salesPeople || customerLocation) && (
          <div>
            <div className="HomeEntry-green-2 HomeEntry-sub-title">Mes Contacts</div>
            <div className="ContactCard-container">
              {salesPeople.length > 0 &&
                salesPeople
                  .filter((people) => !(instanceConfig.profil.hidePA && people.prodActivityCode === 'PA'))
                  .map((people) => (
                    <ContactCard
                      key={people.prodActivityCode}
                      representative={people.salesPersonPurchaser!}
                      description={
                        people.prodActivityCode === 'PV'
                          ? 'Votre Responsable Commerciale Productions Végétales'
                          : 'Votre Responsable Commerciale Productions Animales'
                      }
                    />
                  ))}
              {customerLocation && <ContactCard representative={customerLocation} />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
