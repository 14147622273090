import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

export const getInitials = (firstname?: string | null, lastname?: string | null) => {
  const firstNameString = firstname ? firstname : ''
  const lastNameString = lastname ? lastname : ''

  return `${firstNameString.charAt(0)}${lastNameString.charAt(0)}`.toUpperCase()
}

export const formatPhoneNumber = (value: string) => {
  const phoneNumber = value.replace(/\s/g, '')
  let result = []
  for (let i = 0; i < phoneNumber.length; i = i + 2) {
    result.push(phoneNumber.slice(i, i + 2))
  }

  return result.join(' ')
}

const getDateFormatOuput = (withTime: boolean) => `dd/MM/yyyy${withTime ? ' HH:mm' : ''}`
const getDateFormatForServiceOuput = (withTime: boolean) => `yyyy-MM-dd${withTime ? ' HH:mm' : ''}`

export const formatDate = (value: string, withTime: boolean = false) => {
  try {
    return format(new Date(value), getDateFormatOuput(withTime))
  } catch (error) {
    console.log({ error })
    return 'Date invalide'
  }
}

export const printDate = (value: Date) => {
  return format(value, 'EEEE d MMMM yyyy HH:mm', { locale: fr })
}

export const printDateForService = (value: Date, withTime: boolean = false) => {
  return format(value, getDateFormatForServiceOuput(withTime))
}

export const frenchStringToNumber = (value: string) => {
  if (!/^\d+(,\d+)?$/.test(value)) {
    return 0
  } else {
    return parseFloat(value.replace(',', '.'))
  }
}

export const numberToLocaleString = (value: number | undefined) => {
  return typeof value === 'number' ? value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : value
}

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
