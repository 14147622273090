import './style.scss';
import React, {PropsWithChildren} from 'react';
import {cn} from '@utils/class-names';

export enum StatusTagTheme {
  GREEN = 'green',
  YELLOW = 'yellow',
  GREY = 'grey',
}

type IOrdersStatusTag = PropsWithChildren<{
  theme: StatusTagTheme;
}>;

export const StatusTag: React.FC<IOrdersStatusTag> = ({theme, children}) => {
  return (
    <div
      className={cn('StatusTag', {
        themeGreen: theme === StatusTagTheme.GREEN,
        themeYellow: theme === StatusTagTheme.YELLOW,
        themeGrey: theme === StatusTagTheme.GREY,
      })}
    >
      <span>{children}</span>
    </div>
  );
};
