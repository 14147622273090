import React from 'react';
import {Text, View} from '@react-pdf/renderer';
import styles from '../styles';
import {IPdfGenerator} from '@components/PdfGenerator';
import {Table} from '@components/PdfGenerator/table';

export const CollectePdf: React.FC<IPdfGenerator> = ({
  datas = [],
  columns,
  title,
  subtitle,
  subComponent,
  pdfType,
  headerValue,
}) => {
  return (
    <>
      <View style={styles.titleCard}>
        <View style={styles.titleSubCard}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.subTitleContainer}>
          <View style={[styles.subTitle, styles.subTitlePadding]}>
            <Text>Campagne : </Text>
            <Text style={styles.subValue}>{headerValue?.campaignId ? headerValue.campaignId : 'Toutes'}</Text>
            <Text> Culture : </Text>
            <Text style={styles.subValue}>{headerValue?.speciesId ? headerValue.speciesId : 'Toutes'}</Text>
            <Text> Variété : </Text>
            <Text style={styles.subValue}>{headerValue?.itemId ? headerValue.itemId : 'Toutes'}</Text>
            {headerValue?.filterBy && (
              <>
                <Text>Filtrer par : </Text>
                <Text style={styles.subValue}>{headerValue?.filterBy ? headerValue.filterBy : ''}</Text>
              </>
            )}
          </View>
          {headerValue?.contractType && (
            <View>
              <View style={[styles.subTitle, styles.subTitlePadding, styles.subTitleBorder]}>
                <Text>Type de contrat : </Text>
                <Text style={[styles.subValue, styles.maxWidth]}>
                  {headerValue?.contractType ? headerValue.contractType : ''}
                </Text>
              </View>
            </View>
          )}
          {headerValue?.search && (
            <View>
              <View style={[styles.subTitle, styles.subTitlePadding, styles.subTitleBorder]}>
                <Text>Recherche personnalisée : </Text>
                <Text style={styles.subValue}>{headerValue?.search ? headerValue.search : ''}</Text>
              </View>
            </View>
          )}
        </View>
        {pdfType === 'Collecte' && headerValue?.subValues && (
          <View style={[styles.subTitle, styles.subTitleValuesContainer]}>
            {subtitle === 'invoices' ? (
              <>
                <Text>Quantité facturée : </Text>
                <Text style={styles.subValueCollecte}>
                  {headerValue?.subValues.totalQty ? headerValue.subValues.totalQty : '0.00 T'}
                </Text>
                <Text>Chiffre d’affaires HT : </Text>
                <Text style={styles.subValueCollecte}>
                  {headerValue?.subValues.totalAmountLabel ? headerValue.subValues.totalAmountLabel : '0.00 €'}
                </Text>
                <Text>Chiffre d’affaires TTC : </Text>
                <Text style={styles.subValueCollecte}>
                  {headerValue?.subValues.totalAmountIncludingVATLabel
                    ? headerValue.subValues.totalAmountIncludingVATLabel
                    : '0.00 €'}
                </Text>
              </>
            ) : (
              <>
                <Text>Quantité livrée : </Text>
                <Text style={styles.subValueCollecte}>
                  {headerValue?.subValues.totalQty ? headerValue.subValues.totalQty : '0.00 T'}
                </Text>
                <Text>Reste à facturer : </Text>
                <Text style={styles.subValueCollecte}>
                  {headerValue?.subValues.totalRestToInvoice ? headerValue.subValues.totalRestToInvoice : '0.00 T'}
                </Text>
                <Text>Quantitée facturée : </Text>
                <Text style={styles.subValueCollecte}>
                  {headerValue?.subValues.totalPurch ? headerValue.subValues.totalPurch : '0.00 T'}
                </Text>
              </>
            )}
          </View>
        )}
      </View>

      <Table
        columns={columns}
        datas={datas}
        pdfType={pdfType}
        headerValue={headerValue}
        subtitle={subtitle}
        subComponent={subComponent}
      />
    </>
  );
};
