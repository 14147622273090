import React from 'react';
import {StatusTag, StatusTagTheme} from '@components/UI/StatusTag/StatusTag';

interface IApproInvoicesStatusTag {
  status: ApproInvoicesStatusEnum;
}

export enum ApproInvoicesStatusEnum {
  SOLD = 'Soldé',
}

export const ApproInvoicesStatusTag: React.FC<IApproInvoicesStatusTag> = ({status}) => {
  let theme = StatusTagTheme.GREEN;
  switch (status) {
    case ApproInvoicesStatusEnum.SOLD:
      theme = StatusTagTheme.GREEN;
      break;
  }

  return <StatusTag theme={theme}>{status}</StatusTag>;
};
