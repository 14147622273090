import React from 'react';
import {IRadioGroupChildrenProps} from '../RadioGroup';

interface IRadioItem extends IRadioGroupChildrenProps {
  label: string | React.ReactNode;
  value: any;
  className?: string;
  onRadioChange?: (value: any) => void;
  isCheckedProp?: boolean;
}

const RadioItem: React.FC<IRadioItem> = ({
  label,
  value,
  fieldValue,
  name,
  setValue,
  className = '',
  onRadioChange,
  isCheckedProp,
}) => {
  const isChecked = isCheckedProp !== undefined ? isCheckedProp : value === fieldValue;

  const onChange = () => {
    setValue(value);
    if (onRadioChange) {
      onRadioChange(value);
    }
  };

  const isLabelComponent = React.isValidElement(label);

  return (
    <label className={`containerBtnRadio ${className}`}>
      {!isLabelComponent && <p className="lh-17 size-small mb-15">{label}</p>}
      {isLabelComponent && (label as React.ReactChild)}
      <input type="radio" name={name} checked={isChecked} onChange={onChange} />
      <span className="btn-radio" />
    </label>
  );
};

export default RadioItem;
