import React from 'react';
import {ThreeDots} from 'react-loader-spinner';
import './styles.scss';
import {cn} from '@utils/class-names';

interface ILoader {
  className?: string;
  color?: string;
  height?: number;
  width?: number;
  topMargin?: boolean;
}

const Loader: React.FC<ILoader> = ({className = '', color = '#2E3748', height = 50, width = 50, topMargin = false}) => {
  return (
    <div
      className={cn([
        'loaderContainer',
        className,
        {
          topMargin: topMargin,
        },
      ])}
    >
      <ThreeDots color={color} height={height} width={width} />
    </div>
  );
};

export default Loader;
