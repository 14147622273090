import React from 'react';
import tailwindConfig from "../../../../tailwind.config";

interface IExportButton {
  iconName: string;
  label: string;
  onClick?: () => void;
  color?: string;
}

const ExportButton: React.FC<IExportButton> = ({label, iconName, onClick, color}) => {
  const twColors = tailwindConfig.theme.colors;

  return (
    <button
      className={`ExportButton ${color == "red" ? "text-redPdf-500" : color == "green" ? "text-greenCsv-500" : "text-interactif"} bg-white border ${color == "red" ? "border-redPdf-500" : color == "green" ? "border-greenCsv-500" : "border-grey-400"} rounded-[4px] px-2 lg:px-4 py-1.5 lg:py-2.5 flex items-center gap-1.5 hover:bg-grey-100 uppercase`}
      onClick={onClick}
      type="button"
    >
      {/*<img src={`/icons/${iconName}.svg`} alt=""/>*/}
      <svg width="15" height="15" viewBox="0 0 15 15" fill={color == "red" ? twColors.redPdf["500"] : color == "green" ? twColors.greenCsv["500"] : "#0F4C36"} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_345_28908)">
          <path d="M5 12.5H6.875V9.375H8.125V12.5H10L7.5 15L5 12.5ZM12.1744 4.4325C12.0419 1.96312 10.0037 0 7.5 0C4.99625 0 2.95812 1.96312 2.82562 4.4325C1.21938 4.72187 0 6.12375 0 7.8125C0 9.71062 1.53938 11.25 3.4375 11.25H5.625V10H3.4375C2.23125 10 1.25 9.01875 1.25 7.8125C1.25 6.06437 2.79938 5.41687 4.02063 5.4875C3.91625 2.85125 5.40063 1.25 7.5 1.25C9.65812 1.25 11.1819 2.99812 10.9794 5.4875C12.07 5.45875 13.75 5.95687 13.75 7.8125C13.75 9.01875 12.7688 10 11.5625 10H9.375V11.25H11.5625C13.4606 11.25 15 9.71062 15 7.8125C15 6.12375 13.7806 4.72187 12.1744 4.4325Z"/>
        </g>
      </svg>
      <span>{label}</span>
    </button>
  );
};

export default ExportButton;
