import {types} from 'mobx-state-tree';
import {ServiceStateModel} from '@stores/models';

export const AvailableExtranetServices = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});
export const Location = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  contact: types.maybeNull(types.string),
  postCode: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
});
export const Method = types.model({
  description: types.maybeNull(types.string),
});
export const SalesPersonPurchaser = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
});

export const ContactSalesPeople = types.model({
  // id: types.maybeNull(types.string),
  prodActivityCode: types.maybeNull(types.string),
  salesPersonCode: types.maybeNull(types.string),
  contactCode: types.maybeNull(types.string),
  salesPersonPurchaser: types.maybeNull(SalesPersonPurchaser),
});

export const Customer = types.model({
  id: types.maybeNull(types.string),
  locationCode: types.maybeNull(types.string),
  location: types.maybeNull(Location),
});

export const ContactModel = types.model({
  id: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  email2: types.maybeNull(types.string),
  mobile: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  lastname: types.maybeNull(types.string),
  firstname: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  commercialZone: types.maybeNull(types.string),
  registrationNum: types.maybeNull(types.string),
  vatRegistrationNum: types.maybeNull(types.string),
  legalForm: types.maybeNull(types.string),
  contactSalesPeople: types.maybeNull(types.array(ContactSalesPeople)),
  customer: types.maybeNull(Customer),
  certifOwnerContactNum: types.maybeNull(types.string),
  certiphytoNum: types.maybeNull(types.string),
  certiphytoValidityDate: types.maybeNull(types.string),
  correspondenceType: types.maybeNull(types.integer),
  // purchasePaymentMethod: types.maybeNull(types.maybe(Method)),
  // salesPaymentMethod: types.maybeNull(types.maybe(Method)),
});
export const Profile = types.model({
  civility: types.maybeNull(types.integer),
  givenName: types.maybeNull(types.string),
  familyName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
});

export const ExtranetUser = types.model({
  id: types.maybeNull(types.string),
  contactId: types.maybeNull(types.string),
  legalForm: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  registrationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  mobilePhone: types.maybeNull(types.string),
  contact: types.maybeNull(ContactModel),
});

export const User = types.model({
  identifier: types.string,
  roles: types.array(types.string),
  selectedExtranetUserId: types.string,
  profile: types.maybeNull(Profile),
  extranetUsers: types.maybeNull(types.array(ExtranetUser)),
  availableExtranetServices: types.maybeNull(types.array(AvailableExtranetServices)),
  logoutUrl: types.string,
  switchUrl: types.maybeNull(types.string),
});

export const SSOModel = types.model({
  user: types.maybeNull(User),
  sessionExpired: types.boolean,
  additionalInformationsState: ServiceStateModel,
});
