import {fetchAdivalorPlanning} from '@services/adivalor';
import {AdivalorStoreModel} from './models';
import {types, flow} from 'mobx-state-tree';
import {getPaginationParams} from '@utils/tables';
import {IAdivalorFilters} from '@services/navagri';

export const AdivalorStore = types.optional(
  AdivalorStoreModel.actions((self) => {
    return {
      fetchPlanning: flow(function* (page: number, filters?: IAdivalorFilters) {
        const serviceState = self.fetchPlanningState;
        serviceState.pending();
        try {
          const {planning, locations, types, total} = yield fetchAdivalorPlanning({
            ...getPaginationParams(page),
            ...filters,
          });

          self.planning = planning;
          self.locations = locations;
          self.total = total;
          self.types = types;
          serviceState.done();
        } catch (error) {
          console.log('FAIL ADIVALOR', error);
          serviceState.fail();
        }
      }),
    };
  }),
  {}
);
