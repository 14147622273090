import React from 'react';
import './styles.scss';
import {Link} from 'react-router-dom';
import EnvironmentService from '@utils/environmentService';

interface ILoginContainer {
  children: React.ReactNode;
  className?: string;
}

const LoginContainer: React.FC<ILoginContainer> = ({children, className}) => {
  return (
    <div className="SpineBubble">
      <nav className="BubbleHeader isMobile">
        <Link to="/" className="BubbleHeader-link">
          <img
            className="BubbleHeader-logo"
            src={EnvironmentService.getImageInstancePath('/img/login-logo-white.png')}
            alt=""
          />
        </Link>
      </nav>

      <div className="Login-background">
        <img className="" src={EnvironmentService.getImageInstancePath('/img/extranet-presentation.png')} alt="" />
      </div>

      <div className={`SpineBubble-container ${className}`}>
        <nav className="BubbleHeader isDesktop">
          <Link to="/" className="BubbleHeader-link">
            <img
              className="BubbleHeader-logo"
              src={EnvironmentService.getImageInstancePath('/img/login-logo.png')}
              alt=""
            />
          </Link>
        </nav>
        {children}
      </div>
    </div>
  );
};

export default LoginContainer;
