import React, { Fragment, useEffect, useState } from 'react'
import { fetchOffers, IDriveFiles } from '@services/offers';
import { useStore } from '@stores/index'
import useService from '@hooks/useService'
import './style.scss'
import { folderOffers } from '@constants/foldersOffers.options'


const ApproOffers: React.FC = () => {

  const { offers: offersStore } = useStore();
  const { fetchOffersState } = offersStore;


  const { service: offersService, data } = useService<IDriveFiles[]>()
  const [offers, setOffers] = useState<any>([])

  const onFetchFolder = () => {
    offersService(() => fetchOffers({ parentFolder: folderOffers.appro }))
  }

  useEffect(() => {
    onFetchFolder()
  }, [])

  const getFiles = (data: IDriveFiles[]) => {
    //@ts-ignore
    let foldersId: Array<string> = data.map((f, i) => f.id)
    fetchOffers({ folders: foldersId }).then((file: any) => {
      let offers = data.map((f, i) => {
        let test = file.filter(f => f.parents.includes(data[i].id))
        let offer = { ...f, files: test }
        return offer
      })
      setOffers(offers)
    })
  }

  useEffect(() => {
    if (data)
      getFiles(data)
  }, [data])

  return (
    <div className="w-full flex-1 p-5">
      <div className="page-title mb-10">Présentation des offres</div>

      <div className="offers-container">
        {offers && offers.map((o: any, i: number) => {
          return (
            <Fragment key={i}>
              <p key={i}>{o.name}</p>

              <div className="file-list">
                {o.files.map((f: any, fileIndex: number) => {
                  return (
                    <Fragment key={fileIndex}>
                      <div>
                        <div style={{ marginBottom: '10px' }}>
                          <img src={`https://drive.google.com/thumbnail?id=${f.id }`} />
                        </div>
                        <a href={f.webViewLink} target="_blanck" className="btn-primary">{f.name}</a>
                      </div>
                    </Fragment>
                  )
                })}

              </div>

            </Fragment>
          )
        })}
      </div>

    </div>
  )
}

export default ApproOffers
