import fetch from '@utils/fetch';

const endPoint = `${process.env.API_COTATION}`;

const endPoints = {
  cotations: `${endPoint}/cotation`,
};

export interface ICotationFilters {
  campaign?: string;
  cereale?: string;
  location?: string;
  $limit?: number;
}

export const fetchCotations = (filters: ICotationFilters = {}) => fetch(endPoints.cotations, 'get', filters);

export interface ISpecie {
  id: string;
  value: string;
  name: string;
  label: string;
}

export interface IYear {
  id: number;
  name: number;
  echeances: IEcheanceHeader[];
}

export interface IEcheanceHeader {
  id: number;
  name: string;
  species: any[];
}

export interface ISpecies {
  id: number;
  name: string;
  echeances: ICotation[];
}

export interface ICotation {
  specieId: string;
  specieName: string;
  campaignId: number;
  echanceId: number;
  echanceName: string;
  amount: number;
}

export interface ICotations {
  status: boolean;
  lastUpdate: string;
  zone: {
    id: string;
    name: string;
    tab: string;
  };
  campaignIds: string[];
  species: ISpecie[];
  cotations: ICotation[];
  qualities: ICotationsTaxe[];
}

export interface ICotationsTaxe {
  specieId: string;
  title: string;
  description: string;
  amount: number;
}
