import {ServiceStateModel} from './../models';
import {Instance, types} from 'mobx-state-tree';

export interface IAccountingItemModel extends Instance<typeof AccountingItemModel> {}

export const LinkedModel = types.model({
  postingDate: types.maybeNull(types.string),
  num: types.maybeNull(types.string),
  type: types.maybeNull(types.number),
  echeance: types.maybeNull(types.string),
  letter: types.maybeNull(types.string),
  initialAmount: types.maybeNull(types.number),
});

export const AccountingItemModel = types.model({
  RowNum: types.string,
  postingDate: types.string,
  num: types.string,
  type: types.number,
  echeance: types.string,
  letter: types.string,
  account: types.string,
  restToPaid: types.number,
  initialAmount: types.number,
  linked: types.maybeNull(LinkedModel),
});

export const AccountingModel = types.model({
  list: types.array(AccountingItemModel),
  totalDue: types.maybeNull(types.number),
  expiredTotal: types.maybeNull(types.number),
  fetchAccountingState: ServiceStateModel,
  isDescending: types.optional(types.maybeNull(types.boolean), true),
  sortedColumn: types.optional(types.string, 'postingDate'),
  types: types.array(types.number),
});
