import React from 'react';
import {StatusTag, StatusTagTheme} from '@components/UI/StatusTag/StatusTag';

interface IDeliveryStatusTag {
  outstandingQuantity: number | undefined;
}

export enum DeliveryStatusEnum {
  'billed' = 'Facturée',
  'nonBilled' = 'Non facturée',
}

const DeliveryStatusTag: React.FC<IDeliveryStatusTag> = ({outstandingQuantity}) => {
  const getDeliveryStatus = (outstandingQuantity: any) => {
    switch (outstandingQuantity) {
      case 0:
        return DeliveryStatusEnum.billed;
      default:
        return DeliveryStatusEnum.nonBilled;
    }
  };

  const contractStatus = getDeliveryStatus(outstandingQuantity);

  const theme = [DeliveryStatusEnum.billed].includes(contractStatus) ? StatusTagTheme.GREEN : StatusTagTheme.YELLOW;
  return <StatusTag theme={theme}>{contractStatus}</StatusTag>;
};

export default DeliveryStatusTag;
