import React from 'react';
import {StatusTag, StatusTagTheme} from '@components/UI/StatusTag/StatusTag';

interface IApproContractStatusTag {
  statusType?: string;
}

export enum ApproContractStatusEnum {
  SOLD = 'Soldé',
  SENT = 'Expédié',
  PARTIALLY_SENT = 'Expédition partielle',
  BILLED = 'Facturé',
  PARTIALLY_BILLED = 'Facturation partielle',
}

export const ApproContractsStatusTag: React.FC<IApproContractStatusTag> = ({statusType}) => {
  const status = !statusType ? (ApproContractStatusEnum.SOLD as ApproContractStatusEnum) : statusType;

  return <StatusTag theme={StatusTagTheme.GREEN}>{status}</StatusTag>;
};

interface IApproContractsAllStatusTag {
  apiStatus: Array<keyof typeof apiStatusToComponentStatus>;
}

type T = keyof typeof apiStatusToComponentStatus;

const apiStatusToComponentStatus: Record<string, ApproContractStatusEnum> = {
  sold: ApproContractStatusEnum.SOLD,
  'partially-sent': ApproContractStatusEnum.PARTIALLY_SENT,
  sent: ApproContractStatusEnum.SENT,
  'partially-billed': ApproContractStatusEnum.PARTIALLY_BILLED,
  billed: ApproContractStatusEnum.BILLED,
} as const;

export const ApproContractsAllStatusTag: React.FC<IApproContractsAllStatusTag> = ({apiStatus}) => {
  return (
    <>
      {apiStatus.map((s) => (
        <ApproContractsStatusTag key={s} statusType={apiStatusToComponentStatus[s]} />
      ))}
    </>
  );
};
