import React from 'react';
import {ICommentListModel, IContractModel} from '@stores/contracts/models';
import ContractSignTag from '@components/Contracts/ContractSignTag/ContractSignTag';
import ContractStatusTag from '@components/Contracts/ContractStatusTag/ContractStatusTag';
import {getContractStatus} from '@components/Contracts/ContractsList/columns';
import {getContractTypeLabel} from "@utils/contracts/contractType";
import {isUndefined} from '@lepicard/utils';
import {renderDateSignOff} from '@utils/tables';
import {formatDate} from '@utils/string';
import {renameSpecie} from "@utils/species/species";
import useDownloadPdf from '@hooks/useDownloadPdf';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';

interface IContractRow {
  contract: IContractModel;
  comments: ICommentListModel[];
}

const ContractRow: React.FC<IContractRow> = ({contract, comments}) => {
  const contractType = getContractTypeLabel(contract.id, contract.contractType);
  const commentRegex =  new RegExp('fixation|fiixation', 'mi');

  const commentsToShow = comments
    .filter((comment) => comment.contractId === contract.id && commentRegex.test(comment.comment))
    .map((value) => {
      return value.comment.toUpperCase().replace(commentRegex, 'Fixation')
    }).join(', ');

  const downloadPdfLoader = (value: string, type: number, name: string) => {
    download(value, type, name);
  };
  const {isPending: isPending, download: download} = useDownloadPdf();
  const startAndEndDates = `${formatDate(contract.shipStartingDate)} - ${formatDate(contract.shipEndingDate)}`;
  const quantity = `${contract.qty
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} T`;
  const priceHT = `${contract.unitPrice
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`;
  const reliquatQuantity = `${contract.reliquat
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} T`;
  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle
            title={`CONTRAT ${contract.id}`}
            onDownload={() => downloadPdfLoader(contract.id, 0, contract.id)}
          />
          <div className="Card-tags">
            <ContractStatusTag status={getContractStatus(contract)} />
            <ContractSignTag className="hidden lg:flex" signedDate={renderDateSignOff(contract.signOffDate)} />
          </div>
        </CardTitleContainer>
      </CardRow>

      {commentsToShow.length > 0 && (
        <div className="Card-row">
          <span className="Card-infos">
            <span className="Card-info">
              <span className="Card-label">Commentaire :</span>
              <span className="Card-value">{commentsToShow}</span>
            </span>
          </span>
        </div>
      )}

      <CardRow className="ContractContent">
        <CardInfosWrapper>
          <div className="CardBrand Card-info">
            <div className="CardBrand-name">{renameSpecie(contract.brand)}</div>
            {!isUndefined(contractType) && (
              <div>
                <span className="CardBrand-name">{contractType.name}</span>
                <span className="CardBrand-subname">{contractType.subName}</span>
              </div>
            )}
          </div>

          <CardInfo
            label="Début et fin de période de livraison"
            value={startAndEndDates}
            flags={CardInfoFlag.SEPARATE_LINES}
          />
          <CardInfo
            label="Date de paiement"
            value={renderDateSignOff(contract.nextPaymentDate, 'Pas de date de paiement')}
            flags={CardInfoFlag.SEPARATE_LINES}
          />
        </CardInfosWrapper>

        <div className="Card-tags lg:hidden">
          <ContractSignTag signedDate={renderDateSignOff(contract.signOffDate)} />
        </div>
      </CardRow>

      <CardRow type={CardRowType.SECONDARY}>
        <CardInfosWrapper>
          <CardInfo label="Quantité" value={quantity} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
          <CardInfo label="Reliquat" value={reliquatQuantity} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
          <CardInfo label="Prix HT" value={priceHT} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
        </CardInfosWrapper>
      </CardRow>
    </Card>
  );
};

export default ContractRow;
