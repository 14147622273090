import {types} from 'mobx-state-tree';
import {ServiceStateModel} from '@stores/models';
import {FacetChoiceModel} from "@stores/shared/models";

export const ApproContractsContractGroup = types.model({
  no: types.string,
  date: types.string,
  contractStartingDate: types.string,
  contractEndingDate: types.string,
  totalAmountIncludingVAT: types.number,
  deliveredPercentage: types.number,
  products: types.array(
    types.model({
      uniqueId: types.string,
      documentNo: types.string,
      lineNo: types.number,
      familyCode: types.maybeNull(types.string),
      familyName: types.maybeNull(types.string),
      description: types.string,
      quantity: types.number,
      unitOfMeasureCode: types.string,
      netUnitPrice: types.number,
      amountIncludingVAT: types.number,
      restToDeliver: types.number,
      shippedQuantity: types.number,
      deliveredPercentage: types.number,
      type: types.number,
    })
  ),
  status: types.array(types.string),
});

export const ApproContractsProductGroup = types.model({
  familyCode: types.maybeNull(types.string),
  familyName: types.maybeNull(types.string),
  description: types.string,
  unitOfMeasureCode: types.string,
  totalQuantity: types.number,
  totalAmountIncludingVAT: types.number,
  totalShippedQuantity: types.number,
  deliveredPercentage: types.number,
  contracts: types.array(
    types.model({
      uniqueId: types.string,
      lineNo: types.number,
      documentNo: types.string,
      date: types.string,
      contractStartingDate: types.string,
      contractEndingDate: types.string,
      quantity: types.number,
      netUnitPrice: types.number,
      amountIncludingVAT: types.number,
      restToDeliver: types.number,
      deliveredPercentage: types.number,
      type: types.number,
      status: types.array(types.string),
    })
  ),
});

export const ApproContractsFamilyGroup = types.model({
  familyCode: types.string,
  familyName: types.string,
  totalQuantity: types.number,
  totalShippedQuantity: types.number,
  totalAmountIncludingVAT: types.number,
  deliveredPercentage: types.number,
  products: types.array(ApproContractsProductGroup),
});

export const ApproContractsModel = types.model({
  list: types.array(types.union(ApproContractsContractGroup, ApproContractsProductGroup, ApproContractsFamilyGroup)),
  facets: types.maybeNull(
    types.model({
      availableStatus: types.array(FacetChoiceModel),
      availableItemFamilyCode: types.array(FacetChoiceModel),
    })
  ),
  globalFacets: types.maybeNull(
    types.model({
      availableCampaigns: types.array(FacetChoiceModel),
    })
  ),
  fetchContractsGlobalFiltersOptionsState: ServiceStateModel,
  fetchContractsState: ServiceStateModel,
});
