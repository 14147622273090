import React, {useId} from 'react';
import {useField} from 'formik';
import {isUndefined} from '@lepicard/utils';
import {cn} from '@utils/class-names';
import './styles.scss';

export interface INewFieldWrapper {
  name: string;
  label?: string;
  required?: boolean;
  className?: string;
  children: (props: INewFieldWrapperChildrenProps) => React.ReactNode;
  labelType: 'label-floating' | 'label-out-row' | 'label-out-col' | 'no-label';
}

export interface INewFieldWrapperChildrenProps {
  label?: string;
  showError: boolean;
  id: string;
  labelType: string;
}

const FieldWrapper: React.FC<INewFieldWrapper> = ({
  name,
  children,
  label,
  labelType,
  className = '',
  required = false,
}) => {
  const id = useId();

  const [field, meta, helper] = useField(name);

  const childrenProps: INewFieldWrapperChildrenProps = {
    label: !isUndefined(label) && required ? label + ' *' : label,
    showError: meta.touched && !isUndefined(meta.error),
    id,
    labelType,
  };

  return (
    <div className={cn('FieldWrapper w-full', className)}>
      <div className={cn({'flex items-center gap-3': labelType === 'label-out-row'})}>
        {(labelType === 'label-out-row' || labelType === 'label-out-col') && (
          <label htmlFor={id} className="text-paragraph-2 shrink-0">
            {childrenProps.label}
          </label>
        )}
        <div className={cn({grow: labelType === 'label-out-row'})}>{children(childrenProps)}</div>
      </div>
      {childrenProps.showError && <p className="text-red px-3 py-2">{meta.error}</p>}
    </div>
  );
};

export default FieldWrapper;
