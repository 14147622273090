import {types, onSnapshot, flow} from 'mobx-state-tree';
import {IRemoteDurability, postDurability} from '@services/durability';
import {DurabilityStoreModel} from './models';

export const DurabilityStore = types.optional(
  DurabilityStoreModel.actions((self) => {
    return {
      // flow : asynchronous actions
      postDurability: flow(function* (Durability: IRemoteDurability, file: File | null) {
        const serviceState = self.fetchDurability;
        serviceState.pending();
        try {
          yield postDurability(Durability, file);
          serviceState.done();
        } catch (error) {
          serviceState.fail(true, error.message);
        }
      }),
    };
  }),
  {}
);
