import { fetchPages, fetchPageById, IBlogArticle } from '@services/blog'
import { Instance, flow, types } from 'mobx-state-tree'
import { PagesModel, PageModel } from './models'

export interface IPageModel extends Instance<typeof PageModel> {}
export interface IPagesStore extends Instance<typeof PagesModel> {}

const remotePageToStore = (remotePage: IBlogArticle): IPageModel => ({
  ...remotePage,
  title: remotePage.title.raw,
  content: remotePage.content.rendered,
  excerpt: remotePage.excerpt.raw,
  imageUrl: remotePage._embedded['wp:featuredmedia']
    ? remotePage._embedded['wp:featuredmedia'][0].source_url
    : undefined
})

export const PagesStore = types.optional(
  PagesModel.views(self => {
    return {
      getPageById(id: number) {
        return self.list.find(page => page.id === id)
      },

      getNextAndPreviousPages(id: number) {
        const currentIndex = self.list.findIndex(page => page.id === id)

        const nextPage = self.list[currentIndex + 1]
        const previousPage = self.list[currentIndex - 1]

        return {
          next: nextPage ? nextPage.id : null,
          previous: previousPage ? previousPage.id : null
        }
      },

      get twoFirstPages() {
        return self.list.slice(0, 2)
      },

      get allPagesExceptFirstTwo() {
        return self.list.slice(2)
      }
    }
  }).actions(self => {
    return {
      fetchPages: flow(function*(page: number = 1) {
        const serviceState = self.fetchPagesState

        serviceState.pending()

        try {
          const result = yield fetchPages(page)

          const { total, data } = result

          const list = data.map(remotePageToStore)

          self.list = list
          serviceState.setPagination(parseInt(total), 10)

          serviceState.done()
        } catch (error) {
          serviceState.fail()
        }
      }),

      fetchPageById: flow(function*(pageId: number) {
        const serviceState = self.fetchPageByIdState

        serviceState.pending()

        try {
          const result = yield fetchPageById(pageId)

          let page = remotePageToStore(result)

          self.pageById = page

          serviceState.done()
        } catch (error) {
          serviceState.fail()
        }
      })
    }
  }),
  {
    list: []
  }
)
