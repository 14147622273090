import React from 'react';
import ContractsList from '@components/Contracts/ContractsList';
import PageHead from '@components/UI/PageHead/PageHead';

const Contracts: React.FC = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Mes Contrats"
        description="Retrouvez vos contrats facilement en les filtrant par période de récolte, cultures ou variétés de culture"
      />
      <ContractsList />
    </div>
  );
};

export default Contracts;
