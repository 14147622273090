import React, { useMemo, useState, useEffect, Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '@stores/index'
import { ISoldOrdersFilter, fetchSoldOrders } from '@services/navagri'
import FiltersContainer from '@components/Tables/Filter/FiltersContainer'
import ExportButtons from '@components/Buttons/ExportButtons'
import Select from '@components/Fields/Select'
import usePdfGenerator from '@hooks/usePdfGenerator'
import useCsvGenerator from '@hooks/useCsvGenerator'
import Button from '@components/Buttons/Button'
import useModal from '@hooks/useModal'
import Modal from '@components/Modal'
import SoldOrdersLineList from '../SoldOrdersLineList'
import Table from '@components/Tables/Table'
import useList from '@hooks/useList'
import { numberToLocaleString } from '@utils/string'

const columns = [

  {
    id: 'id',
    Header: 'Article',
    accessor: 'libelle'
  },
  {
    id: 'quantityShipped',
    Header: 'Quantité reçue',
    accessor: 'totalShipped',
    Cell: ({ cell }) => {
      return ` ${cell.value ? numberToLocaleString(cell.value) : ''} `
    },
    render: (column, value) => {
      return ` ${value ? numberToLocaleString(value) : ''} `
    }
  },

  {
    id: 'unitOfMesure',
    Header: 'Unité',
    accessor: 'unitOfMesure'
  },
  {
    id: 'netUnitPrice',
    Header: 'Prix unitaire net ',
    accessor: 'totalNetPrice',
    Cell: ({cell}) => {
      return ` ${cell.value ? ` ${numberToLocaleString(cell.value)} €` : ''}  `
    },
    render: (column, value) => {
      return ` ${value  && value > 0 ? `${numberToLocaleString(value)}  €` : ''} `
    }
  },
  {
    id: 'quantityToInvoiced',
    Header: 'Reste à facturer',
    accessor: 'totalRestToBill',
    Cell: ({ cell }) => {
      return ` ${cell.value ? numberToLocaleString(cell.value) : ''} € `
    },
    render: (column, value) => {
      return ` ${value  && value > 0 ? `${numberToLocaleString(value)}  €` : ''} `
    }
  }
]


const pdfColumns = [
  {
    id: 'id',
    Header: 'Libellé',
    accessor: 'description'
  },
  {
    id: 'quantityShipped',
    Header: 'Quantité Reçue',
    accessor: 'quantityShipped',
    render: (column, value) => {
      return value === 0 ? "0" : typeof value === 'number' ? numberToLocaleString(value) : ''
    }
  },
  {
    id: 'unitOfMesure',
    Header: 'Unité',
    accessor: 'unitOfMesure'
  },
  {
    id: 'quantityToInvoiced',
    Header: 'Reste à facturer',
    accessor: 'quantityToInvoiced',
    render: (column, value) => {
      return value === 0 ? "0" : typeof value === 'number' ? numberToLocaleString(value) : ''
    }
  }
]


const SoldOrersList: React.FC = observer(() => {
  const { soldOrders: soldOrdersStore, campaigns: campaingsStore } = useStore()

  const [filters, setFilters] = useState<ISoldOrdersFilter>({ groupBy: 'description' })
  const [selectedItem, setSelectedItem] = useState()
  const [groupedBy, setGroupedBy] = useState('description')
  const [viewFilters, setViewFilters] = useState<ISoldOrdersFilter>()
  const [groupdByLibelle, setGroupByLibelle] = useState('Article')



  const groupedByOptions = [
    {
      label: 'Article',
      value: 'description'
    },
    {
      label: "Famille d'article",
      value: 'speciesCode'
    }
  ]

  const { currentCampaign } = campaingsStore
  const { fetchSoldOrdersGrouByState, soldOrderCampaigns } = soldOrdersStore
  const { active, toggle } = useModal()
  const data = soldOrdersStore.groupList
  const list = data ? data.list.toJS() : []

  const { items: selectedItems, toggleItem, isItemInList, emptyList } = useList('libelle')

  const onFiltersChange = (values: any) => {
    const newFilters: ISoldOrdersFilter = {}
    Object.keys(values).forEach(key => {
      const currentValue = values[key]
      newFilters[key as keyof ISoldOrdersFilter] = currentValue
    })
    newFilters.groupBy = groupedBy
    setFilters(newFilters)
    emptyList()
  }

  const onGroupByChange = (values: any) => {
    const newFilters: ISoldOrdersFilter = {}
    newFilters.groupBy = values.groupBy
    setGroupedBy(values['groupBy'])
    let option = groupedByOptions.find(option => option.value === values.groupBy)
    setGroupByLibelle(option ? option.label : "")
    setFilters({ ...filters, ...newFilters })
  }

  useEffect(() => {
    if (currentCampaign)
      setFilters({ campaignId: currentCampaign.id, groupBy: 'description' })
  }, [])

  const loadData = (page: number) => {
    soldOrdersStore.fetchSoldOrdersByGroup(page, filters)
  }

  const { isPending: isPdfPending, download: downloadPdf } = usePdfGenerator(pdfColumns)
  const { isPending: isCsvPending, download: downloadCsv } = useCsvGenerator(pdfColumns)

  const loadDataToExport = async () => {
    const { data } = await fetchSoldOrders({ ...filters, $limit: 10000 })
    return data.list
  }

  const loadCsvDataToExport = async () => {
    const { groupBy, ...newFilters } = filters
    const { data } = await fetchSoldOrders({ ...newFilters, $limit: 10000 })
    return data
  }



  const onDownloadPdf = () => {
    downloadPdf({ loadDatas: loadDataToExport, title: 'Mes recapitulatifs', subtitle: '', fileName: 'recapitulatif', filters: filters, groupByLibelle: groupdByLibelle })
  }

  const onDownloadCsv = () => {
    downloadCsv({ loadDatas: loadCsvDataToExport, fileName: 'recapitulatif' })
  }

  const renderDetails = (row: any) => {
    const item = row.original
    const mustDisplayDetails = isItemInList(item.libelle)

    let viewFilter = {}
    if (groupedBy == 'description') {
      viewFilter = {
        description: item.libelle
      }
    }

    if (groupedBy == 'speciesCode') {
      viewFilter = {
        speciesCode: item.libelle
      }
    }

    const { groupBy, ...newFilters } = filters
    const formatFilters = { ...newFilters, ...viewFilter }

    return (
      <>
        {mustDisplayDetails && (
          <div className="w-100 p-3">
            <SoldOrdersLineList groupBy={groupedBy} filters={formatFilters} />
          </div>
        )}
      </>
    )
  }

  const tableColumns = useMemo(
    () => [
      {
        id: 'Action',
        Header: '',
        width: 50,
        Cell: ({ cell }: any) => {
          const item = cell.row.original
          const mustDisplayDetails = isItemInList(item.libelle)

          const onDetailsClick = () => {
            toggleItem(item)
          }

          return (
            <div className="">
              <Button
                className="w-8 h-8 p-1 font-semibold text-center flex justify-center items-center details-btn"
                onClick={onDetailsClick}
              >
                {mustDisplayDetails ? '-' : '+'}
              </Button>
            </div>
          )
        }
      },
      {
        id: 'id',
        Header: 'Libellé',
        accessor: 'libelle'
      },
      {
        id: 'quantityShipped',
        Header: 'Quantité Reçue',
        accessor: 'totalShipped',
        Cell: ({ cell }: any) => {
          return `${ numberToLocaleString(cell.value)} ${cell.row.original.unitOfMesure}`
        },
        render: (column, value) => {
          return typeof value === 'number' ? ` ${ numberToLocaleString(value)}` : ''
        }
      },

      {
        id: 'netUnitPrice',
        Header: 'Prix unitaire net ',
        accessor: 'totalNetPrice',
        Cell: ({cell}) => {
          return ` ${cell.value ? numberToLocaleString(cell.value) : ''} € `
        },
        render: (column, value) => {
          return ` ${value ? numberToLocaleString(value) : ''} € `
        }
      },
      {
        id: 'quantityToInvoiced',
        Header: 'Reste à facturer ',
        accessor: 'totalRestToBill',
        Cell: ({ cell }: any) => {
          return `${ numberToLocaleString(cell.value)} T`
        },
        render: (column, value) => {
          return typeof value === 'number' ? ` ${ numberToLocaleString(value)} T` : ''
        }
      }
    ],
    [groupedBy, filters, selectedItems]
  )


  return (
    <>
      { (currentCampaign && soldOrderCampaigns.length > 0) && (
        <Fragment>
          <div className="flex flex-col lg:flex-row flex-shrink-0 flex-wrap w-full">
            <div className="flex items-start flex-wrap lg:flex-nowrap w-full">
              <FiltersContainer initialValues={{ campaignId: currentCampaign.id }} onChange={onFiltersChange}>
                <Select
                  name="campaignId"
                  options={[...soldOrderCampaigns]}
                  label="Exercice"
                  direction="column"
                  className="md:mr-10"
                  labelClassName="label--input--header"
                  inputContainerWidth={120}
                />
              </FiltersContainer>

              <FiltersContainer initialValues={{ groupBy: 'description' }} onChange={onGroupByChange}>
                <Select
                  name="groupBy"
                  options={groupedByOptions}
                  label="Grouper par"
                  direction="column"
                  className="md:mr-10"
                  labelClassName="label--input--header"
                  inputContainerWidth={120}
                />
              </FiltersContainer>

              <div className="flex-1 flex justify-end">
                <ExportButtons
                  pdfLoading={isPdfPending}
                  csvLoading={isCsvPending}
                  onDownloadPdf={onDownloadPdf}
                  onDownloadCsv={onDownloadCsv}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}

      <Table
        columns={tableColumns}
        loadData={loadData}
        data={list}
        isLoading={fetchSoldOrdersGrouByState.isPending}
        totalItemsCount={fetchSoldOrdersGrouByState.total}
        limit={fetchSoldOrdersGrouByState.limit}
        filters={filters}
        renderDetails={renderDetails}
        paginated
      />

      <Modal
        active={active}
        toggle={toggle}
        minWidth="80%"
        className={'ordersModal'}
        footer={
          <Button className="px-24 text-3xl" onClick={toggle}>
            Fermer
          </Button>
        }
      >
        <div className="w-100">{selectedItem && <SoldOrdersLineList groupBy={groupedBy} filters={viewFilters} />}</div>
      </Modal>
    </>
  )
})

export default SoldOrersList
