import './style.scss';
import React from 'react';
import {IQuotationsTable} from '@components/Quotations/QuotationsList/QuotationsListUI';
import {renameSpecie} from '@utils/species/species';
import {isNull, isUndefined} from '@lepicard/utils';

export const QuotationsTableDesktop: React.FC<IQuotationsTable> = ({data, header, className}) => {
  return (
    <>
      <div className={`QuotationsTableDesktop ${className}`}>
        <div className="QuotationsTableDesktop-legend">
          <div className="QuotationsTableDesktop-header">{header}</div>
          <div className="flex flex-col">
            {data.species.map((specie, idx) => (
              <div key={idx} className="w-full h-14 flex items-center gap-2 text-title-4">
                <img src={`/img/SVG/icons/${specie.id}.svg`} className="w-6" alt="" />
                <p>{renameSpecie(specie.name)}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex overflow-x-auto">
          {data.years.map((year, indexYear) => (
            <div className="QuotationsTableDesktop-yearColumn" key={indexYear}>
              <div className="h-20 flex items-center justify-center text-green-100 bg-white text-4xl">{year.name}</div>
              <div className="flex text-green-100 bg-grey-100">
                {year.echeances.map((echeance, indexEcheance) => {
                  const hasChildren = echeance.species.some((e) => !isUndefined(e.amount) && !isNull(e.amount));
                  if (!hasChildren) {
                    return null;
                  }

                  const splittedEcheance = echeance.name.split(' ');

                  if (splittedEcheance.length === 2) {
                    echeance.name = `${splittedEcheance[0]} ${
                      splittedEcheance[1].charAt(0).toUpperCase() + splittedEcheance[1].slice(1)
                    } `;
                  }

                  return (
                    <div className="QuotationsTableDesktop-monthColumn" key={indexEcheance}>
                      <div className="QuotationsTableDesktop-headerCell">{echeance.name}</div>
                      <div className="QuotationsTableDesktop-cellColumn text-paragraph-1">
                        {echeance.species.map((specie, specieIdx) => (
                          <div className="QuotationsTableDesktop-cell text-paragraph-2" key={specieIdx}>
                            {specie.amount && `${specie.amount} €/T`}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
