import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Form, FormikProps} from 'formik';
import Input from '@components/NewFields/Input';
import Alert from '@components/Alert';
import {useStore} from '@stores/index';
import Button from '@components/Buttons/Button';
import CheckBox from '@components/NewFields/CheckBox';
import CguModal from '@components/CGU/CguModal';
import useModal from '@hooks/useModal';
import Select from '@components/NewFields/Select';
import civilitiesOptions from '@constants/civilities.options';
import './styles.scss';
import {isDef, isNull} from '@lepicard/utils';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';

export interface IActivationFields {
  activationCode: string;

  userAccessToken?: string;

  userCivility: 0 | 1 | 2 | null;
  userFirstname: string;
  userLastname: string;
  userEmail: string;
  userPhone: string;

  accountLegalForm: string;
  accountName: string;
  accountPostalCode: string;
  accountCity: string;
  accountEmail: string;
  accountPhone: string;

  cgu: boolean;
}

export const initialValuesActivationField: IActivationFields = {
  activationCode: '',

  userCivility: null,
  userFirstname: '',
  userLastname: '',
  userEmail: '',
  userPhone: '',

  accountLegalForm: '',
  accountName: '',
  accountPostalCode: '',
  accountCity: '',
  accountPhone: '',
  accountEmail: '',

  cgu: false,
};

export const apiErrorFieldMapping: {[key: string]: string} = {
  'data.user.civility': 'userCivility',
  'data.user.firstname': 'userFirstname',
  'data.user.lastname': 'userLastname',
  'data.user.email': 'userEmail',
  'data.user.phoneNumber': 'userPhone',
  'data.customer.legalForm': 'accountLegalForm',
  'data.customer.name': 'accountName',
  'data.customer.postalCode': 'accountPostalCode',
  'data.customer.city': 'accountCity',
  'data.customer.phoneNumber': 'accountPhone',
  'data.customer.email': 'accountEmail',
};

interface IActivationFormProps extends FormikProps<IActivationFields> {}

const ActivationForm: React.FC<IActivationFormProps> = observer(
  ({values, setErrors, setStatus, setFieldError, validateForm, setFieldValue, setFieldTouched, getFieldMeta}) => {
    const globalErrorsContainerRef = useRef(null);
    const {token}: IAuthContext = useContext(AuthContext);
    const [cguAccepted, setCguAccepted] = useState(false);
    const {activation: activationStore, sso: ssoStore} = useStore();
    const {isPending, error} = activationStore.activationState;
    const [apiErrors, setApiErrors] = useState<any>(null);
    const {user: currentUser} = ssoStore;
    const {active: cguModalActive, toggle: toggleCguModal} = useModal();

    const [hasAccountEmailBeenFocused, setHasAccountEmailBeenFocused] = useState(false);
    const [hasAccountPhoneBeenFocused, setHasAccountPhoneBeenFocused] = useState(false);

    useEffect(() => {
      if (isDef(currentUser?.profile?.civility)) {
        setFieldValue('userCivility', currentUser.profile.civility);
      }
      setFieldValue('userCivility', null);

      if (isDef(currentUser?.profile?.givenName)) {
        setFieldValue('userFirstname', currentUser?.profile?.givenName);
      }
      if (isDef(currentUser?.profile?.familyName)) {
        setFieldValue('userLastname', currentUser?.profile?.familyName);
      }
      if (isDef(currentUser?.profile?.email)) {
        setFieldValue('userEmail', currentUser?.profile?.email);
      }
      if (isDef(currentUser?.profile?.phoneNumber)) {
        setFieldValue('userPhone', currentUser?.profile?.phoneNumber);
      }
      if (token != '') {
        setFieldValue('userAccessToken', token);
      }
    }, [currentUser]);

    useEffect(() => {
      if (error) {
        const objectErrors = JSON.parse(error);
        const apiErrors: any = {
          globals: [],
        };

        if (!objectErrors.globals) {
          for (const objectErrorKey in objectErrors) {
            apiErrors[objectErrorKey] = objectErrors[objectErrorKey];
          }
        }

        for (const objectErrorKey in objectErrors.globals) {
          const objectError = objectErrors.globals[objectErrorKey];
          if (objectError.context && objectError.context.property) {
            const fieldName: string = apiErrorFieldMapping[objectError.context.property];
            apiErrors[fieldName] = objectError.message;
          } else {
            apiErrors.globals.push(objectError);
          }
        }

        if (apiErrors.globals.length > 0) {
          setTimeout(() => {
            scrollToErrors();
          }, 100);
        }
        setApiErrors(apiErrors);
      }
    }, [error]);

    const scrollToErrors = () => {
      if (globalErrorsContainerRef.current) {
        // @ts-ignore
        globalErrorsContainerRef.current.scrollIntoView();
      }
    };

    function acceptCguModal() {
      setFieldValue('cgu', true);
      // Close modal after form is updated
      queueMicrotask(toggleCguModal);
    }

    useEffect(() => {
      if (values.cgu && !cguModalActive) {
        setFieldValue('cgu', false);
        toggleCguModal();
      }
    }, [values.cgu]);

    // Handle autocompletion of account email and phone with user & phone fields
    useEffect(() => {
      const accountEmailMeta = getFieldMeta('accountEmail');

      if (isDef(values.userEmail) && !accountEmailMeta.touched && !hasAccountEmailBeenFocused) {
        setFieldValue('accountEmail', values.userEmail);
      }
    }, [values.userEmail]);

    useEffect(() => {
      const accountPhoneMeta = getFieldMeta('accountPhone');

      if (isDef(values.userPhone) && !accountPhoneMeta.touched && !hasAccountPhoneBeenFocused) {
        setFieldValue('accountPhone', values.userPhone);
      }
    }, [values.userPhone]);

    return (
      <Form className={'ActivationForm'} ref={globalErrorsContainerRef}>
        {apiErrors && apiErrors.globals && (
          <>
            <div className="mb-4 ActivationForm-errors">
              {apiErrors.globals.map((error: { message: string; code: string }) => {
                return <Alert className="mb-4" icon="short-warning" key={error.code} text={error.message}/>;
              })}
            </div>
            <div className="line"></div>
          </>
        )}

        <div className="mb-4">
          <Input label="Code d'activation*" name="activationCode" type="text"/>
          {apiErrors && apiErrors.activationCode && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.activationCode}/>
          )}
        </div>

        <div className="line"></div>

        <div className="mb-4">Votre compte personnel :</div>

        <div className="mb-4">
          <Select name="userCivility" options={civilitiesOptions}/>
          {apiErrors && apiErrors.userCivility && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.userCivility}/>
          )}
        </div>
        <div className="mb-4">
          <Input label="Prénom*" name="userFirstname" type="text"/>
          {apiErrors && apiErrors.userFirstname && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.userFirstname}/>
          )}
        </div>
        <div className="mb-4">
          <Input label="Nom*" name="userLastname" type="text"/>
          {apiErrors && apiErrors.userLastname && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.userLastname}/>
          )}
        </div>
        <div className="mb-4">
          <Input label="Adresse e-mail*" name="userEmail" type="email" disabled={!isNull(currentUser)}/>
          {apiErrors && apiErrors.userEmail && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.userEmail}/>
          )}
        </div>
        <div className="mb-4">
          <Input label="Télephone*" name="userPhone" type="tel"/>
          {apiErrors && apiErrors.userPhone && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.userPhone}/>
          )}
        </div>

        <div className="line"></div>

        <div className="mb-4">Récapitulatif de l’exploitation liée au compte :</div>

        <div className="mb-4">
          <Input label="Forme juridique*" name="accountLegalForm" type="text"/>
          {apiErrors && apiErrors.accountLegalForm && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.accountLegalForm}/>
          )}
        </div>
        <div className="mb-4">
          <Input label="Dénomination*" name="accountName" type="text"/>
          {apiErrors && apiErrors.accountName && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.accountName}/>
          )}
        </div>
        <div className="mb-4">
          <Input label="Code postal*" name="accountPostalCode" type="text"/>
          {apiErrors && apiErrors.accountPostalCode && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.accountPostalCode}/>
          )}
        </div>
        <div className="mb-4">
          <Input label="Ville*" name="accountCity" type="text"/>
          {apiErrors && apiErrors.accountCity && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.accountCity}/>
          )}
        </div>
        <div className="mb-4">
          <Input
            label="Adresse e-mail*"
            name="accountEmail"
            type="email"
            onFocus={() => setHasAccountEmailBeenFocused(true)}
          />
          {apiErrors && apiErrors.accountEmail && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.accountEmail}/>
          )}
        </div>
        <div className="mb-4">
          <Input
            label="Téléphone*"
            name="accountPhone"
            type="tel"
            onFocus={() => setHasAccountPhoneBeenFocused(true)}
          />
          {apiErrors && apiErrors.accountPhone && (
            <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.accountPhone}/>
          )}
        </div>

        <div className="line"></div>

        <div className="mb-4">
          <button className="ActivationForm-cguLink" type="button" onClick={toggleCguModal}>
            Lire les conditions générales d'utilisation
          </button>
        </div>

        <div className="mb-4">
          <CheckBox name="cgu" label="J'accepte les conditions générales d’utilisation"/>
          {apiErrors && apiErrors.cgu && <Alert className="mb-8 mt-2" icon="short-warning" text={apiErrors.cgu}/>}
        </div>

        <div className="Login-buttons">
          <Button type="submit" isLoading={isPending}>
            Activer mon compte
          </Button>
        </div>
        <CguModal active={cguModalActive} toggle={toggleCguModal} onAccept={acceptCguModal}/>
      </Form>
    );
  }
);

export default ActivationForm;
