export default class EnvironmentService {

  public static instanceConfig = this.getInstanceConfig();

  public static getEnvironnement() {
    return process.env.INSTANCE_ENVIRONNEMENT;
  }

  public static getReleaseVersion() {
    return process.env.APP_RELEASE?.split('-')?.[0];
  }

  public static getInstance() {
    return process.env.INSTANCE_NAME || process.env.APP_RELEASE?.split('-')?.[1];
  }

  public static getImageInstancePath(path: string): string {
    const prefix = path.startsWith('/')
      ? `/instances/${EnvironmentService.getInstance()}`
      : `instances/${EnvironmentService.getInstance()}/`;
    return `${prefix}${path}?v=${EnvironmentService.getReleaseVersion()}`;
  }

  public static isProduction() {
    return this.getEnvironnement() === 'production';
  }

  public static getInstanceConfig() {
    // @ts-ignore
    return INSTANCE_CONFIG;
  }
}
