import React from 'react';
import './styles.scss';
import Loader from '@components/Loader';

interface IButtonProps {
  children: React.ReactNode;
  buttonClass?: 'primary' | 'secondary' | 'outline';
  className?: string;
  isLoading?: boolean;
  type?: 'button' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  onClick = () => {},
  children,
  buttonClass = 'primary',
  isLoading = false,
  type = 'button',
  disabled = false,
  className = '',
}) => {
  const onButtonClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <button
      onClick={onButtonClick}
      type={type}
      className={`btn btn-${disabled ? 'secondary' : buttonClass} ${className} uppercase`}
    >
      {isLoading && <Loader color="white" height={30} width={30} />}
      {!isLoading && children}
    </button>
  );
};

export default Button;
