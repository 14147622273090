import { types } from 'mobx-state-tree'
import { ServiceStateModel } from '@stores/models'
import { OrderModel } from '@stores/orders/models'
import {CampaignsListModel} from "@stores/campaigns/models";

export const groupListSoldOrderModel = types.model({
    libelle: types.maybeNull(types.string),
    totalQty: types.number,
    totalRestToBill: types.number,
    totalNetPrice: types.number,
  totalShipped: types.number,
    unitOfMesure: types.string,
})

export const GroupListModel = types.model({
    list: types.array(groupListSoldOrderModel),
    totalRestToBillAmount: types.number,
})

export const SoldOrdersStoreModel = types.model({
    fetchSoldOrdersState: ServiceStateModel,
    fetchSoldOrdersGrouByState: ServiceStateModel,
    groupList: types.maybe(GroupListModel),
    campaigns: types.array(CampaignsListModel),
    list: types.array(OrderModel),

})

