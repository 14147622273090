import React from 'react'
import { observer } from 'mobx-react-lite'
import Table from '@components/Tables/Table'
import useService from '@hooks/useService'
import {customRenderDateSignOff, getPaginationParams, renderDateSignOff} from '@utils/tables'
import { fetchSoldOrders } from '@services/navagri'
import { numberToLocaleString } from '@utils/string'


interface IOrdersLinesList {
  filters?: any
  groupBy?: string
}

const SoldOrdersLineList: React.FC<IOrdersLinesList> = observer(({ filters, groupBy }) => {
  const renderLibelle = cell => {
    return cell.description
  }

  const columns = [
    {
      id: 'date',
      Header: 'Date',
      accessor: 'dueDate',
      Cell: customRenderDateSignOff(false, "Pas de date"),
      render: (column, value) => {
        return `${renderDateSignOff(value, "Pas de date")} `
      },
      isDate: true
    },
    {
      id: 'id',
      Header: 'Libellé',
      accessor: 'description',
      Cell: ({ cell }: any) => {
        return <div>{renderLibelle(cell.row.original)}</div>
      }
    },
    {
      id: 'quantityShipped',
      Header: 'Quantité Reçue',
      accessor: 'quantityShipped',
      Cell: ({ cell }: any) => {
        return `${  numberToLocaleString(cell.value)} ${cell.row.original.unitOfMesure}`
      }
    },
    {
      id: 'netUnitPrice',
      Header: 'Prix unitaire net',
      accessor: 'netUnitPrice',
      Cell: ({ cell }: any) => {
        return `${ numberToLocaleString(cell.value)} €`
      },
      render: (column, value) => {
        return typeof value === 'number' ? ` ${ numberToLocaleString(value.toFixed) } €` : ''
      }
    },
    {
      id: 'quantityToInvoiced',
      Header: 'Reste à facturer',
      accessor: 'quantityToInvoiced',
      Cell: ({ cell }: any) => {
        return `${ numberToLocaleString(cell.value)} €`
      },
      render: (column, value) => {
        return typeof value === 'number' ? ` ${ numberToLocaleString(value) } €` : ''
      }
    },
  ]

  const { data: result, service, pending } = useService<any>({ defaultValue: { total: 0, data: [], limit: 10 } })

  const onFetchData = (page: number) => {
    service(() => fetchSoldOrders({ ...getPaginationParams(page), ...filters }))
  }

  const loadData = (page: number) => {
    onFetchData(page)
  }

  return (
    <div>
      <Table
        columns={columns}
        loadData={loadData}
        data={result.data}
        isLoading={pending}
        totalItemsCount={result.total}
        limit={result.limit}
        filters={filters}
        paginated
      />
    </div>
  )
})

export default SoldOrdersLineList
