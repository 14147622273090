import {isNull} from '@lepicard/utils';
import {getLabelByValue} from '@utils/pdf';
import {formatNumber} from "@utils/number";

export const csvColumns = [
  {
    id: 'libelle',
    Header: 'N° Livraison',
    accessor: 'libelle',
  },
  {
    id: 'speciesId',
    Header: `Type d’espèce`,
    accessor: 'species',
  },
  {
    id: 'shipmentDate',
    Header: 'Date de livraison',
    accessor: 'shipmentDate',
    isDate: true,
  },
  {
    id: 'contract',
    Header: 'N° Contrat',
    accessor: 'contractId',

    render: (column: any, value: any) => {
      return value ? value : '-';
    },
  },
  {
    id: 'standardWeight',
    Header: 'Quantité aux normes',
    accessor: 'totalQty',
    render: (column, value) => {
      return typeof value === 'number' ? formatNumber(value).fixed().decimalComma().render() : '';
    },
  },
  {
    id: 'truckRegistration',
    Header: 'Immatriculation livreur',
    accessor: 'truckRegistration',
  },
  {
    id: 'outstandingQuantity',
    Header: 'Statut',
    accessor: 'list',
    render: (column: any, value: {outstandingQuantity: number | undefined}) => {
      return value && value.outstandingQuantity === 0 ? 'FACTURÉE' : 'NON FACTURÉE';
    },
  },
];

export const pdfColumns = [
  {
    id: 'libelle',
    Header: 'N° Livraison',
    accessor: 'libelle',
  },
  {
    id: 'speciesId',
    Header: `Type d’espèce`,
    accessor: 'species',
  },
  {
    id: 'shipmentDate',
    Header: 'Date de livraison',
    accessor: 'shipmentDate',
    isDate: true,
  },
  {
    id: 'contract',
    Header: 'N° Contrat',
    accessor: 'contractId',

    render: (column: any, value: any) => {
      return value ? value : '-';
    },
  },
  {
    id: 'standardWeight',
    Header: 'Quantité aux normes',
    accessor: 'totalQty',
    render: (column, value) => {
      return `${value
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} T`;
    },
  },
  {
    id: 'truckRegistration',
    Header: 'Immatriculation livreur',
    accessor: 'truckRegistration',
  },
  {
    id: 'outstandingQuantity',
    Header: 'Statut',
    accessor: 'list',
    render: (column: any, value: {outstandingQuantity: number | undefined}) => {
      return value && value.outstandingQuantity === 0 ? 'FACTURÉE' : 'NON FACTURÉE';
    },
  },
];

export function getFormatedQuantity(value: any) {
  return value ? ` ${value?.toFixed(2).toString()} T` : `0.00 T`;
}

const getFilterBy = (tag: string) => {
  return !tag
    ? 'all'
    : tag === 'isNotFinished'
    ? 'Contrats non facturés'
    : tag === 'nextInvoice'
    ? 'Règlements à venir'
    : '';
};

export const getFilteredItemOptions = (formValues, collectDeliveriesStore) => {
  const result =
    formValues && formValues.speciesId !== 'all'
      ? collectDeliveriesStore.specieAsOptions.filter((specie) => formValues.speciesId.includes(specie.specie))
      : collectDeliveriesStore.specieAsOptions;
  return [...result];
};

export const headerValues = (formValues, collectDeliveriesStore, contractTypeOptions) => {
  return {
    speciesId: getLabelByValue(formValues?.speciesId, collectDeliveriesStore.subFamilyAsOptions),
    campaignId: isNull(formValues.campaignId) || formValues.campaignId === 'all' ? '' : formValues.campaignId,
    itemId: getLabelByValue(formValues?.itemId, getFilteredItemOptions(formValues, collectDeliveriesStore)),
    filterBy: getFilterBy(formValues.tagsOption) === 'all' ? '' : getFilterBy(formValues.tagsOption),
    contractType: getLabelByValue(formValues.contractType, contractTypeOptions),
    search: formValues.search,
    subValues: {
      totalQty: getFormatedQuantity(collectDeliveriesStore.global?.totalQty),
      totalRestToInvoice: getFormatedQuantity(collectDeliveriesStore.global?.totalRestToInvoice),
      totalPurch: getFormatedQuantity(collectDeliveriesStore.global?.totalPurch),
    },
  };
};

export const getCsvFileName = (formValues, tagsOptions, collectDeliveriesStore) => {
  const itemId =
    formValues.itemId !== 'all'
      ? getLabelByValue(formValues.itemId, getFilteredItemOptions(formValues, collectDeliveriesStore))?.replace(
          /\s+/g,
          '_'
        )
      : null;
  const speciesId =
    formValues.speciesId !== 'all'
      ? getLabelByValue(formValues.speciesId, collectDeliveriesStore.subFamilyAsOptions)
      : null;
  const tagsOption = getLabelByValue(formValues.tagsOption, tagsOptions)?.replace(/\s+/g, '_');

  const name = itemId || speciesId || null;

  const campaignId = formValues.campaignId;

  return [campaignId, name, tagsOption].filter(Boolean).join('_');
};
