import { BillsStoreModel } from './models'
import { types, flow, cast } from 'mobx-state-tree'
import {fetchBills,  IBillsFilter} from "@services/navagri"
import { getPaginationParams, getGroupedByPaginationParams } from '@utils/tables'
import views from "@stores/bills/views";
import {ConcurrentHelper} from "@utils/ConcurrentHelper";


export const BillsStore = types.optional(
    BillsStoreModel.views(views).actions(self => {
        return {
            fetchBills: flow(function* (page: number, filters?: IBillsFilter) {
                const serviceState = self.fetchBillsState
                serviceState.pending()
                try {
                    const {isLastAction} = ConcurrentHelper.setAction('fetchBills');
                    const { data, total, limit } = yield fetchBills({ ...getPaginationParams(page), ...filters })
                    if (!isLastAction()) {
                      return;
                    }
                    self.list = data;
                    serviceState.setPagination(total, limit)
                    serviceState.done()

                } catch (error) {
                    serviceState.fail()
                }
            }),
            fetchBillsByGroup: flow(function* (page: number, filters?: IBillsFilter) {
                const serviceState = self.fetchBillsState
                serviceState.pending()
                try {
                    const {isLastAction} = ConcurrentHelper.setAction('fetchBillsByGroup');
                    const { data, total, limit, campaigns } = yield fetchBills({ ...getGroupedByPaginationParams(page), ...filters })
                    if (!isLastAction()) {
                      return;
                    }
                    self.groupList = data
                    self.campaigns = campaigns
                    serviceState.setPagination(total, limit)
                    serviceState.done()
                } catch (e) {
                  console.log("error", e)
                    serviceState.fail()
                }
            }),
          fetchCustomerCampaigns: flow(function* () {
            const serviceState = self.fetchCampaignsState
            serviceState.pending()
            try {
              const {isLastAction} = ConcurrentHelper.setAction('fetchCustomerCampaigns');
              const { campaigns } = yield fetchBills({  })
              if (!isLastAction()) {
                return;
              }
              self.campaigns = campaigns
              serviceState.done()
            } catch (e) {
              serviceState.fail()
            }
          })
        }
    }),
    {}
)
