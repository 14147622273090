import React from 'react';
import {Progressbar, ProgressbarTheme} from '@components/UI/Progressbar/Progressbar';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {CardInfoGroup} from '@components/UI/Card/CardInfoGroup';
import {CardShowMore} from '@components/UI/Card/CardShowMore';
import {Card} from '@components/UI/Card/Card';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {Accordion} from '@components/UI/Accordion/Accordion';
import {ApproContractsAllStatusTag} from '@components/ApproContracts/ApproContractsStatusTag';
import {AlertNotification, AlertTheme} from '@components/UI/AlertNotification/AlertNotification';
import {IApproContractsRow} from '@components/ApproContracts/ApproContractsRow/ApproContractsRows';
import {formatNumber} from '@utils/number';
import {getShowMoreContractLabel} from '@components/Orders/OrdersRow/OrdersRows';
import {formatDate} from '@utils/string';
import {getRemainingDays, getRemainingDaysLabel} from '@utils/date';

interface IApproContractsSubRow {
  contract: any;
  product: any;
}

const ApproContractsSubRow: React.FC<IApproContractsSubRow> = ({contract, product}) => {
  const startAndEndDates = `${formatDate(contract.contractStartingDate)} - ${formatDate(contract.contractEndingDate)}`;

  const isContractClosed = contract.status.includes('sold');
  const warningRemainingDays = getRemainingDays(contract.contractEndingDate);
  const showWarningRemainingDays = !isContractClosed && warningRemainingDays <= 31 && warningRemainingDays >= 0;

  const restToDeliverLabel = formatNumber(contract.restToDeliver as number)
    .round()
    .spaces()
    .renderWithUnit('/');

  const totalQuantityLabel = formatNumber(contract.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);
  const fullRestToDeliverLabel = `${restToDeliverLabel}\u00A0${totalQuantityLabel}`;

  const quantityLabel = formatNumber(contract.quantity as number)
    .round()
    .spaces()
    .renderWithUnit(product.unitOfMeasureCode);

  const unitPriceLabel = formatNumber(contract.netUnitPrice as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');
  const fullNetUnitPriceLabel = `${unitPriceLabel}\u00A0/\u00A0${product.unitOfMeasureCode}`;
  const amountLabel = formatNumber(contract.amountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardTitle title={`CONTRAT ${contract.documentNo}`} />
        <div className="Card-tags">
          <ApproContractsAllStatusTag apiStatus={contract.status} />
        </div>
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo
          label="Début et fin de période de livraison"
          value={startAndEndDates}
          flags={[CardInfoFlag.DOUBLE_WIDTH]}
        />
        {showWarningRemainingDays && (
          <AlertNotification
            theme={warningRemainingDays <= 15 ? AlertTheme.RED : AlertTheme.YELLOW}
            label={`Fin de contrat : ${getRemainingDaysLabel(contract.contractEndingDate)}`}
            className="mt-2 lg:mt-0"
          />
        )}
      </CardInfosWrapper>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfoGroup>
          <CardInfo label="Quantité" value={quantityLabel} flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY]} />
          <CardInfo label="Prix unitaire HT" value={fullNetUnitPriceLabel} flags={[CardInfoFlag.QUANTITY]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfoGroup>
          <CardInfo label="Montant TTC" value={amountLabel} flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT]} />
        </CardInfoGroup>
        <CardSeparator className="lg:hidden" />
        <CardInfo
          label="Reste à livrer"
          value={fullRestToDeliverLabel}
          flags={[CardInfoFlag.IMPORTANT, CardInfoFlag.QUANTITY, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const ApproContractsRowByProduct: React.FC<IApproContractsRow> = ({data}) => {
  const totalQuantityLabel = formatNumber(data.totalQuantity as number)
    .round()
    .spaces()
    .renderWithUnit(data.unitOfMeasureCode);

  const totalAmountIncludingVAT = formatNumber(data.totalAmountIncludingVAT as number)
    .fixed()
    .spaces()
    .renderWithUnit('€');

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.description} subTitle={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      <CardRow>
        <CardInfosWrapper>
          <CardInfo label="Quantité" value={totalQuantityLabel} flags={[CardInfoFlag.QUANTITY]} />
          <CardInfo label="Montant TTC" value={totalAmountIncludingVAT} flags={[CardInfoFlag.QUANTITY]} />
          <CardSeparator className="lg:hidden" />
          <CardInfo
            label="Pourcentage livré"
            value={<Progressbar percent={data.deliveredPercentage} theme={ProgressbarTheme.SOFT} />}
            flags={[CardInfoFlag.PROGRESSBAR, CardInfoFlag.END_OF_LINE]}
          />
        </CardInfosWrapper>
      </CardRow>
      <Accordion
        foot={({onClick, toggleIcon, isAccordionOpen}: any) => (
          <CardShowMore toggleIcon={toggleIcon} onClick={onClick}>
            {getShowMoreContractLabel(isAccordionOpen, data.contracts.length)}
          </CardShowMore>
        )}
      >
        {data.contracts.map((contract: any) => (
          <ApproContractsSubRow contract={contract} product={data} key={contract.uniqueId} />
        ))}
      </Accordion>
    </Card>
  );
};
