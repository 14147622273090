import React, {useState, useEffect, useMemo, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import Select, {IOption} from '@components/NewFields/Select';
import {IContractFilters, fetchContracts} from '@services/navagri';
import usePdfGenerator from '@hooks/usePdfGenerator';
import useCsvGenerator from '@hooks/useCsvGenerator';
import useModal from '@hooks/useModal';
import Loader from '@components/Loader';
import {pdfColumns, getCsvColumns, headerValues, getCsvFileName} from './columns';
import {Form, Formik, FormikProps} from 'formik';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import {isNull, isUndefined} from '@lepicard/utils';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import ExportButtons from '@components/UI/ExportButtons/ExportButtons';
import Tag from '@components/UI/Tag/Tag';
import TagsContainer from '@components/UI/TagsContainer/TagsContainer';
import Input from '@components/NewFields/Input';
import OptionModal from '@components/UI/OptionModal/OptionModal';
import Button from '@components/UI/Button/Button';
import CheckBox from '@components/NewFields/CheckBox';
import ContractRow from '@components/Contracts/ContractRow/ContractRow';
import Pagination from '@components/Pagination';
import usePagination from '@hooks/usePagination';
import CheckBoxAllSelector from '@components/NewFields/CheckBoxAllSelector';
import Search from '@components/Icons/Search';
import {format} from 'date-fns';
import {FormikHelpers} from 'formik/dist/types';
import {useInitialSessionStorage} from '@utils/useInitialSessionStorage';
import {convertContractTypeOptions} from '@utils/contracts/contractType';

interface FormikValues {
  campaignId: string;
  speciesId: string;
  itemId: string;
  tagsOption: string;
  contractType: string[];
  status: string[];
  search: string;
}

const ContractsList: React.FC = observer(() => {
  const {contracts: contractsStore, campaigns: campaignsStore} = useStore();
  const {currentCampaign} = campaignsStore;

  // We don't render this component until currentCampaign is defined
  const defaultValues: FormikValues = {
    campaignId: contractsStore.getContractCampaigns.some((option) => option.value === currentCampaign!.id)
      ? currentCampaign!.id
      : 'all',
    speciesId: 'all',
    itemId: 'all',
    tagsOption: 'all',
    contractType: [],
    status: [],
    search: '',
  };

  const [sessionFormValues, syncSessionFormValues] = useInitialSessionStorage<FormikValues>('contractsSessionStorage');
  // @ts-ignore
  const updateFormFn = useRef<FormikHelpers<FormikValues>['setFieldValue']>(() => null);

  const INITIAL_VALUES = !isNull(sessionFormValues) ? sessionFormValues : defaultValues;

  const [formValues, syncFormValues] = useState(INITIAL_VALUES);

  useEffectNotOnFirstRender(() => {
    const formValuesEntries = Object.entries(formValues);
    const defaultValuesEntries = Object.entries(defaultValues);

    // Deep equal comparison
    const isFormValuesEqualsDefaultValues =
      formValuesEntries.length === defaultValuesEntries.length &&
      !formValuesEntries.some(([formKey, formValue]) =>
        defaultValuesEntries.some(([defaultKey, defaultValue]) => {
          if (formKey !== defaultKey) {
            return false;
          }
          if (Array.isArray(formValue) && Array.isArray(defaultValue)) {
            return (
              formValue.length !== defaultValue.length ||
              formValue.some((formValueArr) => defaultValue.includes(formValueArr))
            );
          } else {
            return formValue !== defaultValue;
          }
        })
      );
    syncSessionFormValues(isFormValuesEqualsDefaultValues ? null : formValues);
  }, [formValues, currentCampaign, defaultValues]);

  const formFilters = useMemo<IContractFilters>(() => {
    const newFilters: IContractFilters = {};

    if (formValues.campaignId !== 'all') {
      newFilters.campaignId = formValues.campaignId;
    }

    if (formValues.speciesId !== 'all') {
      newFilters.speciesId = formValues.speciesId;
    }

    if (formValues.itemId !== 'all') {
      newFilters.itemId = formValues.itemId;
    }

    if (formValues.status.length > 0) {
      newFilters.status = formValues.status;
    }

    if (formValues.tagsOption !== 'all' && currentCampaign?.id === formValues.campaignId) {
      newFilters.tagsOption = formValues.tagsOption;
    }

    if (formValues.contractType.length > 0) {
      newFilters.contractType = formValues.contractType;
    }

    if (formValues.search !== '') {
      newFilters.search = formValues.search;
    }

    return newFilters;
  }, [formValues]);

  const onDownloadPdf = () => {
    downloadPdf({
      loadDatas: loadDataToExport,
      title: 'COLLECTE - MES CONTRATS',
      subtitle: 'contrat',
      fileName: `collecte_contrats_${format(new Date(), `dd/MM/yyyy`)}`,
      pdfType: 'Collecte',
      headerValue: headerValues(formValues, contractTypeOptions, contractsStore, filteredSpeciesOptions),
    });
  };

  const onDownloadCsv = () => {
    downloadCsv({
      loadDatas: loadDataToExport,
      fileName: `Export_Collecte_Contrats_${getCsvFileName(
        formValues,
        tagsOptions,
        filteredSpeciesOptions,
        contractsStore
      )}`,
      headerValue: headerValues(formValues, contractTypeOptions, contractsStore, filteredSpeciesOptions),
    });
  };

  const {isPending: isPdfPending, download: downloadPdf} = usePdfGenerator(pdfColumns());
  const {isPending: isCsvPending, download: downloadCsv} = useCsvGenerator(getCsvColumns());

  const {active, toggle} = useModal();

  const loadDataToExport = async () => {
    const {data} = await fetchContracts({...formFilters, canceled: 0, $limit: Number.MAX_SAFE_INTEGER});
    return data;
  };

  const loadData = (page: number) => {
    contractsStore.fetchContracts(page, {...formFilters, canceled: 0});
  };

  // When campaignId changes
  useEffect(() => {
    updateFormFn.current('itemId', 'all');
    updateFormFn.current('speciesId', 'all');
    contractsStore.fetchSpeciesAndCampaignsContracts({campaignId: formFilters.campaignId});
  }, [formValues.campaignId]);

  // When speciesId changes
  useEffect(() => {
    if (formValues.itemId === 'all') {
      return;
    }

    if (formValues.speciesId === 'all') {
      updateFormFn.current('itemId', 'all');
      return;
    }

    const isSpeciesLinkedToItem = contractsStore.subFamilyAsOptions.some(
      (specie: {value: any}) => specie.value === formValues.itemId.slice(0, 2)
    );
    if (isSpeciesLinkedToItem) {
      return;
    }

    updateFormFn.current('itemId', 'all');
  }, [formValues.speciesId]);

  // When itemId changes
  useEffect(() => {
    const foundSpecies = contractsStore.subFamilyAsOptions.find(
      (specie: {value: any}) => specie.value === formValues.itemId.slice(0, 2)
    );
    if (foundSpecies && formValues.speciesId !== foundSpecies.value) {
      updateFormFn.current('speciesId', foundSpecies.value);
    }
  }, [formValues.itemId]);

  useEffect(() => {
    if (!isUndefined(currentCampaign?.id)) {
      loadData(1);
    }
  }, [currentCampaign]);

  useEffectNotOnFirstRender(() => {
    onPageChange();
  }, [formFilters]);

  const data = contractsStore.list.toJS();
  const {pageNumber, onPageChange} = usePagination(loadData);

  //
  // Select options

  const isFilterSelected = (): boolean => {
    return ((formFilters && formFilters?.speciesId) ||
      formFilters?.itemId ||
      formFilters?.contractType ||
      (formFilters?.status && formFilters?.status.length > 0)) as boolean;
  };

  const contractTypesComments = contractsStore.comments?.toJS() ?? [];
  const contractTypeOptions = convertContractTypeOptions(contractsStore.facets?.contractTypes.toJS() ?? []);

  const statusOptions = [
    {value: 'solde', label: 'Soldé'},
    {value: 'en cours', label: 'En cours'},
    {value: 'non solde', label: 'Non soldé'},
  ];

  const filteredSpeciesOptions = useMemo<IOption[]>(() => {
    const filteredResults =
      formValues.speciesId !== 'all'
        ? contractsStore.specieAsOptions.filter((specie) => formValues.speciesId.includes(specie.specie))
        : contractsStore.specieAsOptions;

    return filteredResults.map((i) => ({label: i.label ?? undefined, value: i.value}));
  }, [formValues, contractsStore.specieAsOptions]);

  const tagsOptions = [
    {label: 'Contrats non soldés', value: 'isNotFinished'},
    {label: 'Règlements à venir', value: 'nextInvoice'},
  ];

  if (isUndefined(currentCampaign?.id)) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => console.log('submit', values)}
          children={({values, setFieldValue}: FormikProps<any>) => {
            useEffectNotOnFirstRender(() => {
              syncFormValues(values);
              updateFormFn.current = setFieldValue;
            }, [values]);

            if (contractsStore.fetchContractCampaignsState.state === 'pending') {
              return <Loader />;
            }

            return (
              <Form className="w-full mb-5">
                <FiltersCard className="grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4 mb-7">
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="campaign" className="mr-1 lg:mr-2.5">
                        Campagne
                      </label>
                      <QuickHelp>Période du 1er juillet N au 30 juin N+1</QuickHelp>
                    </div>
                    <Select
                      inputId="campaign"
                      name="campaignId"
                      options={[{label: 'Toutes', value: 'all'}, ...contractsStore.getContractCampaigns]}
                    />
                  </div>
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="species" className="mr-1 lg:mr-2.5">
                        Culture
                      </label>
                      <QuickHelp>Choisissez parmi la liste pour afficher les résultats par culture</QuickHelp>
                    </div>
                    <Select
                      inputId="species"
                      name="speciesId"
                      options={[
                        {
                          label: 'Toutes',
                          value: 'all',
                        },
                        ...contractsStore.subFamilyAsOptions,
                      ]}
                      placeholder="Tous"
                    />
                  </div>
                  <div className="flex-grow flex gap-3">
                    <div className="flex items-center min-w-[110px] lg:min-w-min">
                      <label htmlFor="item" className="mr-1 lg:mr-2.5">
                        Variété
                      </label>
                      <QuickHelp>Choisissez parmi la liste pour afficher les résultats par variété</QuickHelp>
                    </div>
                    <Select
                      inputId="item"
                      name="itemId"
                      options={[{value: 'all', label: 'Toutes'}, ...filteredSpeciesOptions]}
                    />
                  </div>
                </FiltersCard>
                <div className="mb-4">
                  <div className="flex justify-between gap-5">
                    <button className="flex items-center lg:hidden text-interactif" onClick={toggle} type="button">
                      <img src="/icons/filters.svg" alt="" className="me-1.5" />
                      Filtres
                    </button>
                    <Input
                      name="search"
                      placeholder="Rechercher"
                      type="text"
                      className={'h-10 hidden lg:block max-w-xl'}
                      iconAfter={<Search />}
                    />
                    <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />
                  </div>
                  <Input
                    name="search"
                    placeholder="Rechercher"
                    type="text"
                    className="lg:hidden my-4"
                    iconAfter={<Search />}
                  />
                </div>
                <div className="flex justify-between gap-4 ">
                  <div className="!hidden lg:flex gap-8 grow flex-shrink-[9999]">
                    <Select
                      label="Type de contrat"
                      name="contractType"
                      multiple={true}
                      placeholder="Tous"
                      options={contractTypeOptions}
                      selectAllOption="Tous"
                    />
                    {/*<Select
                      label="Statut"
                      name="status"
                      multiple={true}
                      options={statusOptions}
                      selectAllOption="Tous"
                    />*/}
                  </div>
                  <TagsContainer>
                    {currentCampaign!.id === values.campaignId &&
                      tagsOptions.map((tag) => (
                        <Tag
                          key={tag.value}
                          label={tag.label}
                          onClick={() =>
                            setFieldValue('tagsOption', values.tagsOption === tag.value ? 'all' : tag.value)
                          }
                          active={values.tagsOption === tag.value}
                        />
                      ))}
                  </TagsContainer>
                </div>

                <OptionModal
                  active={active}
                  title="Filtres"
                  toggle={toggle}
                  actions={<Button label="Fermer" onClick={toggle} className="w-full" />}
                >
                  {/*<h4 className="text-title-4 text-green-100 mb-3.5">Statut :</h4>
                  <CheckBoxAllSelector name="status" options={statusOptions} label="Tous" className={'mb-7'} />
                  {statusOptions.map(({value, label}, idx) => {
                    return <CheckBox key={value} name="status" label={label} value={value} />;
                  })}

                  <hr className="hr-m" />*/}
                  <h4 className="text-title-4 text-green-100 mb-3.5">Type de contrat :</h4>
                  <CheckBoxAllSelector
                    name="contractType"
                    options={contractTypeOptions}
                    label="Tous"
                    className={'mb-7'}
                  />
                  {contractTypeOptions.map(({value, label}, idx) => (
                    <CheckBox key={idx} name="contractType" label={label} value={value} />
                  ))}
                </OptionModal>
              </Form>
            );
          }}
        />
      </div>

      {!contractsStore.fetchContractCampaignsState.isDone ? (
        <Loader />
      ) : (
        <div>
          <div className="flex flex-col gap-3.5 lg:gap-5">
            {Array.isArray(data) && data.length > 0 ? (
              data.map((c, idx) => <ContractRow key={idx} contract={c} comments={contractTypesComments} />)
            ) : (
              <div className="Card min-h-[150px] text-center justify-center">
                <div className="Card-row text-title-2 text-green-100">
                  {isFilterSelected()
                    ? `Aucune donnée ne correspond aux filtres sélectionnés. Essayez de les modifier pour trouver ce que vous cherchez.`
                    : `Aucune donnée n'a été trouvée pour la campagne ${currentCampaign?.id} en cours.`}
                </div>
              </div>
            )}
          </div>

          <Pagination
            activePage={pageNumber}
            totalItemsCount={contractsStore.fetchContractsState.total ?? 0}
            onChange={onPageChange}
          />
        </div>
      )}
    </>
  );
});

const ContractListContainer = observer(() => {
  const {campaigns: campaignsStore, contracts: contractsStore} = useStore();

  const areCampaignsOptionsLoaded = ['done', 'error'].includes(contractsStore.fetchCampaignsState.state);

  useEffect(() => {
    if (!areCampaignsOptionsLoaded) {
      contractsStore.fetchCampaigns();
    }
  }, []);

  if (isUndefined(campaignsStore.currentCampaign?.id) || !areCampaignsOptionsLoaded) {
    return <Loader />;
  }

  return <ContractsList />;
});

export default ContractListContainer;
