import { fetchCotations, ICotationFilters } from '@services/cotations'
import { CotationStoreModel } from './models'
import { types, flow, cast } from 'mobx-state-tree'

export const CotationStore = types.optional(
  CotationStoreModel.actions(self => {
    return {
      fetchCotations: flow(function*(filters: ICotationFilters = {}) {
        const serviceState = self.fetchCotationsState

        serviceState.pending()

        try {
          const { cotations, species, zones, campaignIds, qualities } = yield fetchCotations(filters)

          console.log({ cotations })

          self.cotations = cotations
          self.zones = zones
          self.campaigns = campaignIds.map((item: any) => item.toString())
          // self.qualities = qualities

          self.cereales = cast(
            Object.keys(species).map(key => ({
              label: species[key].label as string,
              value: key as string
            }))
          )

          serviceState.done()
        } catch (error) {
          console.log('FAIL COTATION', error)
          serviceState.fail()
        }
      })
    }
  }),
  {}
)
