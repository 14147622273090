import {toast} from 'react-toastify';

export const defaultErrorToast = (message: string, code = '') => {
  const codePart = code !== '' ? '\nCode : ' + code : '';
  toast.error(message + codePart, {
    autoClose: 2000,
    hideProgressBar: true,
    toastId: message, // prevent duplicates
  });
};

export const defaultSuccesToast = (message: string) => {
  const toastId = message;

  toast.success(message, {
    autoClose: 2000,
    hideProgressBar: true,
    toastId: toastId, // prevent duplicates
  });
};
