import {fileToBase64} from '@utils/files';
import fetch from '@utils/fetch';

export interface IRemoteDurability {
  managerName: string;
  pacageNumber: string;
  authorId: string;
  authorPhone: string;
  authorEmail: string;
  authorFirstName: string;
  authorLastName: string;
  city: string;
  contactId: string;
  contactName: string;
  bleArea: number;
  colzaArea: number;
  bleDurability: number;
  bleDurabilityRate: string;
  colzaDurability: number;
  colzaDurabilityRate: string;
  cultureLength: number;
  newAreas: boolean;
  statementType: number;
  registrationNum: string;
  vatRegistrationNum: string;
  campaignId: string;
  area: number;
}

const endPoint = `${process.env.API_URL}/extranet`;

const endPoints = {
  askUpdate: `${endPoint}/durabilite/ask-update`,
};

export const postDurability = async (durability: IRemoteDurability, file: File | null) => {
  let body: any = {...durability};

  if (file) {
    const fileName = file.name;
    const fileContent = await fileToBase64(file);

    body = {...body, fileName, file: fileContent};
  }
  console.log('body', body);
  await fetch(endPoints.askUpdate, 'post', body);
};
