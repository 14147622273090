import {getGroupedByPaginationParams, getPaginationParams} from '@utils/tables'
import { fetchOrders, IOrdersFilters } from '@services/navagri'
import { IOrderStoreModel } from './models'
import { flow } from 'mobx-state-tree'
import {ConcurrentHelper} from "@utils/ConcurrentHelper";

export default (self: IOrderStoreModel) => {
    return {
        fetchOrders: flow(function* (page: number, filters?: IOrdersFilters) {
            const serviceState = self.fetchOrdersState
            serviceState.pending()

            try {
                const {isLastAction} = ConcurrentHelper.setAction('fetchOrders');
                const { data, total, limit } = yield fetchOrders({ ...getPaginationParams(page), ...filters })
                if (!isLastAction()) {
                  return;
                }

                console.log("data", data)
                self.list = data
                serviceState.setPagination(total, limit)
                serviceState.done()
            } catch (e) {
                console.log("e:::::", e)
                serviceState.fail()
            }
        }),
        fetchOrdersByGroup: flow(function* (page: number, filters?: IOrdersFilters) {
            const serviceState = self.fetchOrdersState
            serviceState.pending()

            try {
                const {isLastAction} = ConcurrentHelper.setAction('fetchOrdersByGroup');
                const { data, total, limit, campaigns } = yield fetchOrders({ ...getGroupedByPaginationParams(page), ...filters })
                if (!isLastAction()) {
                  return;
                }

                self.groupList = data
                self.campaigns = campaigns

               serviceState.setPagination(total, limit)
                serviceState.done()
            } catch (e) {
                console.log("e:::::", e)

                serviceState.fail()
            }
        })
    }
}
