import React from 'react';
import {isUndefined} from '@lepicard/utils';

interface IContractSignTag {
  signedDate?: string;
  className?: string;
}

const ContractSignTag: React.FC<IContractSignTag> = ({signedDate, className}) => {
  const isSigned = !isUndefined(signedDate);
  const isSignatureFeatureActive = false;

  const label = isSigned
    ? `Contrat signé le ${signedDate}`
    : isSignatureFeatureActive
    ? 'Signer le contrat'
    : 'Contrat non signé';

  return (
    <div
      className={`StatusTag isLower ${className} text-green-100 border border-grey-400 bg-white
       ${!isSigned && isSignatureFeatureActive ? 'cursor-pointer' : 'cursor-default'}`}
    >
      {(isSigned || isSignatureFeatureActive) && (
        <img className='StatusTag-icon' src={`/icons/${isSigned ? 'signed-contract' : 'sign-contract'}.svg`} alt="" />
      )}
      <span>{label}</span>
    </div>
  );
};

export default ContractSignTag;
