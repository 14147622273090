import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Switch, Route, useLocation, Redirect} from 'react-router-dom';
import NotFound from './components/NotFound';
import Login from './containers/Login';
import NavContainer from '@components/Layout/NavContainer';
import {observer} from 'mobx-react-lite';
import {useStore} from './stores';
import Profile from '@containers/Profile';
import LoginContainer from '@components/Layout/LoginContainer';
import routes, {collectRoutes, servicesRoutes, approRoutes} from '@routes';
import Home from '@containers/Home';
import Quotations from '@containers/Quotations';
import Adivalor from '@containers/Adivalor';
import CollectOffers from '@containers/CollectOffers';
import Activation from '@containers/Activation';
import TestExport from '@containers/TestExport';
import Contracts from '@containers/Contracts';
import CollectOptions from '@containers/CollectOptions';
import Bordereaux from '@containers/Bordereaux';
import Deliveries from '@containers/Deliveries';
import Accounting from '@containers/Accounting';
import CmsPage from '@containers/CmsPage';

import ServiceOffers from '@containers/ServiceOffers';
import ServiceFds from '@containers/ServiceFds';

import ApproOffers from '@containers/ApproOffers';
import ApproContracts from '@containers/ApproContracts';
import ApproOrders from '@containers/ApproOrders';
import Durability from '@containers/Durability';
import DurabilitySuccess from '@containers/Durability/durabilitySuccess';

import {ApproInvoices} from '@containers/ApproInvoices';
import ApproSoldOrders from '@containers/SoldOrders';
import ApproDeliveries from '@containers/ApproDeliveries';

import Representative from '@containers/Representative';

import Cgv from '@components/Cgv';
import LegalesNotice from '@components/legales-notices';

import ScrollToTop from '@utils/scrollToTop';
import BulletinsSanteDuVegetal from '@containers/BulletinsSanteDuVegetal';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';
import {isDef} from '@lepicard/utils';
import Loader from '@components/Loader';
import EnvironmentService from '@utils/environmentService';
import Logout from '@containers/Logout';
import {CollectInvoices} from "@containers/CollectInvoices";

const instanceConfig = EnvironmentService.getInstanceConfig();

const Router: React.FC = observer(() => {
  const {hydrated} = useStore();

  const location = useLocation();
  // get user infos
  const {campaigns: campaignsStore} = useStore();
  const {tokenData, token, login, logOut, idToken, error, loginInProgress}: IAuthContext = useContext(AuthContext);
  const {sso: SSOStore} = useStore();

  useEffect(() => {
    if (token !== '' && SSOStore.sessionExpired) {
      SSOStore.setExpired(false);
      SSOStore.retrieveAdditionalSSOInformation(token);
    }
  }, [location, token, SSOStore.sessionExpired]);

  useEffect(() => {
    if (SSOStore.additionalInformationsState.hasFailed) {
      logOut();
    }
  }, [SSOStore.additionalInformationsState.hasFailed, logOut]);

  // END get user infos

  useEffect(() => {
    if (campaignsStore.list.length === 0 && isDef(SSOStore.user) && token !== '') {
      campaignsStore.fetchCampaigns();
    }
  }, [location, SSOStore.user]);

  if (loginInProgress || !hydrated || SSOStore.additionalInformationsState.isPending) {
    return <Loader className="fullScreenLoader" />;
  }

  const subRoute404 = <Route path="*" component={NotFound} />;

  return (
    <>
      {
        <Switch>
          <Route exact path={routes.ACTIVATION}>
            <LoginContainer className={'isFullPage'}>
              <Activation />
            </LoginContainer>
          </Route>

          <Route exact path={routes.LOGOUT}>
            <LoginContainer>
              <Logout />
            </LoginContainer>
          </Route>

          {/* Login */}
          <Route exact path={routes.LOGIN}>
            <LoginContainer>
              <Login />
            </LoginContainer>
            {token !== '' && !isDef(SSOStore.user) && <Redirect to="/" />}
          </Route>

          {location.pathname !== '/login' && token === '' && !isDef(SSOStore.user) && <Redirect to="/login" />}

          {/* Home */}
          <NavContainer>
            <>
              <ScrollToTop />
              <Switch>
                {/* Pages de test */}
                <Route path={'/test-export'}>
                  <TestExport />
                </Route>

                <Route path={`${routes.PAGE}/:pageId`}>
                  <CmsPage />
                </Route>

                <Route path={routes.CGV}>
                  <Cgv />
                </Route>

                <Route path={routes.LEGALES_NOTICE}>
                  <LegalesNotice />
                </Route>

                <Route path={routes.HOME} exact>
                  <Home />
                </Route>

                <Route path={routes.PROFILE}>
                  <Profile />
                </Route>

                <Route path={routes.REPRESENTATIVES}>
                  <Representative />
                </Route>

                {instanceConfig.durabilite.enablePage && (
                  <Route path={routes.DURABILITY}>
                    <Durability />
                  </Route>
                )}

                <Route path={routes.DURABILITYSUCCESS}>
                  <DurabilitySuccess />
                </Route>

                <Route path={routes.QUOTATIONS}>
                  <Quotations />
                </Route>

              <Route path={routes.CONTRACTS}>
                <Contracts />
              </Route>

                <Route path={routes.DELIVERIES}>
                  <Deliveries />
                </Route>

                <Route path={routes.ACCOUNTING}>
                  <Accounting />
                </Route>

              <Route path={routes.COLLECT}>
                <Switch>
                  <Route path={collectRoutes.INVOICES}>
                    <CollectInvoices />
                  </Route>

                  <Route path={collectRoutes.OFFERS}>
                    <CollectOffers />
                  </Route>

                    <Route path={collectRoutes.OPTIONS}>
                      <CollectOptions />
                    </Route>
                    {subRoute404}
                  </Switch>
                </Route>

                {/* <Route path={routes.CEREAL_PRICES}>
                <Cereals />
              </Route> */}

                <Route path={routes.APPRO}>
                  <Switch>
                    {instanceConfig.approContrats.enablePage && (
                      <Route path={approRoutes.APPRO_CONTRACTS}>
                        <ApproContracts />
                      </Route>
                    )}
                    <Route path={approRoutes.APPRO_OFFERS}>
                      <ApproOffers />
                    </Route>
                    <Route path={approRoutes.APPRO_ORDERS}>
                      <ApproOrders />
                    </Route>
                    <Route path={approRoutes.APPRO_INVOICES}>
                      <ApproInvoices />
                    </Route>
                    <Route path={approRoutes.APPRO_SOLD_ORDERS}>
                      <ApproSoldOrders />
                    </Route>
                    <Route path={approRoutes.APPRO_DELIVERIES}>
                      <ApproDeliveries />
                    </Route>
                    {subRoute404}
                  </Switch>
                </Route>

                <Route path={routes.SERVICES}>
                  <Switch>
                    {instanceConfig.bsv.enablePage && (
                      <Route path={servicesRoutes.BSV}>
                        <BulletinsSanteDuVegetal />
                      </Route>
                    )}
                    <Route path={servicesRoutes.ADIVALOR}>
                      <Adivalor />
                    </Route>
                    <Route path={servicesRoutes.OFFERS}>
                      <ServiceOffers />
                    </Route>
                    {instanceConfig.fds.enablePage && (
                      <Route path={servicesRoutes.FDS}>
                        <ServiceFds />
                      </Route>
                    )}
                    {subRoute404}
                  </Switch>
                </Route>

                {subRoute404}
              </Switch>
            </>
          </NavContainer>
        </Switch>
      }
    </>
  );
});

export default Router;
