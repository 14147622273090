export const getStatusText = (statusValue: any) => {

    let res = ''
    switch (statusValue) {
        case 0:
            res = "Non soldé"
            break;
        case 100:
            res = "Soldé"
            break;
        default:
          res = "En cours"
    }
    return res;
}


export const getExpeditionStatus = (statusValue: any) => {

  let res = ''
  switch (statusValue) {
    case 0:
      res = "Non réalisé"
      break;
    case 100:
      res = "Expédié"
      break;
    default:
      res = "En cours"
  }
  return res;
}
