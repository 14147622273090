import React, { Fragment } from 'react'
import FieldWrapper, { IFieldWrapper } from '@components/Layout/FieldWrapper'
import TextInput from '@components/Inputs/TextInput'
import './styles.scss'
import PasswordInput from '@components/Inputs/PasswordInput'

interface IInputProps extends IFieldWrapper {
  placeholder?: string
  type?: string
  icon?: string
  min?: string | number
  max?: string | number
  maxlength?: number
  inputClassName?: string
  disabled?: boolean
  step?: number
}

const Input: React.FC<IInputProps> = ({
  icon,
  type,
  placeholder,
  min,
  max,
  step,
  maxlength,
  disabled,
  inputClassName,
  ...props
}) => {
  return (
    <FieldWrapper {...props}>
      {(field, meta, helper) => {
        const setValue = (value: string) => {
          helper.setValue(props.name === "username" ? value.trim() : value)
        }
        const setTouched = helper.setTouched

        return (
          <Fragment>
            {type === 'password' ? (
              <PasswordInput
                {...field}
                setTouched={setTouched}
                className={inputClassName}
                placeholder={placeholder}
                type={type}
                icon={icon}
                setValue={setValue}
                min={min}
                max={max}
              />
            ) : (
              <TextInput
                {...field}
                // @ts-ignore
                setTouched={setTouched}
                className={inputClassName}
                placeholder={placeholder}
                type={type}
                icon={icon}
                setValue={setValue}
                min={min}
                max={max}
                step={step}
              />
            )}
          </Fragment>
        )
      }}
    </FieldWrapper>
  )
}

export default Input
