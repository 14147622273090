import React from 'react';
import {cn} from '@utils/class-names';
import {isDef, isString, isUndefined} from '@lepicard/utils';

export enum CardInfoFlag {
  PROGRESSBAR = 'hasProgressbar',
  IMPORTANT = 'isImportant',
  END_OF_LINE = 'forceEndOfLine',
  DOUBLE_WIDTH = 'isDoubleWidth',
  QUANTITY = 'isQuantity',
  SEPARATE_LINES = 'onSeparateLines',
  FILL_LINE = 'fillLine',
  RETURN = 'isReturn',
}

interface ICardInfo {
  label: React.ReactNode;
  value: React.ReactNode;
  flags?: CardInfoFlag | CardInfoFlag[];
  className?: string;
}

export const CardInfo: React.FC<ICardInfo> = ({label, value, flags, className}) => {
  if (!isDef(value)) {
    value = '-';
  }

  if (isUndefined(flags)) {
    flags = [];
  } else if (isString(flags)) {
    flags = [flags];
  }

  let defaultValueBlock: React.ReactNode = <span className="Card-value">{value}</span>;

  if (flags.includes(CardInfoFlag.PROGRESSBAR)) {
    defaultValueBlock = value;
  }

  return (
    <div className={cn('Card-info', flags, className)}>
      <span className="Card-label">{label}&nbsp;:</span>
      {defaultValueBlock}
    </div>
  );
};

export const CardInfoReversed: React.FC<ICardInfo> = ({label, value, flags}) => {
  if (!isDef(value)) {
    value = '-';
  }

  if (isUndefined(flags)) {
    flags = [];
  } else if (isString(flags)) {
    flags = [flags];
  }

  let defaultValueBlock: React.ReactNode = <span className="Card-value">{value}</span>;

  if (flags.includes(CardInfoFlag.PROGRESSBAR)) {
    defaultValueBlock = value;
  }

  return (
    <div className={cn('Card-info', flags)}>
      <span className="Card-label-reversed">{label}&nbsp;-</span>
      {defaultValueBlock}
    </div>
  );
};
