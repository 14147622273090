import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores/index';
import {useFormikContext} from 'formik';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import FiltersCard from '@components/UI/FiltersCard/FiltersCard';
import QuickHelp from '@components/UI/QuickHelp/QuickHelp';
import Select from '@components/NewFields/Select';
import TagsContainer from '@components/UI/TagsContainer/TagsContainer';
import Tag from '@components/UI/Tag/Tag';
import {useWindowSize} from '@hooks/useWindowSize';
import useModal from '@hooks/useModal';
import {FiltersButton} from '@components/UI/FiltersButton/FiltersButton';
import ExportButtons from '@components/UI/ExportButtons/ExportButtons';
import Input from '@components/NewFields/Input';
import Search from '@components/Icons/Search';
import OptionModal from '@components/UI/OptionModal/OptionModal';
import Button from '@components/UI/Button/Button';
import CheckBoxAllSelector from '@components/NewFields/CheckBoxAllSelector';
import CheckBox from '@components/NewFields/CheckBox';
import {ApproDeliveriesListFormContext} from '@components/ApproDeliveries/ApproDeliveriesList';

export const groupByOption = [
  {
    label: 'par livraisons',
    value: 'delivery',
  },
  {
    label: 'par articles',
    value: 'product',
  },
  {
    label: "par famille d'articles",
    value: 'family',
  },
];
export const tagsOptions = [{label: 'Retours', value: 'item-returned'}];

export const ApproDeliveriesListFilters: React.FC = observer(() => {
  const {refreshFormValues} = useContext(ApproDeliveriesListFormContext);
  const {approDeliveriesStore} = useStore();
  const {values, setFieldValue} = useFormikContext();

  useEffectNotOnFirstRender(() => {
    refreshFormValues(values);
  }, [values]);

  return (
    <div className="w-full mb-5">
      <FiltersCard className="grid grid-cols-1 lg:grid-cols-3 gap-x-7 gap-y-4 py-5 px-3.5 lg:py-6 lg:px-4 mb-7">
        <div className="flex-grow flex gap-3">
          <div className="flex items-center min-w-[110px] lg:min-w-min">
            <label htmlFor="campaignId" className="mr-1 lg:mr-2.5">
              Exercice
            </label>
            <QuickHelp>Période du 1er juillet au 30 juin</QuickHelp>
          </div>
          <Select
            name="campaignId"
            inputId="campaignId"
            options={[{label: 'Tous', value: 'all'}, ...approDeliveriesStore.getCampaignsOptions]}
          />
        </div>
        <div className="flex-grow flex gap-3">
          <div className="flex items-center min-w-[110px] lg:min-w-min">
            <label htmlFor="groupBy" className="mr-1 lg:mr-2.5">
              Grouper
            </label>
          </div>
          <Select name="groupBy" inputId="groupBy" options={groupByOption} placeholder="Tous" />
        </div>
      </FiltersCard>
      <ApproDeliveriesListAdvancedFilters />
    </div>
  );
});

const ApproDeliveriesListAdvancedFiltersTags: React.FC = () => {
  const {setFieldValue, values} = useFormikContext<any>(); // TODO: add type

  return (
    <TagsContainer>
      {tagsOptions.map((tag) => (
        <Tag
          key={tag.value}
          label={tag.label}
          onClick={() => setFieldValue('tagsOption', values.tagsOption === tag.value ? 'all' : tag.value)}
          active={values.tagsOption === tag.value}
        />
      ))}
    </TagsContainer>
  );
};

const ApproDeliveriesListAdvancedFilters: React.FC = () => {
  const {windowWidth} = useWindowSize();

  const Component =
    windowWidth > 1024 ? ApproDeliveriesListAdvancedFiltersDesktop : ApproDeliveriesListAdvancedFiltersMobile;
  return (
    <div className="mb-4">
      <Component />
    </div>
  );
};

const ApproDeliveriesListAdvancedFiltersMobile: React.FC = observer(() => {
  const {approDeliveriesStore} = useStore();
  const {onDownloadPdf, onDownloadCsv, formValues} = useContext(ApproDeliveriesListFormContext);
  const {active, toggle} = useModal();

  return (
    <>
      <div className="flex justify-between gap-5">
        <FiltersButton onClick={toggle} />
        {formValues.groupBy === 'delivery' && <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />}
      </div>
      <Input
        name="search"
        placeholder="Rechercher par document ou article"
        type="text"
        className="my-4"
        iconAfter={<Search />}
      />

      <ApproDeliveriesListAdvancedFiltersTags />

      <OptionModal
        active={active}
        title="Filtres"
        toggle={toggle}
        actions={<Button label="Fermer" onClick={toggle} className="w-full" />}
      >
        <h4 className="text-title-4 text-green-100 mb-3.5">Statut :</h4>
        <CheckBoxAllSelector
          name="status"
          options={approDeliveriesStore.getStatusOptions}
          label="Tous"
          className={'mb-7'}
        />
        {approDeliveriesStore.getStatusOptions.map(({value, label}) => (
          <CheckBox key={value} name="status" label={label} value={value} />
        ))}

        <hr className="hr-m" />
        <h4 className="text-title-4 text-green-100 mb-3.5">Famille d'articles :</h4>
        <CheckBoxAllSelector
          name="itemFamilyCode"
          options={approDeliveriesStore.getItemFamilyCodeOptions}
          label="Tous"
          className={'mb-7'}
        />
        {approDeliveriesStore.getItemFamilyCodeOptions.map(({value, label}, idx) => (
          <CheckBox key={idx} name="itemFamilyCode" label={label} value={value} />
        ))}
      </OptionModal>
    </>
  );
});

const ApproDeliveriesListAdvancedFiltersDesktop: React.FC = observer(() => {
  const {approDeliveriesStore} = useStore();
  const {onDownloadPdf, onDownloadCsv, formValues} = useContext(ApproDeliveriesListFormContext);

  return (
    <>
      <div className="flex justify-between gap-5 mb-4">
        <Input
          name="search"
          placeholder="Rechercher par document ou article"
          type="text"
          className="h-10 hidden lg:block max-w-xl"
          iconAfter={<Search />}
        />
        {formValues.groupBy === 'delivery' && <ExportButtons onPdfClick={onDownloadPdf} onCsvClick={onDownloadCsv} />}
      </div>
      <div className="flex justify-between gap-4 ">
        <div className="flex gap-2 grow flex-shrink-[9999]">
          <Select
            label="Statut"
            name="status"
            multiple={true}
            placeholder="Tous"
            options={approDeliveriesStore.getStatusOptions}
            selectAllOption="Tous"
          />
          <Select
            label="Famille d'articles"
            name="itemFamilyCode"
            multiple={true}
            placeholder="Tous"
            options={approDeliveriesStore.getItemFamilyCodeOptions}
            selectAllOption="Tous"
          />
        </div>
        <ApproDeliveriesListAdvancedFiltersTags />
      </div>
    </>
  );
});
