import {ThemeConfig} from 'tailwindcss/types/config';

export const sharedTailwindThemeColors = {
  sharedOrange: '#EE9C3C',
  sharedYellow: '#FFCC69',
  sharedGreen: {
    '000': '#0B3526',
    100: '#0F4C36',
    200: '#2E6652',
    300: '#318869',
    500: '#5D8476',
  },
  sharedGrey: {
    '000': '#F7F8FB',
    100: '#EEF1F3',
    200: '#EEF8F4',
    300: '#D2E1E0',
    350: '#BFE1DF',
    400: '#B7C6C5',
    450: '#96b0a8',
    500: '#8BA59C',
    600: '#769B8E',
  },
} satisfies ThemeConfig['colors'];
