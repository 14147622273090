import { IAccount, IPreselectAccount, IContact } from '@services/navicar'
import { IAccountStoreModel, IUpdateAccountInfos } from './store'

export default (self: IAccountStoreModel) => {
  return {
    selectAccount: (newAccount: IAccount) => {
      // We add the account to the list of accounts (it won't if it already in)
      const accountIndex = self.list.findIndex(account => account.id === newAccount.id)

      const account = accountIndex !== -1 ? self.list[accountIndex] : null
      if (account === null) {
        self.list.push(newAccount)
      }

      self.preAccount = null

      // We select the new account
      self.selectedAccount = newAccount.id as any // any Because MST reference Typing sucks
    },

    preSelectAccount: (newAccount: any) => {
      self.preAccount = newAccount
    },

    updateSelectedAccountInformations: ({ firstName, lastName, phone, mobile }: IUpdateAccountInfos) => {
      if (self.selectedAccount) {
        self.selectedAccount.firstName = firstName
        self.selectedAccount.lastName = lastName
        self.selectedAccount.contact.phone = phone
        self.selectedAccount.contact.mobile = mobile
      }
    },

    updateSelectedAccountEmail: (email: string) => {
      if (self.selectedAccount) {
        self.selectedAccount.email = email
      }
    },
    updateSelectedAccountLogin: (login: string) => {
      if (self.selectedAccount) {
        self.selectedAccount.login = login
      }
    },

    updateSelectedAccountContact: (contact: IContact) => {
      if (self.selectedAccount) {
        self.selectedAccount.contact = contact
      }
    },

    clearSelectedAccount: () => {
      self.selectedAccount = null
    }
  }
}
