import {types} from 'mobx-state-tree';

export const SalesPersonPurchaser = types.model({
  name: types.string,
  email: types.string,
  phone: types.string,
});

export const ContactSalesPeople = types.model({
  SalesPersonPurchaser: types.maybeNull(SalesPersonPurchaser),
  prodActivityCode: types.string,
  id: types.string,
});

export const Location = types.model({
  name: types.string,
  city: types.string,
  phone: types.string,
  contact: types.string,
  postCode: types.string,
  email: types.string,
  address: types.string,
});

export const Customer = types.model({
  location: types.maybeNull(Location),
});

export const Method = types.model({
  description: types.string,
});

export const ContactModel = types.model({
  id: types.string,
  email: types.string,
  mobile: types.string,
  phone: types.string,
  name: types.string,
  lastName: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  certifOwnerContactNum: types.maybeNull(types.string),
  certiphytoNum: types.maybeNull(types.string),
  certiphytoValidityDate: types.string,
  address: types.string,
  zipCode: types.string,
  city: types.string,
  registrationNum: types.string,
  vatRegistrationNum: types.string,
  legalForm: types.string,
  ContactSalesPeople: types.maybeNull(types.array(ContactSalesPeople)),
  customer: types.maybe(Customer),
  purchasePaymentMethod: types.maybeNull(types.maybe(Method)),
  salesPaymentMethod: types.maybeNull(types.maybe(Method)),
});

export const AccountModel = types.model({
  id: types.identifier,
  firstName: types.string,
  lastName: types.string,
  email: types.string,
  contact: ContactModel,
  login: types.maybeNull(types.string),
  roles: types.array(types.string),
});

export const PreAccountModel = types.model({
  firstName: types.string,
  lastName: types.string,
  email: types.string,
});

export const AccountStoreModel = types.model('Accounts', {
  list: types.array(AccountModel),

  preAccount: types.maybeNull(PreAccountModel),

  selectedAccount: types.maybeNull(types.reference(AccountModel)),
});
