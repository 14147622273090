import React from 'react';
import {useStore} from '@stores/index';
import {isUndefined} from '@lepicard/utils';
import {ApproInvoicesRowByDelivery} from '@components/ApproInvoices/ApproInvoicesRows/ApproInvoicesRowsByInvoice';
import {CardList} from '@components/UI/Card/CardList';
import {ApproInvoicesRowByProduct} from '@components/ApproInvoices/ApproInvoicesRows/ApproInvoicesRowByProduct';
import {ApproInvoicesRowByFamily} from '@components/ApproInvoices/ApproInvoicesRows/ApproInvoicesRowByFamily';

export interface IApproInvoicesRow {
  data: any;
}

export const ApproInvoicesRows: React.FC = () => {
  const {approInvoicesStore} = useStore();
  const list = approInvoicesStore.list.toJS();

  return (
    <CardList>
      {list.map((data: any) => {
        if (!isUndefined(data.no)) {
          return <ApproInvoicesRowByDelivery key={data.no} data={data} />;
        }
        if (!isUndefined(data.description)) {
          return <ApproInvoicesRowByProduct key={data.description} data={data} />;
        }
        if (!isUndefined(data.familyCode)) {
          return <ApproInvoicesRowByFamily key={data.familyCode} data={data} />;
        }
        return null;
      })}
    </CardList>
  );
};
