import React from 'react';
import PageHead from '@components/UI/PageHead/PageHead';
import AdivalorList from '@components/Adivalor/AdivalorList';

interface IAdivalor {}

const Adivalor: React.FC<IAdivalor> = () => {
  return (
    <div className="w-full flex-1">
      <PageHead
        title="Centres adivalor"
        description="Agriculteurs, Distributeurs, Industriels pour la Valorisation des Déchets Agricoles"
      />
      <AdivalorList />
    </div>
  );
};

export default Adivalor;
