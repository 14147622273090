import {types} from 'mobx-state-tree';
import {ServiceStateModel} from '@stores/models';

// TODO: Make global
export const FacetChoiceModel = types.model({
  value: types.string,
  label: types.string,
});

// ----

export const ApproDeliveriesDeliveryGroup = types.model({
  no: types.string,
  linkedOrder: types.string,
  linkedContracts: types.array(types.string),
  shipmentDate: types.maybeNull(types.string),
  letter: types.maybeNull(types.string), // Can be an empty string ''
  products: types.array(
    types.model({
      uniqueId: types.string,
      documentNo: types.string,
      lineNo: types.number,
      familyCode: types.string,
      familyName: types.string,
      description: types.string,
      quantity: types.number,
      unitOfMeasureCode: types.string,
      type: types.number,
    })
  ),
  returnedProducts: types.array(
    types.model({
      uniqueId: types.string,
      description: types.string,
      familyName: types.string,
    })
  ),
});

export const ApproDeliveriesProductGroup = types.model({
  description: types.string,
  familyCode: types.string,
  familyName: types.string,
  deliveries: types.array(
    types.model({
      no: types.string,
      linkedOrder: types.string,
      linkedContract: types.string,
      shipmentDate: types.maybeNull(types.string),
      quantity: types.number,
      unitOfMeasureCode: types.string,
      letter: types.maybeNull(types.string), // Can be an empty string ''
    })
  ),
  returnDeliveries: types.array(
    types.model({
      uniqueId: types.string,
      rv: types.string,
      cmdId: types.string,
      description: types.string,
      familyName: types.string,
      linkedContract: types.string,
      returnDate: types.string,
      quantity: types.number,
      unitOfMeasureCode: types.string,
    })
  ),
});

export const ApproDeliveriesFamilyGroup = types.model({
  familyCode: types.string,
  familyName: types.string,
  products: types.array(ApproDeliveriesProductGroup),
});

export const ApproDeliveriesModel = types.model({
  list: types.array(types.union(ApproDeliveriesDeliveryGroup, ApproDeliveriesProductGroup, ApproDeliveriesFamilyGroup)),
  facets: types.maybeNull(
    types.model({
      availableStatus: types.array(FacetChoiceModel),
      availableItemFamilyCode: types.array(FacetChoiceModel),
    })
  ),
  globalFacets: types.maybeNull(
    types.model({
      availableCampaigns: types.array(FacetChoiceModel),
    })
  ),
  fetchDeliveriesGlobalFiltersOptionsState: ServiceStateModel,
  fetchDeliveriesState: ServiceStateModel,
});
