import React, {useMemo} from 'react';
import {CardInfo, CardInfoFlag} from '@components/UI/Card/CardInfo';
import {CardRow, CardRowType} from '@components/UI/Card/CardRow';
import {CardSubTitle} from '@components/UI/Card/CardSubTitle';
import {CardInfosWrapper} from '@components/UI/Card/CardInfosWrapper';
import {Card} from '@components/UI/Card/Card';
import {CardTitleContainer} from '@components/UI/Card/CardTitleContainer';
import {CardTitle} from '@components/UI/Card/CardTitle';
import {formatDate} from '@utils/string';
import OrdersStatusTag, {OrdersStatusEnum} from '@components/Orders/OrdersStatusTag';
import {CardRowCountWrapper, CardRowCountWrapperItem} from '@components/UI/Card/CardRowCountWrapper';
import {getShowMoreDeliveriesLabel} from '@components/UI/Card/CardShowMore';
import {formatNumber} from '@utils/number';
import {isDef} from '@lepicard/utils';
import {CardSeparator} from '@components/UI/Card/CardSeparator';
import {IApproDeliveriesRow} from '@components/ApproDeliveries/ApproDeliveriesRows/ApproDeliveriesRows';

interface IOrdersSubRow {
  delivery: any;
}

const SubRow: React.FC<IOrdersSubRow> = ({delivery}) => {
  const quantityLabel = formatNumber(delivery.quantity as number)
    .round()
    .renderWithUnit(delivery.unitOfMeasureCode);

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardSubTitle title={`LIVRAISON ${delivery.no}`} />
        {isDef(delivery.letter) && (
          <div className="Card-tags">
            <OrdersStatusTag status={OrdersStatusEnum.BILLED} />
          </div>
        )}
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande lié" value={delivery.linkedOrder} />
        {isDef(delivery.linkedContract) && <CardInfo label="Contrat lié" value={delivery.linkedContract} />}
        <CardInfo label="Date de livraison" value={formatDate(delivery.shipmentDate)} />
        <CardInfo
          label="Quantité"
          value={quantityLabel}
          flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.END_OF_LINE]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

interface IOrdersSubRowReturn {
  singleReturn: any;
}

const SubRowReturn: React.FC<IOrdersSubRowReturn> = ({singleReturn}) => {
  const quantityLabel = formatNumber(singleReturn.quantity as number)
    .round()
    .negate()
    .renderWithUnit(singleReturn.unitOfMeasureCode);

  return (
    <CardRow type={CardRowType.SUB}>
      <CardTitleContainer>
        <CardSubTitle title={`RETOUR ${singleReturn.rv}`} />
        <div className="Card-tags">
          <OrdersStatusTag status={OrdersStatusEnum.RETURNED} />
        </div>
      </CardTitleContainer>
      <CardSeparator />
      <CardInfosWrapper>
        <CardInfo label="Commande lié" value={singleReturn.cmdId} flags={CardInfoFlag.RETURN} />
        {isDef(singleReturn.linkedContract) && (
          <CardInfo label="Contrat lié" value={singleReturn.linkedContract} flags={CardInfoFlag.RETURN} />
        )}
        <CardInfo label="Date de retour" value={formatDate(singleReturn.returnDate)} flags={CardInfoFlag.RETURN} />
        <CardInfo
          label="Quantité"
          value={quantityLabel}
          flags={[CardInfoFlag.QUANTITY, CardInfoFlag.IMPORTANT, CardInfoFlag.END_OF_LINE, CardInfoFlag.RETURN]}
        />
      </CardInfosWrapper>
    </CardRow>
  );
};

export const ApproDeliveriesRowByProduct: React.FC<IApproDeliveriesRow> = ({data}: any) => {
  //const {isPending: isPdfDownloadPending, download: downloadPdf} = useDownloadPdf();
  function downloadDocument() {
    //downloadPdf(data.no, 5, data.id); // TODO
  }

  const allSubRows = useMemo<CardRowCountWrapperItem[]>(
    () => [
      ...data.deliveries.map((d: any) => ({
        params: {delivery: d},
        key: d.description,
        render: SubRow,
      })),
      ...data.returnDeliveries.map((r: any) => ({
        params: {singleReturn: r},
        key: r.uniqueId,
        render: SubRowReturn,
      })),
    ],
    [data.products, data.returnedProducts]
  );

  return (
    <Card>
      <CardRow>
        <CardTitleContainer>
          <CardTitle title={data.description} subTitle={data.familyName} />
        </CardTitleContainer>
      </CardRow>
      <CardRowCountWrapper items={allSubRows} numberOfItemsInPreview={0} showMoreLabelFn={getShowMoreDeliveriesLabel} />
    </Card>
  );
};
