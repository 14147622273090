import React, {Fragment, useEffect} from 'react';
import {useTable, useResizeColumns, useBlockLayout, useSortBy} from 'react-table';
/* import Pagination from 'react-js-pagination'
import usePagination from '@hooks/usePagination' */
import Loader from '@components/Loader';
import usePagination from '@hooks/usePagination';
import './styles.scss';
import Pagination from '../Pagination';
import useEffectNotOnFirstRender from '@hooks/useEffectNotOnFirstRender';
import usePrevious from '@hooks/usePrevious';
import {getNoDataMessage, isFilterSelected} from '@utils/tables';
import NoData from '@components/NoData/NoData';

interface ITableProps {
  // Structure of the columns for react-table
  columns: any;
  // Data to be displayed in the table
  data: any;
  // Triggers everytime a page of the table is rendered providing the selected page number
  loadData?: (pageNumber: number) => void;
  // Displays a spinner instead of table data's
  isLoading?: boolean;
  cardHeaderComponent?: React.ReactNode;
  // ClassName prop of the container
  className?: string;
  loaderClassName?: string;
  filters?: any;
  currentCampaign: string;
  renderDetails?: (element: any) => React.ReactNode;
  customTableLine?: (element: any) => React.ReactNode;
}

export interface ITableNotPaginatedProps extends ITableProps {
  paginated?: false;
  totalItemsCount?: never;
  limit?: never;
}

export interface ITablePaginatedProps extends ITableProps {
  paginated: true;
  totalItemsCount: number;
  limit?: number;
}

const Table: React.FC<ITableNotPaginatedProps | ITablePaginatedProps> = ({
  columns,
  data = [],
  loadData,
  className = '',
  paginated = false,
  totalItemsCount,
  isLoading = false,
  limit = 10,
  cardHeaderComponent,
  loaderClassName = '',
  filters,
  renderDetails,
  customTableLine,
  currentCampaign,
}) => {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
    },
    useResizeColumns,
    useBlockLayout,
    useSortBy
  );

  const {pageNumber, onPageChange} = usePagination(loadData);

  // load the initial page on mount
  useEffect(() => {
    loadData(pageNumber);
  }, []);

  useEffectNotOnFirstRender(() => {
    onPageChange();
  }, [filters]);

  const prevFilters = usePrevious(filters);

  useEffect(() => {
    if (prevFilters) {
      onPageChange();
    }
  }, [filters]);

  return (
    <>
      {((Array.isArray(data) && data.length === 0) || !Array.isArray(data)) && !isLoading ? (
        <NoData message={getNoDataMessage(filters, currentCampaign)} className="mt-5" />
      ) : (
        <div className="card mt-5">
          {cardHeaderComponent && (
            <div className="card-rowHeader table-cardHeader p-30 p-30Mobile">{cardHeaderComponent}</div>
          )}
          <div className="card-row">
            <div className="card-rowBody p-30">
              <div {...getTableProps()} className={`tableContainer ${className}`}>
                <div className="table-header hidden md:inline">
                  {headerGroups.map((headerGroup: any, idx: number) => (
                    <div key={idx} {...headerGroup.getHeaderGroupProps()} className="headerRow">
                      {headerGroup.headers.map((column: any, index: number) => (
                        <div
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={`headerCell ${index > 0 ? 'last' : ''}`}
                          title={undefined}
                          key={index}
                        >
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="sort-indicator">
                                <i className="icon-short-arrow-bottom"></i>
                              </span>
                            ) : (
                              <span className="sort-indicator upside-down">
                                <i className="icon-short-arrow-bottom"></i>
                              </span>
                            )
                          ) : (
                            ''
                          )}
                          {index < headerGroup.headers.length - 1 && (
                            <div {...column.getResizerProps()} className={`resizer`} />
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                {!isLoading && (
                  <div {...getTableBodyProps()}>
                    {rows.map((row: any, i: number) => {
                      prepareRow(row);
                      const isOdd = i % 2;
                      return (
                        <Fragment key={i}>
                          <div {...row.getRowProps()} className="bodyRow flex-col md:flex-row ">
                            {row.cells.map((cell: any, index: number) => {
                              // TO DO: gérer le responsive
                              const header = cell.column.Header;

                              return (
                                <div
                                  key={index}
                                  {...cell.getCellProps()}
                                  className={` ${customTableLine ? 'border-transparent' : ' '}  ${
                                    isOdd ? 'bg-gray-100' : ''
                                  } flex flex-alignCenter   cell ${index > 0 ? 'notFirstCell' : ''} ${
                                    isOdd ? 'bg-gray-100' : ''
                                  }
                                                          lastRow
                                                                `}
                                >
                                  <div className={`font-bold flex  flex-justifyCenter w-1/2 md:hidden`}>{header}</div>

                                  <div>{cell.render('Cell')}</div>
                                </div>
                              );
                            })}
                          </div>
                          {customTableLine && (
                            <div
                              className={`analysis-container border-solid border-bottom  border-gray-300 p-10 ${
                                isOdd ? 'bg-gray-100' : ''
                              } `}
                            >
                              {customTableLine(row)}
                            </div>
                          )}

                          {renderDetails && renderDetails(row)}
                        </Fragment>
                      );
                    })}
                    {!rows.length && !isLoading && <div className="text-center p-30">Aucune donnée</div>}
                  </div>
                )}
                {isLoading && (
                  <div>
                    <Loader className={loaderClassName} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!isLoading && paginated && totalItemsCount !== undefined && (
        <Pagination
          activePage={pageNumber}
          itemsCountPerPage={limit}
          totalItemsCount={totalItemsCount}
          onChange={onPageChange}
        />
      )}
    </>
  );
};

export default Table;
